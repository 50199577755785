<br>
<br>
<br>
<br>
<div class="qr-scan-area">
  <!-- This is the npm package in-charge of scanning the QR -->
  <zxing-scanner
    #scanner
    [(device)]="currentDevice"
    (scanSuccess)="onCodeResult($event)"
    (permissionResponse)="onHasPermission($event)"
  ></zxing-scanner>
  <div class="qr-area">
    <div class="area"></div>
  </div>
</div>

<!-- Displays message on the screen if guest is valid or not -->
<div class="guest">
  <ng-container *ngIf="userExist === true">
    <div class="alert alert-success">
      <button type="button" class="close" data-dismiss="alert">&times;</button>
      <strong>Success!</strong> User has been found!
      <p>
        Username: {{ userDetails[0].name }} box has been check into our collection center.
      </p>
    </div>
  </ng-container>
  <ng-container #notFound *ngIf="userExist === false">
    <div class="alert alert-warning">
      <button type="button" class="close" data-dismiss="alert">&times;</button>
      <strong>Warning!</strong> This person is not a User!
    </div>
  </ng-container>
  <ng-container #notFound *ngIf="userExist === 1">
    <div class="alert alert-warning">
      <button type="button" class="close" data-dismiss="alert">&times;</button>
      <strong>Warning!</strong> This person box has already been collected
    </div>
  </ng-container>
</div>

<button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal">Open Modal</button>

<!-- The Modal -->
<div class="modal" id="myModal"  #content>
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title" id="modalHeading"></h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <p id="addressModalBody"></p>
        <p id="gcModalBody"></p>
        <p id="cdModalBody"></p>
        <p id="rdModalBody"></p>
        <p><strong>Box Issued Dates: </strong></p>  
        <select name="Box Issued Dates" id="BID" class="browser-default custom-select custom-select-lg mb-3">
          <option *ngFor="let obj of boxIssuedDates">{{obj | date:'MM/dd/yyyy HH:mm:ss Z'}}</option>
        </select>
        <p><strong>Box Donated Dates: </strong></p>  
        <select name="Box Donated Dates" id="BDD" class="browser-default custom-select custom-select-lg mb-3">
          <option *ngFor="let obj of boxDonatedDates">{{obj | date:'MM/dd/yyyy HH:mm:ss Z'}}</option>
        </select>
      </div>

       <!-- Modal footer -->
       <div class="modal-footer">
        <div *ngIf= "boolean == true">
          <button type="button" class="btn btn-warning" (click)="returnB()">Issue New Box</button>
        </div>
        <div *ngIf= "boolean == false">
          <button type="button" class="btn btn-success" (click)="collectB()">Collect Box</button>
        </div>
        <button type="button" class="btn btn-danger" data-dismiss="modal" id="closeModel">Close</button>
      </div>
    </div>
  </div>
</div>
  