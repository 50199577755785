<header class="w3-container w3-top bg-dark w3-xlarge w3-padding-8" style="text-align: center">
    <span> <a routerLink="/home" id="websiteTitle"><img src="./assets/imgs/companyLogo.png" width="85" height="85" style="margin-bottom: 12px;" >Otolith Enrichment</a></span>
    <button class="w3-right w3-button w3-border w3-hover-white w3-large text-light" id="navTop" (click)="w3_open()">☰</button>
  </header>

<nav class="w3-sidebar w3-bar-block w3-white w3-animate-right w3-top w3-text-black w3-large" style="z-index:3;width:340px;font-weight:bold;display:none;right:0;" id="mySidebar">
    <div class="w3-container w3-display-container w3-padding-16">
    <i (click)="w3_close()" class="fa fa-remove w3-button w3-display-topright"></i>
      <h3 class="w3-wide"><b>ECO²BALANCE</b></h3>
    </div>
      <div class="w3-padding-16 w3-large w3-text-grey" style="font-weight:bold">
        <a routerLink="/home" (click)="w3_close()" class="w3-button w3-block w3-white w3-left-align"><i class='fa fa-home'>   Home</i></a>
        <a routerLink="/aboutPage" (click)="w3_close()" class="w3-button w3-block w3-white w3-left-align"><i class='fa fa-info-circle'>   About Us</i></a>
        <a routerLink="/news" (click)="w3_close()" class="w3-button w3-block w3-white w3-left-align"><i class='fa fa-newspaper-o'>   News</i></a>
        <a (click)="myAccFunc()" class="w3-button w3-block w3-white w3-left-align" >
          <i class ='fa fa-shopping-bag'>  Our Eco²Balance Shop</i> <i class="fa fa-caret-down"></i>
        </a>
        <div id="demoAcc" class="w3-bar-block w3-hide w3-padding-large w3-medium">
          <a  class="w3-button w3-block w3-white w3-left-align" [routerLink]="['/products','All']" (click)="w3_close()">All Products</a>
          <div class="dropdown-divider"></div>
          <div *ngFor="let i of productCatergoryName">
            <a class="w3-button w3-block w3-white w3-left-align" [routerLink]="['/products', i]" (click)="w3_close()">{{ i }}</a>
            <div class="dropdown-divider"></div>
          </div>
        </div>
      
        <a href="/programs" class="w3-button w3-block w3-white w3-left-align" onclick="w3_close()">
          <i class="fa fa-users"> Our Program & Workshops</i>
        </a>
        <a routerLink="/projects" (click)="w3_close()" class="w3-button w3-block w3-white w3-left-align"><i class='fa fa-book'>  Our Sustainability Projects</i></a>
        <a routerLink="/contact" (click)="w3_close()" class="w3-button w3-block w3-white w3-left-align"><i class="fa fa-phone">  Contact Us</i></a>
        
        <a (click)="myAccFunc2()"  class="w3-button w3-block w3-white w3-left-align"  *ngIf="service.isStaff()">
          <i class="fa fa-male">  Staff Functions</i> <i class="fa fa-caret-down"></i>
        </a>
        <div id="demoAcc2" class="w3-bar-block w3-hide w3-padding-large w3-medium" *ngIf="service.isStaff()">
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/Scanner" *ngIf="service.isStaff()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Food Waste QR Scanner</a>
          <div *ngIf="service.isStaff()" class="dropdown-divider"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/volunteerSignIn" *ngIf="service.isStaff()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Volunteer Clock In/Out</a>
          <div *ngIf="service.isAdmin()" class="dropdown-divider"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/userList" *ngIf="service.isAdmin()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">List of Residents</a>
          <div *ngIf="service.isStaff()" class="dropdown-divider"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/orderList" *ngIf="service.isStaff()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">List of Orders</a>
          <div *ngIf="service.isStaff()" class="dropdown-divider"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/programList" *ngIf="service.isStaff()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Program & Workshops List</a>
          <div *ngIf="service.isStaff()" class="dropdown-divider"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/staffProjectList" *ngIf="service.isStaff()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Project List</a>
        </div>
  
        <a (click)="myAccFunc3()" class="w3-button w3-block w3-white w3-left-align"  *ngIf="service.isAdmin()">
          <i class="fa fa-user-secret">  Admin Functions</i> <i class="fa fa-caret-down"></i>
        </a>
        <div id="demoAcc3" class="w3-bar-block w3-hide w3-padding-large w3-medium" *ngIf="service.isAdmin()">
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/volunteerEntriesLog" *ngIf="service.isAdmin()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Volunteer Entries Log</a>
          <div class="dropdown-divider" *ngIf="service.isAdmin()"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/statisticPage" *ngIf="service.isAdmin()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Website Statistics</a>
          <div class="dropdown-divider" *ngIf="service.isAdmin()"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/admin" *ngIf="service.isAdmin()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Admin Users List</a>
          <div class="dropdown-divider" *ngIf="service.isAdmin()"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/adminSellerList" *ngIf="service.isAdmin()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Admin Seller List</a>
        </div>
  
  
        <a (click)="myAccFunc4()"  class="w3-button w3-block w3-white w3-left-align"  *ngIf="service.isSupplier()">
          <i class="fa fa-suitcase">  Supplier Functions</i> <i class="fa fa-caret-down"></i>
        </a>
        <div id="demoAcc4" class="w3-bar-block w3-hide w3-padding-large w3-medium" *ngIf="service.isSupplier()">
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/supplierProductListComponent" *ngIf="service.isSupplier()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">My Products & Programs List</a>
          <div class="dropdown-divider" *ngIf="service.isSupplier()"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/supplierOrderListComponent" *ngIf="service.isSupplier()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">My Orders List</a>
        </div>

        <a (click)="w3_close()" class="w3-button w3-block w3-white w3-left-align" routerLink="/wishlist" *ngIf="service.isLoggedIn()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"><i class="fa fa-heart">  My Wishlist</i></a>
        <a (click)="w3_close()" class="w3-button w3-block w3-white w3-left-align" routerLink="/cart" *ngIf="service.isLoggedIn()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"><i class="fa fa-shopping-cart">  My Cart</i></a> 
  
        <a (click)="myAccFunc5()"  class="w3-button w3-block w3-white w3-left-align" >
          <i class="fa fa-user">  Profile</i> <i class="fa fa-caret-down"></i>
        </a>
        <div id="demoAcc5" class="w3-bar-block w3-hide w3-padding-large w3-medium">
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/login" *ngIf="!service.isLoggedIn()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Login</a>
          <div class="dropdown-divider" *ngIf="!service.isLoggedIn()"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/register" *ngIf="!service.isLoggedIn()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Register</a>
          <div class="dropdown-divider" *ngIf="!service.isLoggedIn()"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/userProfile" *ngIf="service.isLoggedIn()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Manage My Profile</a>
          <div class="dropdown-divider" *ngIf="service.isLoggedIn()"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/order" *ngIf="service.isLoggedIn()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Order History</a>
          <div class="dropdown-divider" *ngIf="service.isLoggedIn()"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/regPrograms" *ngIf="service.isLoggedIn()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">My Registered Program & Workshops</a>
          <div class="dropdown-divider" *ngIf="service.isLoggedIn()"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/regProjects" *ngIf="service.isLoggedIn()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">My Registered Projects</a>
          <div class="dropdown-divider" *ngIf="service.isLoggedIn()"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/userStatistic" *ngIf="service.isLoggedIn()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">User Statistics</a>
          <div class="dropdown-divider" *ngIf="service.isLoggedIn()"></div>
          <a class="w3-button w3-block w3-white w3-left-align" (click)="w3_close()" routerLink="/logout" *ngIf="service.isLoggedIn()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Logout</a>
        </div>
     
      </div>
  </nav>
  <!-- Overlay effect when opening sidebar on small screens -->
<div class="w3-overlay w3-animate-opacity" (click)="w3_close()" style="cursor:pointer" title="close side menu" id="myOverlay"></div>

<router-outlet>
</router-outlet>



<footer class="site-footer">
    <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h3 class="footer-heading mb-4 text-white">About</h3>
        <p>Otolith Enrichment is an education company in the areas of aquaculture, agriculture and environmental science. </p>
        <p><a routerLink="/aboutPage" class="btn btn-success pill text-white px-5 py-2">Read More</a></p>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6">
            <h3 class="footer-heading mb-4 text-white">Quick Menu</h3>
              <ul class="list-unstyled">
                <li><a routerLink="/news">News</a></li>
                <li><a routerLink="/products/All">Our Eco²Balance Shop</a></li>
                <li><a routerLink="/programs">Our Programs</a></li>
                <li><a routerLink="/projects">Our Sustainability Projects</a></li>
                <!-- <li><a routerLink="/services">Services</a></li> -->
                <li><a routerLink="/contact"> Contact Us</a></li>
              </ul>
          </div>
          <div class="col-md-6">
              <div class="col-md-12"><h3 class="footer-heading mb-4 text-white">Our Social Media</h3></div>
              <div class="col-md-12">
                <p>
                  <a class="navbar-brand" href="https://www.facebook.com/otolithenrichment/" target="_blank">
                    <img src="/assets/imgs/facebookIcon.png" alt="Our Facebook" style="width:30px; height:30px;">
                  </a>                
                </p>
                <p>
                  <a class="navbar-brand" href="https://www.instagram.com/otolithenrichment/" target="_blank">
                    <img src="/assets/imgs/instagramIcon.png" alt="Our Instagram" style="width:30px; height:30px;">
                    </a>                
                </p>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5 mt-5 text-center">
      <div class="col-md-12">
        <p>
    <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
    Copyright &copy; Otolith Enrichment
    <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
    </p>
  </div>
  
</div>
</div>
</footer>

<app-scroll-top>
</app-scroll-top>
