<br>
<br>
<br>
<br>

<div class="container">
  <form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="p-5 bg-white">
    
      <h1>Login</h1>
      <p>Please fill in this form to login.</p>
      <hr>
      
      <label for="name">Username:</label>
      <p><input type="text" formControlName="name" id="name" placeholder="Your username"/></p>
      <label for="password">Password:</label>
      <p><input type="password" formControlName="password" id="password" placeholder="Your password" />
        <span id="password-status" (click)="passwordToggle()" class="fa fa-eye field-icon"></span></p>
        <p class="right">Forgot<a routerLink="/forgotUsername" routerLinkActive="active"> Username</a> or<a routerLink="/forgotPassword" routerLinkActive="active"> Password?</a></p>
        
      <hr>

      <p><button class="signInbtn" type="submit">Login</button></p>
    
    
    <div class="container signUp">
      <p><a routerLink="/register" routerLinkActive="active">No Account? Register here!</a></p>
    </div>
  </form>
</div>

<button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal">Open Modal</button>

<!-- The Modal -->
<div class="modal" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title" id="modalHeading"></h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <p id="modalBody"></p>
      </div>

       <!-- Modal footer -->
       <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="continueLogin()">Login</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal" id="closeModel">Close</button>

      </div>
    </div>
  </div>
</div>
