import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
providedIn: 'root'
})
export class ConnectionService {
constructor(private http: HttpClient) { }

generatePaynowQRCode(amount: number, email: string, name: string){
  return this.http.post<any[]>('./api/generateQRCode', {'amount': amount, 'email': email, 'name':name });   
}

sendMessage(messageContent: any) {
  return this.http.post('./api/sendEmailOrderConfirmation', JSON.stringify(messageContent), { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text' });
}

sendMessagePaynow(messageContent: any) {
  return this.http.post('./api/sendEmailOrderConfirmationPaynow', JSON.stringify(messageContent), { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text' });
}

sendMessageSupplierCreditCard(messageContent: any) {
  return this.http.post('./api/sendEmailOrderConfirmationSupplierCreditCard', JSON.stringify(messageContent), { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text' });
}

sendMessageSupplierPayNow(messageContent: any) {
  return this.http.post('./api/sendEmailOrderConfirmationSupplierPayNow', JSON.stringify(messageContent), { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text' });
}

sendMessageProject(messageContent: any) {
  return this.http.post('./api/sendEmailProjectRegistration', JSON.stringify(messageContent), { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text' });
}

sendContactForm(messageContent: any) {
  return this.http.post('./api/sendContact', JSON.stringify(messageContent), { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text' });
}

Checkout(amount: number, token: string) {
  return this.http.post<any[]>('./api/stripePayment/', {'amount': amount, 'token':  token });   
} 

sendToResetPassword(messageContent: any) {
  return this.http.post('./api/resetPassword', JSON.stringify(messageContent), { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text' });
}
  
sendToRequestUsername(messageContent: any) {
  return this.http.post('./api/requestUsername', JSON.stringify(messageContent), { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text' });
}

getLocationByPostalCode(postalCode: number) {
  return this.http.get<any[]>('https://developers.onemap.sg/commonapi/search?searchVal='+postalCode+'&returnGeom=Y&getAddrDetails=Y&pageNum=1');
}


}
