import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PostsService } from '../posts.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.css']
})
export class OrderInfoComponent implements OnInit {
  _id: string;
  orderInfo: any = [];
  private sub: any;
  greenCurrencyUsed:number;
  constructor(private postsService: PostsService,private route: ActivatedRoute,  private authService: AuthService, private toastr: ToastrService, private router: Router, public service: AuthService) {
   
   }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this._id = params['_id']; 
      
      this.postsService.postGetOrderUsingID(this._id).subscribe(orderInfo => {
      this.orderInfo = orderInfo;  
     

      });
    });
  }

  //update order status as completed
  updateSupplierOrdersStatusCompleted(orderID){
    if (confirm('Are you sure you receive your product ?')) {
    var date = new Date();
    var dateStr =
      ("00" + date.getDate()).slice(-2) + "/" +
      ( "00" + (date.getMonth() + 1)).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);
    this.postsService.updateSupplierOrdersStatus(orderID, "Completed", dateStr, this.authService.getSecureToken()).subscribe(results => {

      this.toastr.success("Successfully Updated!", 'Success!');
      location.reload();

    });
  }
  }


}
