<br>
<br>
<br>
<br>
<br>
<div id="print-section"> 
<br>
<div class="section-title">
  <h2>List of Residents</h2>

<div class="main">
  <div class="form-group has-search">
  <span class="fa fa-search form-control-feedback"></span>
  <input type="text" (keydown)="search($event)" class="form-control" placeholder="Search for resident name">
  </div>
</div>
</div>
<div class="user-list">
    <table class="table table-hover">
      
        <thead class="thead-dark">
          <tr>
            <th scope="col">UserID QR-Code</th>
            <th scope="col">Username</th>
            <th scope="col">Role</th>
            <th scope="col">Email</th>
            <th scope="col">Mobile</th>
            <th scope="col">Address</th>
            <th scope="col">Green Currency Amount</th>
            <th scope="col">Box Donated</th>
            <th scope="col">Box Status</th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let i of userList">
          <td data-label="qr">
            <ngx-qrcode
            [elementType]= "elementType"
            [value]="i._id"
            class="qrcode"
          ></ngx-qrcode>
          </td>
          <td data-label="Username" [routerLink]="['/userInfo',i._id]">{{ i.name }}</td>
          <td data-label="Role" [routerLink]="['/userInfo',i._id]">{{ i.role }}</td>
          <td data-label="Email" [routerLink]="['/userInfo',i._id]">{{ i.email }}</td>
          <td data-label="Mobile" [routerLink]="['/userInfo',i._id]">{{ i.mobile }}</td>
          <td data-label="Address" [routerLink]="['/userInfo',i._id]">{{ i.address }}  <p *ngIf="i.unitNo != text">Unit No : {{ i.unitNo }}</p></td>
          <td data-label="greenCurrency" [routerLink]="['/userInfo',i._id]">{{ i.greenCurrency.totalGreenCurrency }}</td>
          <td data-label="boxDonated" [routerLink]="['/userInfo',i._id]">{{ i.boxDonated }}</td>
          <td data-label="collected" [routerLink]="['/userInfo',i._id]">
            <div *ngIf= "i.collected == true">
              <button type="button" class="btn btn-warning" (click)="returnB(i._id)">Issue New Box</button>
            </div>
            <div *ngIf= "i.collected == false">
              <button type="button" class="btn btn-success" (click)="collectB(i._id, i.greenCurrency.totalGreenCurrency, i.boxDonated)">Collect Box</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    
  </div>
</div>
<button  id="topright" class="btn btn-outline-success mr-2" printSectionId="print-section" ngxPrint>Print</button> 
