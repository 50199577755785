import { Component, OnInit } from '@angular/core';
import { Chart } from 'node_modules/chart.js'
import { PostsService } from '../posts.service';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from '../auth.service'; 
import { EncrDecrService } from '../encr-decr-service';
import endpoint from 'endpoints.config';

@Component({
  selector: 'app-user-statistic',
  templateUrl: './user-statistic.component.html',
  styleUrls: ['./user-statistic.component.css']
})
export class UserStatisticComponent implements OnInit {


  ordersProductName: any = [];
  entries: any = [];
  entries1: any = [];  
  top5ProductChart: Chart
  productCatergoryChart: Chart
  msg1:string;
  msg2:string;
  empty1: boolean;
  empty2: boolean;
  secretKey = endpoint.secretKey;

  constructor(private postsService: PostsService,public authService: AuthService,private spinner: NgxSpinnerService, private EncrDecr: EncrDecrService) {
 
  }

  findLargest5(array){ 
    // sort descending
    array.sort(function(a,b){

        if(a[1] < b[1]){ return 1; } 
        else if(a[1] == b[1]) { return 0; } 
        else { return -1; }
    });
    return [array[0], array[1], array[2], array[3], array[4]]
  }
  
  getWordCount(array) {
    let map = {};
  for (let i = 0; i < array.length; i++) {
      let item = array[i];
      map[item] = (map[item] + 1) || 1;
    }
    return map;
  }
  
  ngOnInit(): void {
    this.spinner.show();
    // post get all orders
    this.postsService.stopWatching().subscribe(data =>{
			var array = []
			var orderArray = []
			for(var i = 0; i < data.length; i++){
			  var decryptedAddress = this.EncrDecr.get(this.secretKey, data[i].encryptedAddress);
			  var decryptedAmount = this.EncrDecr.get(this.secretKey, data[i].encryptedAmount);
			  var decryptedBillReferenceNumber= this.EncrDecr.get(this.secretKey, data[i].encryptedBillReferenceNumber);
			  var decryptedCategory= this.EncrDecr.get(this.secretKey, data[i].encryptedCategory);
			  var decryptedDiscount_price = data[i].encryptedDiscount_price;
			  var decryptedEmail= this.EncrDecr.get(this.secretKey, data[i].encryptedEmail);
			  var decryptedFullname= this.EncrDecr.get(this.secretKey, data[i].encryptedFullname);
			  var decryptedHousingType= this.EncrDecr.get(this.secretKey, data[i].encryptedHousingType);
			  var decryptedItemID= this.EncrDecr.get(this.secretKey, data[i].encryptedItemID);
			  var decryptedName= this.EncrDecr.get(this.secretKey, data[i].encryptedName);
			  var decryptedOrderStatus= this.EncrDecr.get(this.secretKey, data[i].encryptedOrderStatus);
			  var decryptedOrder_date= this.EncrDecr.get(this.secretKey, data[i].encryptedOrder_date);
        var decryptedPaidWithGC= this.EncrDecr.get(this.secretKey, data[i].encryptedPaidWithGC);
        var decryptedGreenCurrencyUsed= this.EncrDecr.get(this.secretKey, data[i].encryptedGreenCurrencyUsed);
			  var decryptedPhone= this.EncrDecr.get(this.secretKey, data[i].encryptedPhone);
			  var decryptedPrice= this.EncrDecr.get(this.secretKey,data[i].encryptedPrice);
			  var decryptedQuantity= this.EncrDecr.get(this.secretKey, data[i].encryptedQuantity);
			  var decryptedShippingMethod= this.EncrDecr.get(this.secretKey, data[i].encryptedShippingMethod);
			  var decryptedSupplier= data[i].encryptedSupplier;
			  var decryptedUnitNo= this.EncrDecr.get(this.secretKey, data[i].encryptedUnitNo);
			  var decryptedUserID= this.EncrDecr.get(this.secretKey, data[i].encryptedUserID);
			  var decrypted_id= this.EncrDecr.get(this.secretKey, data[i].encrypted_id);
	  
			  var obj = {"address" : decryptedAddress, "amount": decryptedAmount, "billReferenceNumber": decryptedBillReferenceNumber, "category": decryptedCategory, "discount_price":decryptedDiscount_price, "email":decryptedEmail, "fullname":decryptedFullname, "housingType":decryptedHousingType, "itemID":decryptedItemID, "name":decryptedName, "orderStatus":decryptedOrderStatus, "order_date":decryptedOrder_date, "paidWithGC":decryptedPaidWithGC, "greenCurrencyUsed":decryptedGreenCurrencyUsed, "phone":decryptedPhone, "price":decryptedPrice, "quantity":decryptedQuantity, "shippingMethod":decryptedShippingMethod, "supplier":decryptedSupplier, "unitNo":decryptedUnitNo, "userID":decryptedUserID, "_id":decrypted_id };
			
			  array.push(obj);
			} 
		
		  	orderArray = array;
        this.spinner.hide();

        var productCatergory: any =[]
        for (var i = 0; i < orderArray.length; i++){
         if(orderArray[i].userID == this.authService.getUserID() && orderArray[i].category != 'Program' && orderArray[i].category != 'Workshop')
         {
          
          this.ordersProductName.push(orderArray[i].name);
          productCatergory.push(orderArray[i].category); 
         }
        }
        
        productCatergory = this.getWordCount(productCatergory);
        this.entries1 = Object.entries(productCatergory);
        var productCatergoryName: any = [];
        var productCatergoryCount: any = [];
  
        var objProductNameCount = this.getWordCount(this.ordersProductName)
        this.entries = Object.entries(objProductNameCount);
        
        if(this.entries.length >= 5)
        {
          this.empty1 = false;
          this.msg1 = "";
          this.empty2 = false;
          this.msg2 = "";
        }
        else{
          this.empty1 = true;
          this.msg1 = "You need to purchase at least 5 different products for this chart to work";
          this.empty2 = true;
          this.msg2 = "You need to purchase some products for this chart to work";
        }

        var top5PurchasedProducts = this.findLargest5(this.entries);
        
        var top5ProductNameArray: any = [];
        var top5ProductNameCountArray: any = [];

       
  
        for (var i = 0; i < this.entries1.length; i++){
          productCatergoryName.push(this.entries1[i][0]);
          productCatergoryCount.push(this.entries1[i][1]);
        }

        for (var i = 0; i < top5PurchasedProducts.length; i++){
          top5ProductNameArray.push(top5PurchasedProducts[i][0]);
          top5ProductNameCountArray.push(top5PurchasedProducts[i][1]);
        }

        //Start of chart
        this.top5ProductChart = new Chart("myChartBar", {
          type: 'doughnut',
          data: {
              labels: top5ProductNameArray,
              datasets: [{
                  label: 'Top 5 Purchased Products',
                  data: top5ProductNameCountArray,
                  backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                  ],
                  borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                  ],
                  borderWidth: 1
              }]
          },
      
        });//End of chart
  
        //Start of radar chart
        this.productCatergoryChart = new Chart("myChartDoughnut", {
          type: 'doughnut',
          data: {
            labels: productCatergoryName,
            datasets: [{
              label: '# of Tomatoes',
              data: productCatergoryCount,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
              ],
              borderWidth: 1
            }]
          }
        });//end of radar chart
  
      });

  }


}
