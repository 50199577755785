<br>
<br>
<br>
<br>

<div class="container">
  <form [formGroup]="projectForm"   class="p-5 bg-white" (ngSubmit)="onSubmit()">
  <h2>Post Projects</h2>
  <hr>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="title">Title:</label>
        <input type="text" id="title" formControlName="title" class="form-control" placeholder="Enter Title...">
     </div>
  </div>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="body">Description:</label>
        <textarea cols="35" rows="15" id="body" formControlName="body" class="form-control" placeholder="Enter Description..."></textarea>
     </div>
  </div>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
      <label class="font-weight-bold" for="title">Giving.SG Page Link:</label>
      <input type="text" id="givingSG" formControlName="givingSG" class="form-control" placeholder="Enter this project giving.sg page link...">
     </div>
  </div>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label  class="font-weight-bold" for="image">Image:</label>
        <br>
        <input type="file" formControlName="image" (change)="handleUpload($event)" accept=".png, .jpg, .jpeg, .pdf">
     </div>
  </div>
  <br>
  <hr>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <input type="submit" value="Submit" class="btn btn-success pill text-white px-5 py-2" >
     </div>
  </div>
  </form>
</div> 
