import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PostsService } from '../posts.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-programmes-info',
  templateUrl: './programmes-info.component.html',
  styleUrls: ['./programmes-info.component.css']
})
export class ProgrammesInfoComponent implements OnInit {

  _id: string;
  programmesInfo: any = [];
  private sub: any;
  product:any = [];
  name:string;
  price : any;
  category: string;
  image:string;
  quantity:number;
  userName: string;
  residentNo: number = 0;
  empty:boolean;
  carts: any = [];
  programID:string;
  validate: boolean = false;
  specialProgram: any;

  constructor(private postsService: PostsService,private route: ActivatedRoute, private toastr: ToastrService, private router: Router, public service: AuthService, private spinner: NgxSpinnerService) 
  {  
    this.spinner.show();
    this.residentNo = 0;
    this.sub = this.route.params.subscribe(params => {
      this._id = params['_id']; 
      this.postsService.postGetProgrammesUsingID(this._id).subscribe(programmesInfo => {
      this.programmesInfo = programmesInfo;
      if(this.programmesInfo[0].specialProgram == false){
        this.specialProgram = false;
      }
      else{
        this.specialProgram = true;
      }
      });
      if(service.isLoggedIn()){
        this.getCart();
        //get user details
        this.service.postGetUserDetails(this.service.getUserID()).subscribe(userInfo => {
          var userRole = userInfo[0].role
          if(userRole == "admin"){
             this.validate = true
          }
          else{
             this.validate = false
          }
        });
      }
      else{
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);      
      } 
    
    });
       
    
  }  


  ngOnInit() {
    
  }

  //get user cart
  getCart(){
    this.postsService.postGetCart(this.service.getUserID()).subscribe(carts => {
      this.carts = carts;
      setTimeout(() => {
        this.spinner.hide();
      }, 2000); 
      },
       error => {
         console.log("Cart View Error: ", error);
     });
   }

   async getCartAsync(){
    return new Promise((resolve, reject) => {
      resolve(this.carts);
    });
  }

  //remove program based on id
  deleteProgrammes()
  {
    if(confirm('Are you sure that you want to delete this program/workshop ?'))
    {
  this.postsService.postDeleteProgrammes(this._id).subscribe(results => {
    this.toastr.success("Successfully deleted!", 'Success!');
    this.router.navigateByUrl('/programs');
  });
    }
  }

  inArray(target, array)
  {
    for(var i = 0; i < array.length; i++) 
    {
      if(array[i].name == target)
      {
        return true;
      }
    }
    return false; 
  }

  async addProgramToCart() {
    let response = await this.getCartAsync();
    //check whether user login
    if(this.service.isLoggedIn() == false){
      this.router.navigateByUrl('/login');
      this.toastr.warning('Please login to your account first!', 'Warning');
    }
    //check whether this program already in your cart
    else if(this.inArray(this.programmesInfo[0].name, response)){
      this.toastr.warning('This program or workshop is already in your cart', 'Warning');
    }
    else{
      if(confirm('Do you want to add this program or workshop to your cart ?'))
    {
      
      for (var i = 0; i < this.programmesInfo[0].slot.length; i++){
        this.userName = this.service.getSecureToken();
        //check whether user joined this program
        if(this.programmesInfo[0].slot[i] == this.userName){
          this.toastr.warning('You\'ve already joined this program or workshop', 'Warning');
          return;
        }
      }
      //check whether this program meet the maximum capacity
      if(this.programmesInfo[0].slot.length >= this.programmesInfo[0].capacity){
        this.toastr.warning('Sorry the program or workshop is already at max capacity', 'Warning');
      }
      else{
      this.programID = this.programmesInfo[0]._id;
      this.name=this.programmesInfo[0].name;
      this.price=this.programmesInfo[0].price;
      var discountPrice = this.programmesInfo[0].discount_price;
      var maxGreenCurrency = this.programmesInfo[0].maxGreenCurrency;
      this.category=this.programmesInfo[0].category;
      this.image=this.programmesInfo[0].image;
      this.quantity = 1;
      var cap = this.programmesInfo[0].capacity;
      var pSeller = this.programmesInfo[0].supplier;
      delete pSeller.companyLogo;
      //add program to your cart
      this.postsService.addToCart(this.service.getUserID(),this.programID,this.name,this.price,discountPrice, maxGreenCurrency,pSeller, this.category,this.image,this.quantity, cap).subscribe(results =>{
        this.toastr.success("Successfully added item to cart!", 'Success!');
         location.reload();   
         
        });
     
      }
     
    }
    }

    
  }
  
}