<br>
<br>
<br>
<br>
<br>
<div *ngIf = "validate === true">
    <div id="print-section"> 
        <div class="container">
                <br>
                <div class="section-title">
                <h2 class="mb-5">Statistics</h2>
                </div>
           
            <h3 class="center">Top 5 Selling Products</h3>
            <br>
            <h5 class="center" *ngIf = "empty1 === true">
                {{msg1}}
            </h5>
            <div class="divChart">
                <canvas id="myChartBar"></canvas>
            </div>
            <br>
            <br>
            <h3 class="center">Product Catergory Popularity</h3>
            <br>
            <h5 class="center" *ngIf = "empty2 === true">
                {{msg2}}
            </h5>
            <div class="divChart">
                <canvas id="myChartDoughnut"></canvas>
            </div>
            <br>
            <br>
            <h3 class="center">Top 5 Project Participants No.</h3>
            <br>
            <h5 class="center" *ngIf = "empty3 === true">
                {{msg3}}
            </h5>
            <div class="divChart">
                <canvas id="myChartBar1"></canvas>
            </div>
            <br>
            <br>
            <h3 class="center">Top 5 Program Participants No.</h3>
            <br>
            <h5 class="center" *ngIf = "empty4 === true">
                {{msg4}}
            </h5>
            <div class="divChart">
                <canvas id="myChartBar2"></canvas>
            </div>
            <br>
            <br>
            <h3 class="center">Top 5 Food Waste Donor</h3>
             <br>
             <h5 class="center" *ngIf = "empty5 === true">
                {{msg5}}
            </h5>
            <div class="divChart">
            <canvas id="myChartBar3"></canvas>
            </div>
            <br>  
            <br>
            <h3 class="center">Food Waste Contributed by Block No.</h3>
            <div class="table-responsive-sm">
                <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#Ranking</th>
                        <th scope="col">Block No.</th>
                        <th scope="col">Box Donated</th>
                      </tr>
                    </thead>
                    <tbody>
                       <!-- row -->
                        <tr *ngFor="let iteration of postalCodeArrayFinal; let i = index">
                            <th> {{i+1}} </th>
                            <td> {{iteration.address}}</td>
                            <td> {{iteration.boxDonated}}</td>
                            
                        </tr>
                        <!-- row -->
                    </tbody>
                  </table>
            </div>
        </div>
    </div>
    
    <!-- <div class="loader-wrapper">
        <span class="loader"><span class="loader-inner"></span></span>
    </div> -->
    
    
    <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">Loading...</p>
    </ngx-spinner>
</div>

<div *ngIf = "validate === false">
    You don't have the permission to view this
</div>