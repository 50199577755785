
  <br>
  <br>
  
 <!--Main layout-->
<main class="mt-5 pt-4">
  <div class="container dark-grey-text mt-5">
     <!--Grid row-->
     <div class="row wow fadeIn">

        <!--Grid column-->
        <div class="col-md-6 mb-4">
          <img src={{projectInfo[0].image}} class="img-fluid" alt="Project Image" width="700" height="700">
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-md-6 mb-4">

        <!--Content-->
        <div class="p-4">
          <h1> {{projectInfo[0].title}} </h1>
          <a [routerLink]="['/editProject',_id]" *ngIf="validate === true" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"><img src="./assets/imgs/edit.png" width="40" height="40"></a>			
          <img *ngIf="validate === true" src="./assets/imgs/delete.png" name="imgbtn" width="40" height="40" (click)="deleteProject()" style="margin-left: 10px;" >
          <p class="lead font-weight-bold">
             <span> Date Published : {{projectInfo[0].publish | date : 'dd/MM/yyyy'}}</span>
          </p>
          <p class="lead font-weight-bold">Description :</p>
          <p>{{projectInfo[0].body}}</p>
          <form class="d-flex justify-content-left">
             <!-- Default input -->
             
                <!-- <a (click)="addToAttend(projectInfo[0]._id)" class="btn btn-outline-success mr-2">Join this event</a> -->
               <p><button type="button" class="btn btn-outline-success mr-2" data-toggle="modal" data-target="#myModal">
                Join this event
                </button>
             
             <button class="btn btn-outline-primary mr-2" routerLink="/contact">More Information</button></p>
          </form>
          <!-- The Modal -->
          <div class="modal" id="myModal">
             <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                   <!-- Modal Header -->
                   <div class="modal-header">
                      <h4 class="modal-title">Sign Up For {{projectInfo[0].title}}</h4>
                      <button type="button" class="close" data-dismiss="modal">&times;</button>
                   </div>
                   <!-- Modal body -->
                   <div class="modal-body">
                      <div class="btn-group btn-block">
                         <div class="row form-group">
                            <div class="col-md-12 mb-3 mb-md-0">
                               <select id="selected">
                                  <option value="" disabled selected>Select Sign Up As...</option>
                                  <option *ngIf = "projectInfo[0].title != tampinesProject" value="volunteer" id="volunteer">Volunteer</option>
                                  <option *ngIf = "projectInfo[0].title == tampinesProject" value="contributor" id = "contributor">Food waste contributor</option>
                                  <option value="volunteer2" id="volunteer2" *ngIf = "projectInfo[0].title == tampinesProject">Volunteer</option>
                               </select>
                            </div>
                         </div>
                      </div>
                   </div>
                   <!-- Modal footer -->
                   <div class="modal-footer">
                      <button class="btn btn-success" (click)="signUpProject(projectInfo[0]._id)" data-dismiss="modal">SIGN UP</button>
                      <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                   </div>
                </div>
             </div>
          </div>
       </div>
       <!--Content-->
        </div>
        
         <!--Grid column-->
     </div>
     <!--Grid row-->
  </div>
</main>
<!--Main layout-->
<!-- <ng-container *ngIf="validate === true">
  <br>
  <div style="text-align:center">
     <h4>List of Users that sign up:</h4>
     <br>
     <p *ngIf="projectInfo[0].attendees.length === 0">No user sign up yet</p>
  </div>
  <div style="text-align:center" *ngFor="let i of projectInfo[0].attendees">
     <p>Username: {{ i }}</p>
     </div>
    </ng-container> -->
    <!-- <div class="disqusContainer">
      <disqus class="disqus" [identifier]="['/projectInfo',_id]"></disqus>
    </div>  -->
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
