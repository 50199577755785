import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PostsService } from '../posts.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router'; 
import { AuthService } from '../auth.service';
import { ConnectionService } from '../connection.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-program-info',
  templateUrl: './program-info.component.html',
  styleUrls: ['./program-info.component.css']
})
export class ProgramInfoComponent implements OnInit {
  _id: string;
  projectInfo: any = [];
  private sub: any;
  userName: string;
  validate: boolean = false;
  tampinesProject:string;
  givingSGURL: string;
  email:string;
  emailContent: any = [];

  constructor(private postsService: PostsService,private route: ActivatedRoute, private connectionService: ConnectionService, private toastr: ToastrService, private router: Router, public service: AuthService, private spinner: NgxSpinnerService) 
  { 
    this.spinner.show();
    this.tampinesProject = "Sustainability@Tampines Park";
    this.sub = this.route.params.subscribe(params => {

      this._id = params['_id']; 
      
      this.postsService.postGetProjectsID(this._id).subscribe(projectInfo => {
        this.projectInfo = projectInfo;
        this.givingSGURL = projectInfo[0].givingSGLink
          this.spinner.hide();
      });
    });
    if(service.isLoggedIn()){
      //get user details
      this.service.postGetUserDetails(this.service.getUserID()).subscribe(userInfo => {
        this.email = userInfo[0].email
        var userRole = userInfo[0].role
        if(userRole == "admin"){
          this.validate = true
        }
        else{
          this.validate = false
        }
      });
    }

    
  }

  ngOnInit() {

  }

  //remove project based on id
  deleteProject()
  {   
    if(confirm('Are you sure that you want to delete this project ?'))
    {
    this.postsService.postDeleteProject(this._id).subscribe(results => {
    this.toastr.success("Successfully deleted!", 'Success!');
    this.router.navigateByUrl('/projects');
    });
    }
  }

  signUpProject(projectID){
    
    var select = (<HTMLInputElement>document.getElementById("selected")).value;
    //check whether user login
    if(this.service.isLoggedIn() == false){
      this.toastr.warning('Please login to your account first!', 'Warning');
      this.router.navigateByUrl('/login');
    }
    //check whether user select the option
    else if(select == ""){
      this.toastr.warning('Please select the option!', 'Warning');
    }
    else if(select == "contributor"){
      this.userName = this.service.getSecureToken();

      if(confirm('Do you want to sign up as food waste contributor?'))
      {
        for (var i = 0; i < this.projectInfo[0].attendees.length; i++){
          //check whether user had sign up for the project
          if(this.projectInfo[0].attendees[i] == this.userName){
            this.toastr.warning('You\'ve already joined this project', 'Warning');
            return;
          }
        } 
        //add user to the project
        this.postsService.addToAttend(projectID, this.userName) .subscribe(results =>{
          this.toastr.success("Successfully Joined project", 'Success!');
          window.location.reload();
        })      
        location.reload();

       
        this.emailContent.push(this.service.getSecureToken()); //0
        this.emailContent.push(this.email); //1
        this.emailContent.push(this.projectInfo[0].title); //2
        this.emailContent.push(this.projectInfo[0].body); //3

          // sent to email
          this.connectionService.sendMessageProject(this.emailContent).subscribe(() => {

          }, (error: any) => {
            console.log('Error', error);
          });
      }    
    }
    
    else if(select == "volunteer2"){
      if(confirm('Do you want to sign up as volunteer?')){
        var url = this.givingSGURL;
        var win = window.open(url, '_blank');
        win.focus();
      }
    }
    else if(select == "volunteer"){
      if(confirm('Do you want to sign up as volunteer?')){
        this.addToAttend(projectID);
        
      }
    }
  }

  addToAttend(projectID):void{

    this.userName = this.service.getSecureToken();

    if(confirm('Are you sure you want to join this project?'))
    {
      
      for (var i = 0; i < this.projectInfo[0].attendees.length; i++){
        //check whether user had sign up for the project
        if(this.projectInfo[0].attendees[i] == this.userName){
          this.toastr.warning('You\'ve already joined this project', 'Warning');
          return;
        }
      }
      
      var url = this.givingSGURL;;
      var win = window.open(url, this.givingSGURL);
      win.focus();   

       //add user to the project
      this.postsService.addToAttend(projectID, this.userName) .subscribe(results =>{
        this.toastr.success("Successfully Joined project", 'Success!');
      })    
      
      location.reload();
      
      this.emailContent.push(this.service.getSecureToken()); //0
        this.emailContent.push(this.email); //1
        this.emailContent.push(this.projectInfo[0].title); //2
        this.emailContent.push(this.projectInfo[0].body); //3
      
          // sent to email
          this.connectionService.sendMessageProject(this.emailContent).subscribe(() => {

          }, (error: any) => {
            console.log('Error', error);
          });
    }
  }
}