import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FacebookService } from '@greg-md/ng-facebook';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  
  news: any = [];
  p: Number = 1;
  loadednewsList: any[];
  validate: boolean = false;

  constructor(public service: AuthService, public facebook: FacebookService) { 
    facebook.init().subscribe();
    for (let i = 1; i <= 100; i++) {
      this.news.push(`item ${i}`);
    }
  }

  ngOnInit() {
  }

  initializeItems(): void {
    this.news = this.loadednewsList;
  }

}