import { Component, OnInit } from '@angular/core';
import { PostsService } from '../posts.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-staff-project-list',
  templateUrl: './staff-project-list.component.html',
  styleUrls: ['./staff-project-list.component.css']
})
export class StaffProjectListComponent implements OnInit {

  projects: any[];
  plist: any[];
  name = '';
  constructor(private toastr: ToastrService, public postsService: PostsService, public service: AuthService, private spinner: NgxSpinnerService) 
  { 
    this.spinner.show();
    //get all projects
    this.postsService.postGetAllProjects().subscribe(projects => {
      this.projects = projects;
      this.plist = projects;
      this.spinner.hide();
     
    });
  }

  ngOnInit(): void {
    
  }

  initializeItems(): void {
    this.projects = this.plist;
  }

  search(event) {
    
    this.initializeItems();
    //Set searchTerm with input from search bar
    const searchTerm = event.srcElement.value;
  
    if (!searchTerm) {
    return;
    }
    //Filters enquiries list with keywords from searchTerm
    this.projects = this.projects.filter(i => {
    if (i.title && searchTerm) {
    if (i.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
    return true;
    }
    return false;
    }
    });
  }
  //add user to the project
  addResident(_id){
    let value = (<HTMLSelectElement>document.getElementById(_id)).value.trim();
    if(confirm('Do you want to add this user into the project?')){
      this.postsService.addToAttend(_id, value) .subscribe(results =>{
        this.toastr.success("Successfully added " + value + " to the project!" , 'Success!');
      })
      location.reload();
    }  
  }

  //remove user from the project
  removeResident(_id){
    let value1 = (<HTMLSelectElement>document.getElementById(_id +"1")).value;
    if(confirm('Do you want to remove this user from the project?')){
      this.postsService.removeResident(_id, value1).subscribe(results =>{
        this.toastr.success("Successfully remove user", 'Success!');
      })
      location.reload();
    }
  }

}
