import {Component,OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PostsService} from '../posts.service';
import {FormBuilder,FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
	selector: 'app-edit-products',
	templateUrl: './edit-products.component.html',
	styleUrls: ['./edit-products.component.css']
})
export class EditProductsComponent implements OnInit {

	editProductsForm: FormGroup;
	_id: string;
	last_update: Date = new Date();
	private sub: any;
	productsInfo: any = [];
	base64Image = null;
  	oldImage: any;
	sellers: any = []
	supplierID: any;
	supplierName: any;

  constructor(private fb: FormBuilder, private postsService: PostsService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService) 
  {
   
	this.postsService.postGetAllSellers().subscribe(sellers => {
		this.sellers = sellers;
		for(var i = 0; i < sellers.length; i++){
		  if(this.supplierID == sellers[i]._id){
			this.supplierName = sellers[i].companyName
		  }
		}
	});
  }

	ngOnInit() {
		
		this.sub = this.route.params.subscribe(params => {
			this._id = params['_id'];
      
			this.postsService.postGetProductsUsingID(this._id).subscribe(productsInfo => {
				this.productsInfo = productsInfo;
				this.editProductsForm = this.fb.group({
					name: this.productsInfo[0].name,
					price: this.productsInfo[0].price,
					discountPrice: this.productsInfo[0].discount_price,
					maxGreenCurrency: this.productsInfo[0].maxGreenCurrency,
					category: this.productsInfo[0].category,
					supplier: this.productsInfo[0].supplier,
					description: this.productsInfo[0].description,
					availability: this.productsInfo[0].availability,
					image: null
				});
				this.supplierID = this.productsInfo[0].supplier._id
			
				this.oldImage = this.productsInfo[0].image
			});
		});
	}

	handleUpload(event) {
		const comp = this;
		const img = < File > event.target.files[0];
		const promise = new Promise((resolve) => {
			const reader = new FileReader();
			reader.onload = function () {
				resolve(reader.result);
			}
			reader.readAsDataURL(img);
		});

		promise.then(img => {
			comp.base64Image = img;
		});
	}


	editProducts(id: string) {
	var boolean;

	if(this.editProductsForm.value.availability == "" || this.editProductsForm.value.availability == null || this.editProductsForm.value.availability == false){
		boolean = false;
	}
	else {
		boolean = this.editProductsForm.value.availability;
	}
	
    if(this.editProductsForm.value.supplier == null){
      this.toastr.warning('Fill in supplier input field!', 'Warning');

    }
	else if (this.editProductsForm.value.name == "" || this.editProductsForm.value.price == "" || this.editProductsForm.value.category == "" || this.editProductsForm.value.description == "" || this.editProductsForm.value.maxGreenCurrency == "") {
		this.toastr.warning('Fill in all input fields!', 'Warning');
    } 
    else if (this.editProductsForm.value.image == null) {
		    // update product details
			this.postsService.editProducts(id, this.editProductsForm.value.name, this.editProductsForm.value.price, this.editProductsForm.value.discountPrice, this.editProductsForm.value.maxGreenCurrency,this.editProductsForm.value.supplier, this.editProductsForm.value.category, this.editProductsForm.value.description, this.last_update, boolean, this.oldImage).subscribe(results => {

				this.toastr.success("Successfully Updated!", 'Success!');
				this.router.navigateByUrl('/products/All').then(() =>
				{
					location.reload();
				});

			});
    } 
    else {
		    // update product details
			this.postsService.editProducts(id, this.editProductsForm.value.name, this.editProductsForm.value.price, this.editProductsForm.value.discountPrice, this.editProductsForm.value.maxGreenCurrency,this.editProductsForm.value.supplier, this.editProductsForm.value.category, this.editProductsForm.value.description, this.last_update, boolean, this.base64Image).subscribe(results => {

				this.toastr.success("Successfully Updated!", 'Success!');
				this.router.navigateByUrl('/products/All').then(() =>
				{
					location.reload();
				});

			});
		}
	}

}