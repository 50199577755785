<br>
<br>
<br>
<!-- Header -->
<header class="w3-display-container w3-content w3-wide" style="max-width:1600px;" id="home">
  <div class="parallax" style="background-image: url(./assets/imgs/program.jpg);"></div>
  <div class="w3-display-middle w3-margin-top w3-center">
    <h1><b>PROGRAMS & WORKSHOPS</b></h1>
  </div>

</header>
<br>

    <div class="filterBtn">
        <button type="button" class="btn btn-outline-success mr-2 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-filter">   Filter</i>
        </button>
        <div class="dropdown-menu">
          <button class="dropdown-item" (click)="getProgrammesByNewDate()">Date (new to old)</button>
           <div class="dropdown-divider"></div>
           <button class="dropdown-item" (click)="getProgrammesByOldDate()">Date (old to new)</button>
        </div>
        
     </div>
   
    <div class="center">
      <div class="main">
          <div class="form-group has-search">
            <span class="fa fa-search form-control-feedback"></span>
            <input type="text" (keydown)="search($event)" class="form-control" placeholder="Search program/workshops">
          </div>
      </div>
    </div>
     
    <p><a routerLink="/addPrograms" class="postProgramsBtn" *ngIf="service.isAdmin()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Add Program/Workshops</a></p>
      <br>
    <div class="row">
    
        <div class="row equal">
                      
            <div class="col-sm-4 d-flex pb-3" *ngFor="let program of programs | paginate: { itemsPerPage: 6, currentPage: p }">
              
                <div class="card card-block" style="text-align:center">
                    <div class="img-border-sm mb-4">
                      <a [routerLink]="['/programsInfo',program._id]" >
                        <div class="image-box">
                        <img src={{program.image}} alt="" class="img-fluid"  width="350" height="350">
                        </div>
                      </a>
                    </div>
                    <h2 class="heading mb-0" style="font-size:18px"><a style="color:black" [routerLink]="['/programsInfo',program._id]">{{program.name}}</a></h2>
                    
                    <p *ngIf="program.specialProgram === true">
                      Price : Please email info@otolithenrichment.com for plans and pricings
                     </p>
                     <p *ngIf="program.specialProgram === false">
                      Price: {{program.price | currency:'USD'}}
                     </p>
                    <p class="text-danger" *ngIf="program.discount_price != null">Discounted Price: {{program.discount_price | currency:'USD'}}</p>
                    <p>By: {{program.supplier.companyName}}<img src={{program.supplier.companyLogo}} alt="companyLogo" width="30" height="30">
                    </p>
                    
                    </div>                        
            </div>
       
        </div>
        <div class="center">
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
    
      </div>
    
<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple"
>
<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>