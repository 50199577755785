import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { EncrDecrService } from "./encr-decr-service";
import endpoint from "../../endpoints.config";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  secretKey = endpoint.secretKey;

  constructor(
    private router: Router,
    private http: HttpClient,
    private EncrDecr: EncrDecrService
  ) {}

  regUser(
    name: string,
    pw: string,
    role: string,
    email: string,
    mobile: string,
    address: string,
    unitNo: string,
    housingType: string,
    halalBoxType: string
  ) {
    return this.http.post<any[]>("./api/reguser/", {
      name: name,
      password: pw,
      role: role,
      email: email,
      mobile: mobile,
      address: address,
      unitNo: unitNo,
      housingType: housingType,
      token: endpoint.apiToken,
      halalBoxType,
    });
  }

  authUser(username: string, pw: string) {
    return this.http.post<any[]>("./api/authuser/", {
      username: username,
      password: pw,
    });
  }

  verifyUser(username: string, pw: string, token: string) {
    return this.http.post<any[]>("./api/verifyUser/", {
      username: username,
      password: pw,
      token: token,
    });
  }

  verifyAccount(username: string, password: string) {
    return this.http.post<any[]>("./api/verifyAccount/", {
      username: username,
      password: password,
    });
  }

  //postGetAllResident
  stopAndLook() {
    return this.http.post<any[]>("./api/stopAndLook/", {
      token: endpoint.apiToken,
    });
  }

  //postGetAllStaff
  wabbuLubbaDubDuB() {
    return this.http.post<any[]>("./api/wabbuLubbaDubDuB/", {
      token: endpoint.apiToken,
    });
  }

  //postGetAllUser
  macSpicyTooSpicy() {
    return this.http.post<any[]>("./api/macSpicyTooSpicy/", {
      token: endpoint.apiToken,
    });
  }

  //get userlist for register page validations
  dontTryAnythingFunnyPlease() {
    return this.http.post<any[]>("./api/dontTryAnythingFunnyPlease/", {
      token: endpoint.apiToken,
    });
  }

  setSecureToken(secure_token: string) {
    //Set login user in session storage encrypted
    var encrypted = this.EncrDecr.set(this.secretKey, secure_token);
    sessionStorage.setItem("n453tbdfd63m", encrypted);
  }

  getSecureToken() {
    //Get login user in session storage decrypted
    if (sessionStorage.getItem("n453tbdfd63m") !== null) {
      var decrypted = this.EncrDecr.get(
        this.secretKey,
        sessionStorage.getItem("n453tbdfd63m")
      );
      return decrypted;
    } else {
      return null;
    }
  }

  setUserID(userID) {
    //Set login user ID in session storage encrypted
    var encrypted = this.EncrDecr.set(this.secretKey, userID);
    sessionStorage.setItem("I2e209kjfD", encrypted);
  }

  getUserID() {
    //Get login user ID in session storage decrypted
    if (sessionStorage.getItem("I2e209kjfD") !== null) {
      var decrypted = this.EncrDecr.get(
        this.secretKey,
        sessionStorage.getItem("I2e209kjfD")
      );
    }
    return decrypted;
  }

  setKey(login) {
    //Set login user ID in session storage encrypted
    var encrypted = this.EncrDecr.set(this.secretKey, login);
    sessionStorage.setItem("k1u7P3e32E834y", encrypted);
  }

  getKey() {
    //Get login user ID in session storage decrypted
    if (sessionStorage.getItem("k1u7P3e32E834y") !== null) {
      var decrypted = this.EncrDecr.get(
        this.secretKey,
        sessionStorage.getItem("k1u7P3e32E834y")
      );
    }
    return decrypted;
  }

  logout() {
    sessionStorage.removeItem("U90lkR");
    sessionStorage.removeItem("n453tbdfd63m");
    sessionStorage.removeItem("I2e209kjfD");
    sessionStorage.removeItem("k1u7P3e32E834y");
  }

  isLoggedIn() {
    return this.getSecureToken() !== null;
  }

  setUserRole(role: string) {
    //Set login user role in session storage encrypted
    var encrypted = this.EncrDecr.set(this.secretKey, role);
    sessionStorage.setItem("U90lkR", encrypted);
  }

  getUserRole() {
    //Get login user role in session storage decrypted
    if (sessionStorage.getItem("U90lkR") !== null) {
      var decrypted = this.EncrDecr.get(
        this.secretKey,
        sessionStorage.getItem("U90lkR")
      );
    }
    return decrypted;
  }

  isSupplier() {
    return this.getUserRole() == "supplier";
  }

  isStaff() {
    return this.getUserRole() == "staff" || this.getUserRole() == "admin";
  }

  isAdmin() {
    return this.getUserRole() == "admin";
  }

  isUser() {
    return this.getUserRole() == "user" || this.getUserRole() == "staff";
  }

  updateUser(
    username: string,
    password: string,
    email: string,
    mobile: string,
    address: string,
    unitNo: string,
    housingType: string
  ) {
    return this.http
      .put<any[]>("./api/updateusers/" + username, {
        username: username,
        password: password,
        email: email,
        mobile: mobile,
        address: address,
        unitNo: unitNo,
        housingType: housingType,
        token: endpoint.apiToken,
      })
      .subscribe(
        (data) => {
          console.log("PUT Request is successful ", data);
        },
        (error) => {
          console.log("Error", error);
        }
      );
  }

  adminCreateUser(
    name: string,
    pw: string,
    role: string,
    email: string,
    mobile: string,
    address: string,
    unitNo: string,
    housingType: string,
    greenCurrency: number,
    supplier: string
  ) {
    return this.http.post<any[]>("./api/adminCreateUser/", {
      name: name,
      password: pw,
      role: role,
      email: email,
      mobile: mobile,
      address: address,
      unitNo: unitNo,
      housingType: housingType,
      greenCurrency: greenCurrency,
      supplier: supplier,
      token: endpoint.apiToken,
    });
  }

  adminUpdateUser(
    username: string,
    password: string,
    email: string,
    mobile: string,
    address: string,
    unitNo: string,
    housingType: string,
    role: string,
    greenCurrency: number,
    supplier: string
  ) {
    return this.http
      .put<any[]>("./api/adminUpdateUsers/" + username, {
        username: username,
        password: password,
        email: email,
        mobile: mobile,
        address: address,
        unitNo: unitNo,
        housingType: housingType,
        role: role,
        greenCurrency: greenCurrency,
        supplier: supplier,
        token: endpoint.apiToken,
      })
      .subscribe(
        (data) => {
          console.log("PUT Request is successful ", data);
        },
        (error) => {
          console.log("Error", error);
        }
      );
  }

  resetPasswordByName(username: string, password: string) {
    return this.http
      .put<any[]>("./api/resetPasswordByName/" + username, {
        username: username,
        password: password,
      })
      .subscribe(
        (data) => {
          console.log("PUT Request is successful ", data);
        },
        (error) => {
          console.log("Error", error);
        }
      );
  }

  resetPasswordByEmail(password: string, email: string) {
    return this.http
      .put<any[]>("./api/resetPasswordByEmail/" + email, {
        password: password,
        email: email,
      })
      .subscribe(
        (data) => {
          console.log("PUT Request is successful ", data);
        },
        (error) => {
          console.log("Error", error);
        }
      );
  }

  updateCollectionStatusRecieve(
    _id: string,
    boolean: boolean,
    greenCurrency: number,
    boxDonated: number,
    lastBoxDonatedDate: Date
  ) {
    return this.http
      .put<any[]>("./api/box/" + _id, {
        boolean: boolean,
        greenCurrency: greenCurrency,
        boxDonated: boxDonated,
        lastBoxDonatedDate: lastBoxDonatedDate,
        token: endpoint.apiToken,
      })
      .subscribe(
        (data) => {
          console.log("PUT Request is successful ", data);
        },
        (error) => {
          console.log("Error", error);
        }
      );
  }

  issueNewBox(_id: string, newBoxIssueDate: Date, boolean: boolean) {
    return this.http
      .put<any[]>("./api/newBox/" + _id, {
        newBoxIssueDate: newBoxIssueDate,
        boolean: boolean,
        token: endpoint.apiToken,
      })
      .subscribe(
        (data) => {
          console.log("PUT Request is successful ", data);
        },
        (error) => {
          console.log("Error", error);
        }
      );
  }

  useGC(_id: string, greenCurrency: number) {
    return this.http
      .put<any[]>("./api/useGC/" + _id, {
        greenCurrency: greenCurrency,
        token: endpoint.apiToken,
      })
      .subscribe(
        (data) => {
          console.log("PUT Request is successful ", data);
        },
        (error) => {
          console.log("Error", error);
        }
      );
  }

  changeUserRole(_id: string, role: string) {
    return this.http
      .put<any[]>("./api/changeUserRole/" + _id, {
        role: role,
        token: endpoint.apiToken,
      })
      .subscribe(
        (data) => {
          console.log("PUT Request is successful ", data);
        },
        (error) => {
          console.log("Error", error);
        }
      );
  }

  adminVerifyUser(_id: string, boolean: boolean) {
    return this.http
      .put<any[]>("./api/adminVerifyUser/" + _id, {
        boolean: boolean,
        token: endpoint.apiToken,
      })
      .subscribe(
        (data) => {
          console.log("PUT Request is successful ", data);
        },
        (error) => {
          console.log("Error", error);
        }
      );
  }

  postGetUserDetails(_id) {
    return this.http.post<any[]>("./api/postGetUser/" + _id, {
      token: endpoint.apiToken,
    });
  }

  postDeleteUser(_id) {
    return this.http.post<any[]>("./api/postDeleteUser/" + _id, {
      token: endpoint.apiToken,
    });
  }

  postVolunteerStaffEntry(
    _id: string,
    name: string,
    boolean: boolean,
    greenCurrency: number,
    clockInDateTime: string,
    checkInOut: string,
    volunteeringLocation: string,
    greenCurrencyEarned: string
  ) {
    return this.http
      .put<any[]>("./api/postVolunteerStaff/" + _id, {
        name: name,
        DateTime: clockInDateTime,
        boolean: boolean,
        greenCurrency: greenCurrency,
        checkInOut: checkInOut,
        volunteeringLocation: volunteeringLocation,
        greenCurrencyEarned: greenCurrencyEarned,
        token: endpoint.apiToken,
      })
      .subscribe(
        (data) => {
          console.log("PUT Request is successful ", data);
        },
        (error) => {
          console.log("Error", error);
        }
      );
  }

  postGetAllVolunteerStaffEntries() {
    return this.http.post<any[]>("./api/postGetAllVolunteerStaffEntries/", {
      token: endpoint.apiToken,
    });
  }
}
