<br>
<br>
<br>
<br>

<div class="container">
<form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="p-5 bg-white">
   
      <h1>Create User</h1>
      <p>Please fill in this form to create an account.</p>
      <hr>
        <label for="name">Username:</label>
        <p><input type="text" formControlName="name" id="name" placeholder="Your Username" required></p>
  
        <label for="email">Email:</label>
        <p><input type="email" formControlName="email" id="email" placeholder="Your Email" required></p>

        <label for="role">User Role:</label>
        <p><select name="role" id="role" class="browser-default custom-select custom-select-lg mb-3">
            <option selected value="user">user</option>
            <option value="staff">staff</option>
            <option value="supplier">supplier</option>
            <option value="admin">admin</option>
        </select></p>

        <div class="form-group">
          <label for="supplier">Supplier for (leave blank if null): </label>
          <select class="form-control" id="supplierSelect" formControlName="supplier">
            <option *ngFor="let supplier of sellers" [ngValue]="supplier._id">
              {{ supplier.companyName }}
            </option>
          </select>
        </div>

        <label for="mobile">Green Currency.:</label>
        <p><input type="number" formControlName="greenCurrency" id="greenCurrency" required></p>
  
        <label for="mobile">Mobile No.:</label>
        <p><input type="text" formControlName="mobile" id="mobile" minlength="8" maxlength="8" placeholder="Your Mobile No." required></p>
  
        <label for="address">Postal Code.: (e.g. 123456)</label>
        <p><input type="text" formControlName="address" id="address" placeholder="Your house postal code"  minlength="6" maxlength="6" required></p>
  
        <label for="address">Type of housing:</label>
        <div class="form-check">
          <label class="form-check-label" for="radio1">
            <input type="radio" formControlName="radioBtn" id="radioHDB" value="HDB" checked>Public Housing (HDB flats)
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label" for="radio2">
            <input type="radio" formControlName="radioBtn" id="radioApartments" value="Condominiums">Private Condominiums / Apartments
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input type="radio" formControlName="radioBtn" id="radioLandedHousing" value="Landed Housing">Landed Housing
          </label>
        </div>
        <br>
        <label for="address">Unit Number.: (e.g. #01-123)</label>
        <p><input type="text" formControlName="unitNo" id="unitNo" placeholder="Your house unit number" required></p>
  
        <label for="password">Password (8 characters minimum):</label>
        <p><input type="password" formControlName="password" id="password" placeholder="Your password" [(ngModel)]="account.password" minlength="8" required>
          <span id="password-status" (click)="passwordToggle()" class="fa fa-eye field-icon"></span></p>
  
        <label for="repassword">Retype Password:</label>
        <p><input type="password" formControlName="repassword" id="repassword" placeholder="Retype password" />
          <span id="repassword-status" (click)="repasswordToggle()" class="fa fa-eye field-icon"></span></p>
        <app-password-strength-bar [passwordToCheck]="account.password" [barLabel]="barLabel"> </app-password-strength-bar>  
      <hr>
      <p><button class="registerbtn" type="submit" >Create</button></p>
    
  </form>
</div>