import { Component, OnInit } from '@angular/core';
import { Chart } from 'node_modules/chart.js'
import { PostsService } from '../posts.service';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from '../auth.service'; 
import { EncrDecrService } from '../encr-decr-service';
import endpoint from 'endpoints.config';

@Component({
  selector: 'app-statistic-page',
  templateUrl: './statistic-page.component.html',
  styleUrls: ['./statistic-page.component.css']
})
export class StatisticPageComponent implements OnInit {

	ordersProductName: any = [];
	entries: any = [];
	entries1: any = [];
	userName: any = [];
	boxDonated: any = [];
	address: any = [];
	projectName: any = []
	projectParticipantCount: any = []
	programName: any = []
	programParticipantCount: any = []
	top5ProductChart: Chart
	productCatergoryPopularityChart: Chart
	top5ProgramChart: Chart
	top5ProjectChart: Chart
	top5UserDonatedChart: Chart
	bubbleChart: Chart
	validate: boolean = false;
	msg1:string;
	msg2:string;
	empty1: boolean;
	empty2: boolean;
	msg3:string;
	empty3: boolean;
	msg4:string;
	empty4: boolean;
	msg5:string;
	empty5:boolean;
	msg6:string;
	empty6:boolean;
	popCanvas:any;
	secretKey = endpoint.secretKey;
	postalCodeArrayFinal: any = [];

	constructor(private postsService: PostsService, public authService: AuthService, private spinner: NgxSpinnerService, private EncrDecr: EncrDecrService) {
        //get user details
		this.authService.postGetUserDetails(this.authService.getUserID()).subscribe(userInfo => {
			var userRole = userInfo[0].role
		
			if (userRole == "admin") {
				this.validate = true
			} else {
				this.validate = false
			}
		});

	}

	findLargest5(array) {
		// sort descending
		array.sort(function (a, b) {

			if (a[1] < b[1]) {
				return 1;
			} else if (a[1] == b[1]) {
				return 0;
			} else {
				return -1;
			}
		});
		return [array[0], array[1], array[2], array[3], array[4]]
	}

	getWordCount(array) {
		let map = {};
		for (let i = 0; i < array.length; i++) {
			let item = array[i];
			map[item] = (map[item] + 1) || 1;
		}
		return map;
	}

	ngOnInit(): void {
		// post get all orders
		this.postsService.stopWatching().subscribe(data =>{
			var array = []
			var orderArray = []
			for(var i = 0; i < data.length; i++){
			  var decryptedAddress = this.EncrDecr.get(this.secretKey, data[i].encryptedAddress);
			  var decryptedAmount = this.EncrDecr.get(this.secretKey, data[i].encryptedAmount);
			  var decryptedBillReferenceNumber= this.EncrDecr.get(this.secretKey, data[i].encryptedBillReferenceNumber);
			  var decryptedCategory= this.EncrDecr.get(this.secretKey, data[i].encryptedCategory);
			  var decryptedDiscount_price = data[i].encryptedDiscount_price;
			  var decryptedEmail= this.EncrDecr.get(this.secretKey, data[i].encryptedEmail);
			  var decryptedFullname= this.EncrDecr.get(this.secretKey, data[i].encryptedFullname);
			  var decryptedHousingType= this.EncrDecr.get(this.secretKey, data[i].encryptedHousingType);
			  var decryptedItemID= this.EncrDecr.get(this.secretKey, data[i].encryptedItemID);
			  var decryptedName= this.EncrDecr.get(this.secretKey, data[i].encryptedName);
			  var decryptedOrderStatus= this.EncrDecr.get(this.secretKey, data[i].encryptedOrderStatus);
			  var decryptedOrder_date= this.EncrDecr.get(this.secretKey, data[i].encryptedOrder_date);
			  var decryptedPaidWithGC= this.EncrDecr.get(this.secretKey, data[i].encryptedPaidWithGC);
			  var decryptedGreenCurrencyUsed= this.EncrDecr.get(this.secretKey, data[i].encryptedGreenCurrencyUsed);
			  var decryptedPhone= this.EncrDecr.get(this.secretKey, data[i].encryptedPhone);
			  var decryptedPrice= this.EncrDecr.get(this.secretKey,data[i].encryptedPrice);
			  var decryptedQuantity= this.EncrDecr.get(this.secretKey, data[i].encryptedQuantity);
			  var decryptedShippingMethod= this.EncrDecr.get(this.secretKey, data[i].encryptedShippingMethod);
			  var decryptedSupplier= data[i].encryptedSupplier;
			  var decryptedUnitNo= this.EncrDecr.get(this.secretKey, data[i].encryptedUnitNo);
			  var decryptedUserID= this.EncrDecr.get(this.secretKey, data[i].encryptedUserID);
			  var decrypted_id= this.EncrDecr.get(this.secretKey, data[i].encrypted_id);
	  
			  var obj = {"address" : decryptedAddress, "amount": decryptedAmount, "billReferenceNumber": decryptedBillReferenceNumber, "category": decryptedCategory, "discount_price":decryptedDiscount_price, "email":decryptedEmail, "fullname":decryptedFullname, "housingType":decryptedHousingType, "itemID":decryptedItemID, "name":decryptedName, "orderStatus":decryptedOrderStatus, "order_date":decryptedOrder_date, "paidWithGC":decryptedPaidWithGC, "greenCurrencyUsed":decryptedGreenCurrencyUsed, "phone":decryptedPhone, "price":decryptedPrice, "quantity":decryptedQuantity, "shippingMethod":decryptedShippingMethod, "supplier":decryptedSupplier, "unitNo":decryptedUnitNo, "userID":decryptedUserID, "_id":decrypted_id };
			
			  array.push(obj);
			} 
		
			orderArray = array;
			

			var productCatergory: any = []
			for (var i = 0; i < orderArray.length; i++) {
				if(orderArray[i].category != 'Program' && orderArray[i].category != 'Workshop')
				{
				this.ordersProductName.push(orderArray[i].name);
				productCatergory.push(orderArray[i].category);
				}
			}
			productCatergory = this.getWordCount(productCatergory);

			this.entries1 = Object.entries(productCatergory);
			var productCatergoryName: any = [];
			var productCatergoryCount: any = [];

			var objProductNameCount = this.getWordCount(this.ordersProductName)
			this.entries = Object.entries(objProductNameCount);
		
			if(this.entries.length >= 5)
			{
			  this.empty1 = false;
			  this.msg1 = "";
			  this.empty2 = false;
			  this.msg2 = "";
			}
			else{
			  this.empty1 = true;
			  this.msg1 = "You need customer to purchase at least 5 different products for this chart to work";
			  this.empty2 = true;
			  this.msg2 = "You need customer to purchase some products for this chart to work";
			}

			var top5SeliingProducts = this.findLargest5(this.entries);
		
			var top5ProductNameArray: any = [];
			var top5ProductNameCountArray: any = [];

			for (var i = 0; i < this.entries1.length; i++) {
				productCatergoryName.push(this.entries1[i][0]);
				productCatergoryCount.push(this.entries1[i][1]);
			}

			for (var i = 0; i < top5SeliingProducts.length; i++) {
				top5ProductNameArray.push(top5SeliingProducts[i][0]);
				top5ProductNameCountArray.push(top5SeliingProducts[i][1]);
			}

			//Start of bar chart
			this.top5ProductChart = new Chart("myChartBar", {
				type: 'bar',
				data: {
					labels: top5ProductNameArray,
					datasets: [{
						label: 'Top 5 Selling Products',
						data: top5ProductNameCountArray,
						backgroundColor: [
							'rgba(255, 99, 132, 0.2)',
							'rgba(54, 162, 235, 0.2)',
							'rgba(255, 206, 86, 0.2)',
							'rgba(75, 192, 192, 0.2)',
							'rgba(153, 102, 255, 0.2)',
							'rgba(255, 0, 111, 0.2)',
						],
						borderColor: [
							'rgba(255, 99, 132, 1)',
							'rgba(54, 162, 235, 1)',
							'rgba(255, 206, 86, 1)',
							'rgba(75, 192, 192, 1)',
							'rgba(153, 102, 255, 1)',
							'rgba(255, 0, 111, 1)',
						],
						borderWidth: 1
					}]
				},
				options: {
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true
							}
						}]
					}
				}
			}); //End of bar chart

			//Start of radar chart
			this.productCatergoryPopularityChart = new Chart("myChartDoughnut", {
				type: 'doughnut',
				data: {
					labels: productCatergoryName,
					datasets: [{
						label: '# of Tomatoes',
						data: productCatergoryCount,
						backgroundColor: [
							'rgba(255, 99, 132, 0.2)',
							'rgba(54, 162, 235, 0.2)',
							'rgba(255, 206, 86, 0.2)',
							'rgba(75, 192, 192, 0.2)',
							'rgba(153, 102, 255, 0.2)',
							'rgba(255, 0, 111, 0.2)',
							'rgba(0, 255, 111, 0.2)'
						],
						borderColor: [
							'rgba(255, 99, 132, 1)',
							'rgba(54, 162, 235, 1)',
							'rgba(255, 206, 86, 1)',
							'rgba(75, 192, 192, 1)',
							'rgba(153, 102, 255, 1)',
							'rgba(255, 0, 111, 1)',
							'rgba(0, 255, 111, 1)'
						],
						borderWidth: 1
					}]
				}
			}); //end of radar chart

		});

		this.postsService.postGetAllProjects().subscribe(projects => {
			var test: any = []
			
			for (var i = 0; i < projects.length; i++) {
				test.push([projects[i].title, projects[i].attendees.length]);
				
				if(test.length >= 5)
			    {
			     this.empty3 = false;
			     this.msg3 = "";
			   
			    }
			    else{
			     this.empty3 = true;
			     this.msg3 = "You need at least 5 different projects for this chart to work";
			 
		     	}

			}

			var test1 = this.findLargest5(test);

			for (var i = 0; i < test1.length; i++) {
				this.projectName.push(test1[i][0]);
				this.projectParticipantCount.push(test1[i][1]);
			}

			//Start of top 5 popular project
			this.top5ProjectChart = new Chart("myChartBar1", {
				type: 'bar',
				data: {
					labels: this.projectName,
					datasets: [{
						label: 'Project Participants No.',
						data: this.projectParticipantCount,
						backgroundColor: [
							'rgba(255, 99, 132, 0.2)',
							'rgba(54, 162, 235, 0.2)',
							'rgba(255, 206, 86, 0.2)',
							'rgba(75, 192, 192, 0.2)',
							'rgba(153, 102, 255, 0.2)',
						],
						borderColor: [
							'rgba(255, 99, 132, 1)',
							'rgba(54, 162, 235, 1)',
							'rgba(255, 206, 86, 1)',
							'rgba(75, 192, 192, 1)',
							'rgba(153, 102, 255, 1)',
						],
						borderWidth: 1
					}]
				},
				options: {
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true
							}
						}]
					}
				}
			});

		});
        this.spinner.show();
		this.postsService.postGetAllProgrammes().subscribe(programs => {
			this.spinner.hide();
			var test: any = []
			for (var i = 0; i < programs.length; i++) {
				test.push([programs[i].name, programs[i].slot.length]);
				if(test.length >= 5)
			    {
			     this.empty4 = false;
			     this.msg4 = "";
			   
			    }
			    else{
			     this.empty4 = true;
			     this.msg4 = "You need at least 5 different programs for this chart to work";
			 
		     	}
			}

			var test1 = this.findLargest5(test);

			for (var i = 0; i < test1.length; i++) {
				this.programName.push(test1[i][0]);
				this.programParticipantCount.push(test1[i][1]);
			}

			//Start of top 5 popular Program
			this.top5ProgramChart = new Chart("myChartBar2", {
				type: 'horizontalBar',
				data: {
					labels: this.programName,
					datasets: [{
						label: 'Program Participants No.',
						data: this.programParticipantCount,
						backgroundColor: [
							'rgba(255, 99, 132, 0.2)',
							'rgba(54, 162, 235, 0.2)',
							'rgba(255, 206, 86, 0.2)',
							'rgba(75, 192, 192, 0.2)',
							'rgba(153, 102, 255, 0.2)',
						],
						borderColor: [
							'rgba(255, 99, 132, 1)',
							'rgba(54, 162, 235, 1)',
							'rgba(255, 206, 86, 1)',
							'rgba(75, 192, 192, 1)',
							'rgba(153, 102, 255, 1)',
						],
						borderWidth: 1
					}]
				},
				options: {
					scales: {
						xAxes: [{
							ticks: {
								beginAtZero: true
							}
						}]
					}
				}
			});

		}); //end of top 5 popular Program

        // Post get all users
		this.authService.macSpicyTooSpicy().subscribe(data => {
				var array = []
				var userArray =[]
				for(var i = 0; i < data.length; i++){
				  var collected
				  var decryptedClockIn= data[i].encryptedClockIn
				  var decryptedClockInDateTime= data[i].encryptedClockInDateTime;
				  var decryptedClockInDateTimeList= data[i].encryptedClockInDateTimeList  
				  var decryptedVolunteeringLocation= data[i].encryptedVolunteeringLocation;
				  var decryptedName = this.EncrDecr.get(this.secretKey, data[i].encryptedName);
				  var decryptedEmail = this.EncrDecr.get(this.secretKey, data[i].encryptedEmail);
				  var decryptedAddress = this.EncrDecr.get(this.secretKey, data[i].encryptedAddress);
				  var decryptedUnitNo = this.EncrDecr.get(this.secretKey, data[i].encryptedUnitNo);
				  var decryptedBoxDonated= this.EncrDecr.get(this.secretKey, data[i].encryptedBoxDonated);
				  var decryptedBoxDonatedDates= data[i].encryptedBoxDonatedDates;
				  var decryptedBoxIssuedDates= data[i].encryptedBoxIssuedDates;
				  var decryptedCollected= this.EncrDecr.get(this.secretKey, data[i].encryptedCollected);
				  if(decryptedCollected == "true"){
					collected = true;
				  }
				  else{
					collected = false;
				  }
				  var decryptedHousingType= this.EncrDecr.get(this.secretKey, data[i].encryptedHousingType);
				  var decryptedLastBoxDonatedDate= data[i].encryptedLastBoxDonatedDate;
				  var decryptedMobile= this.EncrDecr.get(this.secretKey, data[i].encryptedMobile);
				  var decryptedNewBoxIssueDate= data[i].encryptedNewBoxIssueDate;
				  var decryptedPassword= this.EncrDecr.get(this.secretKey, data[i].encryptedPassword);
				  var decryptedRole= this.EncrDecr.get(this.secretKey, data[i].encryptedRole);
				  var decryptedSupplier= this.EncrDecr.get(this.secretKey, data[i].encryptedSupplier);
				  var decryptedTemporaryToken= data[i].encryptedTemporaryToken;
				  var decryptedVerified= data[i].encryptedVerified;
				  var decrypted_id= this.EncrDecr.get(this.secretKey, data[i].encrypted_id);
				  var obj = {"volunteeringLocation":decryptedVolunteeringLocation, "clockInDateTimeList":decryptedClockInDateTimeList,"clockInDateTime":decryptedClockInDateTime,"clockIn":decryptedClockIn,"_id":decrypted_id,"verified":decryptedVerified,"temporaryToken":decryptedTemporaryToken,"supplier":decryptedSupplier,"role":decryptedRole,"password":decryptedPassword,"newBoxIssueDate":decryptedNewBoxIssueDate,"mobile":decryptedMobile,"lastBoxDonatedDate":decryptedLastBoxDonatedDate,"housingType":decryptedHousingType,"greenCurrency":data[i].greenCurrency,"collected":collected,"boxIssuedDates":decryptedBoxIssuedDates,"boxDonatedDates":decryptedBoxDonatedDates,"boxDonated":decryptedBoxDonated,"name": decryptedName, "email": decryptedEmail, "address": decryptedAddress, "unitNo":decryptedUnitNo};
				  array.push(obj);
				} 
				
			//Data manipulation for top 5 user donor chart
			userArray = array;	

			var test: any = []
		
			for (var i = 0; i < userArray.length; i++) {
				test.push([userArray[i].name, userArray[i].boxDonated]);
				if(test.length >= 5)
			    {
			    	this.empty5 = false;
			     	this.msg5 = "";
			    }
			    else{
			     	this.empty5 = true;
			     	this.msg5 = "You need at least 5 residents donated food waste for this chart to work";
		     	}
			}

			var test1 = this.findLargest5(test);

			for (var i = 0; i < test1.length; i++) {
				this.userName.push(test1[i][0]);
				this.boxDonated.push(test1[i][1]);
			}

			//Data manipulation for postal code donor table
			
			var postalCodeArray0 =[];
			
			for (var i = 0; i < userArray.length; i++) {
				postalCodeArray0.push([userArray[i].address, userArray[i].boxDonated]);
			}
			
			postalCodeArray0.sort()
			var postalCodeArray1 =[];

			for (var i = 0; i < postalCodeArray0.length; i++) {
				if(i == 0)
			    {
					var j = [postalCodeArray0[i][0]];
					var res0 = postalCodeArray0.filter(function (i) {
						return j.indexOf(i[0]) >= 0 
					});
					var res1 = res0.reduce((accum,item) => accum +  parseInt(item[1]), 0)
					postalCodeArray1.push({"address":postalCodeArray0[i][0], "boxDonated":res1})
				}
				else if(postalCodeArray0[i][0] != postalCodeArray0[i-1][0])
				{
					var j = [postalCodeArray0[i][0]];
					var res0 = postalCodeArray0.filter(function (i) {
						return j.indexOf(i[0]) >= 0 
					});
					var res1 = res0.reduce((accum,item) => accum +  parseInt(item[1]), 0)
					postalCodeArray1.push({"address":postalCodeArray0[i][0], "boxDonated":res1})
				}
				
			}
			this.postalCodeArrayFinal = postalCodeArray1.sort((a, b) => parseFloat(b.boxDonated) - parseFloat(a.boxDonated));
			console.log(this.postalCodeArrayFinal);

			//Start of top 5 resident food waste donor chart
			this.top5UserDonatedChart = new Chart("myChartBar3", {
				type: 'horizontalBar',
				data: {
					labels: this.userName,
					datasets: [{
						label: 'Box Count',
						data: this.boxDonated,
						backgroundColor: [
							'rgba(255, 99, 132, 0.2)',
							'rgba(54, 162, 235, 0.2)',
							'rgba(255, 206, 86, 0.2)',
							'rgba(75, 192, 192, 0.2)',
							'rgba(153, 102, 255, 0.2)',
						],
						borderColor: [
							'rgba(255, 99, 132, 1)',
							'rgba(54, 162, 235, 1)',
							'rgba(255, 206, 86, 1)',
							'rgba(75, 192, 192, 1)',
							'rgba(153, 102, 255, 1)',
						],
						borderWidth: 1
					}]
				},
				options: {
					scales: {
						xAxes: [{
							ticks: {
								beginAtZero: true
							}
						}]
					}
				}
			}); //end of top 5 resident food waste donor chart

		

		}); 

	
	}

}