<br>
<br>
<br>
<br>
<br>
<br>
<div class="section-title">
  <h2>List of Program & Workshops</h2>

<div class="main">
  <div class="form-group has-search">
  <span class="fa fa-search form-control-feedback"></span>
  <input type="text" (keydown)="search($event)" class="form-control" placeholder="Search for program/workshops">
  </div>
</div>
</div>
<div class="user-list">
    <table class="table table-hover">
      
        <thead class="thead-dark">
          <tr>
            <th scope="col">Image</th>
            <th scope="col">Title</th>
            <th scope="col">Price</th>
            <th scope="col">Category</th>
            <th scope="col">Date</th>
            <!-- <th scope="col">Time</th> -->
            <th scope="col">Attendees</th>
            <th scope="col">Capacity</th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let i of programs">
        <a [routerLink]="['/programsInfo',i._id]" >
          <td data-label="qr">
            <img  src={{i.image}} name="Image" width="200" height="145">
          </td>
        </a>
          <td data-label="name" [routerLink]="['/programsInfo',i._id]" style="width:15%">{{ i.name}}</td>
          <td *ngIf="i.specialProgram === true" data-label="price" [routerLink]="['/programsInfo',i._id]">Please email info@otolithenrichment.com for plans and pricings</td>
          <td *ngIf="i.specialProgram === false" data-label="price" [routerLink]="['/programsInfo',i._id]"><a *ngIf="i.discount_price === null">{{ i.price | currency:'USD'}}</a> <a *ngIf="i.discount_price !== null">
            Discounted Price : {{i.discount_price | currency:'USD' }}
          </a></td>
        
          <td style="width: 8%;" data-label="category" [routerLink]="['/programsInfo',i._id]">{{ i.category}}</td>
   
          <td *ngIf="i.specialProgram === true" data-label="date" [routerLink]="['/programsInfo',i._id]">Contact us at info@otolithenrichment.com for the next available dates</td>
            <!--<td *ngIf="i.specialProgram === true" data-label="time" [routerLink]="['/programsInfo',i._id]">Contact us at info@otolithenrichment.com for the next available time</td> -->
          <td *ngIf="i.specialProgram === false" data-label="date" [routerLink]="['/programsInfo',i._id]">{{ i.date }}</td> 
           <!-- <td *ngIf="i.specialProgram === false" data-label="time" [routerLink]="['/programsInfo',i._id]">{{ i.time }}</td> -->
            <td data-label="attendees" style="width: 15%;">
                <select name="attendees" id={{i._id+1}} class="browser-default custom-select custom-select-lg mb-3">
                  <option *ngFor="let obj of i.slot">{{obj}}</option>
                </select>
                <div *ngIf="service.isAdmin()">
                    <input type="text" class="form-control" id={{i._id}} name="username" placeholder="Enter username here">
                </div>
                <div *ngIf="service.isAdmin()">
                    <button type="button" class="btn btn-outline-success btn-block" (click)="addToPrograms(i._id)">Add</button>
                </div>
                <div *ngIf="service.isAdmin()">
                    <button type="button" class="btn btn-outline-danger btn-block" (click)="removeFromPrograms(i._id)">Remove</button>
                </div>
            </td>
            <td data-label="capacity" [routerLink]="['/programsInfo',i._id]">{{ i.capacity }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white; text-align: center">Loading...</p>
</ngx-spinner>
