import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service'; 
import { Router } from '@angular/router'; 
import { FormBuilder, FormGroup } from '@angular/forms'; 
import { ToastrService } from 'ngx-toastr';
import { ConnectionService } from '../connection.service';
import { EncrDecrService } from '../encr-decr-service';
import endpoint from 'endpoints.config';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  myForm: FormGroup;

  userNameList: any[];
  emailContent: any = [];
  msg: string;
  reset:boolean;
  secretKey = endpoint.secretKey;

  constructor(private fb: FormBuilder, private connectionService: ConnectionService, private authService: AuthService, private router: Router, private toastr: ToastrService, private EncrDecr: EncrDecrService) 
  { 
    // Post get all users
    this.authService.macSpicyTooSpicy().subscribe(data =>{
      var array = []
      for(var i = 0; i < data.length; i++){
        var collected
        var decryptedClockIn= data[i].encryptedClockIn
        var decryptedClockInDateTime= data[i].encryptedClockInDateTime;
        var decryptedClockInDateTimeList= data[i].encryptedClockInDateTimeList  
        var decryptedVolunteeringLocation= data[i].encryptedVolunteeringLocation;
        var decryptedName = this.EncrDecr.get(this.secretKey, data[i].encryptedName);
        var decryptedEmail = this.EncrDecr.get(this.secretKey, data[i].encryptedEmail);
        var decryptedAddress = this.EncrDecr.get(this.secretKey, data[i].encryptedAddress);
        var decryptedUnitNo = this.EncrDecr.get(this.secretKey, data[i].encryptedUnitNo);
        var decryptedBoxDonated= this.EncrDecr.get(this.secretKey, data[i].encryptedBoxDonated);
        var decryptedBoxDonatedDates= data[i].encryptedBoxDonatedDates;
        var decryptedBoxIssuedDates= data[i].encryptedBoxIssuedDates;
        var decryptedCollected= this.EncrDecr.get(this.secretKey, data[i].encryptedCollected);
        if(decryptedCollected == "true"){
          collected = true;
        }
        else{
          collected = false;
        }
        var decryptedHousingType= this.EncrDecr.get(this.secretKey, data[i].encryptedHousingType);
        var decryptedLastBoxDonatedDate= data[i].encryptedLastBoxDonatedDate;
        var decryptedMobile= this.EncrDecr.get(this.secretKey, data[i].encryptedMobile);
        var decryptedNewBoxIssueDate= data[i].encryptedNewBoxIssueDate;
        var decryptedPassword= this.EncrDecr.get(this.secretKey, data[i].encryptedPassword);
        var decryptedRole= this.EncrDecr.get(this.secretKey, data[i].encryptedRole);
        var decryptedSupplier= this.EncrDecr.get(this.secretKey, data[i].encryptedSupplier);
        var decryptedTemporaryToken= data[i].encryptedTemporaryToken;
        var decryptedVerified= data[i].encryptedVerified;
        var decrypted_id= this.EncrDecr.get(this.secretKey, data[i].encrypted_id);
        var obj = {"volunteeringLocation":decryptedVolunteeringLocation, "clockInDateTimeList":decryptedClockInDateTimeList,"clockInDateTime":decryptedClockInDateTime,"clockIn":decryptedClockIn,"_id":decrypted_id,"verified":decryptedVerified,"temporaryToken":decryptedTemporaryToken,"supplier":decryptedSupplier,"role":decryptedRole,"password":decryptedPassword,"newBoxIssueDate":decryptedNewBoxIssueDate,"mobile":decryptedMobile,"lastBoxDonatedDate":decryptedLastBoxDonatedDate,"housingType":decryptedHousingType,"greenCurrency":data[i].greenCurrency,"collected":collected,"boxIssuedDates":decryptedBoxIssuedDates,"boxDonatedDates":decryptedBoxDonatedDates,"boxDonated":decryptedBoxDonated,"name": decryptedName, "email": decryptedEmail, "address": decryptedAddress, "unitNo":decryptedUnitNo};
        array.push(obj);
      } 
      
      this.userNameList = array;
      //console.log(this.userNameList)
    });
  }

  ngOnInit() {
    
    this.myForm = this.fb.group ({
      nameOrEmail: '',
    });
  }


 

  onSubmit() {
  
    //Check if all input fields are filled
    if(this.myForm.value.nameOrEmail == "" ){
      this.toastr.warning('Fill in all input fields!', 'Warning');
      return;
    }
  
    var unlistLength = this.userNameList.length;
    for (var i = 0; i < unlistLength; i++){
      var userName = this.userNameList[i].name;
      var email = this.userNameList[i].email;
         
     if(userName == this.myForm.value.nameOrEmail || email == this.myForm.value.nameOrEmail){
        
      this.emailContent.push(this.myForm.value.nameOrEmail);
      this.emailContent.push(email); 
      //sent reset password email to your registered email
      this.connectionService.sendToResetPassword(this.emailContent).subscribe(() => {
       
        localStorage.setItem("data",this.myForm.value.nameOrEmail);
        this.myForm.reset();
        this.toastr.success('Sent to your email sucessfully, please go to your registered email to reset password', 'Success');
        this.router.navigateByUrl('/login');
      });
     
     return;
     }
    

  } 


    var unlistLength = this.userNameList.length;
    for (var i = 0; i < unlistLength; i++){
      var userName = this.userNameList[i].name;
      var email = this.userNameList[i].email;
       
      if(userName != this.myForm.value.nameOrEmail || email != this.myForm.value.nameOrEmail){
      
      this.toastr.warning('Username or email does not exist!', 'Warning');  
   
      return;
      }


    } 
    
 
  }

}
