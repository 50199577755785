import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PostsService } from '../posts.service';
import { AuthService } from '../auth.service'; 
import { ToastrService } from 'ngx-toastr';
import * as xlsx from 'xlsx';
import { NgxSpinnerService } from "ngx-spinner";
import { EncrDecrService } from '../encr-decr-service';
import endpoint from 'endpoints.config';

@Component({
  selector: 'app-supplier-order-list',
  templateUrl: './supplier-order-list.component.html',
  styleUrls: ['./supplier-order-list.component.css']
})
export class SupplierOrderListComponent implements OnInit {
  @ViewChild('orderList', { static: false }) orderList: ElementRef;

  supplierID: any;
  orders: any = [];
  loadedOrderList: any[];
  companyName: any;
  secretKey = endpoint.secretKey;

  constructor(private postsService: PostsService, private authService: AuthService, private toastr: ToastrService,  private spinner: NgxSpinnerService, private EncrDecr: EncrDecrService) {
    this.spinner.show();
    //get user details
    this.authService.postGetUserDetails(this.authService.getUserID()).subscribe(userInfo => {
      // this.supplierID = userInfo[0].supplier
      
      this.postsService.postGetSupplierOrders(userInfo[0].supplier).subscribe(data => {
        var array = []
        for(var i = 0; i < data.length; i++){
          var decryptedAddress = this.EncrDecr.get(this.secretKey, data[i].encryptedAddress);
          var decryptedAmount = this.EncrDecr.get(this.secretKey, data[i].encryptedAmount);
          var decryptedBillReferenceNumber= this.EncrDecr.get(this.secretKey, data[i].encryptedBillReferenceNumber);
          var decryptedCategory= this.EncrDecr.get(this.secretKey, data[i].encryptedCategory);
          var decryptedDiscount_price = data[i].encryptedDiscount_price;
          var decryptedEmail= this.EncrDecr.get(this.secretKey, data[i].encryptedEmail);
          var decryptedFullname= this.EncrDecr.get(this.secretKey, data[i].encryptedFullname);
          var decryptedHousingType= this.EncrDecr.get(this.secretKey, data[i].encryptedHousingType);
          var decryptedItemID= this.EncrDecr.get(this.secretKey, data[i].encryptedItemID);
          var decryptedName= this.EncrDecr.get(this.secretKey, data[i].encryptedName);
          var decryptedOrderStatus= this.EncrDecr.get(this.secretKey, data[i].encryptedOrderStatus);
          var decryptedOrder_date= this.EncrDecr.get(this.secretKey, data[i].encryptedOrder_date);
          var decryptedPaidWithGC= this.EncrDecr.get(this.secretKey, data[i].encryptedPaidWithGC);
          var decryptedGreenCurrencyUsed= this.EncrDecr.get(this.secretKey, data[i].encryptedGreenCurrencyUsed);
          var decryptedPhone= this.EncrDecr.get(this.secretKey, data[i].encryptedPhone);
          var decryptedPrice= this.EncrDecr.get(this.secretKey,data[i].encryptedPrice);
          var decryptedQuantity= this.EncrDecr.get(this.secretKey, data[i].encryptedQuantity);
          var decryptedShippingMethod= this.EncrDecr.get(this.secretKey, data[i].encryptedShippingMethod);
          var decryptedSupplier= data[i].encryptedSupplier;
          var decryptedUnitNo= this.EncrDecr.get(this.secretKey, data[i].encryptedUnitNo);
          var decryptedUserID= this.EncrDecr.get(this.secretKey, data[i].encryptedUserID);
          var decrypted_id= this.EncrDecr.get(this.secretKey, data[i].encrypted_id);
  
          var obj = {"address" : decryptedAddress, "amount": decryptedAmount, "billReferenceNumber": decryptedBillReferenceNumber, "category": decryptedCategory, "discount_price":decryptedDiscount_price, "email":decryptedEmail, "fullname":decryptedFullname, "housingType":decryptedHousingType, "itemID":decryptedItemID, "name":decryptedName, "orderStatus":decryptedOrderStatus, "order_date":decryptedOrder_date, "paidWithGC":decryptedPaidWithGC, "greenCurrencyUsed":decryptedGreenCurrencyUsed, "phone":decryptedPhone, "price":decryptedPrice, "quantity":decryptedQuantity, "shippingMethod":decryptedShippingMethod, "supplier":decryptedSupplier, "unitNo":decryptedUnitNo, "userID":decryptedUserID, "_id":decrypted_id };
          
          array.push(obj);
        } 
        var Length = data.length;
  
        for (var i = 0; i < Length; i++){
          JSON.stringify(data[i]._id);
        }
        this.orders = array;
        this.loadedOrderList = array;
        this.spinner.hide();

      });   
    });
    
  }

  ngOnInit(): void {
  }

  initializeItems(): void {
    this.orders = this.loadedOrderList;
  }

  search(event) {
    
    this.initializeItems();
    //Set searchTerm with input from search bar
    const searchTerm = event.srcElement.value;
  
    if (!searchTerm) {
    return;
    }
    //Filters enquiries list with keywords from searchTerm
    this.orders = this.orders.filter(i => {
    if (i.fullname && searchTerm) {
    if (i.fullname.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
    return true;
    }
    return false;
    }
    });
  }

  updateSupplierOrdersStatusCompleted(orderID){
    var date = new Date();
    var dateStr =
      ("00" + date.getDate()).slice(-2) + "/" +
      ( "00" + (date.getMonth() + 1)).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);
    this.postsService.updateSupplierOrdersStatus(orderID, "Completed", dateStr, this.authService.getSecureToken()).subscribe(results => {

      this.toastr.success("Successfully Updated!", 'Success!');
      location.reload();

    });
  }
  // updateSupplierOrdersStatusRefunded(orderID){
  //   this.postsService.updateSupplierOrdersStatus(orderID, "Refunded").subscribe(results => {

  //     this.toastr.success("Successfully Updated!", 'Success!');
  //     location.reload();

  //   });
  // }
  updateSupplierOrdersStatusProcessing(orderID){
    var date = new Date();
    var dateStr =
      ("00" + date.getDate()).slice(-2) + "/" +
      ( "00" + (date.getMonth() + 1)).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);
    this.postsService.updateSupplierOrdersStatus(orderID, "Processing", dateStr, this.authService.getSecureToken()).subscribe(results => {

      this.toastr.success("Successfully Updated!", 'Success!');
      location.reload();

    });
  }
  //export table to excel
  exportToExcel() {
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.orderList.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'order.xlsx');
   }

}
