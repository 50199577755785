<br>
<br>
<br>

<!-- Header -->
<header class="w3-display-container w3-content w3-wide" style="max-width:1600px;" id="home">
   <div class="parallax" style="background-image: url(./assets/imgs/newsBackground.jpg);"></div>
  <div class="w3-display-middle w3-margin-top w3-center">
    <h1><a class="effect-underline"><b>NEWS FEED</b></a></h1>
  </div>
</header>
<br>
<div class="row">
    <div class="col-sm-8">
       <!-- <div class="elfsight-app-db4533f9-4a42-43e5-bb9c-aa03c3be09c7"></div> -->
      <div class="elfsight-app-a376564c-5155-459d-bd98-2397afb1d9e7"></div>
    </div>
    <div class="col-sm-4" id="facebookNewsFeed">
    <!-- <div class="fb-page" data-href="https://www.facebook.com/otolithenrichment" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true" loading="data-lazy"><blockquote cite="https://www.facebook.com/otolithenrichment" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/otolithenrichment">Otolith Enrichment</a></blockquote></div> -->
     <h3>#OtolithFacebook</h3>
    <fb-page  href="https://www.facebook.com/otolithenrichment/events/?ref=page_internal"></fb-page >
    </div>
  </div>


