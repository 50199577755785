import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PostsService } from '../posts.service';
import { Router } from '@angular/router'; 
import { AuthService } from '../auth.service'; 
import { ConnectionService } from '../connection.service';

import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { async } from '@angular/core/testing';

@Component({
	selector: 'app-add-order',
	templateUrl: './add-order.component.html',
	styleUrls: ['./add-order.component.css']
})
export class AddOrderComponent implements OnInit {

	@ViewChild(StripeCardComponent) card: StripeCardComponent;

	cardOptions: StripeCardElementOptions = {
		style: {
			base: {
				iconColor: '#666EE8',
				color: '#31325F',
				fontWeight: '300',
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSize: '18px',
				'::placeholder': {
					color: '#CFD7E0'
				}
			}
		}
	};

	elementsOptions: StripeElementsOptions = {
		locale: 'en'
	};

	addOrderForm: FormGroup;
	orders: any = [];
	carts: any = [];
	itemName: string;
	price: number;
	amount: number;
	category: string;
	image: string;
	quantity: number;
	order_date: Date = new Date();
	emailContent: any = [];
	supplierEmailContents: any = [];
	totalAmount: any;
	userGCA: number;
	submitted: boolean;
	formProcess: boolean;
	itemID: string;
	programID: string;
	discountPrice: number;
	loginUserDetails: any;
	supplier: any;
	paidWithGC:boolean;
	shippingMethod:string;
	supplierTotal: number =0;
	greenCurrencyUsed:number;

	constructor(private stripeService: StripeService, private postsService: PostsService, private fb: FormBuilder, private toastr: ToastrService, private router: Router, private authService: AuthService, private connectionService: ConnectionService) {
		//Get values passed from cart.component.ts
		const navigation = this.router.getCurrentNavigation();
		const state = navigation.extras.state as {
			total: number,
			ID: any,
			userGCA: number,
			shippingMethod:string
		};
		this.userGCA = state.userGCA;
		this.totalAmount = state.total.toFixed(2);
		this.programID = state.ID;
        this.shippingMethod = state.shippingMethod;
		//Get currenty login user info
		this.authService.postGetUserDetails(this.authService.getUserID()).subscribe(userInfo => {
			this.loginUserDetails = userInfo;
		});
		//Get user cart
		this.postsService.postGetCart(this.authService.getUserID()).subscribe(carts => {
			this.carts = carts;
		});
	}

	ngOnInit() {
		this.addOrderForm = this.fb.group({
			fullname: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			phone: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
			address: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
			radioBtn: ['', Validators.required],
			unitNo: '',
			tnc: ['', Validators.required]
		});
		//Hide text for terms and conditional and payment policy by default
		var moreText = document.getElementById("more");
		moreText.style.display = "none";
		var more2Text = document.getElementById("more2");
		more2Text.style.display = "none";
	}
	
	//Delete all selected items in users cart and go to order page
	clearSelectedItem() {
		this.postsService.postClearSelectedItem(this.authService.getUserID()).subscribe(results => {
			setTimeout(() =>{ 
				this.router.navigateByUrl('/order').then(() =>
				{
				location.reload();
				});    
			}, 3000);
			
		});
	}

	resolveAfter2Seconds() {
		return new Promise(function(myResolve, myReject) {
			let x = 0;
		  if (x == 0) {
			setTimeout(() => {
			  myResolve('resolved');
			}, 2000);
		  } 
		  else {
			myReject("Error");
		  }
		  });
	  }
	  
	  
	resolveAfter3Seconds() {
		return new Promise(function(myResolve, myReject) {
			let x = 0;
		  if (x == 0) {
			setTimeout(() => {
			  myResolve('resolved2');
			}, 3000);
		  } 
		  else {
			myReject("Error");
		  }
		  });
	  }
	  
	  
	async asyncCall() {
		try{
		  // expected output: "resolved"
		  console.log('calling');
		  const result = await this.resolveAfter2Seconds();
		  console.log(result);
		  console.log('next action');
		  const result2 = await this.resolveAfter3Seconds();
		  console.log(result2)
		} 
		catch(err) {
			  // expected output: "Error"
		  console.log(err);
		}
	   
	}
	  
	//Create token for stripe payment
	async createToken() {
		const name = this.addOrderForm.get('fullname').value;
		await this.stripeService
			.createToken(this.card.element, {
				name
			})
			.subscribe((result) => {
				//if credit card token was created successfully
				if (result.token) {
					this.connectionService.Checkout(this.totalAmount, result.token.id).subscribe(() => {
						var productArray: any = [];
						var supplierEmailArray: any =[];
						var productList: any = [];
						var userHouseLoc: any;
						var unitNo: any;
						this.connectionService.getLocationByPostalCode(this.addOrderForm.value.address).subscribe(location => {

							for (var i = 0; i < this.carts.length; i++) {
								var deductedAmount: number = 0;
								var deductedAmount1: number = 0;
								var total1 = 0;
								var total2 = 0;
								var total3 = 0;
								var total4 = 0;
								var greenCurrency1 = 0;
								var greenCurrency2 = 0;
								this.itemID = this.carts[i].itemID;
								this.itemName = this.carts[i].name;
								productArray.push(this.carts[i].name);
								this.price = this.carts[i].price;
								this.discountPrice = this.carts[i].discount_price;
								this.category = this.carts[i].category;
								this.quantity = this.carts[i].quantity;
								this.supplier = this.carts[i].supplier;
								delete this.supplier.companyLogo
								this.paidWithGC = this.carts[i].paidWithGC;
								
								supplierEmailArray.push({"itemName":this.carts[i].name, "itemPrice": this.carts[i].price, "itemDiscountPrice": this.carts[i].discount_price, "itemQuantity": this.carts[i].quantity, "supplierName": this.carts[i].supplier.companyName, "supplierEmail": this.carts[i].supplier.sellersEmail, "paidWithGC": this.carts[i].paidWithGC, "maxGreenCurrency": this.carts[i].maxGreenCurrency, "selected": this.carts[i].selected, "pickupLocation": this.carts[i].supplier.pickupLocation, "deliveryFee": this.carts[i].supplier.deliveryFee});
			                    // check whether if the product has discount price
								if (this.carts[i].discount_price != null) {
									// check whether the user used green currency
									if (this.carts[i].paidWithGC == true) {
										deductedAmount = this.carts[i].discount_price * this.carts[i].quantity * this.carts[i].maxGreenCurrency / 100
										deductedAmount1 = Math.round((deductedAmount + Number.EPSILON) * 100) / 100
			
										total1 += (this.carts[i].discount_price * this.carts[i].quantity - deductedAmount1);
									    // calculate amount of green currency used
										greenCurrency1 = this.carts[i].discount_price * this.carts[i].maxGreenCurrency;
									} else {
										total2 += this.carts[i].discount_price * this.carts[i].quantity;
			
									}
								} 
								else {
									// check whether the user used green currency
									if (this.carts[i].paidWithGC == true) {
										deductedAmount = this.carts[i].price * this.carts[i].quantity * this.carts[i].maxGreenCurrency / 100
										deductedAmount1 = Math.round((deductedAmount + Number.EPSILON) * 100) / 100
			
										total3 += (this.carts[i].price * this.carts[i].quantity - deductedAmount1);
										// calculate amount of green currency used
										greenCurrency2 = this.carts[i].price * this.carts[i].maxGreenCurrency;
									} else {
										total4 += this.carts[i].price * this.carts[i].quantity;
									}
								}
								//calculate all selected items from cart and total amount of green currency the user use
								this.amount = total1 + total2 + total3 + total4;
								this.greenCurrencyUsed = greenCurrency1 + greenCurrency2;
								
								if (location["results"][0] == null) {
									this.toastr.warning('Invalid Postal Code', 'Warning');
									return;
								} else if (this.addOrderForm.value.radioBtn == "Landed Housing") {
									userHouseLoc = location["results"][0]["ADDRESS"];
									if (this.carts[i].selected == true) {
										//Create a new order
										this.postsService.addOrder(this.authService.getUserID(), this.itemID, this.itemName, this.price, this.discountPrice, this.category, this.quantity, this.amount, this.addOrderForm.value.fullname, this.addOrderForm.value.email, this.addOrderForm.value.phone, userHouseLoc, "not applicable", this.addOrderForm.value.radioBtn, this.order_date, "Credit/Debit Card", this.supplier, "Processing ", this.paidWithGC, this.greenCurrencyUsed, this.shippingMethod).subscribe(results => {
											this.toastr.success("Successfully ordered!", 'Success!');
											this.clearSelectedItem();
											this.authService.useGC(this.authService.getUserID(), this.userGCA)
										});
									}
								} else {
									//Check for unit no format
									if (/^#[\d]{2}-[\d]{2,4}$/.test(this.addOrderForm.value.unitNo) == false) {
										this.toastr.warning('Invalid unit no format', 'Warning');
										return
									} else {
										if (this.carts[i].selected == true) {
											userHouseLoc = location["results"][0]["ADDRESS"];
											//Create a new order
											this.postsService.addOrder(this.authService.getUserID(), this.itemID, this.itemName, this.price, this.discountPrice, this.category, this.quantity, this.amount, this.addOrderForm.value.fullname, this.addOrderForm.value.email, this.addOrderForm.value.phone, userHouseLoc, this.addOrderForm.value.unitNo, this.addOrderForm.value.radioBtn, this.order_date, "Credit/Debit card", this.supplier, "Processing", this.paidWithGC, this.greenCurrencyUsed, this.shippingMethod).subscribe(results => {
												this.toastr.success("Successfully ordered!", 'Success!');
												this.clearSelectedItem();
												this.authService.useGC(this.authService.getUserID(), this.userGCA);
											});
										}
									}
								}
			
							}
							//Add user to programs if they paid for it
							this.addToPrograms();
			
							for (var i = 0; i < productArray.length; i++) {
								if (this.carts[i].selected == true) {
									productList += '<li>' + productArray[i] + '  QTY: ' + this.carts[i].quantity + '  Supplier: ' + this.carts[i].supplier.companyName + '  Pickup Location: ' + this.carts[i].supplier.pickupLocation + '</li>';
								}
							}
			
							if (this.addOrderForm.value.unitNo == "") {
								unitNo = 'not applicable';
							} else {
								unitNo = this.addOrderForm.value.unitNo;
							}
							
							var supplierProductList: any = []
							var supplierNames: any = []
							var supplierEmailNow: any;
							var supplierNameNow: any;
							var supplierDeliveryFee:any;
							for (var i = 0; i < supplierEmailArray.length; i++) {
								if(supplierNames.includes(supplierEmailArray[i].supplierName) == false){
									supplierNames.push(supplierEmailArray[i].supplierName);
								}
							}
			
							for(var j = 0; j < supplierNames.length; j++){
													  
									for(var i = 0; i < supplierEmailArray.length; i++) {
																	
										if (supplierEmailArray[i].supplierName == supplierNames[j]) {
											
											if (supplierEmailArray[i].selected == true) {
			
													supplierProductList += '<li>' + supplierEmailArray[i].itemName + '  QTY: ' + supplierEmailArray[i].itemQuantity + '  Supplier: ' + supplierEmailArray[i].supplierName + '  Pickup Location: ' + supplierEmailArray[i].pickupLocation + '</li>';
													supplierEmailNow = supplierEmailArray[i].supplierEmail
													supplierNameNow = supplierEmailArray[i].supplierName;
													//if user select delivery then save the delivery fee
													if(this.shippingMethod == "Delivery"){
														supplierDeliveryFee = supplierEmailArray[i].deliveryFee;
														}
														else{
														supplierDeliveryFee = 0;
														}
													if (supplierEmailArray[i].itemDiscountPrice != null) {
														
														if (supplierEmailArray[i].paidWithGC == true) {
															var minusAmount = supplierEmailArray[i].itemDiscountPrice * supplierEmailArray[i].itemQuantity * supplierEmailArray[i].maxGreenCurrency / 100
															var minusAmount1 = Math.round((minusAmount + Number.EPSILON) * 100) / 100
								
															this.supplierTotal += (supplierEmailArray[i].itemDiscountPrice * supplierEmailArray[i].itemQuantity - minusAmount1);
														} 
														else {
															this.supplierTotal += supplierEmailArray[i].itemDiscountPrice * supplierEmailArray[i].itemQuantity;
								
														}
													} 
													else {
														
														if (supplierEmailArray[i].paidWithGC == true) {
															
															var minusAmount = supplierEmailArray[i].itemPrice * supplierEmailArray[i].itemQuantity * supplierEmailArray[i].maxGreenCurrency / 100
															
															var minusAmount1 = Math.round((minusAmount + Number.EPSILON) * 100) / 100
								
															this.supplierTotal += (supplierEmailArray[i].itemPrice * supplierEmailArray[i].itemQuantity - minusAmount1);
														} 
														else {
															this.supplierTotal += supplierEmailArray[i].itemPrice * supplierEmailArray[i].itemQuantity;
														}
													}
												}
			
										}
			
									}
									var supplierTotalAmount = this.supplierTotal + supplierDeliveryFee;
									this.supplierEmailContents.push(this.order_date.toISOString().slice(0, 10));
									this.supplierEmailContents.push(supplierTotalAmount.toFixed(2));
									this.supplierEmailContents.push(this.addOrderForm.value);
									this.supplierEmailContents.push(supplierProductList);
									this.supplierEmailContents.push(userHouseLoc);
									this.supplierEmailContents.push(unitNo);
									this.supplierEmailContents.push(this.shippingMethod);
									this.supplierEmailContents.push(supplierEmailNow);
									this.supplierEmailContents.push(supplierNameNow);
									//Send email of all the product the user purchased from the supplier to the supplier
									this.connectionService.sendMessageSupplierCreditCard(this.supplierEmailContents).subscribe(() => {
									}, (error: any) => {
										console.log('Error', error);
									});
								supplierProductList = [];
								supplierEmailNow ="";
								supplierNameNow =""
								this.supplierEmailContents = [];
								this.supplierTotal = 0;
								  }
							
							this.emailContent.push(this.order_date.toISOString().slice(0, 10));//0
							this.emailContent.push(this.totalAmount);//1
							this.emailContent.push(this.addOrderForm.value);//2
							this.emailContent.push(productList);//3
							this.emailContent.push(userHouseLoc);//4
							this.emailContent.push(unitNo);//5
							this.emailContent.push(this.shippingMethod);//6
			
							// sent email to user and otolith staff
							 this.connectionService.sendMessage(this.emailContent).subscribe(() => {
								this.addOrderForm.reset();
							}, (error: any) => {
								console.log('Error', error);
							});
						});

					}, (error: any) => {
						this.toastr.error('Error!', error);
					});
				//If there was an error creating credit card token	
				} else if (result.error) {
					// Error creating the token
					this.toastr.error('Error!', result.error.message);
					return 0;
				}
			});
	}
	//Add user to programs they paid for
	addToPrograms() {
		var userName = this.authService.getSecureToken();

		for (var i = 0; i < this.programID.length; i++) {
			this.postsService.addToPrograms(this.programID[i], userName).subscribe(results => {});
		}
	}

	// show or hide the terms and conditions
	showText() {
		var dots = document.getElementById("dots");
		var moreText = document.getElementById("more");
		var tncText = document.getElementById("myTNC");
	  
		if (dots.style.display === "none") {
		  dots.style.display = "inline";
		  tncText.innerHTML = "Terms and Conditions"; 
		  moreText.style.display = "none";
		} else {
		  dots.style.display = "none";
		  tncText.innerHTML = "Terms and Conditions"; 
		  moreText.style.display = "inline";
		}
	  }

	  // show or hide the privacy policy
	  showText2() {
		var dots2 = document.getElementById("dots2");
		var more2Text = document.getElementById("more2");
		var policyText = document.getElementById("myPolicy");
	  
		if (dots2.style.display === "none") {
		  dots2.style.display = "inline";
		  policyText.innerHTML = "Privacy Policy"; 
		  more2Text.style.display = "none";
		} else {
		  dots2.style.display = "none";
		  policyText.innerHTML = "Privacy Policy"; 
		  more2Text.style.display = "inline";
		}
	  }
	//Field validations
	onSubmit() {
	
		if (this.addOrderForm.value.fullname == "" || this.addOrderForm.value.email == "" || this.addOrderForm.value.phone == "" || this.addOrderForm.value.address == "" || this.addOrderForm.value.radioBtn == "" || this.addOrderForm.value.tnc == "") {
			this.toastr.warning('Fill in all input fields and agree with the terms and conditions & privacy policy!', 'Warning');
    	} 
    	else if (this.addOrderForm.controls.email.hasError('email')) {
			this.toastr.warning('Invalid email!', 'Warning');
		} else if (/[\d]{8}/.test(this.addOrderForm.value.phone) == false) {
			this.toastr.warning('Invalid phone number format!', 'Warning');
		} else if (this.addOrderForm.controls.address.hasError('minlength') || this.addOrderForm.controls.address.hasError('maxlength')) {
			this.toastr.warning('Invalid postal code!', 'Warning');
		} else if (confirm('Are you sure you want to order now ?')) {
			this.createToken()
		 } //End of if statement

	}


}
