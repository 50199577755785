import { Component, OnInit } from "@angular/core";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { EncrDecrService } from "../encr-decr-service";
import endpoint from "endpoints.config";

@Component({
	selector: "app-email-validation",
	templateUrl: "./email-validation.component.html",
	styleUrls: ["./email-validation.component.css"],
})
export class EmailValidationComponent implements OnInit {
	myForm: FormGroup;
	results: any = false;
	userNameList: any[];
	secretKey = endpoint.secretKey;

	constructor(
		private fb: FormBuilder,
		private authService: AuthService,
		private router: Router,
		private toastr: ToastrService,
		private EncrDecr: EncrDecrService
	) {
		// Post get all users
		this.authService.macSpicyTooSpicy().subscribe((data) => {
			var array = [];
			for (var i = 0; i < data.length; i++) {
				var collected;
				var decryptedClockIn = data[i].encryptedClockIn;
				var decryptedClockInDateTime = data[i].encryptedClockInDateTime;
				var decryptedClockInDateTimeList = data[i].encryptedClockInDateTimeList;
				var decryptedVolunteeringLocation = data[i].encryptedVolunteeringLocation;
				var decryptedName = this.EncrDecr.get(this.secretKey, data[i].encryptedName);
				var decryptedEmail = this.EncrDecr.get(this.secretKey, data[i].encryptedEmail);
				var decryptedAddress = this.EncrDecr.get(this.secretKey, data[i].encryptedAddress);
				var decryptedUnitNo = this.EncrDecr.get(this.secretKey, data[i].encryptedUnitNo);
				var decryptedBoxDonated = this.EncrDecr.get(this.secretKey, data[i].encryptedBoxDonated);
				var decryptedBoxDonatedDates = data[i].encryptedBoxDonatedDates;
				var decryptedBoxIssuedDates = data[i].encryptedBoxIssuedDates;
				var decryptedCollected = this.EncrDecr.get(this.secretKey, data[i].encryptedCollected);
				if (decryptedCollected == "true") {
					collected = true;
				} else {
					collected = false;
				}
				var decryptedHousingType = this.EncrDecr.get(this.secretKey, data[i].encryptedHousingType);
				var decryptedLastBoxDonatedDate = data[i].encryptedLastBoxDonatedDate;
				var decryptedMobile = this.EncrDecr.get(this.secretKey, data[i].encryptedMobile);
				var decryptedNewBoxIssueDate = data[i].encryptedNewBoxIssueDate;
				var decryptedPassword = this.EncrDecr.get(this.secretKey, data[i].encryptedPassword);
				var decryptedRole = this.EncrDecr.get(this.secretKey, data[i].encryptedRole);
				var decryptedSupplier = this.EncrDecr.get(this.secretKey, data[i].encryptedSupplier);
				var decryptedTemporaryToken = data[i].encryptedTemporaryToken;
				var decryptedVerified = data[i].encryptedVerified;
				var decrypted_id = this.EncrDecr.get(this.secretKey, data[i].encrypted_id);
				var obj = {
					volunteeringLocation: decryptedVolunteeringLocation,
					clockInDateTimeList: decryptedClockInDateTimeList,
					clockInDateTime: decryptedClockInDateTime,
					clockIn: decryptedClockIn,
					_id: decrypted_id,
					verified: decryptedVerified,
					temporaryToken: decryptedTemporaryToken,
					supplier: decryptedSupplier,
					role: decryptedRole,
					password: decryptedPassword,
					newBoxIssueDate: decryptedNewBoxIssueDate,
					mobile: decryptedMobile,
					lastBoxDonatedDate: decryptedLastBoxDonatedDate,
					housingType: decryptedHousingType,
					greenCurrency: data[i].greenCurrency,
					collected: collected,
					boxIssuedDates: decryptedBoxIssuedDates,
					boxDonatedDates: decryptedBoxDonatedDates,
					boxDonated: decryptedBoxDonated,
					name: decryptedName,
					email: decryptedEmail,
					address: decryptedAddress,
					unitNo: decryptedUnitNo,
				};
				array.push(obj);
			}

			this.userNameList = array;
			//console.log(this.userNameList)
		});
	}

	ngOnInit() {
		this.myForm = this.fb.group({
			password: "",
			repassword: "",
		});
	}

	// toggle password visiability
	passwordToggle() {
		var x = <HTMLInputElement>document.getElementById("password");
		var y = <HTMLInputElement>document.getElementById("password-status");
		if (x.type === "password") {
			x.type = "text";
			y.className = "fa fa-eye-slash field-icon";
		} else {
			x.type = "password";
			y.className = "fa fa-eye field-icon";
		}
	}

	// toggle repassword visiability
	repasswordToggle() {
		var x = <HTMLInputElement>document.getElementById("repassword");
		var y = <HTMLInputElement>document.getElementById("repassword-status");
		if (x.type === "password") {
			x.type = "text";
			y.className = "fa fa-eye-slash field-icon";
		} else {
			x.type = "password";
			y.className = "fa fa-eye field-icon";
		}
	}

	onSubmit() {
		if (this.myForm.value.password == "" || this.myForm.value.repassword == "") {
			this.toastr.warning("Fill in all fields!", "Warning");
		}

		//Check if passwords match
		else if (this.myForm.value.password != this.myForm.value.repassword) {
			this.toastr.warning("Passwords don't match!", "Warning");
		} else {
			//verify your account successful
			const token = window.location.href.split("/").pop();
			this.authService.verifyAccount(token, this.myForm.value.password.trim()).subscribe((data) => {
				this.results = data;

				if (this.results[0].auth) {
					this.authService.setSecureToken(this.results[0].username);
					this.authService.setUserID(this.results[0].userID);
					sessionStorage.setItem("key", "loggin");
					this.toastr.success(this.results[0].username, "Welcome!");
					localStorage.removeItem("regUsername");
					this.router.navigateByUrl("/home");
				} else {
					this.toastr.warning("Invalid input", "Warning");
				}
			});
		}
	}
}
