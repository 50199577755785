<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>

<!-- Header -->
<header class="w3-display-container w3-content w3-wide" style="max-width:1600px;" id="home"> 
  <!-- <div class="parallax" style="background-image: url(./assets/imgs/productBackgroundImage.jpg);"></div>
  -->
  <div style="text-align: center;">
   <img class="w3-image" src="./assets/imgs/finalLogo.png">
  </div>
</header> 
<br>
<br>
<br>
<br>
<br>

<div class="filterBtn">
    <button type="button" class="btn btn-outline-success mr-2 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="fa fa-filter">   Filter</i>
    </button>
    <div class="dropdown-menu">
      <button class="dropdown-item" (click)="getNewDate()">Date (new to old)</button>
       <div class="dropdown-divider"></div>
       <button class="dropdown-item" (click)="getOldDate()">Date (old to new)</button>
       <div *ngFor="let supplier of sellers" [ngValue]="supplier">
       <div class="dropdown-divider"></div>
       <button class="dropdown-item" (click)="getProductsBySupplier(supplier._id)">Supplier ({{ supplier.companyName }})</button>
      </div>
    </div>
    
 </div>

<div class="center">
  <div class="main">
     <div class="form-group has-search">
        <span class="fa fa-search form-control-feedback"></span>
        <input type="text" (keydown)="search($event)" class="form-control" placeholder="Search product">
     </div>
  </div>
</div>
<p><a routerLink="/addProducts" class="postProductsBtn" *ngIf="service.isAdmin()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Add Products</a></p>
<br>
<div class="row">
    <div class="row equal">        
        <div class="col-sm-4 d-flex pb-3" *ngFor="let product of products | paginate: { itemsPerPage: 6, currentPage: p } ">
          
            <div class="card card-block" style="text-align:center">
                <div class="img-border-sm mb-4">
                   <a [routerLink]="['/productsInfo',product._id]" >
                      <div class="image-box">
                         <img src={{product.image}} alt="" class="img-fluid">
                      </div>
                   </a>
                </div>
                <h3 class="heading mb-0" style="font-size:18px"><a style="color: #000" [routerLink]="['/productsInfo',product._id]">{{product.name}}</a></h3>
                <p *ngIf="product.price != null">Retail Price: {{product.price | currency:'USD'}}</p>
                <p class="text-danger" *ngIf="product.discount_price != null">Discounted Price: {{product.discount_price | currency:'USD'}}</p>
                <p>By: {{product.supplier.companyName}}<img src={{product.supplier.companyLogo}} alt="companyLogo" width="30" height="30">
                </p>
               
                <p>
                   <button (click)="addToCart(product._id, product.name, product.price, product.discount_price, product.maxGreenCurrency, product.category, product.image, product.availability, product.supplier)" class="btn btn-outline-primary mr-2"><i class="fa fa-shopping-cart"></i>  Add To Cart</button>
                   <button (click)="addToWishlist(product._id, product.name, product.price, product.discount_price, product.maxGreenCurrency, product.category, product.image, product.availability, product.supplier)" class="btn btn-outline-success mr-2"><i class="fa fa-heart-o"></i>  Add To Wishlist</button>
                </p>
             </div>                          
        </div>
   
    </div>

  <div class="center">
     <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>