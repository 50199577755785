<br>
<br>
<br>
<br>
<br>
<div id="print-section"> 
<br>
<div class="section-title">
   <h2> List of Orders</h2>
   <div class="main">
      <div class="form-group has-search">
          <span class="fa fa-search form-control-feedback"></span>
         <input type="text" (keydown)="search($event)" class="form-control" placeholder="Search By Customer Name">
      </div>
   </div>
</div>

  <div class="filterBtn" style="margin-left:10px">
        <button type="button" class="btn btn-outline-success mr-2 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-filter">   Filter</i>
        </button>
        <div class="dropdown-menu">
          <div *ngFor="let supplier of sellers" [ngValue]="supplier">
           <button class="dropdown-item" (click)="getSupplierOrdersBySupplier(supplier._id)">Supplier ({{ supplier.companyName }})</button>
          </div>
        </div>
        
     </div>
  <br>
  <br>

<!-- Order table -->
<div class="table-responsive" #orderList>
   <table class="table table-hover">
      <!-- Table head -->
      <thead class="thead-dark">
         <tr>
            <th class="font-weight-bold">
               <strong> Order ID #</strong>
            </th>
            <th class="font-weight-bold">
               <strong>Ordered By</strong>
            </th>
            <th class="font-weight-bold">
               <strong>Product</strong>
            </th>
            <th class="font-weight-bold">
               <strong>Price</strong>
            </th>
            <th class="font-weight-bold">
               <strong>Category</strong>
            </th>
            <th class="font-weight-bold">
               <strong>Supplier</strong>
            </th>
            <th class="font-weight-bold">
               <strong>Quantity</strong>
            </th>
            <th class="font-weight-bold">
               <strong>Total Amount Paid</strong>
            </th>
            <th class="font-weight-bold">
               <strong>Order Status</strong>
            </th>
            <th class="font-weight-bold">
               <strong>Order Date</strong>
            </th>
           
         </tr>
      </thead>
      <!-- /.Table head -->
      <!-- Table body -->
      <tbody >
         <!-- row -->
         <tr *ngFor="let order of orders">
            <th  [routerLink]="['/orderInfo',order._id]"> {{order.userID}} </th>
            <td  [routerLink]="['/orderInfo',order._id]"> {{order.fullname}}</td>
            <td  [routerLink]="['/orderInfo',order._id]"> {{order.name}}</td>
            <td  [routerLink]="['/orderInfo',order._id]"><a *ngIf="order.price != null">Retail Price: {{order.price | currency:'USD'}}</a><br><a *ngIf="order.discount_price != null && order.discount_price != '' ">Discounted Price: {{order.discount_price | currency:'USD'}}</a></td>
            <td  [routerLink]="['/orderInfo',order._id]"> {{order.category}}</td>
            <td  [routerLink]="['/orderInfo',order._id]"> {{order.supplier.companyName}} </td>
            <td  [routerLink]="['/orderInfo',order._id]"> {{order.quantity}}</td>
            <td  class="font-weight-bold"  [routerLink]="['/orderInfo',order._id]">{{order.amount | currency:'USD'}}</td>
            <td> {{order.orderStatus}}
                <div class="btn-group">
                   <button type="button" *ngIf="service.isAdmin()" class="btn btn-success dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Amend Status
                   </button>
                   <div class="dropdown-menu">
                     <button class="dropdown-item" *ngIf="service.isAdmin()" (click)="updateSupplierOrdersStatusCompleted(order._id)">Completed</button>
                     <div class="dropdown-divider" *ngIf="service.isAdmin()"></div>
                     <button class="dropdown-item" *ngIf="service.isAdmin()" (click)="updateSupplierOrdersStatusRefunded(order._id)">Refunded</button>
                     <div class="dropdown-divider" *ngIf="service.isAdmin()"></div>
                     <button class="dropdown-item" *ngIf="service.isAdmin()" (click)="updateSupplierOrdersStatusCancelled(order._id)">Cancelled</button>
                   </div>
                 </div>   
             </td>
            <td  [routerLink]="['/orderInfo',order._id]"> {{order.order_date | date : 'dd/MM/yyyy'}}</td>
            
         </tr>
         <!-- row -->
      </tbody>
      <!-- /.Table body -->
   </table>
 <!-- /.Order table -->
</div>

</div>
<button (click)="exportToExcel()"  id="topright2" class="btn btn-outline-success mr-2">Export To Excel</button> 
<button id="topright" class="btn btn-outline-success mr-2" printSectionId="print-section" ngxPrint>Print</button> 
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>