import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PostsService } from '../posts.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-seller-info',
  templateUrl: './seller-info.component.html',
  styleUrls: ['./seller-info.component.css']
})
export class SellerInfoComponent implements OnInit {
  editSellerForm: FormGroup;
  _id: string;
  private sub: any;
  sellerInfo: any = [];
  base64Image = null;
  oldImage: any;
  productsArray: any = [];
  productsEmpty:boolean;
  msg:string;
  programsArray: any = [];
  programsEmpty:boolean;
  msg2:string;
  companyUEN: any;

  constructor(private fb: FormBuilder, private postsService: PostsService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService) 
  {
    //get all products
    this.postsService.postGetAllProducts().subscribe(product => {
      for(var i = 0; i < product.length; i++)
      {
        if(product[i].supplier.companyUEN == this.companyUEN)
        {
          this.productsArray.push(product[i]);
          this.msg = ""
          this.productsEmpty = false
        }
        else
        {
          this.msg = "This company haven't added any product"
          this.productsEmpty = true
        }
      }
          
      });
      //get all programs
      this.postsService.postGetAllProgrammes().subscribe(program => {
        for(var i = 0; i < program.length; i++)
        {
          if(program[i].supplier.companyUEN == this.companyUEN)
          {
            this.programsArray.push(program[i]);
            this.msg2 = ""
            this.programsEmpty = false
          }
          else
          {
            this.msg2 = "This company haven't added any program"
            this.programsEmpty = true
          }
        }
      });
  }
  
  ngOnInit() {
  
    this.sub = this.route.params.subscribe(params => {
      this._id = params['_id'];
  
      this.postsService.postGetSellersByID(this._id).subscribe(sellerInfo => {
        this.sellerInfo = sellerInfo;
        this.companyUEN = this.sellerInfo[0].companyUEN;

        this.editSellerForm = this.fb.group({
          companyName: this.sellerInfo[0].companyName,
          companyUEN: this.sellerInfo[0].companyUEN,
          sellersEmail: [this.sellerInfo[0].sellersEmail, [Validators.required, Validators.email]],
          deliveryFee: this.sellerInfo[0].deliveryFee,
          giveFreeShipping: this.sellerInfo[0].giveFreeShipping,
          pickupLocation: this.sellerInfo[0].pickupLocation,
          image: null
        });
        this.oldImage = this.sellerInfo[0].companyLogo
  
      });
  
    });
  }
  
  handleUpload(event) {
    const comp = this;
    const img = < File > event.target.files[0];
    const promise = new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      }
      reader.readAsDataURL(img);
    });
  
    promise.then(img => {
      comp.base64Image = img;
    });
  }
  
  updateSeller(id: string) {
    if (this.editSellerForm.value.companyName == "" || this.editSellerForm.value.companyUEN == "" || this.editSellerForm.value.deliveryFee == "" || this.editSellerForm.value.sellersEmail =="" || this.editSellerForm.value.giveFreeShipping == "" || this.editSellerForm.value.pickupLocation == "") {
      this.toastr.warning('Fill in all input fields!', 'Warning');
    }

    else if(this.editSellerForm.controls.sellersEmail.hasError('email'))
    {
      this.toastr.warning('Invalid email!', 'Warning');
    }

     else if (this.editSellerForm.value.image == null) {
      //update seller info
      this.postsService.editSeller(id, this.editSellerForm.value.companyName, this.oldImage, this.editSellerForm.value.sellersEmail, this.editSellerForm.value.companyUEN, this.editSellerForm.value.deliveryFee, this.editSellerForm.value.giveFreeShipping, this.editSellerForm.value.pickupLocation).subscribe(results => {
        this.toastr.success("Successfully Updated!", 'Success!');
        this.router.navigateByUrl('/adminSellerList');
      });
    } else {
      //update seller info
      this.postsService.editSeller(id, this.editSellerForm.value.companyName, this.base64Image, this.editSellerForm.value.sellersEmail, this.editSellerForm.value.companyUEN, this.editSellerForm.value.deliveryFee, this.editSellerForm.value.giveFreeShipping, this.editSellerForm.value.pickupLocation).subscribe(results => {
        this.toastr.success("Successfully Updated!", 'Success!');
        this.router.navigateByUrl('/adminSellerList');
      });
    }
  
  }
}
