import { Component, OnInit } from '@angular/core';
import { PostsService } from '../posts.service';
import { AuthService } from '../auth.service';
import { EncrDecrService } from '../encr-decr-service';
import endpoint from 'endpoints.config';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  orders: any = [];
  secretKey = endpoint.secretKey;

  constructor(private postsService: PostsService, public service: AuthService, private EncrDecr: EncrDecrService) {
    //Post get all orders 30 days
    this.postsService.doYouKnowThatHackingIsACrimeTT().subscribe(data => {
     var array = []
     var orderArray = []
       for(var i = 0; i < data.length; i++){
         var decryptedOrder_date = this.EncrDecr.get(this.secretKey, data[i].encryptedOrder_date);
         var decrypted_id = this.EncrDecr.get(this.secretKey, data[i].encrypted_id);
         var decryptedOrderStatus= this.EncrDecr.get(this.secretKey, data[i].encryptedOrderStatus);
         var obj = { "order_date":decryptedOrder_date,  "_id":decrypted_id, "orderStatus":decryptedOrderStatus };
      
             array.push(obj);
         } 
      orderArray = array;

      for (var i = 0; i < orderArray.length; i++){

        JSON.stringify(orderArray[i]._id);
        // Selected date
        var selectedTimestamp = new Date(orderArray[i].order_date).getTime();
        //console.log("orders[i].order_date: " + selectedTimestamp);
        //Current date - 30 days
        var timestamp = new Date().getTime() - (30 * 24 * 60 * 60 * 1000);
        //console.log("currentdatetime: " + timestamp);

        if (timestamp > selectedTimestamp) {
          // The selected time is less than 30 days from now
          //console.log("OrderID: " + orders[i]._id + " 30 days or more has passed from the selected time to now");
          if(orderArray[i].orderStatus == "Completed")
          {
            //console.log("OrderID: " + orders[i]._id + " is already completed");
          }
          else
          {
            this.postsService.systemUpdateOrdersStatus(orderArray[i]._id).subscribe(results => {
            });;
          }
         
        }
        else if (timestamp < selectedTimestamp) {
          // The selected time is more than 30 days from now
          //console.log("OrderID: " + orders[i]._id + " The selected time is less than 30 days from now");
        }
        else {
          // -Exact- same timestamps.
          //console.log("OrderID: " + orders[i]._id + " order_date the exact same timestamps");
        }
      }
    });
  }

  ngOnInit() {
    // var moreText = document.getElementById("more");
    // moreText.style.display = "none";
  }

  // showText() {
  //   var dots = document.getElementById("dots");
  //   var moreText = document.getElementById("more");
  //   var btnText = document.getElementById("myBtn");
  
  //   if (dots.style.display === "none") {
  //     dots.style.display = "inline";
  //     btnText.innerHTML = "Read more"; 
  //     moreText.style.display = "none";
  //   } else {
  //     dots.style.display = "none";
  //     btnText.innerHTML = "Read less"; 
  //     moreText.style.display = "inline";
  //   }
  // }
 
}
