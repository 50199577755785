import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service'; 
import { EncrDecrService } from '../encr-decr-service';
import endpoint from 'endpoints.config';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  elementType: "url" | "canvas" | "img" = "url";
  userList: any = [];
  loadedUserList: any[];
  dateNow: Date = new Date();
  text: string = "not applicable";
  secretKey = endpoint.secretKey;

  constructor(private authService: AuthService, private EncrDecr: EncrDecrService) 
  {
    //post get all residents
    this.authService.stopAndLook().subscribe(data =>{
      var array = []
      for(var i = 0; i < data.length; i++){
        var collected
        var decryptedName = this.EncrDecr.get(this.secretKey, data[i].encryptedName);
        var decryptedEmail = this.EncrDecr.get(this.secretKey, data[i].encryptedEmail);
        var decryptedAddress = this.EncrDecr.get(this.secretKey, data[i].encryptedAddress);
        var decryptedUnitNo = this.EncrDecr.get(this.secretKey, data[i].encryptedUnitNo);
        var decryptedBoxDonated= this.EncrDecr.get(this.secretKey, data[i].encryptedBoxDonated);
        var decryptedBoxDonatedDates= this.EncrDecr.get(this.secretKey, data[i].encryptedBoxDonatedDates);
        var decryptedBoxIssuedDates= this.EncrDecr.get(this.secretKey, data[i].encryptedBoxIssuedDates);
        var decryptedCollected= this.EncrDecr.get(this.secretKey, data[i].encryptedCollected);
        if(decryptedCollected == "true"){
          collected = true;
        }
        else{
          collected = false;
        }
        var decryptedHousingType= this.EncrDecr.get(this.secretKey, data[i].encryptedHousingType);
        var decryptedLastBoxDonatedDate= this.EncrDecr.get(this.secretKey, data[i].encryptedLastBoxDonatedDate);
        var decryptedMobile= this.EncrDecr.get(this.secretKey, data[i].encryptedMobile);
        var decryptedNewBoxIssueDate= this.EncrDecr.get(this.secretKey, data[i].encryptedNewBoxIssueDate);
        var decryptedPassword= this.EncrDecr.get(this.secretKey, data[i].encryptedPassword);
        var decryptedRole= this.EncrDecr.get(this.secretKey, data[i].encryptedRole);
        var decryptedSupplier= this.EncrDecr.get(this.secretKey, data[i].encryptedSupplier);
        var decryptedTemporaryToken= this.EncrDecr.get(this.secretKey, data[i].encryptedTemporaryToken);
        var decryptedVerified= this.EncrDecr.get(this.secretKey, data[i].encryptedVerified);
        var decrypted_id= this.EncrDecr.get(this.secretKey, data[i].encrypted_id);
        var obj = {"_id":decrypted_id,"verified":decryptedVerified,"temporaryToken":decryptedTemporaryToken,"supplier":decryptedSupplier,"role":decryptedRole,"password":decryptedPassword,"newBoxIssueDate":decryptedNewBoxIssueDate,"mobile":decryptedMobile,"lastBoxDonatedDate":decryptedLastBoxDonatedDate,"housingType":decryptedHousingType,"greenCurrency":data[i].greenCurrency,"collected":collected,"boxIssuedDates":decryptedBoxIssuedDates,"boxDonatedDates":decryptedBoxDonatedDates,"boxDonated":decryptedBoxDonated,"name": decryptedName, "email": decryptedEmail, "address": decryptedAddress, "unitNo":decryptedUnitNo};
        array.push(obj);
      } 
      var Length = data.length;

      for (var i = 0; i < Length; i++){
        JSON.stringify(data[i]._id);
      }
      this.loadedUserList = array;
      this.userList = array;
    });
  }

  ngOnInit(): void {
  }

  //return the food waste box
  returnB(_id) {
    if(confirm('Are you sure you want to issue a new box?'))
    {
      this.authService.issueNewBox(_id, this.dateNow, false);
      location.reload();
    }
    
  }
  //collect a new food waste
  collectB(_id, userGreenCurrency, userBoxDonated){
    if(confirm('Are you sure you collected this resident food waste box?'))
    {
      var boxDonated = parseInt(userBoxDonated);
      this.authService.updateCollectionStatusRecieve(_id, true, userGreenCurrency + 30, boxDonated + 1, this.dateNow);
      location.reload();
    }
    
  }

  initializeItems(): void {
    this.userList = this.loadedUserList;
  }

  search(event) {
    
    this.initializeItems();
    //Set searchTerm with input from search bar
    const searchTerm = event.srcElement.value;
  
    if (!searchTerm) {
    return;
    }
    //Filters enquiries list with keywords from searchTerm
    this.userList = this.userList.filter(i => {
    if (i.name && searchTerm) {
    if (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
    return true;
    }
    return false;
    }
    });
  }

}
