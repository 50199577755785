  
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service'; 
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConnectionService } from '../connection.service';
import { PostsService } from '../posts.service';
import { EncrDecrService } from '../encr-decr-service';
import endpoint from 'endpoints.config';

@Component({
  selector: 'app-admin-user-info',
  templateUrl: './admin-user-info.component.html',
  styleUrls: ['./admin-user-info.component.css']
})
export class AdminUserInfoComponent implements OnInit {
  public account = {  
    password: null  
    
  };  
  private sub: any;
  userInfo: any = [];
  userID: string;
  userName: string;
  role: string;
  public barLabel: string = "Password strength:"; 
  myForm: FormGroup;
  elementType: "url" | "canvas" | "img" = "url"; 
  userNameList: any[];
  sellers: any;
  supplierID: any;
  supplierName: any;
  secretKey = endpoint.secretKey;

  constructor(private postsService: PostsService ,private connectionService: ConnectionService, private fb: FormBuilder, private route: ActivatedRoute, private toastr: ToastrService, private router: Router, public authService: AuthService, private EncrDecr: EncrDecrService) 
  {
    // Post get all users
    this.authService.macSpicyTooSpicy().subscribe(data =>{
      var array = []
      for(var i = 0; i < data.length; i++){
        var collected
        var decryptedClockIn= data[i].encryptedClockIn
        var decryptedClockInDateTime= data[i].encryptedClockInDateTime;
        var decryptedClockInDateTimeList= data[i].encryptedClockInDateTimeList  
        var decryptedVolunteeringLocation= data[i].encryptedVolunteeringLocation;
        var decryptedName = this.EncrDecr.get(this.secretKey, data[i].encryptedName);
        var decryptedEmail = this.EncrDecr.get(this.secretKey, data[i].encryptedEmail);
        var decryptedAddress = this.EncrDecr.get(this.secretKey, data[i].encryptedAddress);
        var decryptedUnitNo = this.EncrDecr.get(this.secretKey, data[i].encryptedUnitNo);
        var decryptedBoxDonated= this.EncrDecr.get(this.secretKey, data[i].encryptedBoxDonated);
        var decryptedBoxDonatedDates= data[i].encryptedBoxDonatedDates;
        var decryptedBoxIssuedDates= data[i].encryptedBoxIssuedDates;
        var decryptedCollected= this.EncrDecr.get(this.secretKey, data[i].encryptedCollected);
        if(decryptedCollected == "true"){
          collected = true;
        }
        else{
          collected = false;
        }
        var decryptedHousingType= this.EncrDecr.get(this.secretKey, data[i].encryptedHousingType);
        var decryptedLastBoxDonatedDate= data[i].encryptedLastBoxDonatedDate;
        var decryptedMobile= this.EncrDecr.get(this.secretKey, data[i].encryptedMobile);
        var decryptedNewBoxIssueDate= data[i].encryptedNewBoxIssueDate;
        var decryptedPassword= this.EncrDecr.get(this.secretKey, data[i].encryptedPassword);
        var decryptedRole= this.EncrDecr.get(this.secretKey, data[i].encryptedRole);
        var decryptedSupplier= this.EncrDecr.get(this.secretKey, data[i].encryptedSupplier);
        var decryptedTemporaryToken= data[i].encryptedTemporaryToken;
        var decryptedVerified= data[i].encryptedVerified;
        var decrypted_id= this.EncrDecr.get(this.secretKey, data[i].encrypted_id);
        var obj = {"volunteeringLocation":decryptedVolunteeringLocation, "clockInDateTimeList":decryptedClockInDateTimeList,"clockInDateTime":decryptedClockInDateTime,"clockIn":decryptedClockIn,"_id":decrypted_id,"verified":decryptedVerified,"temporaryToken":decryptedTemporaryToken,"supplier":decryptedSupplier,"role":decryptedRole,"password":decryptedPassword,"newBoxIssueDate":decryptedNewBoxIssueDate,"mobile":decryptedMobile,"lastBoxDonatedDate":decryptedLastBoxDonatedDate,"housingType":decryptedHousingType,"greenCurrency":data[i].greenCurrency,"collected":collected,"boxIssuedDates":decryptedBoxIssuedDates,"boxDonatedDates":decryptedBoxDonatedDates,"boxDonated":decryptedBoxDonated,"name": decryptedName, "email": decryptedEmail, "address": decryptedAddress, "unitNo":decryptedUnitNo};
        array.push(obj);
      } 
      
      this.userNameList = array;
    });
    // get all seller for options in html
    this.postsService.postGetAllSellers().subscribe(sellers => {
      this.sellers = sellers;
      for(var i = 0; i < sellers.length; i++){
        if(this.supplierID == sellers[i]._id){
          this.supplierName = sellers[i].companyName
        }
      }
		});
  }

  ngOnInit(): void {
    
    this.sub = this.route.params.subscribe(params => {
      var _id = params['_id']; 
      // get user details
      this.authService.postGetUserDetails(_id).subscribe(userInfo => {
      this.userInfo = userInfo;
      this.myForm = this.fb.group ({
        name: this.userInfo[0].name,
        email: [this.userInfo[0].email, Validators.email],
        mobile: this.userInfo[0].mobile,
        address: this.userInfo[0].address,
        unitNo: this.userInfo[0].unitNo,
        supplier: this.userInfo[0].supplier,
        housingType: this.userInfo[0].housingType,
        role: this.userInfo[0].role,
        greenCurrency: this.userInfo[0].greenCurrency.totalGreenCurrency,
        password: '',
        repassword: ''
      });
      this.supplierID = this.userInfo[0].supplier;
      this.userID = this.userInfo[0]._id;
      this.userName = this.userInfo[0].name;
      this.role = this.userInfo[0].role
      });
    });
  }
  // checkForExistingAddress(target, array)
  //   {
  //     for(var i = 0; i < array.length; i++) 
  //     {
  //       if(array[i].address + array[i].unitNo  == target)
  //       {
  //         return true;
  //       }
  //     }
  //     return false; 
  //   }

  //Update user green currency
  updateUserGC(){
    this.authService.useGC(this.userID, this.myForm.value.greenCurrency);
    window.location.reload();
  }
  
  // toggle password visiability
  passwordToggle() {
    var x =  (<HTMLInputElement>document.getElementById("password"));
    var y =  (<HTMLInputElement>document.getElementById("password-status"));
    if (x.type === "password") {
      x.type = "text";
      y.className = "fa fa-eye-slash field-icon";
    } else {
      x.type = "password";
      y.className = "fa fa-eye field-icon";
    }
  }
  // toggle repassword visiability
  repasswordToggle() {
    var x =  (<HTMLInputElement>document.getElementById("repassword"));
    var y =  (<HTMLInputElement>document.getElementById("repassword-status"));
    if (x.type === "password") {
      x.type = "text";
      y.className = "fa fa-eye-slash field-icon";
    } else {
      x.type = "password";
      y.className = "fa fa-eye field-icon";
    }
  }
  // On submit update existing user with validations
  onSubmit() {

    //Check if all input fields are filled
    if(this.myForm.value.address == "" || this.myForm.value.housingType == "" || this.myForm.value.password == "" || this.myForm.value.repassword == "" || this.myForm.value.mobile == "" ){
      this.toastr.warning('Fill in all input fields!', 'Warning');
    }

    // else if(this.myForm.controls.email.hasError('email'))
    // {
    //   this.toastr.warning('Invalid email!', 'Warning');
    // }

    else if(this.myForm.controls.password.hasError('minlength'))
    {
      this.toastr.warning('Minimum 8 character is required for password!', 'Warning');
    }

    //Check if passwords match
    else if(this.myForm.value.password != this.myForm.value.repassword){

    this.toastr.warning('Passwords don\'t match!', 'Warning');
    }

    else if(/[\d]{8}/.test(this.myForm.value.mobile) == false)
    {
      this.toastr.warning('Invalid phone number format!', 'Warning');
    }
    
    else if(this.myForm.controls.address.hasError('minlength') || this.myForm.controls.address.hasError('maxlength'))
    {
      this.toastr.warning('Invalid postal code!', 'Warning');
    }

    //Update user with input from myForm 
    else{
        var userHouseLoc 
        this.connectionService.getLocationByPostalCode(this.myForm.value.address).subscribe(location => {
          
          if(location["results"][0] == null){
            this.toastr.warning('Invalid Postal Code', 'Warning');
          }

          // else if(this.checkForExistingAddress(location["results"][0]["ADDRESS"] + this.myForm.value.unitNo, this.userNameList) == true){
          //   this.toastr.warning('Address already exist', 'Warning');
          // }
          
          else if(this.myForm.value.housingType == "Landed Housing"){
            userHouseLoc = location["results"][0]["ADDRESS"];
            this.toastr.success('User has been updated', 'Success');
            // update user details
            this.authService.adminUpdateUser( this.myForm.value.name, this.myForm.value.password, this.myForm.value.email, this.myForm.value.mobile, userHouseLoc, "not applicable", this.myForm.value.housingType, this.myForm.value.role, this.myForm.value.greenCurrency, this.myForm.value.supplier);
            window.location.reload();
          }
          
          else{
            if(/^#[\d]{2}-[\d]{2,4}$/.test(this.myForm.value.unitNo) == false)
            {
              this.toastr.warning('Invalid unit no format', 'Warning');
            }
            else{
              userHouseLoc = location["results"][0]["ADDRESS"];
              this.toastr.success('User has been updated', 'Success');
              // update user details
              this.authService.adminUpdateUser(this.myForm.value.name, this.myForm.value.password, this.myForm.value.email, this.myForm.value.mobile, userHouseLoc, this.myForm.value.unitNo, this.myForm.value.housingType, this.myForm.value.role, this.myForm.value.greenCurrency, this.myForm.value.supplier);
              window.location.reload();
            }
  
          }
  
        });

    }

  }

}