import { Component, OnInit } from '@angular/core';
import { PostsService } from '../posts.service';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationExtras } from '@angular/router';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
	selector: 'app-cart',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

	carts: any = [];
	price: number;
	quantity: number;
	amount: number;
	total: number = 0;
	msg: string;
	empty: boolean;
	ID: any = [];
	programIDList: any = [];
	programIDArray: any = [];
	userInfo: any = [];
	userGCA: number;
	clicked: boolean;
	cartLength: number = 0;
	wishlists: any = [];
	shippingFee: number = 0.00;
	sellers: any = [];

	constructor(private router: Router, private postsService: PostsService, private toastr: ToastrService, private authService: AuthService, private spinner: NgxSpinnerService) {
		this.quantity = 0;
		this.clicked = false;
		this.spinner.show();
		//Get user cart
		this.postsService.postGetCart(this.authService.getUserID()).subscribe(carts => {

				this.carts = carts;
				this.cartLength = carts.length;
				setTimeout(() => {
					this.spinner.hide();
				}, 1000);
				this.calculateShippingFee();


				if (this.carts.length == 0) {
					this.msg = 'Your cart is empty!'
					this.empty = true

				} else {
					this.msg = ''
					this.empty = false

				}

			},
			error => {
				console.log("Cart View Error: ", error);
			});
		//Get users info and greencurrency
		this.authService.postGetUserDetails(this.authService.getUserID()).subscribe(userInfo => {
			this.userInfo = userInfo;
			this.userGCA = userInfo[0].greenCurrency.totalGreenCurrency

		});

		this.getWishlist();

		//Update user green currency is not used 
		this.postsService.cartUpdateUseGC(this.authService.getUserID(), false).subscribe(results => {});

		//Update all the items inside cart are not selected
		this.postsService.cartUpdateSelectedItem(this.authService.getUserID(), false).subscribe(results => {});


	}

	ngOnInit(): void {
		//hide pickup locations for self-collection by default
		var moreLocationText = document.getElementById("moreLocation");
		moreLocationText.style.display = "none";
	}

	//Show pickup locations for self-collection if select self-collection
	showLocation() {
		var dotsLocation = document.getElementById("dotsLocation");
		var moreLocationText = document.getElementById("moreLocation");

		var selectedSupplierCollectionAddress = [];
		for (var i = 0; i < this.carts.length; i++) {
			if (this.carts[i].category !== "Program" && this.carts[i].category !== "Workshop" && ( < HTMLInputElement > document.getElementById(this.carts[i]._id + 3)).checked == true) {
				selectedSupplierCollectionAddress.push({
					"UEN": this.carts[i].supplier.companyUEN,
					"companyName": this.carts[i].supplier.companyName,
					"pickupLocation": this.carts[i].supplier.pickupLocation
				});
			}
		}
		selectedSupplierCollectionAddress.sort(this.compare);

		for (var i = 0; i < selectedSupplierCollectionAddress.length; i++) {
			if (i == 0) {
				this.sellers.push({
					"UEN": selectedSupplierCollectionAddress[i].companyUEN,
					"companyName": selectedSupplierCollectionAddress[i].companyName,
					"pickupLocation": selectedSupplierCollectionAddress[i].pickupLocation
				});
			} else if (selectedSupplierCollectionAddress[i].UEN != selectedSupplierCollectionAddress[i - 1].UEN) {
				this.sellers.push({
					"UEN": selectedSupplierCollectionAddress[i].companyUEN,
					"companyName": selectedSupplierCollectionAddress[i].companyName,
					"pickupLocation": selectedSupplierCollectionAddress[i].pickupLocation
				});
			}
		}

		if (dotsLocation.style.display != "none") {
			dotsLocation.style.display = "none";
			moreLocationText.style.display = "inline";
		} else {
			dotsLocation.style.display = "inline";
			moreLocationText.style.display = "none";
			this.sellers = [];
		}

	}

	//Hide pickup locations for self-collection if select delivery or N.A
	hideLocation() {
		(( < HTMLInputElement > document.getElementById('radioCollection')).checked) = false;
		var dotsLocation = document.getElementById("dotsLocation");
		var moreLocationText = document.getElementById("moreLocation");
		this.sellers = [];

		if (dotsLocation.style.display === "none") {
			dotsLocation.style.display = "inline";
			moreLocationText.style.display = "none";
		}

	}
	//Get users wishlist
	getWishlist() {
		this.postsService.postGetWishlist(this.authService.getUserID()).subscribe(wishlists => {
				this.wishlists = wishlists;
			},
			error => {
				console.log("Wishlist View Error: ", error);
			});
	}

	async getWishlistAsync() {
		return new Promise((resolve, reject) => {
			resolve(this.wishlists);
		});
	}

	//User use his/her green currency 
	useGreenCurrency(ID, maxGreenCurrency, price, discount_price, quantity) {
		//Hello future intern/full time, Jonathan told me 100 GC = $1 SGD
		//Also if you're reading this goood luck and God bless.
		var checkBox = ( < HTMLInputElement > document.getElementById(ID + 1)).value;
		var regularPriceText = document.getElementById(ID);
		var afterDiscountText = document.getElementById(ID + 2);
		var deductedAmount: number = 0;
		var amount
		if (discount_price != null) {
			amount = discount_price;
		} else {
			amount = price;
		}
		deductedAmount = amount * quantity * maxGreenCurrency / 100
		var deductedAmount1 = Math.round((deductedAmount + Number.EPSILON) * 100) / 100

		if (checkBox == "true") {
			//check whether have enough green currency
			if (this.userGCA < deductedAmount1 * 100) {
				( < HTMLInputElement > document.getElementById(ID + 1)).checked = false;

				this.toastr.warning("Sorry you don't have enough green currency to deduct from this product or program", 'Warning!');
			} 
			//check you have selected a product or program first before select you want to use green currency
			 else if (( < HTMLInputElement > document.getElementById(ID + 3)).checked == false) {
				( < HTMLInputElement > document.getElementById(ID + 1)).checked = false;
				this.toastr.warning("Please select the product or program first before using your green currency!", 'Warning!');

			} else {
				//successful used green currency 
				afterDiscountText.style.display = "block";
				regularPriceText.style.display = "none";
				( < HTMLInputElement > document.getElementById(ID + 1)).value = ""
				this.toastr.success("Successfully deducted $" + deductedAmount1 + " costing " + deductedAmount1 * 100 + " green currency", 'Success!');
				// update used green currency for this item
				this.postsService.paidWithGC(ID, true).subscribe(results => {});
				this.userGCA = this.userGCA - deductedAmount1 * 100;
				this.total = this.total - deductedAmount1;
			}

		} else {
			//return your green currency if you unchecked the checkbox for green currency
			afterDiscountText.style.display = "none";
			regularPriceText.style.display = "block";
			( < HTMLInputElement > document.getElementById(ID + 1)).value = "true"
			this.toastr.success("Successfully returned your green currency back into your wallet", 'Success!');
			// update does not use green currency for this item
			this.postsService.paidWithGC(ID, false).subscribe(results => {});
			this.userGCA = Math.round((this.userGCA + Number.EPSILON) * 100) / 100 + deductedAmount1 * 100;
			this.total = this.total + deductedAmount1;
		}
	}

	//Pass cart total amount, program ID, updated user green currency and shipping method to add orders page (credit card)
	passValueCard() {
		var afterGCDeduction: number;
		afterGCDeduction = this.total

		if (this.total + this.shippingFee < 6) {
			this.toastr.warning('You have to spend a minimum of $6 before you can check out.', 'Warning');
		} 
		  // check whether you have select your shipping method before checkout through stripe
		  else if (( < HTMLInputElement > document.getElementById('radioDelivery')).checked == false && ( < HTMLInputElement > document.getElementById('radioCollection')).checked == false && ( < HTMLInputElement > document.getElementById('radioNA')).checked == false) {
			this.toastr.warning('You must select your shipping method before you can check out.', 'Warning');
		} else {
			if (this.clicked == true) {

				for (var i = 0; i < this.carts.length; i++) {
					this.programIDList = this.carts[i].itemID;
					this.programIDArray.push(this.programIDList);
				}
				if (( < HTMLInputElement > document.getElementById('radioDelivery')).checked) {

					const navigationExtras: NavigationExtras = {
						state: {
							total: afterGCDeduction + this.shippingFee,
							ID: this.programIDArray,
							userGCA: this.userGCA,
							shippingMethod: "Delivery"
						}
					};
					this.router.navigate(['addOrder'], navigationExtras);
				} else if (( < HTMLInputElement > document.getElementById('radioCollection')).checked) {
					const navigationExtras: NavigationExtras = {
						state: {
							total: afterGCDeduction + this.shippingFee,
							ID: this.programIDArray,
							userGCA: this.userGCA,
							shippingMethod: "Self-Collection"
						}
					};
					this.router.navigate(['addOrder'], navigationExtras);
				} else {
					const navigationExtras: NavigationExtras = {
						state: {
							total: afterGCDeduction + this.shippingFee,
							ID: this.programIDArray,
							userGCA: this.userGCA,
							shippingMethod: "N.A"
						}
					};
					this.router.navigate(['addOrder'], navigationExtras);
				}
			} else {
				for (var i = 0; i < this.carts.length; i++) {
					this.programIDList = this.carts[i].itemID;
					this.programIDArray.push(this.programIDList);


				}

				if (( < HTMLInputElement > document.getElementById('radioDelivery')).checked) {

					const navigationExtras: NavigationExtras = {
						state: {
							total: this.total + this.shippingFee,
							ID: this.programIDArray,
							userGCA: this.userGCA,
							shippingMethod: "Delivery"
						}
					};
					this.router.navigate(['addOrder'], navigationExtras);
				} else if (( < HTMLInputElement > document.getElementById('radioCollection')).checked) {
					const navigationExtras: NavigationExtras = {
						state: {
							total: this.total + this.shippingFee,
							ID: this.programIDArray,
							userGCA: this.userGCA,
							shippingMethod: "Self-Collection"
						}
					};
					this.router.navigate(['addOrder'], navigationExtras);
				} else {
					const navigationExtras: NavigationExtras = {
						state: {
							total: this.total + this.shippingFee,
							ID: this.programIDArray,
							userGCA: this.userGCA,
							shippingMethod: "N.A"
						}
					};
					this.router.navigate(['addOrder'], navigationExtras);
				}

			}
		}
	}

	//Pass cart total amount, program ID, updated user green currency and shipping method to checkout page (paynow)
	passValuePaynow() {
		var afterGCDeduction: number;
		afterGCDeduction = this.total
		// check whether you have select your shipping method before checkout through paynow
		if (( < HTMLInputElement > document.getElementById('radioDelivery')).checked == false && ( < HTMLInputElement > document.getElementById('radioCollection')).checked == false && ( < HTMLInputElement > document.getElementById('radioNA')).checked == false) {
			this.toastr.warning('You must select your shipping method before you can check out.', 'Warning');
		} else {
			if (this.clicked == true) {

				for (var i = 0; i < this.carts.length; i++) {
					this.programIDList = this.carts[i].itemID;
					this.programIDArray.push(this.programIDList);

				}
				if (( < HTMLInputElement > document.getElementById('radioDelivery')).checked) {

					const navigationExtras: NavigationExtras = {
						state: {
							total: afterGCDeduction + this.shippingFee,
							ID: this.programIDArray,
							userGCA: this.userGCA,
							shippingMethod: "Delivery"
						}
					};
					this.router.navigate(['checkOut'], navigationExtras);
				} else if (( < HTMLInputElement > document.getElementById('radioCollection')).checked) {

					const navigationExtras: NavigationExtras = {
						state: {
							total: afterGCDeduction + this.shippingFee,
							ID: this.programIDArray,
							userGCA: this.userGCA,
							shippingMethod: "Self-Collection"
						}
					};
					this.router.navigate(['checkOut'], navigationExtras);
				} else {
					const navigationExtras: NavigationExtras = {
						state: {
							total: afterGCDeduction + this.shippingFee,
							ID: this.programIDArray,
							userGCA: this.userGCA,
							shippingMethod: "N.A"
						}
					};
					this.router.navigate(['checkOut'], navigationExtras);
				}
			} else {
				for (var i = 0; i < this.carts.length; i++) {
					this.programIDList = this.carts[i].itemID;
					this.programIDArray.push(this.programIDList);
				}
				if (( < HTMLInputElement > document.getElementById('radioDelivery')).checked) {
					const navigationExtras: NavigationExtras = {
						state: {
							total: this.total + this.shippingFee,
							ID: this.programIDArray,
							userGCA: this.userGCA,
							shippingMethod: "Delivery"
						}
					};
					this.router.navigate(['checkOut'], navigationExtras);
				} else if (( < HTMLInputElement > document.getElementById('radioCollection')).checked) {
					const navigationExtras: NavigationExtras = {
						state: {
							total: this.total + this.shippingFee,
							ID: this.programIDArray,
							userGCA: this.userGCA,
							shippingMethod: "Self-Collection"
						}
					};
					this.router.navigate(['checkOut'], navigationExtras);
				} else {
					const navigationExtras: NavigationExtras = {
						state: {
							total: this.total + this.shippingFee,
							ID: this.programIDArray,
							userGCA: this.userGCA,
							shippingMethod: "N.A"
						}
					};
					this.router.navigate(['checkOut'], navigationExtras);
				}
			}
		}
	}

	//Select everything inside cart
	selectAll() {
		(( < HTMLInputElement > document.getElementById('radioCollection')).checked) = false;
		var dotsLocation = document.getElementById("dotsLocation");
		var moreLocationText = document.getElementById("moreLocation");
		dotsLocation.style.display = "inline";
		moreLocationText.style.display = "none";
		this.sellers = [];
		for (var i = 0; i < this.carts.length; i++) {
			( < HTMLInputElement > document.getElementById(this.carts[i]._id + 3)).checked = true;
			this.totalAmount();
		}
	}

	//Select delivery button for products only, return a warning if not a product
	selectDeliveryRadioBtn() {

		for (var i = 0; i < this.carts.length; i++) {
			if (( < HTMLInputElement > document.getElementById(this.carts[i]._id + 3)).checked == true) {
				if (this.carts[i].category == "Program" || this.carts[i].category == "Workshop") {
					(( < HTMLInputElement > document.getElementById('radioDelivery')).checked) = false;
					this.toastr.warning('Please select N.A as shipping to pay for signing up program or workshop!', 'Warning');
				}
			}
		}
		//select item in the cart first before select delivery as shipping method
		if (this.total == 0.00) {
			(( < HTMLInputElement > document.getElementById('radioDelivery')).checked) = false;
			this.toastr.warning('Please select the product you want to purchase first before select delivery!', 'Warning');
		}
	}

	//Select self-collection button for products only, return a warning if not a product
	selectCollectionRadioBtn() {
		for (var i = 0; i < this.carts.length; i++) {
			if (( < HTMLInputElement > document.getElementById(this.carts[i]._id + 3)).checked == true) {

				if (this.carts[i].category == "Program" || this.carts[i].category == "Workshop") {
					(( < HTMLInputElement > document.getElementById('radioCollection')).checked) = false;
					this.toastr.warning('Please select N.A as shipping to pay for signing up program or workshop!', 'Warning');
				}
			}

		}
		//select item in the cart first before select self-collection as shipping method
		if (this.total == 0.00) {
			(( < HTMLInputElement > document.getElementById('radioCollection')).checked) = false;
			this.toastr.warning('Please select the product you want to purchase first before select self-collection!', 'Warning');
		}

	}

	//Select N.A shipping button for programs/workshops only, return a warning if not program/workshop
	selectNARadioBtn() {

		for (var i = 0; i < this.carts.length; i++) {
			if (( < HTMLInputElement > document.getElementById(this.carts[i]._id + 3)).checked == true) {
				if (this.carts[i].category !== "Program" && this.carts[i].category !== "Workshop") {
					(( < HTMLInputElement > document.getElementById('radioNA')).checked) = false;
					this.toastr.warning('Please select delivery or self-collection as shipping for purchasing of product!', 'Warning');
				}

			}

		}
		//select program in the cart first before select N.A as shipping method
		if (this.total == 0.00) {
			(( < HTMLInputElement > document.getElementById('radioNA')).checked) = false;
			this.toastr.warning('Please select the program or workshop you want to pay for signing up first before select N.A!', 'Warning');
		}

	}

	//Compare and sort seller UEN
	compare(a, b) {
		if (a.UEN < b.UEN) {
			return -1;
		}
		if (a.UEN > b.UEN) {
			return 1;
		}
		return 0;
	}

	//Calculate shipping fee & to check if they're qualified for free delivery
	calculateShippingFee() {
		var array1 = [];
		for (var i = 0; i < this.carts.length; i++) {

			if (this.carts[i].category !== "Program" && this.carts[i].category !== "Workshop" && ( < HTMLInputElement > document.getElementById(this.carts[i]._id + 3)).checked == true) {
				if (this.carts[i].discount_price !== null) {
					var price = this.carts[i].discount_price * this.carts[i].quantity
					array1.push({
						"deliveryFee": this.carts[i].supplier.deliveryFee,
						"giveFreeShipping": this.carts[i].supplier.giveFreeShipping,
						"price": price,
						"UEN": this.carts[i].supplier.companyUEN,
						"companyName": this.carts[i].supplier.companyName
					});
				} else {
					var price = this.carts[i].price * this.carts[i].quantity
					array1.push({
						"deliveryFee": this.carts[i].supplier.deliveryFee,
						"giveFreeShipping": this.carts[i].supplier.giveFreeShipping,
						"price": price,
						"UEN": this.carts[i].supplier.companyUEN,
						"companyName": this.carts[i].supplier.companyName
					});
				}
			}

		}


		array1.sort(this.compare);

		var cart1 = [];
		//Add total cost spent for each supplier
		for (var i = 0; i < array1.length; i++) {
			if (i == 0) {
				cart1.push({
					"deliveryFee": array1[i].deliveryFee,
					"giveFreeShipping": array1[i].giveFreeShipping,
					"price": array1[i].price,
					"UEN": array1[i].UEN,
					"companyName": array1[i].companyName
				})
			} else if (array1[i].UEN != array1[i - 1].UEN) {
				cart1.push({
					"deliveryFee": array1[i].deliveryFee,
					"giveFreeShipping": array1[i].giveFreeShipping,
					"price": array1[i].price,
					"UEN": array1[i].UEN,
					"companyName": array1[i].companyName
				})
			} else {
				for (var j = 0; j < cart1.length; j++) {
					if (cart1[j].UEN == array1[i].UEN) {
						cart1[j].price = array1[i].price + cart1[j].price;
					}
				}
			}
		}

		var cart2 = [];
		//Remove shipping if price is more than equals to freeShipping 
		for (var i = 0; i < cart1.length; i++) {
			if (cart1[i].price < cart1[i].giveFreeShipping) {
				cart2.push({
					"deliveryFee": cart1[i].deliveryFee,
					"giveFreeShipping": cart1[i].giveFreeShipping,
					"price": cart1[i].price,
					"UEN": cart1[i].UEN
				})
			} else {
				this.toastr.info("Yay, you don\'t have to pay delivery fee for " + cart1[i].companyName + " because you spent more than $" + cart1[i].giveFreeShipping, 'Free Shipping!');
			}
		}

		var unique = cart2
			.map(e => e['UEN'])
			.map((e, i, final) => final.indexOf(e) === i && i)
			.filter(obj => array1[obj])
			.map(e => array1[e]);
		var unique1 = unique.map(e => e['deliveryFee']);

		if (( < HTMLInputElement > document.getElementById('radioDelivery')).checked) {
			if (unique1.length == 1) {

				this.shippingFee = parseFloat(unique1[0])

			} else if (unique1.length == 0) {
				this.shippingFee = 0.00;
			} else {

				var unique2 = unique1.reduce((a, b) => parseFloat(a) + parseFloat(b));
				this.shippingFee = Math.round((unique2 + Number.EPSILON) * 100) / 100;


			}
		} 
		//If select self-collection or N.A, then shipping fee will be $0
		else {
			this.shippingFee = 0.00;
		}
	}

	//Calculate cart total amount 
	totalAmount() {
		var total1 = 0;
		var total2 = 0;
		this.total = 0;

		this.invalidShippingSelected();
		for (var i = 0; i < this.carts.length; i++) {
			//if the checkbox is checked update this item is selected and calculate the price
			if (( < HTMLInputElement > document.getElementById(this.carts[i]._id + 3)).checked == true) {
				this.calculateShippingFee();
				this.postsService.selectCartItem(this.carts[i]._id, true).subscribe(results => {});
				if (this.carts[i].discount_price != null) {
					total1 += this.carts[i].discount_price * this.carts[i].quantity;
				} else {
					total2 += this.carts[i].price * this.carts[i].quantity;

				}
			} else {
				this.calculateShippingFee();
				//update this item is not selected
				this.postsService.selectCartItem(this.carts[i]._id, false).subscribe(results => {});

			}

			this.total = total1 + total2;

		}

		return this.total
	}
	//Check to prevent invalid shipping was selected for product or program
	invalidShippingSelected() {
		for (var i = 0; i < this.carts.length; i++) {
			
			if (( < HTMLInputElement > document.getElementById('radioDelivery')).checked == true && (this.carts[i].category == 'Program' || this.carts[i].category == "Workshop")) {
				(( < HTMLInputElement > document.getElementById(this.carts[i]._id + 3)).checked) = false;
			} 
			else if (( < HTMLInputElement > document.getElementById('radioCollection')).checked == true && (this.carts[i].category == 'Program' || this.carts[i].category == "Workshop")) {
				(( < HTMLInputElement > document.getElementById(this.carts[i]._id + 3)).checked) = false;
			} 
			else if (( < HTMLInputElement > document.getElementById('radioNA')).checked == true && this.carts[i].category != 'Program' && this.carts[i].category != "Workshop") {
				(( < HTMLInputElement > document.getElementById(this.carts[i]._id + 3)).checked) = false;
			}

		}

	}
	//Delete cart item based on ID
	deleteCart(id: string) {
		if (confirm('Do you want to remove this item?')) {

			this.postsService.postDeleteCart(id).subscribe(results => {
				location.reload();
			});
		}
	}
	//Delete all cart item
	clearCart() {
		if (confirm('Are you sure you want to remove all items in the cart ?')) {
			this.postsService.postClearCart(this.authService.getUserID()).subscribe(results => {
				location.reload();
			});
		}
	}

	//Increase item quantity 
	increaseQuantity(_id: number, oldquantity: string) {
		this.postsService.increaseQuantity(_id,
			parseInt(oldquantity) + 1).subscribe(results => {
			location.reload();
		});
	}

	//decrease item quantity 
	decreaseQuantity(_id: number, oldquantity: string) {
		if (parseInt(oldquantity) <= 1) {
			alert('Quantity cannot be less than 1')
		} else {
			this.postsService.decreaseQuantity(_id,
				parseInt(oldquantity) - 1).subscribe(results => {
				location.reload();
			});
		}
	}
	//Check if in array contain the same object.name
	inArray(target, array) {
		for (var i = 0; i < array.length; i++) {
			if (array[i].name == target) {
				return true;
			}
		}
		return false;
	}

	//Add to wishlist
	async addToWishlist(pID, pName, pPrice, pDiscountPrice, pMaxGreenCurrency, pCat, pImg, pAva, pQuantity, cartID, pSeller) {
		let response = await this.getWishlistAsync();
		//check if this item in wishlist already
		if (this.inArray(pName, response)) {
			this.toastr.warning('This item is already in your wishlist', 'Warning');
		} else {
			if (confirm('Do you want to add this item to your wishlist ?')) {
				delete pSeller.companyLogo;

				this.postsService.addToWishlist(this.authService.getUserID(), pID, pName, pPrice, pDiscountPrice, pMaxGreenCurrency, pSeller, pCat, pImg, pQuantity, pAva).subscribe(results => {

					this.toastr.success("Successfully added item to wishlist!", 'Success!');
					location.reload();
				});
				this.postsService.postDeleteCart(cartID).subscribe(results => {});


			}
		}
	}
}

