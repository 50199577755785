
<br>
<br>
<br>
<br>
<br>
<div id="print-section">
   <div class="container">
      <div class="row">
         <div class="col-md-6 mx-auto text-center mb-5 section-heading">
            <br>
            <div class="section-title">
               <h2 class="mb-5">User Details</h2>
            </div>
            <br>
            <div data-label="qr">
               <ngx-qrcode [elementType]= "elementType" [value]="userInfo[0]._id" class="qrcode"></ngx-qrcode>
               <!-- You can resize as much as you want. 
                  The information is encoded in the pattern of the data, not in the size of the dots themselves. 
                  As long as a scanner can resolve properly between light/dark, the QR code should be readable at any size. -Caleb -->
               <!-- <div id="printableArea">
                  All the printable content goes here......
                  </div>
                  <a href="javascript:void(0);" onclick="printPageArea('printableArea')">Print</a> -->
            </div>
            <br>
            <br>
         </div>
      </div>
      <div class="container">
         <div class="row justify-content-start">
            <div class="col-12">
               <p><strong>Username: </strong>{{userInfo[0].name}}
               <p>
               <p><strong>Role: </strong>{{userInfo[0].role}}</p>
               <p><strong>Email: </strong>{{userInfo[0].email}}</p>
               <p><strong>Address: </strong>{{userInfo[0].address}}</p>
               <p><strong>Green Currency: </strong>{{ userInfo[0].greenCurrency.totalGreenCurrency}}</p>
               <p><strong>Number Of Box Donated: </strong>{{userInfo[0].boxDonated}}</p>
               <p><strong>Box Collected: </strong>{{userInfo[0].collected}}</p>
               <div *ngIf= "userInfo[0].collected == true">
                  <button type="button" class="btn btn-warning" (click)="returnB(userInfo[0]._id)">Issue New Box</button>
               </div>
               <div *ngIf= "userInfo[0].collected == false">
                  <button type="button" class="btn btn-success" (click)="collectB(userInfo[0]._id, userInfo[0].greenCurrency.totalGreenCurrency, userInfo[0].boxDonated)">Collect Box</button>
               </div>
               <br>
               <p><strong>Box Issued Dates: </strong></p>
               <select name="Box Issued Dates" id="BID" class="browser-default custom-select custom-select-lg mb-3">
                  <option *ngFor="let obj of userInfo[0].boxIssuedDates">{{obj | date:'MM/dd/yyyy HH:mm:ss Z'}}</option>
               </select>
               <p><strong>Box Donated Dates: </strong></p>
               <select name="Box Donated Dates" id="BDD" class="browser-default custom-select custom-select-lg mb-3">
                  <option *ngFor="let obj of userInfo[0].boxDonatedDates">{{obj | date:'MM/dd/yyyy HH:mm:ss Z'}}</option>
               </select>
               <br>
               <br>
               <h3 class="mb-5"> Registered Program & Workshops</h3>
               <h5 *ngIf= "programsArray.length == 0">
                  {{msg}}
               </h5>
               <div class="table-responsive" *ngIf="programsArray.length != 0">
                  <table class="table table-hover">
                     <thead class="thead-dark">
                        <tr>
                           <th class="font-weight-bold"><strong>Image</strong></th>
                           <th class="font-weight-bold"><strong>Program Name</strong></th>
                           <th class="font-weight-bold"><strong>Price</strong></th>
                           <th class="font-weight-bold"><strong>Category</strong></th>
                           <th class="font-weight-bold"><strong>Date</strong></th>
                           <th class="font-weight-bold"><strong>Time</strong></th>
                        </tr>
                     </thead>
                     <tbody *ngFor="let program of programsArray">
                        <tr>
                           <a [routerLink]="['/programsInfo',program._id]">
                              <td data-label="image">
                                 <img  src={{program.image}} name="Image" width="200" height="145">
                              </td>
                           </a>
                           <td data-label="name" [routerLink]="['/programsInfo',program._id]">{{program.name}}</td>
 
                           <td *ngIf="program.specialProgram === true" data-label="price" [routerLink]="['/programsInfo',program._id]"  style="width:10%">Please email info@otolithenrichment.com for plans and pricings</td>
                           <td *ngIf="program.specialProgram === false" data-label="price" [routerLink]="['/programsInfo',program._id]" style="width:10%"><a *ngIf="program.discount_price === null">{{ program.price | currency:'USD'}}</a> <a *ngIf="program.discount_price !== null">
                             Discounted Price : {{program.discount_price | currency:'USD' }}
                           </a></td>
                           <td data-label="category" [routerLink]="['/programsInfo',program._id]" style="width:10%">{{program.category}}</td>
              
                           <td *ngIf="program.specialProgram === true" data-label="date" [routerLink]="['/programsInfo',program._id]" style="width:10%">Contact us at info@otolithenrichment.com for the next available dates</td>
                           <td *ngIf="program.specialProgram === true" data-label="time" [routerLink]="['/programsInfo',program._id]" style="width:15%">Contact us at info@otolithenrichment.com for the next available time</td> 
                           <td *ngIf="program.specialProgram === false" data-label="date" [routerLink]="['/programsInfo',program._id]"  style="width:10%">{{ program.date }}</td> 
                           <td *ngIf="program.specialProgram === false" data-label="time" [routerLink]="['/programsInfo',program._id]" style="width:15%">{{ program.time }}</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
               <br>
               <br>
               <h3 class="mb-5">Registered Projects</h3>
               <h5 *ngIf= "projectsArray.length == 0">
                  {{msg2}}
               </h5>
               <div class="table-responsive"  *ngIf="projectsArray.length != 0">
                  <table class="table table-hover">
                     <thead class="thead-dark">
                        <tr>
                           <th class="font-weight-bold"><strong>Image</strong></th>
                           <th class="font-weight-bold"><strong>Project Name</strong></th>
                           <th class="font-weight-bold"><strong>Date</strong></th>
                        </tr>
                     </thead>
                     <tbody *ngFor="let project of projectsArray">
                        <tr>
                           <a [routerLink]="['/projectInfo',project._id]">
                              <td data-label="image">
                                 <img  src={{project.image}} name="Image" width="200" height="145">
                              </td>
                           </a>
                           <td data-label="name" [routerLink]="['/projectInfo',project._id]">{{project.title}}</td>
                           <td data-label="publish" [routerLink]="['/projectInfo',project._id]">{{project.publish  | date : 'dd/MM/yyyy' }}</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
               <br>
            </div>
         </div>
      </div>
   </div>
</div>
<button  id="topright" class="btn btn-outline-success mr-2" [useExistingCss]="true" printSectionId="print-section" ngxPrint>Print</button> 
<ngx-spinner
   bdColor="rgba(51,51,51,0.8)"
   size="medium"
   color="#fff"
   type="ball-scale-multiple"
   >
   <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>