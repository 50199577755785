import { Component, OnInit } from '@angular/core';
import { PostsService } from '../posts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  _id:string;
  products: any = [];
  p: Number = 1;
  type: any;
  name:string;
  price : number;
  category: string;
  image:string;
  quantity:number;
  carts: any = [];
  loadedproductList: any[];  
  productID:string;
  wishlists: any = [];
  discountPrice:number;
  sellers:any=[];

  constructor(private postsService: PostsService, private route: ActivatedRoute, private router:Router,private toastr: ToastrService, public service: AuthService, private spinner: NgxSpinnerService) {

    this.spinner.show();
    //get supplier
    this.postsService.postGetAllSellers().subscribe(sellers => {
      this.sellers = sellers;
      
    });
 
    if(service.isLoggedIn()){
      this.getCart();
      this.getWishlist();
    }
    
    this.type = this.route.snapshot.paramMap.get("type");

    if(this.type == "All"){
      for (let i = 1; i <= this.products.length; i++) {
        this.products.push(`item ${i}`);
      }
      //get all products
    this.postsService.postGetAllProducts().subscribe(products => {
        this.products = products;
        this.loadedproductList = products;
        this.spinner.hide();        
    });
        
    }
    else{
      //get products by category
   this.postsService.postGetProductsByCategory(this.type).subscribe(Data => 
      {
        this.products = Data;
        this.loadedproductList = Data;
        this.spinner.hide();
      });
    }
  }

  getCart(){
    //get user cart
   this.postsService.postGetCart(this.service.getUserID()).subscribe(carts => {
      this.carts = carts;
    },
    error => {
      console.log("Cart View Error: ", error);
    });
  }

  getWishlist(){
    //get user wishlist
   this.postsService.postGetWishlist(this.service.getUserID()).subscribe(wishlists => {
      this.wishlists = wishlists;
    },
    error => {
      console.log("Wishlist View Error: ", error);
    });
  }

  async getCartAsync(){
    return new Promise((resolve, reject) => {
      resolve(this.carts);
    });
  }

  async getWishlistAsync(){
    return new Promise((resolve, reject) => {
      resolve(this.wishlists);
    });
  }
  
  getProductsBySupplier(_id){
    if(this.type == "All"){
     this.postsService.postGetAllProductsBySupplier(_id).subscribe(products => {
        this.products = products;
      });
        
    }
    else{
     this.postsService.postGetProductsBySupplier(_id, this.type).subscribe(Data => 
      {
        this.products = Data;
       
      });
    }
  }

  getNewDate()
   {
    if(this.type == "All"){
     this.postsService.postGetAllProductsNewDate().subscribe(products => {
        this.products = products;
        });
        
    }
    else{
   this.postsService.postGetProductsByNewDate(this.type).subscribe(Data => 
      {
        this.products = Data;
       
      });
    }
   }

   getOldDate()
   {
    if(this.type == "All"){
     this.postsService.postGetAllProductsOldDate().subscribe(products => {
        this.products = products;
        });
        
    }
    else{
   this.postsService.postGetProductsByOldDate(this.type).subscribe(Data => 
      {
        this.products = Data;
      });
    }
   }


  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  initializeItems(): void {
    this.products = this.loadedproductList;
  }

  search(event) {
    
    this.initializeItems();
    //Set searchTerm with input from search bar
    const searchTerm = event.srcElement.value;
  
    if (!searchTerm) {
    return;
    }
    //Filters enquiries list with keywords from searchTerm
    this.products = this.products.filter(i => {
    if (i.name && searchTerm) {
    if (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
    return true;
    }
    return false;
    }
    });
  }

  inArray(target, array)
  {
    for(var i = 0; i < array.length; i++) 
    {
      if(array[i].name == target)
      {
        return true;
      }
    }
    return false; 
  }
  // add product to wishlist
  async addToWishlist(pID, pName, pPrice, pDiscountPrice, pMaxGreenCurrency,pCat, pImg, pAva, pSeller){
    let response = await this.getWishlistAsync();
      //check whether you have login
      if(this.service.isLoggedIn() == false){
        this.router.navigateByUrl('/login');
        this.toastr.warning('Please login to your account first!', 'Warning');
      }
      //check whether product exists in the wishlist
      else if(this.inArray(pName, response)){
        this.toastr.warning('This product is already in your wishlist', 'Warning');
      }
      else{
        if(confirm('Do you want to add this item to your wishlist ?'))
      {
       var itemID = pID; 
       this.name = pName;
       this.price = pPrice;
       this.discountPrice = pDiscountPrice;
       var maxGreenCurrency = pMaxGreenCurrency;
       this.category = pCat;
       this.image = pImg;
       this.quantity = 1;
       var productsAvailability = pAva;
       delete pSeller.companyLogo;
       //add product to wishlist
       this.postsService.addToWishlist(this.service.getUserID(), itemID,this.name,this.price, this.discountPrice, maxGreenCurrency,pSeller,this.category,this.image,this.quantity, productsAvailability).subscribe(results =>{
  
        this.toastr.success("Successfully added item to wishlist!", 'Success!');    
        location.reload();
        });
      
      }
      }      
  } 

  async addToCart(pID, pName, pPrice, pDiscountPrice, pMaxGreenCurrency, pCat, pImg, pAva, pSeller) {
    let response = await this.getCartAsync();
       //check whether you have login
      if(this.service.isLoggedIn() == false){
        this.router.navigateByUrl('/login');
        this.toastr.warning('Please login to your account first!', 'Warning');
      }
      //check whether product already in your cart
      else if(this.inArray(pName, response)){
        this.toastr.warning('This product is already in your cart', 'Warning');
      }
      //check if product is out of stock
      else if (pAva == false)
      {
        this.toastr.warning('This product is out of stock!', 'Warning');
      }
      
      else{
        if(confirm('Do you want to add this item to your cart ?'))
        {
          this.productID = pID;
          this.name = pName;
          this.price = pPrice;
          this.discountPrice = pDiscountPrice;
          var maxGreenCurrency = pMaxGreenCurrency;
          this.category = pCat;
          this.image= pImg;
          this.quantity=1;
          var productsAvailability = pAva;
          delete pSeller.companyLogo;
          //add product to your cart
          this.postsService.addToCart(this.service.getUserID(),this.productID,this.name,this.price, this.discountPrice, maxGreenCurrency,pSeller,this.category,this.image,this.quantity, productsAvailability) .subscribe(results =>{
  
            this.toastr.success("Successfully added item to cart!", 'Success!');
            location.reload();
            });
        
        }
      }   
  }
}