import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutpage',
  templateUrl: './aboutpage.component.html',
  styleUrls: ['./aboutpage.component.css']
})
export class AboutpageComponent implements OnInit {

  constructor() {
   
   }

  ngOnInit(): void {
    //Hide additional text
    var moreText = document.getElementById("more");
    moreText.style.display = "none";
    var more2Text = document.getElementById("more2");
    more2Text.style.display = "none";
  }
 

  

  showText() {
    //Show additional text
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less"; 
      moreText.style.display = "inline";
    }
  }

  showText2() {
    //Show additional text
    var dots2 = document.getElementById("dots2");
    var more2Text = document.getElementById("more2");
    var btn2Text = document.getElementById("myBtn2");
  
    if (dots2.style.display === "none") {
      dots2.style.display = "inline";
      btn2Text.innerHTML = "Read more"; 
      more2Text.style.display = "none";
    } else {
      dots2.style.display = "none";
      btn2Text.innerHTML = "Read less"; 
      more2Text.style.display = "inline";
    }
  }

}
