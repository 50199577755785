<br>
<br>
<br>
<br>

<div class="container">
    <form [formGroup]="editSellerForm"  class="p-5 bg-white">
       <h2>Edit Seller</h2>
       <hr>
       <div class="row form-group">
          <div class="col-md-12 mb-3 mb-md-0">
             <label class="font-weight-bold" for="companyName">Seller Company Name:</label>
             <input type="text" id="companyName" formControlName="companyName" class="form-control" value={{sellerInfo[0].companyName}}>
          </div>
       </div>
       <div class="row form-group">
          <div class="col-md-12 mb-3 mb-md-0">
             <label class="font-weight-bold" for="companyUEN">Company UEN:</label>
             <input type="text" id="companyUEN" formControlName="companyUEN" class="form-control" >
          </div>
       </div>
       <div class="row form-group">
         <div class="col-md-12 mb-3 mb-md-0">
            <label class="font-weight-bold" for="email">Seller Email:</label>
            <input type="email" id="sellersEmail" formControlName="sellersEmail" class="form-control" >
         </div>
      </div>
       <div class="row form-group">
          <div class="col-md-12 mb-3 mb-md-0">
             <label class="font-weight-bold" for="deliveryFee">Seller Delivery Fee:</label>
             <input type="number" id="deliveryFee" formControlName="deliveryFee" class="form-control" >
          </div>
       </div>
       <div class="row form-group">
         <div class="col-md-12 mb-3 mb-md-0">
            <label class="font-weight-bold" for="giveFreeShipping">Required Purchase Cost for Free shipping:</label>
            <input type="number" id="giveFreeShipping" formControlName="giveFreeShipping" class="form-control">
         </div>
      </div>
      <div class="row form-group">
         <div class="col-md-12 mb-3 mb-md-0">
            <label class="font-weight-bold" for="pickupLocation">Pickup Location:</label>
            <input type="text" id="pickupLocation" formControlName="pickupLocation" class="form-control">
         </div>
      </div>
       <div class="row form-group">
          <div class="col-md-12 mb-3 mb-md-0">
             <label  class="font-weight-bold" for="image">Company Logo:</label>
             <br>
             <input type="file" formControlName="image" (change)="handleUpload($event)" accept=".png, .jpg, .jpeg, .pdf">
          </div>
       </div>
       <br>
       <hr>
       <button type="submit" class="btn btn-success"  (click)="updateSeller(sellerInfo[0]._id)" >Save changes </button>
    </form>
 <br>
 <br>
 <h3 class="mb-5">Supplier Products</h3>
 <h5 *ngIf= "productsArray.length == 0">
    {{msg}}
 </h5>
 <div class="table-responsive" *ngIf="productsArray.length != 0">
    <table class="table table-hover">
       <thead class="thead-dark">
          <tr>
             <th class="font-weight-bold"><strong>Image</strong></th>
             <th class="font-weight-bold"><strong>Product Name</strong></th>
             <th class="font-weight-bold"><strong>Price</strong></th>
             <th class="font-weight-bold"><strong>Category</strong></th>
             <th class="font-weight-bold"><strong>Availability</strong></th>
          </tr>
       </thead>
       <tbody *ngFor="let product of productsArray">
          <tr>
             <a [routerLink]="['/productsInfo',product._id]">
                <td data-label="image">
                   <img  src={{product.image}} name="Image" width="200" height="145">
                </td>
             </a>
             <td data-label="name" [routerLink]="['/productsInfo',product._id]">{{product.name}}</td>
             <td data-label="price" [routerLink]="['/productsInfo',product._id]"><a *ngIf="product.discount_price == null">Retail Price: {{product.price | currency:'SGD'}}</a><a *ngIf="product.discount_price !== null">Discounted Price: {{product.discount_price | currency:'SGD'}}</a></td>
             <td data-label="category" [routerLink]="['/productsInfo',product._id]">{{product.category}}</td>
             <td data-label="availability" [routerLink]="['/productsInfo',product._id]">{{product.availability}}</td>
          </tr>
       </tbody>
    </table>
 </div>
 <br>
 <br>
 <h3 class="mb-5">Supplier Programs & Workshops</h3>
 <h5 *ngIf= "programsArray.length == 0">
    {{msg2}}
 </h5>
 <div class="table-responsive" *ngIf="programsArray.length != 0">
    <table class="table table-hover">
       <thead class="thead-dark">
          <tr>
             <th class="font-weight-bold"><strong>Image</strong></th>
             <th class="font-weight-bold"><strong>Program Name</strong></th>
             <th class="font-weight-bold"><strong>Price</strong></th>
             <th class="font-weight-bold"><strong>Category</strong></th>
             <th class="font-weight-bold"><strong>Capacity</strong></th>
          </tr>
       </thead>
       <tbody *ngFor="let program of programsArray">
          <tr>
             <a [routerLink]="['/programsInfo',program._id]">
                <td data-label="image">
                   <img  src={{program.image}} name="Image" width="200" height="145">
                </td>
             </a>
             <td data-label="name" [routerLink]="['/programsInfo',program._id]">{{program.name}}</td>
             <td *ngIf="program.specialProgram === true" data-label="price" [routerLink]="['/programsInfo',program._id]">Please email info@otolithenrichment.com for plans and pricings</td>
             <td *ngIf="program.specialProgram === false" data-label="price" [routerLink]="['/programsInfo',program._id]"><a *ngIf="program.discount_price == null">Price: {{program.price | currency:'SGD'}}</a><a *ngIf="program.discount_price !== null">Discounted Price: {{program.discount_price | currency:'SGD'}}</a></td>
             <td data-label="category" [routerLink]="['/programsInfo',program._id]">{{program.category}}</td>
             <td data-label="capacity" [routerLink]="['/programsInfo',program._id]">{{program.capacity}}</td>
          </tr>
       </tbody>
    </table>
 </div>
 <br>
</div>
