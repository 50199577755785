import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service'; 
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PostsService } from '../posts.service'; 
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-reg-programs',
  templateUrl: './reg-programs.component.html',
  styleUrls: ['./reg-programs.component.css']
})

export class RegProgramsComponent implements OnInit {

  programsArray: any = [];
  programEmpty:boolean;
  msg:string;
  orders:any=[];
  constructor(public postsService: PostsService,private route: ActivatedRoute, private toastr: ToastrService, private router: Router, public authService: AuthService,private spinner: NgxSpinnerService) {
    this.spinner.show();
    //get all programs
    this.postsService.postGetAllProgrammes().subscribe(programs => {
      this.spinner.hide();
      for(var i = 0; i < programs.length; i++)
      {
        if(programs[i].slot.length == 0)
        {
      
          this.msg = "You haven't signed up for any program or workshop"
          this.programEmpty = true
        }
        
        for(var j = 0; j < programs[i].slot.length; j++)
        {
          // get programs user had sign up
          if(programs[i].slot[j] == this.authService.getSecureToken())
          {
            this.programsArray.push(programs[i]);
            this.msg = ""
            this.programEmpty = false
          }
          else
          {
            this.msg = "You haven't sign up for any program or workshop"
            this.programEmpty = true
          }
        }
      }
          
      });

      this.postsService.postGetOrders(this.authService.getUserID()).subscribe(orders => {

        this.orders = orders;
  
      });
     }

  ngOnInit(): void {
  }

  // removeProgram(_id){
  //   let userName =  this.authService.getSecureToken();
  //   if(confirm('Are you sure that you want to cancel your registration for this program or workshop ?')){
  //     var currentDate= new Date();
  //     for(var i =0; i<this.orders.length; i++)
  //     {
  //       var orderDate = new Date(this.orders[i].order_date);
       
  //       if(this.orders[i].itemID == _id && orderDate.getFullYear() == currentDate.getFullYear() && orderDate.getMonth() == currentDate.getMonth() && orderDate.getDate() == currentDate.getDate())
  //       {
        
  //          this.postsService.removeFromPrograms(_id,userName).subscribe(results =>{
  //          this.toastr.success("Successfully cancel your registration for this program or workshop", 'Success!');
  //         });
  //        location.reload();
  //        return;
  //       }

   
  //       if(orderDate.getFullYear() != currentDate.getFullYear() || orderDate.getMonth() != currentDate.getMonth() || orderDate.getDate() != currentDate.getDate())
  //       {
  //        alert('Sorry! You are only allow to cancel your program or workshop within the day you registered');
  //        return;
  //       }
     
  //     }

     
    
  //   }
  // }

}
