import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service'; 
import { Router } from '@angular/router'; 
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { ToastrService } from 'ngx-toastr';
import { ConnectionService } from '../connection.service';
import { PostsService } from '../posts.service';
import { EncrDecrService } from '../encr-decr-service';
import endpoint from 'endpoints.config';

@Component({
  selector: 'app-admin-create-user',
  templateUrl: './admin-create-user.component.html',
  styleUrls: ['./admin-create-user.component.css']
})
export class AdminCreateUserComponent implements OnInit {

    public account = {  
      password: null  
    };  
      
    public barLabel: string = "Password strength:"; 
    myForm: FormGroup;
  
    userNameList: any[];
    sellers: any;
    secretKey = endpoint.secretKey;

    constructor(private postsService: PostsService ,private fb: FormBuilder, private authService: AuthService, private router: Router,  private toastr: ToastrService, private connectionService: ConnectionService, private EncrDecr: EncrDecrService) 
    { 
      // Post get all users
      this.authService.macSpicyTooSpicy().subscribe(data =>{
      var array = []
      for(var i = 0; i < data.length; i++){
        var collected
        var decryptedClockIn= data[i].encryptedClockIn
        var decryptedClockInDateTime= data[i].encryptedClockInDateTime;
        var decryptedClockInDateTimeList= data[i].encryptedClockInDateTimeList  
        var decryptedVolunteeringLocation= data[i].encryptedVolunteeringLocation;
        var decryptedName = this.EncrDecr.get(this.secretKey, data[i].encryptedName);
        var decryptedEmail = this.EncrDecr.get(this.secretKey, data[i].encryptedEmail);
        var decryptedAddress = this.EncrDecr.get(this.secretKey, data[i].encryptedAddress);
        var decryptedUnitNo = this.EncrDecr.get(this.secretKey, data[i].encryptedUnitNo);
        var decryptedBoxDonated= this.EncrDecr.get(this.secretKey, data[i].encryptedBoxDonated);
        var decryptedBoxDonatedDates= data[i].encryptedBoxDonatedDates;
        var decryptedBoxIssuedDates= data[i].encryptedBoxIssuedDates;
        var decryptedCollected= this.EncrDecr.get(this.secretKey, data[i].encryptedCollected);
        if(decryptedCollected == "true"){
          collected = true;
        }
        else{
          collected = false;
        }
        var decryptedHousingType= this.EncrDecr.get(this.secretKey, data[i].encryptedHousingType);
        var decryptedLastBoxDonatedDate= data[i].encryptedLastBoxDonatedDate;
        var decryptedMobile= this.EncrDecr.get(this.secretKey, data[i].encryptedMobile);
        var decryptedNewBoxIssueDate= data[i].encryptedNewBoxIssueDate;
        var decryptedPassword= this.EncrDecr.get(this.secretKey, data[i].encryptedPassword);
        var decryptedRole= this.EncrDecr.get(this.secretKey, data[i].encryptedRole);
        var decryptedSupplier= this.EncrDecr.get(this.secretKey, data[i].encryptedSupplier);
        var decryptedTemporaryToken= data[i].encryptedTemporaryToken;
        var decryptedVerified= data[i].encryptedVerified;
        var decrypted_id= this.EncrDecr.get(this.secretKey, data[i].encrypted_id);
        var obj = {"volunteeringLocation":decryptedVolunteeringLocation, "clockInDateTimeList":decryptedClockInDateTimeList,"clockInDateTime":decryptedClockInDateTime,"clockIn":decryptedClockIn,"_id":decrypted_id,"verified":decryptedVerified,"temporaryToken":decryptedTemporaryToken,"supplier":decryptedSupplier,"role":decryptedRole,"password":decryptedPassword,"newBoxIssueDate":decryptedNewBoxIssueDate,"mobile":decryptedMobile,"lastBoxDonatedDate":decryptedLastBoxDonatedDate,"housingType":decryptedHousingType,"greenCurrency":data[i].greenCurrency,"collected":collected,"boxIssuedDates":decryptedBoxIssuedDates,"boxDonatedDates":decryptedBoxDonatedDates,"boxDonated":decryptedBoxDonated,"name": decryptedName, "email": decryptedEmail, "address": decryptedAddress, "unitNo":decryptedUnitNo};
        array.push(obj);
      } 
      
      this.userNameList = array;
    });
      // get all seller for options in html
      this.postsService.postGetAllSellers().subscribe(sellers => {
        this.sellers = sellers;
      });
  
    }
  
    ngOnInit() {
      
      this.myForm = this.fb.group ({
        name: '',
        email: ['' ,Validators.email],
        greenCurrency: 0,
        mobile: '',
        address: ['',[Validators.required,Validators.minLength(6),Validators.maxLength(6)]],
        unitNo: '',
        supplier: '',
        radioBtn: '',
        password: '',
        repassword: ''
      });
    
    }
    // toggle password visability
    passwordToggle() {
      var x =  (<HTMLInputElement>document.getElementById("password"));
      var y =  (<HTMLInputElement>document.getElementById("password-status"));
      if (x.type === "password") {
        x.type = "text";
        y.className = "fa fa-eye-slash field-icon";
      } else {
        x.type = "password";
        y.className = "fa fa-eye field-icon";
      }
    }
    // toggle repassword visability
    repasswordToggle() {
      var x =  (<HTMLInputElement>document.getElementById("repassword"));
      var y =  (<HTMLInputElement>document.getElementById("repassword-status"));
      if (x.type === "password") {
        x.type = "text";
        y.className = "fa fa-eye-slash field-icon";
      } else {
        x.type = "password";
        y.className = "fa fa-eye field-icon";
      }
    }
    // Chech array for existing address
    checkForExistingAddress(target, array)
    {
      for(var i = 0; i < array.length; i++) 
      {
        if(array[i].address + array[i].unitNo  == target)
        {
          return true;
        }
      }
      return false; 
    }
    // On submit create new user with validations
    onSubmit() {
      let role = (<HTMLSelectElement>document.getElementById("role")).value;  

      var unlistLength = this.userNameList.length;
      for (var i = 0; i < unlistLength; i++){
        // check whether username exist
        if(this.userNameList[i].name == this.myForm.value.name){
          this.toastr.warning('Username already taken', 'Warning');
          return;
        }
      }
       var unlistLength = this.userNameList.length;
       for (var i = 0; i < unlistLength; i++){
         // check whether email exist
         if(this.userNameList[i].email == this.myForm.value.email){
           this.toastr.warning('Email already in use', 'Warning');
           return;
         }
       }
      
      //Check if all input fields are filled
      if(this.myForm.value.name == "" || this.myForm.value.password == "" || this.myForm.value.repassword == "" || this.myForm.value.mobile == "" || this.myForm.value.email == "" || this.myForm.value.address == "" || this.myForm.value.radioBtn == ""){
        this.toastr.warning('Fill in all input fields!', 'Warning');
      }
      
      else if(this.myForm.controls.email.hasError('email'))
      {
        this.toastr.warning('Invalid email!', 'Warning');
      }
  
      else if(this.myForm.controls.password.hasError('minlength'))
      {
        this.toastr.warning('Minimum 8 character is required for password!', 'Warning');
      }
  
      //Check if passwords match
      else if(this.myForm.value.password != this.myForm.value.repassword){
        this.toastr.warning('Passwords don\'t match!', 'Warning');
      }
  
      
      else if(/[\d]{8}/.test(this.myForm.value.mobile) == false)
      {
        this.toastr.warning('Invalid phone number format!', 'Warning');
      }
  
      else if(this.myForm.controls.address.hasError('minlength') || this.myForm.controls.address.hasError('maxlength'))
      {
        this.toastr.warning('Invalid postal code!', 'Warning');
      }

  
    
      //Creates new user from register form 
      else{
        var userHouseLoc
        var notApplicable
        this.connectionService.getLocationByPostalCode(this.myForm.value.address).subscribe(location => {
          
          
          if(location["results"][0] == null){
            this.toastr.warning('Invalid Postal Code', 'Warning');
          }

          
          else if(this.myForm.value.radioBtn == "Landed Housing"){
            notApplicable = "not applicable"
            // check if address exist
            if(this.checkForExistingAddress(location["results"][0]["ADDRESS"] + notApplicable, this.userNameList) == true){
              this.toastr.warning('Address already exist', 'Warning');
            }
            else{
            userHouseLoc = location["results"][0]["ADDRESS"];
            //create a new user
            this.authService.adminCreateUser(this.myForm.value.name,
            this.myForm.value.password, role, this.myForm.value.email, this.myForm.value.mobile, userHouseLoc, "not applicable", this.myForm.value.radioBtn, this.myForm.value.greenCurrency, this.myForm.value.supplier).subscribe();
            this.toastr.success('Account sucessfully created', 'Success');
            
            this.router.navigateByUrl('/admin').then(() =>
            {
             window.location.reload();
            });
            }
          }
          
          else{
            if(/^#[\d]{2}-[\d]{2,4}$/.test(this.myForm.value.unitNo) == false)
            {
              this.toastr.warning('Invalid unit no format', 'Warning');
            }
            else if(this.checkForExistingAddress(location["results"][0]["ADDRESS"] + this.myForm.value.unitNo, this.userNameList) == true){
              this.toastr.warning('Address already exist', 'Warning');
            }
            else{
              userHouseLoc = location["results"][0]["ADDRESS"];
              // create a new user
              this.authService.adminCreateUser(this.myForm.value.name,
              this.myForm.value.password, role, this.myForm.value.email, this.myForm.value.mobile, userHouseLoc, this.myForm.value.unitNo, this.myForm.value.radioBtn, this.myForm.value.greenCurrency, this.myForm.value.supplier).subscribe();
              this.toastr.success('Account sucessfully created', 'Success');
              this.router.navigateByUrl('/admin').then(() =>
              {
               window.location.reload();
              });
            }
  
          }
  
        });
        
      }
    }
  
  }
