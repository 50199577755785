<br>
<br>
<br>
<br>
<br>
<div id="print-section">
  <br>
  <div class="section-title">
    <h2>Volunteer Entries Log</h2>
    <br>
    <h5>{{msg}}</h5>
  </div>
<div *ngIf = !empty>
  <div class="main">
    <div class="form-group has-search">
    <span class="fa fa-search form-control-feedback"></span>
    <input type="text" (keydown)="search($event)" class="form-control" placeholder="Search By Name">
    </div>
    </div>
    <div class="user-list" #entriesLog>
        <table class="table table-hover">
          
            <thead class="thead-dark">
              <tr>            
                <th scope="col">Name</th>
                <th scope="col">Date Time</th>
                <th scope="col">Action</th>
                <th scope="col">Location</th>
                <th scope="col">Green Currency Earned</th>

              </tr>
            </thead>
            <tbody>
            <tr *ngFor="let i of entires">
              <td data-label="name">{{ i.name }}</td>
              <td data-label="datetime">{{ i.date }}</td>
              <td data-label="action">{{ i.action }}</td>
              <td data-label="location">{{ i.location }}</td>
              <td data-label="greenCurrencyEarned">{{ i.greenCurrencyEarned }}</td>
            </tr>
          </tbody>
      </table>
      </div>
    </div>
  </div>

  <button (click)="exportToExcel()"  id="topright2" class="btn btn-outline-success mr-2">Export To Excel</button> 
  <button  id="topright" class="btn btn-outline-success mr-2" [useExistingCss]="true" printSectionId="print-section" ngxPrint>Print</button> 