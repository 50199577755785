import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import endpoint from '../../endpoints.config';
@Injectable({
  providedIn: 'root'
})
export class PostsService {

  constructor(private http:HttpClient) { }
      

  postGetAllProjects()  {    
    return this.http.post<any[]>('./api/postGetProjects/', {'token': endpoint.apiToken});   
  }

  postGetProjectsByNewDate()  {    
    return this.http.post<any[]>('./api/postGetProjectsByNewDate/', {'token': endpoint.apiToken});   
  }


  postGetProjectsByOldDate()  {    
    return this.http.post<any[]>('./api/postGetProjectsByOldDate/', {'token': endpoint.apiToken});   
  }
    
  
  postGetProjectsID(_id)  {    
    return this.http.post<any[]>('./api/postGetProjectsInfo/' + _id , {'token': endpoint.apiToken});   
  }

  postProject (title: string, body:string, publish: Date, image:string, attendees:any , givingSGLink: string) {
    return this.http.post<any[]>('./api/postProject/', {'title':title,'body': body,'publish':publish,'image':image, 'attendees':attendees, 'givingSGLink':givingSGLink, 'token':endpoint.apiToken });
  }
  
  editProject(id: string, newTitle:string, newDescription:string, publish:Date, newImage:string, givingSGLink: string) {
    return this.http.put<any[]>('./api/editProject/' + id, {'title': newTitle,'body': newDescription, 'publish':publish ,'image':newImage, 'givingSGLink':givingSGLink, 'token': endpoint.apiToken});
  }    

  postDeleteProject(id: string) {
    return this.http.post<any[]>('./api/postDeleteProject/' + id, {'token':endpoint.apiToken});
  }
  
  addToAttend (_id:string, userID:string) {
    return this.http.put<any[]>('./api/postAttendee/'+_id, {'test': userID, 'token': endpoint.apiToken});
  }

  removeResident (_id:string, userID:string) {
    return this.http.put<any[]>('./api/removeResident/'+_id, {'test': userID, 'token': endpoint.apiToken});
  }

  postGetAllProducts()  {    
    return this.http.post<any[]>('./api/postGetProducts/', {'token': endpoint.apiToken});   
    }


  postGetAllProductsBySupplier(supplierID:string)  {    
      return this.http.post<any[]>('./api/postGetAllProductsBySupplier/' +supplierID , {'token': endpoint.apiToken});   
    }


  postGetProductsBySupplier(supplierID: string,category: string)  {    
      return this.http.post<any[]>('./api/postGetProductsBySupplier/' + supplierID + '/' + category , {'token': endpoint.apiToken});   
    }


  postGetProductsByCategory(category: string)  {    
    return this.http.post<any[]>('./api/postGetProductsByCategory/' + category , {'token': endpoint.apiToken});   
    }


  postGetAllProductsNewDate()  {    
    return this.http.post<any[]>('./api/postGetAllProductsNewDate/', {'token': endpoint.apiToken});   
    }

  postGetProductsByNewDate(category: string)  {    
    return this.http.post<any[]>('./api/postGetProductsByNewDate/' + category , {'token': endpoint.apiToken});   
    }
  

  postGetAllProductsOldDate()  {    
    return this.http.post<any[]>('./api/postGetAllProductsOldDate/', {'token': endpoint.apiToken});   
    }

  postGetProductsByOldDate(category: string)  {    
    return this.http.post<any[]>('./api/postGetProductsByOldDate/' + category , {'token': endpoint.apiToken});   
    }


  postGetProductsUsingID(_id)  {    
    return this.http.post<any[]>('./api/postGetProductsInfo/' + _id , {'token': endpoint.apiToken});   
    }

  addProducts (name: string, price:number, discountPrice:number, maxGreenCurrency:number, supplier:object, category: string,description: string, last_update: Date, availability: boolean, image:string) {
    return this.http.post<any[]>('./api/addProducts/', {'name':name,'price': price, 'discount_price':discountPrice, 'maxGreenCurrency':maxGreenCurrency,'supplier': supplier, 'category':category,'description':description,'last_update':last_update,'availability':availability,'image':image, 'token': endpoint.apiToken });
    }
    
  postDeleteProducts(id: string) {
    return this.http.post<any[]>('./api/postDeleteProducts/' + id, {'token':endpoint.apiToken});
    }

  editProducts(id: string, newName:string,newPrice:number, newDiscountPrice:number, newMaxGreenCurrency:number,supplier:object, newCategory:string,newDescription:string,newDate:Date,newAvailability:boolean, newImage:string) {
    return this.http.put<any[]>('./api/editProducts/' + id, {'name': newName,'price': newPrice, 'discount_price':newDiscountPrice, 'maxGreenCurrency':newMaxGreenCurrency,'supplier': supplier,'category': newCategory,'description': newDescription,'last_update':newDate, 'availability':newAvailability, 'image':newImage, 'token': endpoint.apiToken
      });
    }

  editProductsAvailability(id: string, availability:boolean) {
    return this.http.put<any[]>('./api/editProductsAvailability/' + id, {'availability' :availability, 'token': endpoint.apiToken});
  }


  postGetCart(uID: string)  {    
    return this.http.post<any[]>('./api/postGetCart/' + uID , {'token': endpoint.apiToken});   
    }
    

  addToCart(userID: string,itemID: string, name: string, price:number, discountPrice:number, maxGreenCurrency:number,supplier:object, category: string, image:string, quantity:number, availability: boolean) { 
    return this.http.post<any[]>('./api/addToCart/', {'userID': userID , 'itemID': itemID, 'name':name, 'price':price, 'discount_price':discountPrice, 'maxGreenCurrency':maxGreenCurrency,'supplier': supplier, 'category':category, 'image':image, 'quantity':quantity, 'availability':availability, 'paidWithGC':false, 'selected':false, 'token': endpoint.apiToken});
  }
    
  postDeleteCart(id: string) {
    return this.http.post<any[]>('./api/postDeleteCart/' + id, {'token':endpoint.apiToken});
  }

  postClearCart(userID: string) {
    return this.http.post<any[]>('./api/postClearCart/' + userID, {'token':endpoint.apiToken});
  }

  postClearSelectedItem(userID: string) {
    return this.http.post<any[]>('./api/postClearSelectedItem/' + userID, {'token':endpoint.apiToken});
  }

  paidWithGC(_id: number, paidWithGC: boolean) {
    return this.http.put<any[]>('./api/cartUpdateGC/' + _id, {'paidWithGC': paidWithGC, 'token': endpoint.apiToken
     });
     }

  selectCartItem(_id: number, selected: boolean) {
    return this.http.put<any[]>('./api/cartUpdateSelectedItem/' + _id, {'selected': selected, 'token': endpoint.apiToken
      });
      }
  
  decreaseQuantity(_id: number, newquantity: number) {
    return this.http.put<any[]>('./api/cartUpdateQty/' + _id, {'quantity': newquantity, 'token': endpoint.apiToken
     });
     }

  increaseQuantity(_id: number, newquantity: number) {
    return this.http.put<any[]>('./api/cartUpdateQty/' + _id, {'quantity': newquantity, 'token': endpoint.apiToken
    });
  }

  cartUpdateUseGC(uID: string, paidWithGC: boolean) {
    return this.http.put<any[]>('./api/cartUpdateAllGC/' + uID, {'paidWithGC': paidWithGC, 'token': endpoint.apiToken
    });
  }

  cartUpdateSelectedItem(uID: string, selected: boolean) {
    return this.http.put<any[]>('./api/cartUpdateAllSelectedItem/' + uID, {'selected': selected, 'token': endpoint.apiToken
    });
  }


  addOrder (userID:string,itemID:string,name: string,price:number,discountPrice:number,category:string, quantity:number,amount:number,fullname: string, email:string,phone:number,address:string,unitNo:string, housingType:string, order_date:Date, card_type: string, supplier:object, orderStatus: string, paidWithGC: boolean, greenCurrencyUsed:number, shippingMethod:string) {
    return this.http.post<any[]>('./api/order/', {'userID':userID, 'itemID':itemID, 'name':name,'price':price, 'discount_price':discountPrice, 'category':category, 'quantity':quantity, 'amount':amount, 'fullname':fullname, 'email':email,'phone':phone, "address": address, "unitNo": unitNo, "housingType":housingType,'order_date':order_date, 'card_type' :card_type, 'supplier':supplier, 'orderStatus':orderStatus, 'paidWithGC': paidWithGC, 'greenCurrencyUsed': greenCurrencyUsed, 'shippingMethod':shippingMethod, 'token': endpoint.apiToken });
  }

  addOrderPaynow (userID:string,itemID:string,name: string,price:number,discountPrice:number,category:string, quantity:number,amount:number,fullname: string, email:string,phone:number,address:string,unitNo:string, housingType:string,order_date:Date, billReferenceNumber: string, supplier:object, orderStatus: string, paidWithGC:boolean, greenCurrencyUsed:number, shippingMethod:string) {
    return this.http.post<any[]>('./api/orderPaynow/', {'userID':userID,'itemID':itemID,'name':name,'price':price,'discount_price':discountPrice,'category':category,'quantity':quantity,'amount': amount,'fullname':fullname,'email':email,'phone':phone, "address": address, "unitNo": unitNo, "housingType":housingType,'order_date':order_date,'billReferenceNumber':billReferenceNumber, 'supplier':supplier, 'orderStatus':orderStatus, 'paidWithGC': paidWithGC, 'greenCurrencyUsed':greenCurrencyUsed, 'shippingMethod':shippingMethod, 'token': endpoint.apiToken });
  }

  doYouKnowThatHackingIsACrimeTT()  {    
    return this.http.post<any[]>('./api/doYouKnowThatHackingIsACrimeTT/' , {'token': endpoint.apiToken});   
  }

  stopWatching()  {    
    return this.http.post<any[]>('./api/stopWatching/' , {'token': endpoint.apiToken});   
  }

  systemUpdateOrdersStatus(_id: number) {
    return this.http.put<any[]>('./api/systemUpdateOrderStatus/' + _id, { 'token': endpoint.apiToken });
  }

  updateSupplierOrdersStatus(_id: number, orderStatus: string, dateTime: string, editor: string) {
    return this.http.put<any[]>('./api/supplierOrdersStatus/' + _id, { 'orderStatus': orderStatus, 'dateTime':dateTime, 'editor':editor, 'token': endpoint.apiToken });
  }


  postGetOrders(userID: string)  {    
    return this.http.post<any[]>('./api/postGetOrder/' + userID , {'token': endpoint.apiToken});   
  }

  postGetSupplierOrders(supplierID: string)  {    
    return this.http.post<any[]>('./api/postGetSupplierOrders/' + supplierID , {'token': endpoint.apiToken});   
  }
  
    postGetOrderUsingID(_id)  {    
      return this.http.post<any[]>('./api/postGetOrderInfo/' + _id , {'token': endpoint.apiToken});   
    }

    postDeleteOrder(id: string) {
    return this.http.post<any[]>('./api/postDeleteOrder/' + id, {'token':endpoint.apiToken});
    }
 
    postGetWishlist(uID: string)  {    
      return this.http.post<any[]>('./api/postGetWishlist/' + uID , {'token': endpoint.apiToken});   
    }

    addToWishlist(userID: string, itemID:string, name: string, price:number, discountPrice:number, maxGreenCurrency:number, supplier: object, category: string, image:string, quantity:number, availability: Boolean) {
      return this.http.post<any[]>('./api/addToWishlist/', {'userID' :userID ,'itemID':itemID,'name':name,'price':price, 'discount_price':discountPrice, 'maxGreenCurrency':maxGreenCurrency,'supplier': supplier,'category':category,'image':image,'quantity':quantity, 'availability':availability, 'token': endpoint.apiToken});
    }

    increaseQty(id: number, newquantity: number) {
      return this.http.put<any[]>('./api/wishlist/' + id, {'quantity': newquantity, 'token': endpoint.apiToken
       });
       }
    
    decreaseQty(id: number, newquantity: number) {
      return this.http.put<any[]>('./api/wishlist/' + id, {'quantity': newquantity, 'token': endpoint.apiToken
       });
       }

    postDeleteWishlist(id: string) {
      return this.http.post<any[]>('./api/postDeleteWishlist/' + id, {'token':endpoint.apiToken});
      }    
  
    postRemoveWishlist(_id: string) {
      return this.http.post<any[]>('./api/postRemoveWishlist/' + _id, {'token':endpoint.apiToken});
      }  

    postGetAllProgrammes()  {    
      return this.http.post<any[]>('./api/postGetProgrammes/' , {'token': endpoint.apiToken});   
      }

    postGetProgrammesByNewDate()  {    
      return this.http.post<any[]>('./api/postGetProgrammesByNewDate/' , {'token': endpoint.apiToken});   
      }
    

    postGetProgrammesByOldDate()  {    
      return this.http.post<any[]>('./api/postGetProgrammesByOldDate/' , {'token': endpoint.apiToken});   
      }
    
    postGetProgrammesUsingID(_id)  {    
      return this.http.post<any[]>('./api/postGetProgrammesInfo/' + _id , {'token': endpoint.apiToken});   
      }
    
    addProgrammes (name: string, price:number, discountPrice:number,maxGreenCurrency:number,category: string, synopsis: string, topicsCovered:string, learningObjective:string, duration:string, location:string, last_update: Date, capacity: number, date: string, time:string, image:string, slot:any, supplier: object, specialProgram:boolean) {
      
      return this.http.post<any[]>('./api/addProgrammes/', {'name':name,'price': price, 'discount_price':discountPrice,'maxGreenCurrency':maxGreenCurrency,'category':category, 'synopsis': synopsis, 'topicsCovered':topicsCovered, 'learningObjective':learningObjective, 'duration':duration, 'location':location, 'last_update': last_update, 'capacity':capacity, 'date': date, 'time':time,'image':image,'slot':slot, 'supplier':supplier, 'specialProgram':specialProgram, 'token': endpoint.apiToken});
      }

    addToPrograms (_id:string, userID:string) {
    
      return this.http.put<any[]>('./api/addToPrograms/'+_id, {'test': userID, 'token': endpoint.apiToken});
      }

    removeFromPrograms (_id:string, userID:string) {
    
    return this.http.put<any[]>('./api/removeFromPrograms/'+_id, {'test': userID, 'token': endpoint.apiToken});
      }

    
    postDeleteProgrammes(id: string) {
      return this.http.post<any[]>('./api/postDeleteProgrammes/' + id, {'token':endpoint.apiToken});
      }
          
    editProgrammes(id: string, newName:string, newPrice: number, newDiscountPrice:number, newMaxGreenCurrency:number,newCategory: string, newSynopsis:string, newTopicsCovered:string, newLearningObjective:string, newDuration:string, newLocation:string, newLastUpdate:Date, newCapacity:number, newDate:string, newTime:string, newImage:string, supplier: object, specialProgram:boolean) {
      return this.http.put<any[]>('./api/editProgrammes/' + id, {'name': newName, 'price': newPrice, 'discount_price':newDiscountPrice, 'maxGreenCurrency':newMaxGreenCurrency, 'category': newCategory,'synopsis': newSynopsis, 'topicsCovered':newTopicsCovered, 'learningObjective':newLearningObjective, 'duration':newDuration, 'location':newLocation, 'last_update':newLastUpdate, 'capacity':newCapacity, 'date': newDate, 'time':newTime, 'image':newImage, 'supplier': supplier, 'specialProgram':specialProgram, 'token': endpoint.apiToken
         });
      }


    postGetAllSellers()  {    
      return this.http.post<any[]>('./api/postGetAllSellers/' , {'token': endpoint.apiToken});   
      }

   
    postGetSellersByID(_id)  {    
      return this.http.post<any[]>('./api/postGetSellersBy/' + _id , {'token': endpoint.apiToken});   
      }
    
    addSeller(companyName: string, companyLogo: string, sellersEmail:string, companyUEN:string, deliveryFee: number, giveFreeShipping: number, pickupLocation:string) {
      
      return this.http.post<any[]>('./api/addSeller/', { 'companyName':companyName, 'companyLogo':companyLogo, 'sellersEmail': sellersEmail,'companyUEN': companyUEN, 'deliveryFee':deliveryFee, 'giveFreeShipping':giveFreeShipping, 'pickupLocation':pickupLocation, 'token': endpoint.apiToken });
      }
    
    postDeleteSeller(id: string) {
      return this.http.post<any[]>('./api/postDeleteSeller/' + id, {'token':endpoint.apiToken});
      }
          
    editSeller(id: string, companyName: string, companyLogo: string, sellersEmail:string, companyUEN:string, deliveryFee: number, giveFreeShipping: number, pickupLocation:string) {
      return this.http.put<any[]>('./api/editSeller/' + id, {'companyName':companyName, 'companyLogo':companyLogo, 'sellersEmail': sellersEmail,'companyUEN': companyUEN, 'deliveryFee':deliveryFee, 'giveFreeShipping':giveFreeShipping, 'pickupLocation':pickupLocation, 'token': endpoint.apiToken
         });
      }
      
}
