<br>
<br>
<br>
<br>

<div class="container-fluid">
  <div class="row">
     <div class="col-sm-3 col-md-6 col-lg-4">
        <div class="center">
           <form [formGroup]="checkOutForm"  class="p-5 bg-white" (ngSubmit)="onSubmit()">
           <h2>Confirm Billing Information</h2>
           <hr>
           <div class="row form-group">
              <div class="col-md-12 mb-3 mb-md-0">
                 <label class="font-weight-bold" for="fullname">Full Name:</label>
                 <input type="text" id="fullname" formControlName="fullname" class="form-control" placeholder="Enter Fullname...">
              </div>
           </div>
           <div class="row form-group">
              <div class="col-md-12 mb-3 mb-md-0">
                 <label class="font-weight-bold" for="email">Email:</label>
                 <input type="email" id="email" formControlName="email" class="form-control" placeholder="Enter Email...">
              </div>
           </div>
           <div class="row form-group">
              <div class="col-md-12 mb-3 mb-md-0">
                 <label class="font-weight-bold" for="phone">Phone No:</label>
                 <input type="text" id="phone" formControlName="phone" class="form-control" placeholder="Enter Phone Number..." minlength="8" maxlength="8" required>
              </div>
           </div>
           <div class="row form-group">
              <div class="col-md-12 mb-3 mb-md-0">
                 <label class="font-weight-bold" for="address">Postal Code.: (e.g. 123456)</label>
                 <input type="text" formControlName="address" id="address" class="form-control" placeholder="Enter house postal code..." minlength="6" maxlength="6" required>
              </div>
           </div>
           <div class="row form-group">
              <div class="col-md-12 mb-3 mb-md-0">
                 <label class="font-weight-bold" for="address">Type of housing:</label>
                 <div class="form-check">
                    <label class="form-check-label" for="radio1">
                    <input type="radio" formControlName="radioBtn" id="radioHDB" value="HDB" checked>Public Housing (HDB flats)
                    </label>
                 </div>
                 <div class="form-check">
                    <label class="form-check-label" for="radio2">
                    <input type="radio" formControlName="radioBtn" id="radioApartments" value="Condominiums">Private Condominiums / Apartments
                    </label>
                 </div>
                 <div class="form-check">
                    <label class="form-check-label">
                    <input type="radio" formControlName="radioBtn" id="radioLandedHousing" value="Landed Housing">Landed Housing
                    </label>
                 </div>
              </div>
           </div>
           <div class="row form-group">
              <div class="col-md-12 mb-3 mb-md-0">
                 <label class="font-weight-bold" for="address">Unit Number.: (e.g. #01-123 or #01-12)</label>
                 <input type="text" formControlName="unitNo" id="unitNo" class="form-control"  placeholder="Enter house unit number..."  required>
              </div>
           </div>
           <div class="row form-group">
              <div class="col-md-12 mb-3 mb-md-0">
                 <label class="font-weight-bold" for="address">Bill Reference Number: </label>
                 <input type="text" formControlName="billReferenceNumber" id="billReferenceNumber" class="form-control"  placeholder="Enter Paynow Billing Reference Number..."  required>
              </div>
           </div>
           <br>
              <div class="row form-group">
                <div class="col-md-12 mb-3 mb-md-0">
            
           
              <p style="font-size:14px;color:black"><input type ="checkbox" id="tnc" formControlName="tnc" required/> I have read and agree to Eco²Balance
                    
                <a (click)="showText()" id="myTNC" style="color:blue;text-decoration: underline;"> Terms and Conditions</a> &
                <a (click)="showText2()" id="myPolicy" style="color:blue;text-decoration: underline;">Privacy Policy</a> </p>

                <p class="mb-5" style="font-size:14px">
               
                    <span id="dots"></span><span id="more">   
                      <h3>Terms and Conditions</h3>  
                      By accessing or using our site, you acknowledge that you have read, understood, and agreed, without limitations or qualifications, to be bound by these Terms & Conditions which include our Privacy Policy.
                     <br>
                     <br>
                      <strong>1. Application of terms and conditions of sale</strong>
                      <br>
                      By completing the product purchase order form and clicking on the “Submit” button via the Eco²Balance application, the customer represents and warrants that he or she has the requisite legal capacity, authorization and consent (under the relevant laws in the relevant jurisdiction) to contract with Eco²Balance and agrees to be bound by the Terms and Conditions.
                      The Terms and Conditions represent the complete and existing contract between the customer and Eco²Balance in relation to a purchase of any of the products. Any typographical, clerical or other error or omission in any sales literature, quotation, price list, acceptance of offer, invoice or other document or information issued by Eco²Balance shall be subject to amendment without any liability on the part of Eco²Balance. Customers below the age of 16 are to seek parental consent prior to making any online purchases.
                     
                      <br>
                      <br>
                     <strong>2. Product warranty</strong>
                     <br>
                      All warranty conditions are stated in the product information provided except in the case where none is provided by the manufacturer. Eco²Balance shall pass on any product warranty offered by the manufacturer of the products to the customer. However, as Eco²Balance does not manufacture the products itself, Eco²Balance shall neither be held responsible for any faults or defects of the products nor shall it be liable for any claims for loss, harm or damage by the customer or any end-consumer or any third party arising out of or in connection with the products.
                      <br>
                      <br>
                     <strong>3. Terms of payment</strong>
                     <br>
                      Payments for all orders can be made using credit card through Stripe, via PayLah! or Paynow. Customers are to inform Eco²Balance by emailing us at eco2balancesg@gmail.com with a screenshot of their transaction slip along with the order number to complete the order. Confirmation of the customer’s order will be done via email. All prices quoted are exclusive of delivery charges, taxes/levies and other fees, unless otherwise stated.
                     <br>
                     <br>
                     <strong>4. Delivery service</strong>
                     <br>
                      Delivery service is not available outside Singapore mainland, e.g. Jurong Island.
                      <br>
                      <br>
                      Delivery of orders will be arranged within 1-7 working days upon receiving orders. However, if a longer delivery period is expected due to the non-availability of stocks, individual customers will be informed in advance via email.
                      <br>
                      <br>
                      Delivery of the products shall be made in accordance with the trade terms agreed at such place, date or time as Eco²Balance may notify the customer by email or phone.
                      <br>
                      <br>
                      Self-collection service
                      <br>
                      <br>
                      Collection of the products shall be made in accordance with the trade terms agreed at such place, date or time as Eco²Balance may notify the customer by email or phone.
                      <br>
                      <br>
                      Collection timing for the following locations are as such:
                      <br>
                      Lorong Chencharu: After 4:00 pm
                      <br>
                      Sengkang: After 8:30 pm
                      <br>
                      <br>
                      Eco²Balance will not be held liable for any mortality of livestock collected after 6:00 pm on the notified day of collection.
                      <br>
                      <br>
                     <strong>5. Order acceptance</strong>
                     <br>
                      Eco²Balance will send an electronic acknowledgement to the customer within 1 minute upon successful submission of the electronic order. Eco²Balance is unable to guarantee that every product offered for sale online would be shipped immediately as it is subject to inventory availability. Should there by a product which is indicated as available during the point of purchase, but found to be sold at the time of processing, Eco²Balance will inform the customer via email and refund the additional value.
                      <br>
                      <br>
                     <strong>6. Order cancellation policy</strong>
                      <br>
                      <br>
                      6.1 There shall be no cancellation of or changes to customer’s order (upon submission of customer’s order and/or acceptance of customer’s order by Eco²Balance) unless through prior permission sought from Eco²Balance via email (eco2balancesg@gmail.com). Approved cancellation will incur the following charges, where only part of the payment would be refunded:
                      <br>
                      • A cancellation charge of 20% of the order will be imposed for all cancellation made within 24 hours upon ordering.
                      <br>
                      • A cancellation charge of 30% of the order will be imposed for all cancellation made after 48 hours upon ordering.
                      <br>
                      <br>
                      6.2 Eco²Balance reserves the right to amend or cancel the customer’s order under any circumstances (“Event”) and to exercise its right to amend or cancel the customer’s order. The affected customer shall be notified duly and any payment that the customer has paid shall be refunded within a reasonable timeframe. Such event may include, but is not limited to, any typographical, clerical or other error or omission in any sales literature, quotation, price list, acceptance of offer, invoice or other document or information issued by Eco²Balance.
                     <br>
                     <br>
                     <strong>7. Return policy</strong>
                     <br>
                      In the event that the products or parts of the products are damaged, defective or faulty (through no fault of the customer), Eco²Balance shall, at its discretion:
                     <br>
                      • Replace the same products or part(s) due to manufacturing fault.
                      <br>
                      <br>
                      Provided that:
                      <br>
                      • The customer notifies Eco²Balance their intention to return the products within 3 days of the delivery or deemed delivery of the products to the customer; and
                      <br>
                      • The customer returns the products or part(s) thereof to Eco²Balance in their original packing to such collection point designated by Eco²Balance; and must be accompanied with the sales invoice with the date of purchase and a brief description of the fault of the product(s).
                      <br>
                      • Eco²Balance agrees to such return of the products or part(s) thereof by the customer.
                      <br>
                      • The customer shall bear all delivery charges in relation to the return of the products or part(s) thereof to the collection point designated by Eco²Balance.
                      <br>
                      <br>
                      All our goods sold are non-exchangeable and non-returnable.
                      <br>
                      <br>
                     <strong>8. Return Policy</strong>
                     <br>
                      (Refer to return agreement)
                      <br>
                      <br>
                     <strong>9. Late delivery, non-delivery, partial delivery</strong>
                     <br>
                      Timings indicated on the website regarding the delivery period of the products are only estimates. Eco²Balance shall undertake all reasonable efforts to ensure that the products are delivered to customer within the relevant period(s) specified on the website, or as agreed between Eco²Balance and the customer. Notwithstanding the aforesaid, Eco²Balance is unable to guarantee that there shall be no late delivery or non-delivery of the products and Eco²Balance makes no representations or warranties that the products will be delivered within such period(s). The customer hereby agrees that Eco²Balance shall not be liable for any loss, damage, and cost or expense suffered or incurred by him or her as a result of such late delivery or non-delivery.
                      <br>
                      <br>
                     <strong>10. Characteristics of products</strong>
                     <br>
                      Eco²Balance ensures to the best of its ability that the images of the products materially resemble the products delivered to the customer. These images are provided to Eco²Balance by the suppliers/manufacturers of the products or other third parties. Due to differences in monitors, the colour seen on the screen may not be the exact colour of the product. Accordingly and due to limitations beyond Eco²Balance control, Eco²Balance is not responsible or liable for any differences or non-conformity of the products delivered to the customer as compared to the images of the products displayed on the website.
                      <br>
                      <br>
                     <strong>11. Ownership of intellectual property, rights</strong>
                     <br>
                      All intellectual property rights existing in or in relation to the website and/or contents (including but not limited to any text, picture, image, sound, graphics, video and other data), the products and/or any information, images or drawings supplied with the products belong to Eco²Balance and/or the supplier/manufacturer of the products (where applicable). Nothing herein shall or shall be deemed to pass any such intellectual property right to the customer.
                      Eco²Balance makes no representations or warranties of any kind (whether expressed or implied) as to the intellectual property rights in relation to the products and/or any information images or drawings supplied with the products.
                      The customer shall not use or download any material from the website and/or its contents (including but not limited to any text, picture, image, sound, graphics, video and other data) without obtaining prior consent or a license to do so from Eco²Balance.
                      <br>
                      <br>
                     <strong>12. Law and regulations</strong>
                     <br>
                      By transmitting an order via the website, the customer agrees that the Singapore Law shall govern the Terms and Conditions and any contract or legal relationship formed on the basis of the order.
                      
                    </span>
                  </p>
                  <p class="mb-5" style="font-size:14px">
                   
                      <span id="dots2"></span><span id="more2">  
                        <h3>Privacy Agreement</h3>   
                        We, at Eco²Balance, understand your concerns on how your personal information would be used and shared. We would like to address your concern by providing you with the affirmation that the personal data collected through this website would be treated sensibly and in confidentiality, and by doing so, we seek to gain your trust. The following information describes our privacy policy, of which you are obliged to accept when accessing our webpage.
                        <br>
                        <br>
                        <strong>When will Eco²Balance collect personal data?</strong>
                        <br>
                        We will/may collect personal data about you:
                        <br>
                        <br>
                       • when you register on our website;
                       <br>
                       • when you submit any form, including, but not limited to, application forms or other forms relating to any of our products and services, whether online or by way of a physical form;
                       <br>
                       • when you interact with us, such as via telephone calls (which may be recorded), letters, fax, face-to-face meetings, social media platforms and emails;
                       <br>
                       • when you provide us with feedback or complaints;
                       <br>
                       • when you register for a program/project; or
                       <br>
                       • when you submit your personal data to us for any reason;
                       <br>
                       <br>
                        The above does not purport to be exhaustive and sets out some common instances of when personal data about you may be collected.
                       <br>
                       <br> 
                      <strong>What personal data we collect and why we collect it</strong>
                        <br>
                       • name;
                       <br>
                       • email address;
                       <br>
                       • billing address;
                       <br>
                       • phone number;
                       <br>
                       • information sent by or associated with the device(s) used to access our website or app;
                       <br>
                       • any other information about the user when the user signs up to use our website;
                       <br>
                       • comments;
                       <br>
                       <br>
                        You agree not to submit any information to us which is inaccurate or misleading, and you agree to inform us of any inaccuracies or changes to such information. We reserve the right, at our sole discretion to require further documentation to verify the information provided by you.
                        <br>
                        <br>
                      <strong> How long we retain your data</strong>
                        <br>
                        If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.
                        For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see and edit their personal information at any time (except they cannot change their username, email, role, Green Currency and address). Website administrators can also see and edit this information.
                        <br>
                        <br>
                      <strong>What rights you have over your data</strong>
                        <br>
                        If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.
                        <br>
                        <br>
                      <strong>How does Eco²Balance protect and retain customer information?</strong>
                        <br>
                        We implement a variety of security measures and strive to ensure the security of your personal data on our systems. User personal data is contained behind secured networks and is only accessible by a limited number of employees who have special access rights to such systems. 
                        <br>
                        <br>
                      <strong>Transfer of Personal Information Overseas</strong>
                        <br>
                        In the event where personal information are required to be shared with third parties vendors, some of whom may be located overseas, we will ensure that the recipient is bounded legally by the local equivalent of PDPA2012 and seek your understanding on this matter.
                        <br>
                        <br>
                      <strong>Updates to Privacy Policy</strong>
                        <br>
                        We might revise this privacy policy from time to time. Please refer to this page for the latest version of our policy which will be used for governing our collection, use and disclosure of your personal information.
                      </span>
                    </p>
              </div>
              </div>
           <hr>
           <div class="row form-group">
              <div class="col-md-12 mb-3 mb-md-0">
                 <input type="submit" value="Submit" class="btn btn-success pill text-white px-5 py-2"  *ngIf="!formProcess">
                 <span class="btn btn-success pill text-white px-5 py-2" *ngIf="formProcess">Processing please wait...</span>
              </div>
           </div>
           </form>      
        </div>
     </div>
     <div class="col-sm-9 col-md-6 col-lg-8">
        <div class="center">
           <br>
           <br>
           <!-- <p>To: OTOLITH ENRICHMENT</p><br>
              <p>Date: {{ order_date }}</p><br> -->
           <p>1. You will receive a billing reference number sent to your accounts email📧</p>
           <br>
           <p>2. Use any bank app to scan the QR code bellow 📲 </p>
           <br>
           <ngx-qrcode 
           [elementType]="elementType" 
           [value] = "value"
           cssClass = "aclass"
           errorCorrectionLevel = "L">
           </ngx-qrcode>
           <h1>S${{ totalAmount }}</h1>
           <br>
           <p>3. Enter your billing reference number inside the form on the left and <br>
              enter the bill reference number at your bank's internet banking page
           </p>
           <br>
           <p><a href="https://www.mom.gov.sg/faq/work-pass-general/when-can-i-use-paynow-to-pay-for-work-pass-related-charges" target="_blank">Where to enter the bill reference number for my preferred bank's internet banking page?</a></p>
           <br>
           <p>4. Click next and pay</p>
           <br>
           <img src="./assets/imgs/dbs.png" class="rounded p-1" width="40" height="40">
           <img src="./assets/imgs/posb.jpg" class="rounded p-1" width="40" height="40">
           <img src="./assets/imgs/paylah.jpg" class="rounded p-1" width="40" height="40"> 
           <img src="./assets/imgs/ocbc.jpg" class="rounded p-1" width="40" height="40"> 
           <img src="./assets/imgs/uob.jpg" class="rounded p-1" width="40" height="40">
           <img src="./assets/imgs/citi-bank.jpg" class="rounded p-1" width="40" height="40">
           <img src="./assets/imgs/hsbc.jpg" class="rounded p-1" width="40" height="40">
           <img src="./assets/imgs/standard-chartered.jpg" class="rounded p-1" width="40" height="40">
           <img src="./assets/imgs/maybank.jpg" class="rounded p-1" width="40" height="40">
           <img src="./assets/imgs/bank-of-china.jpg" class="rounded p-1" width="40" height="40">
           <img src="./assets/imgs/icbc.jpg" class="rounded p-1" width="40" height="40">
           <br>    
           <p><strong>*Please remember to enter the bill reference number, <br> so we'll know it's you and can start processing your order thank you!</strong></p>
           <br>
        </div>
        <br>
     </div>
  </div>
</div>