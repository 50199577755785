import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service'; 
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PostsService } from '../posts.service'; 
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-reg-projects',
  templateUrl: './reg-projects.component.html',
  styleUrls: ['./reg-projects.component.css']
})
export class RegProjectsComponent implements OnInit {

  projectsArray: any = [];
  projectEmpty:boolean;
  msg:string;

  constructor(public postsService: PostsService,private route: ActivatedRoute, private toastr: ToastrService, private router: Router, public authService: AuthService,private spinner: NgxSpinnerService) {
    this.spinner.show();
    //get all projects
    this.postsService.postGetAllProjects().subscribe(projects => {
      this.spinner.hide();
      for(var i = 0; i < projects.length; i++)
      {
        if(projects[i].attendees.length == 0)
        {
      
          this.msg = "You haven't signed up for any project"
          this.projectEmpty = true
        }

        for(var j = 0; j < projects[i].attendees.length; j++)
        {
          // get projects you have registered
          if(projects[i].attendees[j] == this.authService.getSecureToken())
          {
            this.projectsArray.push(projects[i]);
            this.msg = ""
            this.projectEmpty = false
          }
          else
          {
            this.msg = "You haven't sign up for any project"
            this.projectEmpty = true
          }
        }
      }
      
    });
   }

  ngOnInit(): void {

     
  }

  // removeProject(_id){
    
  //   let userName = this.authService.getSecureToken();
  //   if(confirm('Are you sure that you want to cancel your registration for this project ?')){
  //     this.postsService.removeResident(_id, userName).subscribe(results =>{
  //       this.toastr.success("Successfully cancel your registration", 'Success!');
  //     })
  //     location.reload();
  //   }
  // }
}
