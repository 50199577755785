import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { PostsService } from '../posts.service';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-add-seller',
  templateUrl: './add-seller.component.html',
  styleUrls: ['./add-seller.component.css']
})
export class AddSellerComponent implements OnInit {

  sellerForm: FormGroup;
  base64Image= null;

  constructor(private postsService: PostsService,private fb: FormBuilder, private toastr: ToastrService, private router: Router) { }

  ngOnInit() {
    
    this.sellerForm = this.fb.group({
      companyName: ['', Validators.required],
      companyUEN: ['', Validators.required],
      sellersEmail: ['', [Validators.required, Validators.email]],
      deliveryFee: ['', Validators.required],
      giveFreeShipping: ['', Validators.required],
      pickupLocation: ['', Validators.required],
      image: ['', Validators.required]     
    });
  }

  // convert file to base64 string
  handleUpload(event) {
    const comp = this;
    const img = <File>event.target.files[0];
    const promise = new Promise((resolve) => {
     const reader = new FileReader();
     reader.onload = function () {
        resolve(reader.result);
      }
      reader.readAsDataURL(img);
    });
  
    promise.then(img => {
      comp.base64Image = img;
    });
  }


  onSubmit(){
    if(this.sellerForm.value.companyName == "" || this.sellerForm.value.companyUEN == "" || this.sellerForm.value.sellersEmail == "" || this.sellerForm.value.deliveryFee == ""  || this.sellerForm.value.giveFreeShipping == ""  || this.sellerForm.value.pickupLocation == "" || this.sellerForm.value.image == ""){
      this.toastr.warning('Fill in all input fields!', 'Warning!');
    }

    else if(this.sellerForm.controls.sellersEmail.hasError('email'))
    {
      this.toastr.warning('Invalid email!', 'Warning');
    }

    else{
      // create new seller
      this.postsService.addSeller(this.sellerForm.value.companyName, this.base64Image, this.sellerForm.value.sellersEmail, this.sellerForm.value.companyUEN, this.sellerForm.value.deliveryFee, this.sellerForm.value.giveFreeShipping, this.sellerForm.value.pickupLocation).subscribe(results => {
      this.toastr.success("Successfully added!", 'Success!');
      this.router.navigateByUrl('/adminSellerList');
    });
    }
  }

}
