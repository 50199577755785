    
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PostsService } from '../posts.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-products-info',
  templateUrl: './products-info.component.html',
  styleUrls: ['./products-info.component.css'],
})
export class ProductsInfoComponent implements OnInit {
  _id: string;
  productsInfo: any = [];
  carts: any = [];
  private sub: any;
  product:any = [];
  name:string;
  price : number;
  category: string;
  image:string;
  quantity:number;
  wishlists: any = [];
  productID:string;
  itemID:string;
  validate: boolean = false;
  supplierID: any;
  productSupplierID: any;
  constructor(private postsService: PostsService,private route: ActivatedRoute, private toastr: ToastrService, private router: Router, public service: AuthService, private spinner: NgxSpinnerService) 
  { 
    this.spinner.show();   
    this.sub = this.route.params.subscribe(params => {
      this._id = params['_id']; 
      this.postsService.postGetProductsUsingID(this._id).subscribe(productsInfo => {
        this.productsInfo = productsInfo;
        this.productID = productsInfo[0]._id;
        this.productSupplierID = productsInfo[0].supplier._id;
      });
      if(this.service.isLoggedIn()){
        this.getCart();
        this.getWishlist();
        this.service.postGetUserDetails(this.service.getUserID()).subscribe(userInfo => {
          this.supplierID = userInfo[0].supplier
        });  
      }
      else{
        this.spinner.hide();
      }    
        
    });
  }  

  ngOnInit() {
    
  }

  getCart(){
   //get user cart
   this.postsService.postGetCart(this.service.getUserID()).subscribe(carts => {
      this.carts = carts;
      this.spinner.hide();

    },
      error => {
        console.log("Cart View Error: ", error);

    });
  }
  
  getWishlist(){
    //get user wishlist
   this.postsService.postGetWishlist(this.service.getUserID()).subscribe(wishlists => {
      this.wishlists = wishlists;
      
    });
  }

  async getCartAsync(){
    return new Promise((resolve, reject) => {
      resolve(this.carts);
    });
  }

  async getWishlistAsync(){
    return new Promise((resolve, reject) => {
      resolve(this.wishlists);
    });
  }

  deleteProducts()
  {
    if(confirm('Are you sure that you want to delete this product ?'))
    {
      //remove product based on id
    this.postsService.postDeleteProducts(this._id).subscribe(results => {
    this.toastr.success("Successfully deleted!", 'Success!');
    this.router.navigateByUrl('/products/All').then(() =>
    {
      location.reload();
    });
    });
    }
  }

  inArray(target, array)
  {
    for(var i = 0; i < array.length; i++) 
    {
      if(array[i].name == target)
      {
        return true;
      }
    }
    return false; 
  }

  //add product to cart
  async addToCart() {
    let response = await this.getCartAsync();
    //console.log(response);
    //check whether user login
    if(this.service.isLoggedIn() == false){
      this.router.navigateByUrl('/login');
      this.toastr.warning('Please login to your account first!', 'Warning');
    }
    //check whether product already in your cart
    else if(this.inArray(this.productsInfo[0].name, response)){
      this.toastr.warning('This product is already in your cart', 'Warning');
    }
    
    //check whether product is out of stock
    else if (this.productsInfo[0].availability == false)
    {
      this.toastr.warning('This product is out of stock!', 'Warning');
    }
    
    else{
      if(confirm('Do you want to add this item to your cart ?'))
    {
      this.productID = this.productsInfo[0]._id;
      this.name=this.productsInfo[0].name;
      this.price=this.productsInfo[0].price;
      var discountPrice = this.productsInfo[0].discount_price;
      var maxGreenCurrency = this.productsInfo[0].maxGreenCurrency;
      this.category=this.productsInfo[0].category;
      this.image=this.productsInfo[0].image;
      this.quantity = 1;
      var pAva = this.productsInfo[0].availability;
      var pSeller = this.productsInfo[0].supplier;
      delete pSeller.companyLogo;
      //add product to your cart
      this.postsService.addToCart(this.service.getUserID(),this.productID,this.name,this.price,discountPrice, maxGreenCurrency,pSeller, this.category,this.image,this.quantity, pAva, ).subscribe(results =>{

        this.toastr.success("Successfully added item to cart!", 'Success!');
        
        location.reload();   
        });
    
    }
    }  
  }
  
  //add product to wishlist
  async addToWishlist(){
    let response = await this.getWishlistAsync();
    //check whether you have login
    if(this.service.isLoggedIn() == false){
      this.router.navigateByUrl('/login');
      this.toastr.warning('Please login to your account first!', 'Warning');
    }
    //check whether product exists in your wishlist
    else if(this.inArray(this.productsInfo[0].name, response)){
      this.toastr.warning('This product is already in your wishlist', 'Warning');
    }
    else{
      if(confirm('Do you want to add this item to your wishlist ?'))
    {
     this.itemID=this.productsInfo[0]._id; 
     this.name=this.productsInfo[0].name;
     this.price=this.productsInfo[0].price;
     var discountPrice = this.productsInfo[0].discount_price;
     var maxGreenCurrency = this.productsInfo[0].maxGreenCurrency;
     this.category=this.productsInfo[0].category;
     this.image=this.productsInfo[0].image;
     this.quantity = 1;
     var pAva = this.productsInfo[0].availability;
     var pSeller = this.productsInfo[0].supplier;
     delete pSeller.companyLogo;
     //add product to your wishlist
     this.postsService.addToWishlist(this.service.getUserID(),this.itemID,this.name,this.price,discountPrice, maxGreenCurrency,pSeller,this.category,this.image,this.quantity, pAva).subscribe(results =>{

      this.toastr.success("Successfully added item to wishlist!", 'Success!'); 
     
      location.reload();   
      });
    
    }
    }  
  }

  //update the product to in stock
  supplierEditAvailabilityTrue(){

    if(this.supplierID != this.productSupplierID)
    {
      this.toastr.warning("Warning you can't updated product that aren't yours!", 'Warning!');

    }
    else
    {
      this.postsService.editProductsAvailability(this.productID, true).subscribe(results => {

        this.toastr.success("Successfully Updated!", 'Success!');
        window.location.reload();
  
      });
    }
  } 
  //update the product to out of stock
  supplierEditAvailabilityFalse(){
    if(this.supplierID != this.productSupplierID)
    {
      this.toastr.warning("Warning you can't updated a product that isn't yours!", 'Warning!');
    }
    else
    {
      this.postsService.editProductsAvailability(this.productID, false).subscribe(results => {

        this.toastr.success("Successfully Updated!", 'Success!');
        window.location.reload();
  
      });
    }

  } 
}