import { Component, OnInit } from '@angular/core';
import { PostsService } from '../posts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {
  wishlists: any = [];
  carts: any = [];
  p: Number = 1;
  name: string;
  price : number;
  category: string;
  quantity : number;
  image: string;
  amount : number;
  total : number;
  msg:string;
  empty: boolean;
  ID:string;
  wishListLength: number;
  discountPrice:number;

  constructor(private postsService: PostsService, private route: ActivatedRoute, private router:Router,private toastr: ToastrService,public service: AuthService,  private spinner: NgxSpinnerService) { 
    this.quantity = 0;
    this.spinner.show();
    
    if(service.isLoggedIn()){
    this.getCart();
    }
    for (let i = 1; i <= this.wishlists.length; i++) {
    this.wishlists.push(`item ${i}`);
    }
    //get user wishlist
    this.postsService.postGetWishlist(this.service.getUserID()).subscribe(wishlists => {
      this.wishlists = wishlists;
      this.wishListLength = wishlists.length;
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
      if(this.wishlists.length == 0){
        this.msg = 'Your wishlist is empty!'
        this.empty = true
      }
      else{
        this.msg = ''
        this.empty = false
      }
    });
  }
  ngOnInit(): void {
  }

  getCart(){
    this.postsService.postGetCart(this.service.getUserID()).subscribe(carts => {
       this.carts = carts;
     },
     error => {
       console.log("Cart View Error: ", error);
     });
   }
 
   async getCartAsync(){
     return new Promise((resolve, reject) => {
       resolve(this.carts);
     });
   }

   //remove product from wishlist
  deleteWishlist(id:string)
  {
    if(confirm('Are you sure you want to remove this item from your wishlist ?'))
  {
 
    this.postsService.postDeleteWishlist(id).subscribe(results => {
      location.reload();
    
      });
    }
  }
  
  //remove product from wishlist
  removeWishlist(id:string)
  {
    this.postsService.postRemoveWishlist(id).subscribe(results => {
    
      });
  }

  //increase quantity for product
  increaseQty(id: number, oldquantity: string)
  {
    this.postsService.increaseQty(id, 
    parseInt(oldquantity)+1).subscribe(results => {
      location.reload();
    });
  }

  //decrease quantity for product
  decreaseQty(id: number, oldquantity: string)
  {
    if(parseInt(oldquantity) <= 1)
    {
      alert('Quantity cannot be less than 1')
    }

    else{
      this.postsService.decreaseQty(id, 
      parseInt(oldquantity)-1).subscribe(results => {
        location.reload();
      });
    }
  }

  inArray(target, array)
  {
    for(var i = 0; i < array.length; i++) 
    {
      if(array[i].name == target)
      {
        return true;
      }
    }
    return false; 
  }

  async addToCart(pID,pName, pPrice, pDiscountPrice, pMaxGreenCurrency, pCat, pImg, pQty, pAva, wID, wSeller) {
    let response = await this.getCartAsync();
    // check whether product exists in your cart
    if(this.inArray(pName, response)){
      this.toastr.warning('This item is already in your cart', 'Warning');
    }
    //check the availability of the product
    else if (pAva == false)
    {
      this.toastr.warning('This item is out of stock!', 'Warning');
    }
    else{
      if(confirm('Do you want to add this item to your cart ?'))
      {
        //remove product from wishlist and add the product to the cart
        this.deleteWishlist(wID);

        this.ID = pID;
        this.name = pName;
        this.price = pPrice;
        this.discountPrice = pDiscountPrice;
        var maxGreenCurrency = pMaxGreenCurrency;
        this.category = pCat;
        this.image= pImg;
        this.quantity= pQty;
        var pA = pAva;
        delete wSeller.companyLogo;

        this.postsService.addToCart(this.service.getUserID(), this.ID,this.name,this.price,this.discountPrice, maxGreenCurrency, wSeller,this.category,this.image,this.quantity, pA) .subscribe(results =>{

          this.toastr.success("Successfully added item to cart!", 'Success!');
          location.reload();
          });
        
      }
    }
      
  }

}
