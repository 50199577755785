<br>
<br>
<br>
<br>
<br>
<p><a routerLink="/adminAddSeller" class="postSellerBtn"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Add New Seller</a></p>
<br>
<div class="section-title">
  <h2>List Of Website Sellers</h2>

<div class="main">
  <div class="form-group has-search">
  <span class="fa fa-search form-control-feedback"></span>
  <input type="text" (keydown)="search($event)" class="form-control" placeholder="Search for Seller">
  </div>
</div>
</div>
<div class="user-list">
    <table class="table table-hover">
      
        <thead class="thead-dark">
          <tr>            
            <th scope="col">Company Logo</th>
            <th scope="col">Company Name</th>
            <th scope="col">Company UEN</th>
            <th scope="col">Company Email</th>
            <th scope="col">Delivery Fee</th>
            <th scope="col">Give Free Shipping</th>
            <th scope="col">Pickup Location</th>
            <th scope="col">Delete Seller</th>

          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let i of sellerList">
          <a [routerLink]="['/adminSellerInfo',i._id]" >
            <td data-label="qr">
              <img  src={{i.companyLogo}} name="companyLogo" width="145" height="145">
            </td>
          </a>
          <td data-label="companyName" [routerLink]="['/adminSellerInfo',i._id]">{{ i.companyName }}</td>
          <td data-label="UEN" [routerLink]="['/adminSellerInfo',i._id]">{{ i.companyUEN }}</td>
          <td data-label="sellersEmail" [routerLink]="['/adminSellerInfo',i._id]">{{ i.sellersEmail }}</td>
          <td data-label="deliveryFee" [routerLink]="['/adminSellerInfo',i._id]">{{ i.deliveryFee | currency:'USD' }}</td>
          <td data-label="giveFreeShipping" [routerLink]="['/adminSellerInfo',i._id]">{{ i.giveFreeShipping | currency:'USD' }}</td>
          <td data-label="pickupLocation" [routerLink]="['/adminSellerInfo',i._id]">{{ i.pickupLocation}}</td>
          <td data-label="delete">
            <button type="button" class="btn btn-danger" (click)="deleteSeller(i._id)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
