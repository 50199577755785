<br>
<br>
<br>
<br>

<div class="container">
<form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="p-5 bg-white">
  
    <h1>Your Profile:</h1>
    <hr>
    <div class="form-inline justify-content-center">
      <ngx-qrcode
      [elementType]= "elementType"
      [value]="userID"
      class="qrcode"
    ></ngx-qrcode>
    </div>  
    <label for="name">Username:</label>
    <p><input type="text" formControlName="name" id="name" disabled></p>
    <label for="email">Email:</label>
    <p><input type="email" formControlName="email" id="email" disabled></p>

    <label for="role">User access level:</label>
    <p><input type="text" formControlName="role" id="role" placeholder="" disabled></p>

    <label for="role">Green Currency Amount:</label>
    <p><input type="number" formControlName="greenCurrency" id="greenCurrency" placeholder="" disabled></p>

    <label for="mobile">Mobile No.:</label>
    <p><input type="text" formControlName="mobile" id="mobile" minlength="8" maxlength="8" placeholder="Your Mobile No." required></p>

    <label for="address">Postal Code.: (e.g. 123456)</label>
    <p><input type="text" formControlName="address" id="address" placeholder="Your house postal code" minlength="6" maxlength="6" disabled></p>

      <label for="address">Type of housing:</label>
      <div class="form-check">
        <label class="form-check-label" for="radio1">
          <input type="radio" formControlName="housingType" id="radioHDB" value="HDB" checked disabled>Public Housing (HDB flats)
        </label>
      </div>
      <div class="form-check">
        <label class="form-check-label" for="radio2">
          <input type="radio" formControlName="housingType" id="radioApartments" value="Condominiums" disabled>Private Condominiums / Apartments
        </label>
      </div>
      <div class="form-check">
        <label class="form-check-label">
          <input type="radio" formControlName="housingType" id="radioLandedHousing" value="Landed Housing" disabled>Landed Housing
        </label>
      </div>
      <br>
      <label for="address">Unit Number.: (e.g. #01-123)</label>
      <p><input type="text" formControlName="unitNo" id="unitNo" placeholder="Your house unit number" disabled></p>

    <label for="password">New Password (8 characters minimum):</label>
    <p><input type="password" formControlName="password" id="password" placeholder="Your password" [(ngModel)]="account.password" minlength="8" required>
      <span id="password-status" (click)="passwordToggle()" class="fa fa-eye field-icon"></span></p>
    
    <label for="repassword">Retype Password:</label>
    <p><input type="password" formControlName="repassword" id="repassword" placeholder="Retype password" />
      <span id="repassword-status" (click)="repasswordToggle()" class="fa fa-eye field-icon"></span></p>
    
    <app-password-strength-bar [passwordToCheck]="account.password" [barLabel]="barLabel"> </app-password-strength-bar>  
    <hr>
    <p><button class="updatebtn" type="submit">Update</button></p>
 
</form>
</div>
  