<br>
<br>

  <!--Main layout-->
  <main class="mt-5 pt-4">
    <div class="container dark-grey-text mt-5">

      <!--Grid row-->
      <div class="row wow fadeIn">

        <!--Grid column-->
        <div class="col-md-6 mb-4">
            <div class="image-box">
          <img src={{programmesInfo[0].image}} class="img-fluid" alt="Program Image" height="700" width="750">
           </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-6 mb-4">

          <!--Content-->
          <div class="p-4">
            <h1>{{programmesInfo[0].name}}</h1>
            <a [routerLink]="['/editPrograms',_id]" *ngIf="validate === true" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"><img src="./assets/imgs/edit.png" width="40" height="40"></a>
            <img  src="./assets/imgs/delete.png" name="imgbtn" width="40" height="40" (click)="deleteProgrammes()" style="margin-left: 10px;" *ngIf="validate === true">

            <p>
            <span style="color:blue">Category : {{programmesInfo[0].category}}</span>
            </p>
            <ng-container *ngIf="specialProgram === true">
              <h2 class="font-family-serif h4 mb-3"> Price : Please email info@otolithenrichment.com for plans and pricings</h2>
            </ng-container>
            <ng-container *ngIf="specialProgram === false">
              <h2 class="font-family-serif h4 mb-3"> Price : {{programmesInfo[0].price | currency:'SGD' }}</h2>
              <p class="lead font-weight-bold" *ngIf="programmesInfo[0].discount_price != null" style="color:red">
                <span>Discounted Price : {{programmesInfo[0].discount_price | currency:'SGD' }}</span>
              </p>
            </ng-container>
            <p><strong>Seller : </strong>{{programmesInfo[0].supplier.companyName}}<img src={{programmesInfo[0].supplier.companyLogo}} alt="companyLogo" width="30" height="30">
            </p>
            <p><strong>Green Currency Limit : </strong>{{programmesInfo[0].maxGreenCurrency}}%</p>
            <ng-container *ngIf="specialProgram === true">
              <p><strong>Datetime : </strong>Contact us at info@otolithenrichment.com for the next available dates</p>
            </ng-container>
            <ng-container *ngIf="specialProgram === false">
              <p><strong>Date : </strong>{{programmesInfo[0].date}}</p>
              <p><strong>Time : </strong>{{programmesInfo[0].time}}</p>
            </ng-container>
            <p><strong>Duration : </strong>{{programmesInfo[0].duration}}</p>
            <p><strong>Location : </strong>{{programmesInfo[0].location}}</p>
            <p><strong>Total Capacity : </strong>{{programmesInfo[0].capacity}}</p>
            <p><strong>Last update : </strong>{{programmesInfo[0].last_update  | date : 'dd/MM/yyyy'}}</p>

            <form class="d-flex justify-content-left">
              <!-- Default input -->
              <ng-container *ngIf="specialProgram === false">
                <div>
                  <button (click)="addProgramToCart()" class="btn btn-outline-success mr-2">Sign Up</button>
                </div>
              </ng-container>

            </form>

          </div>
          <!--Content-->

        </div>
        <!--Grid column-->

      </div>
      <!--Grid row-->

    </div>
  </main>
  <!--Main layout-->
<div style="margin-left: 35px">
  <p class="lead font-weight-bold">Synopsis :</p>
  <p>{{programmesInfo[0].synopsis}}</p>

  <br>

  <p class="lead font-weight-bold">Topics Covered :</p>
  <p>{{programmesInfo[0].topicsCovered}}</p>

  <br>
  <p class="lead font-weight-bold">Learning Objectives :</p>
  <p>{{programmesInfo[0].learningObjective}}</p>
</div>

  <!-- <ng-container *ngIf="validate === true">
    <br>
    <div style="text-align:center">
      <h4><strong>List of Users that sign up:</strong></h4>
     <br>
     <p *ngIf="programmesInfo[0].slot.length === 0">No user sign up yet</p>
   </div>
   <div *ngFor="let i of programmesInfo[0].slot">
   <div  style="text-align:center">
     <p>Username : {{ i }}</p>
   </div>
   </div>
  </ng-container> -->

  <!-- <div class="disqusContainer">
    <disqus class="disqus" [identifier]="['/programsInfo',_id]"></disqus>
  </div> -->
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>