<br>
<br>
<br>
<br>
<div class="container">
  <form [formGroup]="programsForm"  class="p-5 bg-white" (ngSubmit)="onSubmit()">
  <h2>Add Program/Workshops</h2>
  <hr>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="name">Name:</label>
        <input type="text" id="name" formControlName="name" class="form-control" placeholder="Enter Name...">
     </div>
  </div>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="price">Price:</label>
        <input type="number" id="price" formControlName="price" class="form-control" placeholder="Enter Price...">
     </div>
  </div>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="discountPrice">Discount Price:</label>
        <input type="number" id="discountPrice" formControlName="discountPrice" class="form-control" placeholder="Enter Discount Price...">
     </div>
  </div>
  <div class="row form-group">
   <div class="col-md-12 mb-3 mb-md-0">
     <label class="font-weight-bold" for="maxGreenCurrency">Max Percentage of Green Currency can use: (e.g 50)</label>
     <input type="number" id="maxGreenCurrency" formControlName="maxGreenCurrency" class="form-control" placeholder="Enter Maximum Percentage of Green Currency can use...">
   </div>
 </div>
 <div class="row form-group">
    <div class="col-md-12 mb-3 mb-md-0">
     <label class="font-weight-bold" for="supplier">Supplier: </label>
     <select type="text" class="form-control" id="supplier" formControlName="supplier">
      <option value="" disabled selected>Select Supplier...</option>
     <option *ngFor="let supplier of sellers" [ngValue]="supplier">
     {{supplier.companyName }}
     </option>
     </select>
     </div>
  </div>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="category">Category:</label>
        <select type="text" id="category" class="form-control" formControlName="category">
           <option value="" disabled selected>Select Category...</option>
           <option>Program</option>
           <option>Workshop</option>
        </select>
     </div>
  </div>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="synopsis">Synopsis:</label>
        <textarea cols="35" rows="10" id="synopsis" formControlName="synopsis" class="form-control" placeholder="Enter Synopsis..."></textarea>
     </div>
  </div>
  <div class="row form-group">
    <div class="col-md-12 mb-3 mb-md-0">
       <label class="font-weight-bold" for="topic">Topics Covered:</label>
       <textarea cols="35" rows="10" id="topic" formControlName="topic" class="form-control" placeholder="Enter Topics Covered..."></textarea>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-12 mb-3 mb-md-0">
       <label class="font-weight-bold" for="objective">Learning Objectives:</label>
       <textarea cols="35" rows="10" id="objective" formControlName="objective" class="form-control" placeholder="Enter Learning Objectives..."></textarea>
    </div>
 </div>
 <div class="row form-group">
  <div class="col-md-12 mb-3 mb-md-0">
     <label class="font-weight-bold" for="duration">Duration:</label>
     <textarea cols="35" rows="2" id="duration" formControlName="duration" class="form-control" placeholder="Enter Duration..."></textarea>
  </div>
</div>
<div class="row form-group">
  <div class="col-md-12 mb-3 mb-md-0">
     <label class="font-weight-bold" for="location">Location:</label>
     <input type="text" id="location" formControlName="location" class="form-control" placeholder="Enter Location...">
  </div>
</div>
 
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="capacity">Capacity:</label>
        <input type="number" id="capacity" formControlName="capacity" class="form-control" placeholder="Enter Capacity...">
     </div>
  </div>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="date">Date:</label>
        <input type="text" id="date" formControlName="date" class="form-control" placeholder="Enter Date...">
     </div>
  </div>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="time">Time:</label>
        <input type="text" id="time" formControlName="time" class="form-control" placeholder="Enter Time...">
     </div>
  </div>

  <label class="font-weight-bold" for="specialProgram">Select the radio button "Yes" if there is no fixed price, date and time:</label>
  <div class="form-check">
    <label class="form-check-label" for="radio1">
      <input type="radio" formControlName="specialProgram" id="specialProgramYes" value="true">"Yes"
    </label>
  </div>
  <div class="form-check">
    <label class="form-check-label" for="radio2">
      <input type="radio" formControlName="specialProgram" id="specialProgramNo" value="false">"No"
    </label>
  </div>
 <br>

  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label  class="font-weight-bold" for="image">Image:</label>
        <br>
        <input type="file" id="image" formControlName="image" (change)="handleUpload($event)" accept=".png, .jpg, .jpeg, .pdf">
     </div>
  </div>
  <br> 
  <hr>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <input type="submit" value="Submit" class="btn btn-success pill text-white px-5 py-2"  >
     </div>
  </div>
  </form>
</div>
