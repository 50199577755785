 <!--Main layout-->
 <main class="mt-5 pt-4">
  <div class="container dark-grey-text mt-5">

    <!--Grid row-->
    <div class="row wow fadeIn">

      <!--Grid column-->
      <div class="col-md-6 mb-4">
        <img src={{newsInfo[0].image}} class="img-fluid" alt="Project Image" width="700" height="700">
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-md-6 mb-4">

      <!--Content-->
      <div class="p-4">
        <h1> {{newsInfo[0].title}} </h1>
        <a [routerLink]="['/editNews',_id]" *ngIf="validate === true" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"><img src="./assets/imgs/edit.png" width="40" height="40"></a>    
        <img src="./assets/imgs/delete.png" name="imgbtn" width="40" height="40" (click)="deleteNews()" style="margin-left: 10px;" *ngIf="validate === true" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
      <p class="lead font-weight-bold">
        <span>Date Published:{{newsInfo[0].publish  | date : 'dd/MM/yyyy'}}</span>
      </p>
      <p class="lead font-weight-bold">Description</p>
      <p>{{newsInfo[0].body}}</p>     
      </div>
      <!--Content-->

    </div>
    <!--Grid column-->

  </div>
  <!--Grid row-->

  </div>
</main>
<!--Main layout-->

<div class="disqusContainer">
  <disqus class="disqus" [identifier]="['/newsInfo',_id]"></disqus>
</div>
