<br>
<br>
<br>
<br>

<div class="container">
<form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="p-5 bg-white">
    
      <h1>Reset Password</h1>
      <p>Please fill in this form to reset your password.</p>
      <hr>
        <label for="password">New Password :</label>
        <p><input type="password" formControlName="password" id="password" placeholder="Your password" [(ngModel)]="account.password" minlength="8" required>
          <span id="password-status" (click)="passwordToggle()" class="fa fa-eye field-icon"></span></p>
  
        <label for="repassword">Confirm Password :</label>
        <p><input type="password" formControlName="repassword" id="repassword" placeholder="Confirm password" />
          <span id="repassword-status" (click)="repasswordToggle()" class="fa fa-eye field-icon"></span></p>
          
        <app-password-strength-bar [passwordToCheck]="account.password" [barLabel]="barLabel"> </app-password-strength-bar>  
      <hr>
      <p><button class="btn" type="submit" >Reset Password</button></p>
  
  </form>
</div>
    