import { Component, OnInit } from '@angular/core';
import { PostsService } from '../posts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-programmes',
  templateUrl: './programmes.component.html',
  styleUrls: ['./programmes.component.css']
})
export class ProgrammesComponent implements OnInit {

  _id:string;
  programs: any = [];
  p: Number = 1;
  name:string;
  price : number;
  category: string;
  image:string;
  quantity:number;
  loadedprogramList: any[];

  constructor(private postsService: PostsService, private route: ActivatedRoute, private router:Router,private toastr: ToastrService, public service: AuthService, private spinner: NgxSpinnerService) {
    this.spinner.show();

    for (let i = 1; i <= this.programs.length; i++) {
      this.programs.push(`item ${i}`);
    }
    //get all programs
    this.postsService.postGetAllProgrammes().subscribe(programs => {
      this.programs = programs;
      this.loadedprogramList = programs;
      this.spinner.hide();        
    });
        
    }

  

  getProgrammesByNewDate()
   {
    
      this.postsService.postGetProgrammesByNewDate().subscribe(programs => {
        this.programs = programs;
        });

   }

   getProgrammesByOldDate()
   {
    
      this.postsService.postGetProgrammesByOldDate().subscribe(programs => {
        this.programs = programs;
        });

   }


  ngOnInit() {
   
  }

  initializeItems(): void {
    this.programs = this.loadedprogramList;
  }

  search(event) {
    
    this.initializeItems();
    //Set searchTerm with input from search bar
    const searchTerm = event.srcElement.value;
  
    if (!searchTerm) {
    return;
    }
    //Filters enquiries list with keywords from searchTerm
    this.programs = this.programs.filter(i => {
    if (i.name && searchTerm) {
    if (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
    return true;
    }
    return false;
    }
    });
  }

 
  

}

