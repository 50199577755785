<br>
<br>

<!--Main layout-->
<main class="mt-5 pt-4">
  <div class="container dark-grey-text mt-5">
     <!--Grid row-->
     <div class="row wow fadeIn">
        <!--Grid column-->
        <div class="col-md-6 mb-4">
          <div class="image-box">
            <img src={{productsInfo[0].image}} class="img-fluid" alt="product image">
          </div>
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-md-6 mb-4">
           <!--Content-->
           <div class="p-4">
              <h1>{{productsInfo[0].name}} </h1>
              <a [routerLink]="['/editProducts',_id]" *ngIf="service.isAdmin()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"><img src="./assets/imgs/edit.png" width="40" height="40"></a>			
              <img  src="./assets/imgs/delete.png" name="imgbtn" width="40" height="40" (click)="deleteProducts()" style="margin-left: 10px;" *ngIf="service.isAdmin()">
              
              <p>
                <span style="color:blue">Category : {{productsInfo[0].category}}</span>
              </p>
              <h2 class="font-family-serif h4 mb-3">Retail Price : {{productsInfo[0].price | currency:'SGD' }}</h2>
              <p class="lead font-weight-bold" *ngIf="productsInfo[0].discount_price != null" style="color:red">
                 <span>Discounted Price : {{productsInfo[0].discount_price | currency:'SGD' }}</span>
              </p>
              <p><strong>Green Currency Limit : </strong>{{productsInfo[0].maxGreenCurrency}}%</p>
              <p><strong>Supplier : </strong>{{productsInfo[0].supplier.companyName}}<img src={{productsInfo[0].supplier.companyLogo}} alt="companyLogo" width="30" height="30">
              </p>
              <p><strong>In stock : </strong>{{productsInfo[0].availability}}</p>
              <p *ngIf="service.isSupplier()">
                  <strong>Edit availability : </strong>
                  <button type="button" class="btn btn-outline-success" (click)="supplierEditAvailabilityTrue()">In stock</button>
                  <button type="button" class="btn btn-outline-danger" style="margin-left: 10px" (click)="supplierEditAvailabilityFalse()">Out of stock</button>
               </p>
              <p><strong>Last update : </strong>{{productsInfo[0].last_update  | date : 'dd/MM/yyyy'}}</p>
             
              <form class="d-flex justify-content-left">
                 <!-- Default input -->
                 <p><button (click)="addToCart()" class="btn btn-outline-primary mr-2"><i class="fa fa-shopping-cart"></i>   Add To Cart</button>
                    <button class="btn btn-outline-success mr-2" (click)="addToWishlist()" style="margin-left: 10px"><i  class="fa fa-heart-o" ></i>  Add To Wishlist</button>
                 </p>
              </form>
           </div>
           <!--Content-->
        </div>
        <!--Grid column-->
     </div>
     <!--Grid row-->
  </div>
</main>
<!--Main layout-->
<div style="margin-left:35px">
<p class="lead font-weight-bold">Description :</p>
<p>{{productsInfo[0].description}}</p>
</div>
<!-- <div class="disqusContainer">
  <disqus class="disqus" [identifier]="['/productsInfo',_id]"></disqus>
</div> -->

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>