import { Component, OnInit } from "@angular/core";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ConnectionService } from "../connection.service";
import { EncrDecrService } from "../encr-decr-service";
import endpoint from "endpoints.config";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  public account = {
    password: null,
  };

  public barLabel: string = "Password strength:";
  myForm: FormGroup;
  secretKey = endpoint.secretKey;
  userNameList: any[];

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private connectionService: ConnectionService,
    private EncrDecr: EncrDecrService
  ) {
    //post get all users
    this.authService.dontTryAnythingFunnyPlease().subscribe((data) => {
      var array = [];
      for (var i = 0; i < data.length; i++) {
        var decryptedName = this.EncrDecr.get(this.secretKey, data[i].yoMaMA);
        var decryptedEmail = this.EncrDecr.get(
          this.secretKey,
          data[i].daddyJon
        );
        var decryptedAddress = this.EncrDecr.get(
          this.secretKey,
          data[i].chickenCutleb
        );
        var decryptedUnitNo = this.EncrDecr.get(
          this.secretKey,
          data[i].blackSheepWall
        );
        var obj = {
          yoMaMA: decryptedName,
          daddyJon: decryptedEmail,
          chickenCutleb: decryptedAddress,
          blackSheepWall: decryptedUnitNo,
        };
        array.push(obj);
      }
      this.userNameList = array;
    });
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      name: "",
      email: ["", Validators.email],
      mobile: "",
      address: [
        "",
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
      ],
      unitNo: "",
      radioBtn: "",
      password: "",
      repassword: "",
      tnc: ["", Validators.required],
      radioHalalBoxType: "",
    });
    var more3Text = document.getElementById("more3");
    more3Text.style.display = "none";
    var more4Text = document.getElementById("more4");
    more4Text.style.display = "none";
  }

  //show or hide the terms and conditions
  showText() {
    var dots3 = document.getElementById("dots3");
    var more3Text = document.getElementById("more3");
    var tnc3Text = document.getElementById("myTNC3");

    if (dots3.style.display === "none") {
      dots3.style.display = "inline";
      tnc3Text.innerHTML = "Terms and Conditions";
      more3Text.style.display = "none";
    } else {
      dots3.style.display = "none";
      tnc3Text.innerHTML = "Terms and Conditions";
      more3Text.style.display = "inline";
    }
  }
  //show or hide the privacy policy
  showText2() {
    var dots4 = document.getElementById("dots4");
    var more4Text = document.getElementById("more4");
    var policy4Text = document.getElementById("myPolicy4");

    if (dots4.style.display === "none") {
      dots4.style.display = "inline";
      policy4Text.innerHTML = "Privacy Policy";
      more4Text.style.display = "none";
    } else {
      dots4.style.display = "none";
      policy4Text.innerHTML = "Privacy Policy";
      more4Text.style.display = "inline";
    }
  }
  // toggle password visiability
  passwordToggle() {
    var x = <HTMLInputElement>document.getElementById("password");
    var y = <HTMLInputElement>document.getElementById("password-status");
    if (x.type === "password") {
      x.type = "text";
      y.className = "fa fa-eye-slash field-icon";
    } else {
      x.type = "password";
      y.className = "fa fa-eye field-icon";
    }
  }

  // toggle repassword visiability
  repasswordToggle() {
    var x = <HTMLInputElement>document.getElementById("repassword");
    var y = <HTMLInputElement>document.getElementById("repassword-status");
    if (x.type === "password") {
      x.type = "text";
      y.className = "fa fa-eye-slash field-icon";
    } else {
      x.type = "password";
      y.className = "fa fa-eye field-icon";
    }
  }

  //check whether address exists
  checkForExistingAddress(target, array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].chickenCutleb + array[i].blackSheepWall == target) {
        return true;
      }
    }
    return false;
  }

  onSubmit() {
    var unlistLength = this.userNameList.length;
    for (var i = 0; i < unlistLength; i++) {
      //check whether username exists
      if (this.userNameList[i].yoMaMA == this.myForm.value.name) {
        this.toastr.warning("Username already taken", "Warning");
        return;
      }
    }
    var unlistLength = this.userNameList.length;
    for (var i = 0; i < unlistLength; i++) {
      //check whether email exists
      if (this.userNameList[i].daddyJon == this.myForm.value.email) {
        this.toastr.warning("Email already in use", "Warning");
        return;
      }
    }

    //Check if all input fields are filled
    if (
      this.myForm.value.name == "" ||
      this.myForm.value.password == "" ||
      this.myForm.value.repassword == "" ||
      this.myForm.value.mobile == "" ||
      this.myForm.value.email == "" ||
      this.myForm.value.address == "" ||
      this.myForm.value.radioBtn == "" ||
      this.myForm.value.tnc == ""
    ) {
      this.toastr.warning(
        "Fill in all input fields and agree with the terms and conditions & privacy policy!",
        "Warning"
      );
    } else if (this.myForm.controls.email.hasError("email")) {
      this.toastr.warning("Invalid email!", "Warning");
    } else if (this.myForm.controls.password.hasError("minlength")) {
      this.toastr.warning(
        "Minimum 8 character is required for password!",
        "Warning"
      );
    }

    //Check if passwords match
    else if (this.myForm.value.password != this.myForm.value.repassword) {
      this.toastr.warning("Passwords don't match!", "Warning");
    } else if (/[\d]{8}/.test(this.myForm.value.mobile) == false) {
      this.toastr.warning("Invalid phone number format!", "Warning");
    } else if (
      this.myForm.controls.address.hasError("minlength") ||
      this.myForm.controls.address.hasError("maxlength")
    ) {
      this.toastr.warning("Invalid postal code!", "Warning");
    }

    //Creates new user from register form
    else {
      var userHouseLoc;
      var notApplicable;
      this.connectionService
        .getLocationByPostalCode(this.myForm.value.address)
        .subscribe((location) => {
          if (location["results"][0] == null) {
            this.toastr.warning("Invalid Postal Code", "Warning");
          } else if (this.myForm.value.radioBtn == "Landed Housing") {
            notApplicable = "not applicable";
            if (
              this.checkForExistingAddress(
                location["results"][0]["ADDRESS"] + notApplicable,
                this.userNameList
              ) == true
            ) {
              this.toastr.warning("Address already exist", "Warning");
            } else {
              userHouseLoc = location["results"][0]["ADDRESS"];
              //register a new account
              this.authService
                .regUser(
                  this.myForm.value.name,
                  this.myForm.value.password,
                  "user",
                  this.myForm.value.email,
                  this.myForm.value.mobile,
                  userHouseLoc,
                  "not applicable",
                  this.myForm.value.radioBtn,
                  this.myForm.value.halalBoxType
                )
                .subscribe();
              localStorage.setItem("regUsername", this.myForm.value.name);
              this.router.navigateByUrl("/login");
              this.toastr.success(
                "Registered sucessfully, please go to you entered email to verify your account",
                "Success"
              );
            }
          } else {
            if (
              /^#[\d]{2}-[\d]{2,4}$/.test(this.myForm.value.unitNo) == false
            ) {
              this.toastr.warning("Invalid unit no format", "Warning");
            } else if (
              this.checkForExistingAddress(
                location["results"][0]["ADDRESS"] + this.myForm.value.unitNo,
                this.userNameList
              ) == true
            ) {
              this.toastr.warning("Address already exist", "Warning");
            } else {
              userHouseLoc = location["results"][0]["ADDRESS"];
              //register a new account
              this.authService
                .regUser(
                  this.myForm.value.name,
                  this.myForm.value.password,
                  "user",
                  this.myForm.value.email,
                  this.myForm.value.mobile,
                  userHouseLoc,
                  this.myForm.value.unitNo,
                  this.myForm.value.radioBtn,
                  this.myForm.value.radioHalalBoxType
                )
                .subscribe();
              localStorage.setItem("regUsername", this.myForm.value.name);
              this.router.navigateByUrl("/login");
              this.toastr.success(
                "Registered sucessfully, please go to you entered email to verify your account",
                "Success"
              );
            }
          }
        });
    }
  }
}
