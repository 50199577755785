import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { PostsService } from '../posts.service';
import { Router } from '@angular/router'; 
@Component({
  selector: 'app-add-programmes',
  templateUrl: './add-programmes.component.html',
  styleUrls: ['./add-programmes.component.css']
})
export class AddProgrammesComponent implements OnInit {

  programsForm: FormGroup;
  programs: any = [];
  base64Image= null;
  last_update: Date = new Date();
  slot:any = [];
  sellers: any = []

  constructor(private postsService: PostsService,private fb: FormBuilder, private toastr: ToastrService, private router: Router) 
  { 
    // get supplier
    this.postsService.postGetAllSellers().subscribe(sellers => {
      this.sellers = sellers;
      
    });
  }

  ngOnInit() {
    
    this.programsForm = this.fb.group({
      name: ['', Validators.required],
      price: ['', Validators.required],
      supplier: ['', Validators.required],
      discountPrice: '',
      maxGreenCurrency:['', Validators.required],
      category: ['', Validators.required],
      synopsis: ['', Validators.required],
      topic: ['', Validators.required],
      objective: ['', Validators.required],
      duration: ['', Validators.required],
      location: ['', Validators.required],
      capacity: ['', Validators.required],
      date: ['', Validators.required],
      time: ['', Validators.required],
      specialProgram: ['', Validators.required],
      image: ['', Validators.required]     
    });
  }

  //convert file to base64 string 
  handleUpload(event) {
    const comp = this;
    const img = <File>event.target.files[0];
    const promise = new Promise((resolve) => {
     const reader = new FileReader();
     reader.onload = function () {
        resolve(reader.result);
      }
      reader.readAsDataURL(img);
    });
  
    promise.then(img => {
      comp.base64Image = img;
    });
  }


  onSubmit(){

    if(this.programsForm.value.specialProgram == ""){
      this.toastr.warning('Please select the radio button!', 'Warning!');
    }

    else if(this.programsForm.value.specialProgram == false){
    if(this.programsForm.value.name == "" || this.programsForm.value.price == "" || this.programsForm.value.maxGreenCurrency == "" || this.programsForm.value.category == "" || this.programsForm.value.synopsis == "" || this.programsForm.value.topic == "" || this.programsForm.value.objective == "" || this.programsForm.value.duration == "" || this.programsForm.value.location == "" || this.programsForm.value.capacity == "" || this.programsForm.value.date == "" || this.programsForm.value.time == ""){
      this.toastr.warning('Fill in all input fields!', 'Warning!');
     }
    }
    else if(this.programsForm.value.specialProgram == true){
      if(this.programsForm.value.name == "" || this.programsForm.value.maxGreenCurrency == "" || this.programsForm.value.category == "" || this.programsForm.value.synopsis == "" || this.programsForm.value.topic == "" || this.programsForm.value.objective == "" || this.programsForm.value.duration == "" || this.programsForm.value.location == "" || this.programsForm.value.capacity == ""){
        this.toastr.warning('Fill in all input fields!', 'Warning!');
       }
    }

    else if(this.programsForm.value.image == "" || this.programsForm.value.supplier == ""){
      this.toastr.warning('Fill in all input fields!', 'Warning!');
    }
    
    else if(this.programsForm.value.discountPrice == ""){
      //convert string to boolean
      var specialProgram = JSON.parse(this.programsForm.value.specialProgram);
      // add new program
      this.postsService.addProgrammes(this.programsForm.value.name,this.programsForm.value.price,null,this.programsForm.value.maxGreenCurrency,this.programsForm.value.category,this.programsForm.value.synopsis,this.programsForm.value.topic,this.programsForm.value.objective,this.programsForm.value.duration,this.programsForm.value.location,this.last_update,this.programsForm.value.capacity,this.programsForm.value.date,this.programsForm.value.time,this.base64Image,this.slot, this.programsForm.value.supplier, specialProgram).subscribe(results => {
        this.toastr.success("Successfully added!", 'Success!');
        this.router.navigateByUrl('/programs');
      });
    }
    
    else{
      // convert string to boolean
      var specialProgram = JSON.parse(this.programsForm.value.specialProgram);
      // add new program
      this.postsService.addProgrammes(this.programsForm.value.name,this.programsForm.value.price,this.programsForm.value.discountPrice,this.programsForm.value.maxGreenCurrency,this.programsForm.value.category,this.programsForm.value.synopsis,this.programsForm.value.topic,this.programsForm.value.objective,this.programsForm.value.duration,this.programsForm.value.location,this.last_update,this.programsForm.value.capacity,this.programsForm.value.date,this.programsForm.value.time,this.base64Image,this.slot, this.programsForm.value.supplier, specialProgram).subscribe(results => {
      this.toastr.success("Successfully added!", 'Success!');
      this.router.navigateByUrl('/programs');
    });
    }
}



}

