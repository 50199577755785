<br>
<br>
<br>
<br>

<div class="container">
  <form [formGroup]="productsForm"  class="p-5 bg-white" (ngSubmit)="onSubmit()">
  <h2>Add Products</h2>
  <hr>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="name">Name:</label>
        <input type="text" id="name" formControlName="name" class="form-control" placeholder="Enter Name...">
     </div>
  </div>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="price">Price:</label>
        <input type="number" id="price" formControlName="price" class="form-control" placeholder="Enter Price...">
     </div>
  </div>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="price">Discounted Price:</label>
        <input type="number" id="discountPrice" formControlName="discountPrice" class="form-control" placeholder="Enter Discount Price...">
     </div>
  </div>
  <div class="row form-group">
   <div class="col-md-12 mb-3 mb-md-0">
     <label class="font-weight-bold" for="maxGreenCurrency">Max Percentage of Green Currency can use: (e.g 50)</label>
     <input type="number" id="maxGreenCurrency" formControlName="maxGreenCurrency" class="form-control" placeholder="Enter Maximum Percentage of Green Currency can use...">
   </div>
 </div>
 <div class="row form-group">
    <div class="col-md-12 mb-3 mb-md-0">
  <label class="font-weight-bold" for="supplier">Supplier: </label>
  <select type="text" class="form-control" id="supplierSelect" formControlName="supplier">
    <option value="" disabled selected>Select Supplier...</option>
    <option *ngFor="let supplier of sellers" [ngValue]="supplier">
      {{ supplier.companyName }}
    </option>
  </select>
  </div>
 </div>

  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="category">Category:</label>
        <input type="text" id="category" class="form-control" formControlName="category" list="categorys" placeholder="Enter Category..."/>
        <datalist id="categorys">
           <option>Vegetable</option>
           <option>Fish</option>
           <option>Fertilizer</option>
           <option>Aqua</option>
           <option>Consumable</option>
        </datalist>
     </div>
  </div>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="description">Description:</label>
        <textarea cols="35" rows="10" id="description" formControlName="description" class="form-control" placeholder="Enter Description..."></textarea>
     </div>
  </div>
  <div class="row form-group">
      <div class="col-md-12 mb-3 mb-md-0">
         <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="availability" formControlName="availability">
            <label class="custom-control-label" for="availability">Availability</label>
          </div>
        <!-- <label class="font-weight-bold" for="availability">Availability:</label>
        <input type="checkbox" id="availability" formControlName="availability" class="form-control" placeholder="Enter Availability..."> -->
      </div>
  </div>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <label  class="font-weight-bold" for="image">Image:</label>
        <br>
        <input type="file" id="image" formControlName="image" (change)="handleUpload($event)" accept=".png, .jpg, .jpeg, .pdf">
     </div>
  </div>
  <br>
  <hr>
  <div class="row form-group">
     <div class="col-md-12 mb-3 mb-md-0">
        <input type="submit" value="Submit" class="btn btn-success pill text-white px-5 py-2"  >
     </div>
  </div>
  </form>
</div>