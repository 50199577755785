import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PostsService } from '../posts.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-programmes',
  templateUrl: './edit-programmes.component.html',
  styleUrls: ['./edit-programmes.component.css']
})
export class EditProgrammesComponent implements OnInit {

  editProgramsForm: FormGroup;
  _id: string;
  last_update: Date = new Date();
  private sub: any;
  programmesInfo: any = [];
  base64Image= null;
  oldImage: any;
  sellers: any = []

  constructor(private fb: FormBuilder,private postsService: PostsService,private route: ActivatedRoute,private router: Router, private toastr: ToastrService) 
  { 
    //get supplier
    this.postsService.postGetAllSellers().subscribe(sellers => {
      this.sellers = sellers;
       
    });
  }

  ngOnInit(){
  
    this.sub = this.route.params.subscribe(params => {
      this._id = params['_id']; 
     
      this.postsService.postGetProgrammesUsingID(this._id).subscribe(programmesInfo => {
      this.programmesInfo = programmesInfo;
      var specialP = this.programmesInfo[0].specialProgram.toString()

        this.editProgramsForm = this.fb.group({
          name: this.programmesInfo[0].name,
          price: this.programmesInfo[0].price,
          discountPrice: this.programmesInfo[0].discount_price,
          maxGreenCurrency: this.programmesInfo[0].maxGreenCurrency,
          category: this.programmesInfo[0].category,
          supplier: this.programmesInfo[0].supplier,
          synopsis: this.programmesInfo[0].synopsis,
          topic: this.programmesInfo[0].topicsCovered,
          objective: this.programmesInfo[0].learningObjective,
          duration: this.programmesInfo[0].duration,
          location: this.programmesInfo[0].location,
          capacity: this.programmesInfo[0].capacity,
          date: this.programmesInfo[0].date,
          time: this.programmesInfo[0].time,
          specialProgram: specialP,
          image: null
          
        });
        
        this.oldImage = this.programmesInfo[0].image
        
     }); 
    });
  }

 handleUpload(event) {
   const comp = this;
   const img = <File>event.target.files[0];
   const promise = new Promise((resolve) => {
     const reader = new FileReader();
     reader.onload = function () {
       resolve(reader.result);
     }
     reader.readAsDataURL(img);
   });
 
   promise.then(img => {
     comp.base64Image = img;
   });
 }


 editProgrammes(id: string)
 {
  
  if(this.editProgramsForm.value.name == "" || this.editProgramsForm.value.maxGreenCurrency == "" || this.editProgramsForm.value.category == "" || this.editProgramsForm.value.synopsis == "" || this.editProgramsForm.value.topic == "" || this.editProgramsForm.value.objective == "" || this.editProgramsForm.value.duration == "" || this.editProgramsForm.value.location == "" || this.editProgramsForm.value.capacity == "" || this.editProgramsForm.value.specialProgram == ""){
    this.toastr.warning('Fill in all input fields and select the radio button!', 'Warning!');  
  }

  else if(this.editProgramsForm.value.supplier == null){
    this.toastr.warning('Fill in supplier input field!', 'Warning');

  }
  else if(this.editProgramsForm.value.image == null){
     //convert string to boolean
     var specialProgram = JSON.parse(this.editProgramsForm.value.specialProgram);
     //update program details
    this.postsService.editProgrammes(id,
      this.editProgramsForm.value.name, this.editProgramsForm.value.price, this.editProgramsForm.value.discountPrice, this.editProgramsForm.value.maxGreenCurrency,this.editProgramsForm.value.category, this.editProgramsForm.value.synopsis,this.editProgramsForm.value.topic,this.editProgramsForm.value.objective,this.editProgramsForm.value.duration,this.editProgramsForm.value.location, this.last_update, this.editProgramsForm.value.capacity, this.editProgramsForm.value.date, this.editProgramsForm.value.time, this.oldImage, this.editProgramsForm.value.supplier, specialProgram).subscribe(results => {

      this.toastr.success("Successfully Updated!", 'Success!');
      this.router.navigateByUrl('/programs');
    });
  }
  else{
     //convert string to boolean
     var specialProgram = JSON.parse(this.editProgramsForm.value.specialProgram);
    //update program details
    this.postsService.editProgrammes(id,
      this.editProgramsForm.value.name, this.editProgramsForm.value.price, this.editProgramsForm.value.discountPrice, this.editProgramsForm.value.maxGreenCurrency,this.editProgramsForm.value.category, this.editProgramsForm.value.synopsis, this.editProgramsForm.value.topic,this.editProgramsForm.value.objective,this.editProgramsForm.value.duration,this.editProgramsForm.value.location, this.last_update, this.editProgramsForm.value.capacity, this.editProgramsForm.value.date, this.editProgramsForm.value.time, this.base64Image, this.editProgramsForm.value.supplier, specialProgram).subscribe(results => {

      this.toastr.success("Successfully Updated!", 'Success!');
      this.router.navigateByUrl('/programs');
    });
  }
  
}

}