<br>
<br>
<br>
     <!-- Header -->
<header class="w3-display-container w3-content w3-wide" style="max-width:1500px;" id="home">
  <div class="parallax" style="background-image: url(./assets/imgs/img1.jpg);"></div>
  <div class="w3-display-middle w3-margin-top w3-center">
    <h2><a class="homeGif"><b>ABOUT US</b></a></h2>
  </div>
</header>

      <div class="container">
        <div class="row mb-5 align-items-center">
        <div class="col-lg-7 pr-lg-5 mb-5 mb-lg-0">
        <img src="./assets/imgs/about.png" alt="Image placeholder" class="img-fluid" height="auto" width="auto">
        </div>
        <div class="col-lg-5 pl-lg-5">
        <div class="section-heading">
          <h1>About Us</h1>
        </div>
        <p class="mb-5">
          Otolith Enrichment is an education company in the areas of aquaculture, agriculture and environmental science. Like otoliths – structures found in the inner ear bones of vertebrates that help animals listen and find balance – Otolith Enrichment aims to do the same for you.<br>
          <span id="dots">...</span><span id="more">
          For education, we offer in-school programs that contain both theoretical and practical components specially crafted to kindle students’ passion for learning and to help them realize their full potential. We believe in the importance of not just developing knowledge, but also character, cognitive skills and meta-learning ability. To encourage learning outside of textbooks and classrooms, our various facilities provide students with excellent opportunities for hands-on, real experiences. Here, students get up-close experiences with livestock, handling sophisticated scientific equipment, and immersing themselves in the connections between nature and their academic learning. Most importantly, this experiential learning provides exposure for the young learners, allowing them to have first-hand application of knowledge to industry.<br>
          Our rich experience with the industry, government and academia. Specifically those that are seeking solutions built on comprehensive expertise paired with modern, sustainable farming technologies and methodologies. Together with our thorough knowledge of local government policies and plans for the agricultural sector, we aim to provide the most holistic and sustainable solution for your company.<br>
          Take advantage of our holistic and sustainable solutions for your company now! So, talk to us. We’re listening. And we’ll help you find your way.<br>
          </span>
        </p>
        <p>
          <button type="button" (click)="showText()" class="btn btn-success"  id="myBtn"> Read more </button>
        </p>
        </div>
        </div>
      </div>

      <img src="./assets/imgs/hrImage.png" class="hrNew1" style="width:100%;">
<br>
<br>
<br>

<div class="container-fluid text-center">
  <h1><strong>What's Eco²Balance?</strong></h1>
  <img src="./assets/imgs/otolithEnrichmentIcon.jfif" alt="Image placeholder" id="otolithLogo" class="img-fluid" >
  <img class="w3-image" src="./assets/imgs/finalLogo.png" id="eco2BalanceLogo">
  <br>
  <br>
  <h5>We are an online engagement platform, created by Otolith Enrichment to encourage sustainability practices within communities in Singapore. Residents can get involve in our <a routerLink="/projects">Sustainability projects</a> and earn green currency while they are at it!</h5>
  <br>

</div>
<br>
<img src="./assets/imgs/hrImage.png" class="hrNew1" style="width:100%;">
<br>
<br>
<br>
<div class="container-fluid text-center">
  <h1><strong>Our Eco²Balance Telegram Group</strong></h1>
  <br>
  <br>
  <h5> We encourage you to join our telegram group for discussion and share your feedback and experience for purchasing our products or involving in our programs and community projects!</h5>
  <br><p>Please click on the following link to join our group : <a href="https://t.me/Eco2Balance" target="_blank">https://t.me/Eco2Balance</a></p>
  <br>

</div>
<br>
<img src="./assets/imgs/hrImage.png" class="hrNew1" style="width:100%;">
<br>
<br>
<br>
<div class="container">
  <div class="row mb-5 align-items-center">

  <div class="col-lg-5 pl-lg-5">
  <div class="section-heading">
  <h3>What's Green Currency?</h3>
  </div>
  <p class="mb-5">The Green Currency is the official reward system for our Eco²Balance users.<br>
    <span id="dots2">...</span><span id="more2">
      It can be earned by volunteering or participating at any one of our sustainability projects and can be used to offset the cost of purchase for products in our <a routerLink="/products/All">Eco2Balance Shop</a>.<br>

      <br><h3>How to earn Green Currency?</h3>
      There are 2 ways through which you can earn Green Currency - either as a volunteer or a food waste contributor.<br>
      For food waste contributors, Green Currency will be awarded only after scanning your Eco²Balance QR code (which is done when you collect a clean food waste plastic container after giving us a filled one).<br>
      Information on the amount of Green currency to be earned can be found on the Sustainability project page.<br>
      Volunteers can accumulate Green Currency by helping out at our different project sites. You will have to scan in with your Eco²Balance QR code before you enter the site to clock in and scan out with your Eco²Balance QR code when you leave to clock out.<br>
      The application will then credit to you Green Currency based on the amount of time you spent at the project site. You will earn 1 Green Currency for every minute you volunteer at the project site.<br>
      Do remember to clock out each time you finish your session, otherwise, you might risk losing the green currency to be earned that day.<br>
      <br><h3>Will my Green Currency expire?</h3>

      Yes, Green Currency will expire 12 months after they are credited to you (e.g. Coins earned anytime starting 1/1/2021, it will expire 31/12/2021). Spending your Green Currency on our online shop will also extend the shelf life of your green currency.<br>
      Once your Green Currency expires, it cannot be retrieved. Otolith Enrichment will not be liable for the loss of your Green Currency.<br>

      </span>
  </p>
  <p>
    <button type="button" (click)="showText2()" class="btn btn-success"  id="myBtn2"> Read more </button>
  </p>
  </div>
    <div class="col-lg-7 pr-lg-5 mb-5 mb-lg-0 align-items-center">
      <img src="./assets/imgs/eco2balanceCoin.png" alt="Image placeholder" class="img-fluid" height="50%" width="50%" style="display: block;
      margin-left: auto;
      margin-right: auto;">
    </div>
  </div>
</div>
<img src="./assets/imgs/hrImage.png" class="hrNew1" style="width:100%;">
<br>
<br>

      <div class="container">
        <div class="row mb-5 align-items-center">

        <div class="col-lg-5 pl-lg-5">
        <div class="section-heading">
          <h1>Our Vision</h1>
        </div>
        <p class="mb-5">Drivers for sustainable growth in individuals and firms paired in with a strong desire to promote and sustain environmental ethics and responsibility.</p>
        </div>
        <div class="col-lg-7 pr-lg-5 mb-5 mb-lg-0">
          <img src="./assets/imgs/vision.png" alt="Image placeholder" class="img-fluid"  height="auto" width="auto">
        </div>
        </div>
      </div>

      <div class="container">
        <div class="row mb-5 align-items-center">
        <div class="col-lg-7 pr-lg-5 mb-5 mb-lg-0">
        <img src="./assets/imgs/mission.jpg" alt="Image placeholder" class="img-fluid" height="100%" width="100%">
        </div>
        <div class="col-lg-5 pl-lg-5">
        <div class="section-heading">
        <h1>Our Mission</h1>
        </div>
        <p class="mb-5">To nurture the heart and mind of every individual, seeking to help them to find their balance in life.</p>
        </div>
        </div>
      </div>
<img src="./assets/imgs/hrImage.png" class="hrNew1" style="width:100%;">
<br>
<br>
<br>
<div class="container-fluid text-left">
  <h1><strong>Privacy Policy</strong></h1>
  <br>
  <br>

  <p>Effective Date: 29/June/2023</p>
  <p>Otolith Enrichment ("we," "us," or "our") operates the Eco2Balance mobile application (the "App"). This Privacy Policy informs you of our policies regarding the collection, use, and disclosure of personal information when you use our App.</p>
  <p>By using the App, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
  <h3>1. Information We Collect</h3>
  <h3>1.1 Personal Information</h3>
  <p>We may collect personal information that you voluntarily provide to us when you use our App. This may include:</p>
  <ul>
      <li>Your name, email address, and other contact information you provide when creating an account or contacting us.</li>
      <li>Information you provide when participating in surveys, promotions, or contests.</li>
      <li>Any other personal information you choose to provide to us.</li>
  </ul>
  <h3>1.2 Non-Personal Information</h3>
  <p>We may also collect non-personal information automatically when you use the App. This may include:</p>
  <ul>
      <li>Device information, such as your device type, operating system, unique device identifiers, and mobile network information.</li>
      <li>Usage data, including the features you access, the pages you visit, and the actions you take within the App.</li>
      <li>Log data, such as your IP address, browser type, access times, and referring URLs.</li>
  </ul>
  <h3>2. Use of Information</h3>
  <p>We may use the collected information for various purposes, including:</p>
  <ul>
      <li>Providing and maintaining the App's functionality.</li>
      <li>Personalizing and improving user experience.</li>
      <li>Analyzing usage patterns and trends to enhance our services.</li>
      <li>Sending you updates, newsletters, marketing communications, and other relevant information.</li>
      <li>Responding to your inquiries, comments, or feedback.</li>
      <li>Investigating and preventing fraudulent or unauthorized activities.</li>
      <li>Complying with legal obligations.</li>
  </ul>
  <h3>3. Data Retention</h3>
  <p>We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
  <h3>4. Data Sharing and Disclosure</h3>
  <p>We may share your personal information with third parties in the following circumstances:</p>
  <ul>
      <li>With your consent or at your direction.</li>
      <li>With service providers who assist us in operating the App and delivering services.</li>
      <li>To comply with legal obligations, such as responding to a subpoena or similar legal process.</li>
      <li>To protect our rights, property, or safety, as well as the rights, property, or safety of others.</li>
      <li>In connection with a merger, acquisition, or sale of all or a portion of our assets.</li>
  </ul>
  <p>We do not share or sell your personal information to third party marketing services.</p>
  <h3>5. Security</h3>
  <p>We take reasonable measures to protect the security of your personal information. However, no method of transmission or storage is 100% secure, and we cannot guarantee absolute security.</p>
  <h3>6. Your Rights and Choices</h3>
  <p>You may have certain rights and choices regarding the personal information we collect and process. You can access, update, correct, or delete your personal information by contacting us.</p>
  <h3>7. Children's Privacy</h3>
  <p>The App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete it promptly. If you believe that we may have collected personal information from a child under 13, please contact us.</p>
  <h3>8. Changes to This Privacy Policy</h3>
  <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We advise you to periodically review this Privacy Policy for any updates or changes.</p>

  <br>

</div>
<br>