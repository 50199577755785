import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service'; 
import { ToastrService } from 'ngx-toastr';
import { PostsService } from '../posts.service';
import { EncrDecrService } from '../encr-decr-service';
import endpoint from 'endpoints.config';
@Component({
  selector: 'app-volunteer-sign-in',
  templateUrl: './volunteer-sign-in.component.html',
  styleUrls: ['./volunteer-sign-in.component.css']
})
export class VolunteerSignInComponent implements OnInit {

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  hasDevices: boolean;
  hasPermission: boolean;
  qrResult: any;
  userExist: any;
  staffList: any[];
  staffDetails: any;
  boolean: boolean;
  clockInDetails: any;
  projectsList: any;
  secretKey = endpoint.secretKey;

  constructor(private postsService: PostsService,private authService: AuthService, private toastr: ToastrService, private EncrDecr: EncrDecrService) 
  { 
    //post get all staff
    this.authService.wabbuLubbaDubDuB().subscribe(data =>{
      var array = []
      for(var i = 0; i < data.length; i++){
        var collected
        var decryptedClockIn= data[i].encryptedClockIn
        var decryptedClockInDateTime= data[i].encryptedClockInDateTime;
        var decryptedClockInDateTimeList= data[i].encryptedClockInDateTimeList
        var decryptedVolunteeringLocation= data[i].encryptedVolunteeringLocation;
        var decryptedName = this.EncrDecr.get(this.secretKey, data[i].encryptedName);
        var decryptedEmail = this.EncrDecr.get(this.secretKey, data[i].encryptedEmail);
        var decryptedAddress = this.EncrDecr.get(this.secretKey, data[i].encryptedAddress);
        var decryptedUnitNo = this.EncrDecr.get(this.secretKey, data[i].encryptedUnitNo);
        var decryptedBoxDonated= this.EncrDecr.get(this.secretKey, data[i].encryptedBoxDonated);
        var decryptedBoxDonatedDates= data[i].encryptedBoxDonatedDates;
        var decryptedBoxIssuedDates= data[i].encryptedBoxIssuedDates;
        var decryptedCollected= this.EncrDecr.get(this.secretKey, data[i].encryptedCollected);
        if(decryptedCollected == "true"){
          collected = true;
        }
        else{
          collected = false;
        }
        var decryptedHousingType= this.EncrDecr.get(this.secretKey, data[i].encryptedHousingType);
        var decryptedLastBoxDonatedDate= data[i].encryptedLastBoxDonatedDate;
        var decryptedMobile= this.EncrDecr.get(this.secretKey, data[i].encryptedMobile);
        var decryptedNewBoxIssueDate= data[i].encryptedNewBoxIssueDate;
        var decryptedPassword= this.EncrDecr.get(this.secretKey, data[i].encryptedPassword);
        var decryptedRole= this.EncrDecr.get(this.secretKey, data[i].encryptedRole);
        var decryptedSupplier= this.EncrDecr.get(this.secretKey, data[i].encryptedSupplier);
        var decryptedTemporaryToken= data[i].encryptedTemporaryToken;
        var decryptedVerified= data[i].encryptedVerified;
        var decrypted_id= this.EncrDecr.get(this.secretKey, data[i].encrypted_id);
        var obj = {"volunteeringLocation":decryptedVolunteeringLocation, "clockInDateTimeList":decryptedClockInDateTimeList,"clockInDateTime":decryptedClockInDateTime,"clockIn":decryptedClockIn,"_id":decrypted_id,"verified":decryptedVerified,"temporaryToken":decryptedTemporaryToken,"supplier":decryptedSupplier,"role":decryptedRole,"password":decryptedPassword,"newBoxIssueDate":decryptedNewBoxIssueDate,"mobile":decryptedMobile,"lastBoxDonatedDate":decryptedLastBoxDonatedDate,"housingType":decryptedHousingType,"greenCurrency":data[i].greenCurrency,"collected":collected,"boxIssuedDates":decryptedBoxIssuedDates,"boxDonatedDates":decryptedBoxDonatedDates,"boxDonated":decryptedBoxDonated,"name": decryptedName, "email": decryptedEmail, "address": decryptedAddress, "unitNo":decryptedUnitNo};
        array.push(obj);
      }
      
      this.staffList = array;
    });
    //get all projects
    this.postsService.postGetAllProjects().subscribe(projects => {
      this.projectsList = projects
    });
  }

  ngOnInit(): void {

  }
  
  clearResult(): void {
    this.qrResult = null;
  }

  // delay = ms => new Promise(res => setTimeout(res, ms));

  async onCodeResult(resultString: string) {
    this.userExist = null;
    if (resultString != null) {
      this.qrResult = resultString;
      this.checkInUser(this.qrResult);
      this.clearMessage();
    } else {
      this.userExist = false;
      this.clearMessage();
    }
    // await this.delay(2000);
    // console.log("Waited 2s");
  }

  inArray(target, array)
  {
    for(var i = 0; i < array.length; i++) 
    {
      if(array[i]._id == target)
      {
        return true;
      }
    }
    return false; 
  }

  onHasPermission(has: boolean): void {
    this.hasPermission = has;
  }

  submit(){
    //console.log(this.clockInDetails);
    var projectName = (<HTMLSelectElement>document.getElementById("selectProject")).value;
    //console.log(this.clockInDetails[2]);
    if(this.clockInDetails[2] == true){
      //Values = this.clockInDetails = [0.this.staffDetails[0]._id, 1.this.staffDetails[0].name, 2.true, 3.this.staffDetails[0].greenCurrency, 4.dateStr, 5."Clock In", 6.this.staffDetails[0].volunteeringLocation];
      this.authService.postVolunteerStaffEntry(this.clockInDetails[0], this.clockInDetails[1], this.clockInDetails[2], this.clockInDetails[3], this.clockInDetails[4], this.clockInDetails[5], projectName, "Null")
    }
    else{
      //Values = this.clockInDetails = [0.this.staffDetails[0]._id, 1.this.staffDetails[0].name, 2.false, 3.this.staffDetails[0].greenCurrency + commitedMins, 4.dateStr, 5."Clock Out", 6.this.staffDetails[0].volunteeringLocation, 7.commitedMins];
      this.authService.postVolunteerStaffEntry(this.clockInDetails[0], this.clockInDetails[1], this.clockInDetails[2], this.clockInDetails[3], this.clockInDetails[4], this.clockInDetails[5], this.clockInDetails[6], this.clockInDetails[7])
    }
    document.getElementById('closeModel').click();  
  }

  checkInUser(results: string): void { 
    var date = new Date();
    var dateStr =
      ("00" + date.getDate()).slice(-2) + "-" +
      ( "00" + (date.getMonth() + 1)).slice(-2) + "-" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);
    var dateStr2 =
      ("00" + date.getDate()).slice(-2) + "-" +
      ( "00" + (date.getMonth() + 1)).slice(-2) + "-" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2);
    if(this.inArray(results, this.staffList) == true)
    {
      var d = new Date();
      if(sessionStorage.getItem("lastTimeStamp") == null){
        var lastTimestamp = d.getTime();
      }
      else{
        lastTimestamp = parseInt(sessionStorage.getItem("lastTimeStamp"));
      }
      var seconds = (d.getTime() - lastTimestamp) / 1000;
      console.log("seconds: " + seconds);
      if(seconds <= 2) {
        // Too soon after the last QR, so ignore.
        console.log("goes here");
        sessionStorage.setItem("lastTimeStamp", d.getTime().toString());
        lastTimestamp = parseInt(sessionStorage.getItem("lastTimeStamp"));
      }
      else{
        sessionStorage.setItem("lastTimeStamp", d.getTime().toString());
        lastTimestamp = parseInt(sessionStorage.getItem("lastTimeStamp"));
        this.authService.postGetUserDetails(results).subscribe(data => {
          this.staffDetails = data;
          var clockInDateTime
  
          if(this.staffDetails[0].clockInDateTime == null){
            clockInDateTime == dateStr2
            document.getElementById("openModalButton").click();
            document.getElementById("modalHeading").innerHTML = this.staffDetails[0].name + ' has clock in!';
            document.getElementById("modalBody").innerHTML = "Welcome " + this.staffDetails[0].name + " thanks for helping out!";
            this.clockInDetails = [this.staffDetails[0]._id, this.staffDetails[0].name, true, this.staffDetails[0].greenCurrency.totalGreenCurrency, dateStr, "Clock In", this.staffDetails[0].volunteeringLocation];
            this.boolean = true;
            this.clearResult();
            this.clearMessage();
            return
          }
          else if(this.staffDetails[0].clockIn == false || this.staffDetails[0].clockIn == null){
            this.userExist = true;
              document.getElementById("openModalButton").click();
              document.getElementById("modalHeading").innerHTML = this.staffDetails[0].name + ' has clock in!';
              document.getElementById("modalBody").innerHTML = "Welcome " + this.staffDetails[0].name + " thanks for helping out!";
              this.clockInDetails = [this.staffDetails[0]._id, this.staffDetails[0].name, true, this.staffDetails[0].greenCurrency.totalGreenCurrency, dateStr, "Clock In", this.staffDetails[0].volunteeringLocation];
              this.boolean = true;
              this.clearResult();
              this.clearMessage();
              return
          }
          else if(this.staffDetails[0].clockIn == true){
            this.userExist = true
            var commitedMins = this.getMins(dateStr) - this.getMins(this.staffDetails[0].clockInDateTime)
            //console.log(commitedMins)
            document.getElementById("openModalButton").click();
            document.getElementById("modalHeading").innerHTML = this.staffDetails[0].name + ' has clock out!';
            document.getElementById("modalBody").innerHTML = "Thanks for volunteering " + this.staffDetails[0].name + " you have been rewarded " + commitedMins + " green currency, hope to see you again!";
            this.clockInDetails = [this.staffDetails[0]._id, this.staffDetails[0].name, false, this.staffDetails[0].greenCurrency.totalGreenCurrency + commitedMins, dateStr, "Clock Out", this.staffDetails[0].volunteeringLocation, commitedMins];
            this.boolean = false;
            this.clearResult();
            this.clearMessage();
            return
          }
          else{
            return          
          }
        });   
      }
    }
    else {
          this.userExist = false;
          this.toastr.warning('This person is not a Staff!', 'Warning');
          this.clearResult();
          this.clearMessage();
          return
    }

  }

  getMins(dateTime) {
    var dayHr = parseInt(dateTime.split("-")[0])*24*60 ; 
    var monthHr = parseInt(dateTime.split("-")[1])*30*24*60;
    var str =  dateTime.split("-")[2];
    var yearHr = parseInt(str.split(" ")[0])*12*30*24*60;
    var str2 = str.split(" ")[1];
    var hours = parseInt(str2.split(":")[0])*60 ;
    var mins = parseInt(str2.split(":")[1]);
  
    return yearHr + monthHr + dayHr + hours + mins

  }

  clearMessage() {
    setTimeout(() => {
      this.userExist = null;
    }, 6000);
  }

  checkQRJSON(qrString: string): boolean {
    if (
      /^[\],:{}\s]*$/.test(
        qrString
          .replace(/\\["\\\/bfnrtu]/g, "@")
          .replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
            "]"
          )
          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

}
