import { Component, OnInit  } from '@angular/core';
import { AuthService } from '../auth.service'; 
import { ToastrService } from 'ngx-toastr';
import { EncrDecrService } from '../encr-decr-service';
import endpoint from 'endpoints.config';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.css']
})
export class ScannerComponent implements OnInit {

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  hasDevices: boolean;
  hasPermission: boolean;
  qrResult: any;
  userExist: any;
  userList: any[];
  userDetails: any;
  dateNow: any = new Date();
  Heading: any;
  returnBoxDetails: any;
  collectionBoxDetails: any;
  boolean: boolean;
  boxIssuedDates: any;
  boxDonatedDates: any;
  secretKey = endpoint.secretKey;

  constructor(private authService: AuthService, private toastr: ToastrService, private EncrDecr: EncrDecrService) 
  {
    //console.log(new Date());
    
    // Post get all users
    this.authService.macSpicyTooSpicy().subscribe(data =>{
      var array = []
      for(var i = 0; i < data.length; i++){
        var collected
        var decryptedClockIn= data[i].encryptedClockIn
        var decryptedClockInDateTime= data[i].encryptedClockInDateTime;
        var decryptedClockInDateTimeList= data[i].encryptedClockInDateTimeList  
        var decryptedVolunteeringLocation= data[i].encryptedVolunteeringLocation;
        var decryptedName = this.EncrDecr.get(this.secretKey, data[i].encryptedName);
        var decryptedEmail = this.EncrDecr.get(this.secretKey, data[i].encryptedEmail);
        var decryptedAddress = this.EncrDecr.get(this.secretKey, data[i].encryptedAddress);
        var decryptedUnitNo = this.EncrDecr.get(this.secretKey, data[i].encryptedUnitNo);
        var decryptedBoxDonated= this.EncrDecr.get(this.secretKey, data[i].encryptedBoxDonated);
        var decryptedBoxDonatedDates= data[i].encryptedBoxDonatedDates;
        var decryptedBoxIssuedDates= data[i].encryptedBoxIssuedDates;
        var decryptedCollected= this.EncrDecr.get(this.secretKey, data[i].encryptedCollected);
        if(decryptedCollected == "true"){
          collected = true;
        }
        else{
          collected = false;
        }
        var decryptedHousingType= this.EncrDecr.get(this.secretKey, data[i].encryptedHousingType);
        var decryptedLastBoxDonatedDate= data[i].encryptedLastBoxDonatedDate;
        var decryptedMobile= this.EncrDecr.get(this.secretKey, data[i].encryptedMobile);
        var decryptedNewBoxIssueDate= data[i].encryptedNewBoxIssueDate;
        var decryptedPassword= this.EncrDecr.get(this.secretKey, data[i].encryptedPassword);
        var decryptedRole= this.EncrDecr.get(this.secretKey, data[i].encryptedRole);
        var decryptedSupplier= this.EncrDecr.get(this.secretKey, data[i].encryptedSupplier);
        var decryptedTemporaryToken= data[i].encryptedTemporaryToken;
        var decryptedVerified= data[i].encryptedVerified;
        var decrypted_id= this.EncrDecr.get(this.secretKey, data[i].encrypted_id);
        var obj = {"volunteeringLocation":decryptedVolunteeringLocation, "clockInDateTimeList":decryptedClockInDateTimeList,"clockInDateTime":decryptedClockInDateTime,"clockIn":decryptedClockIn,"_id":decrypted_id,"verified":decryptedVerified,"temporaryToken":decryptedTemporaryToken,"supplier":decryptedSupplier,"role":decryptedRole,"password":decryptedPassword,"newBoxIssueDate":decryptedNewBoxIssueDate,"mobile":decryptedMobile,"lastBoxDonatedDate":decryptedLastBoxDonatedDate,"housingType":decryptedHousingType,"greenCurrency":data[i].greenCurrency,"collected":collected,"boxIssuedDates":decryptedBoxIssuedDates,"boxDonatedDates":decryptedBoxDonatedDates,"boxDonated":decryptedBoxDonated,"name": decryptedName, "email": decryptedEmail, "address": decryptedAddress, "unitNo":decryptedUnitNo};
        array.push(obj);
      } 
      
      this.userList = array;
    });
  }

  ngOnInit(): void {
    
  }

  clearResult(): void {
    this.qrResult = null;
  }


  onCodeResult(resultString: string) {
    this.userExist = null;
    if (resultString != null) {
      this.qrResult = resultString;
      this.checkInUser(this.qrResult);
      this.clearMessage();
    } else {
      this.userExist = false;
      this.clearMessage();
    }

  }

  inArray(target, array)
  {
    for(var i = 0; i < array.length; i++) 
    {
      if(array[i]._id == target)
      {
        return true;
      }
    }
    return false; 
  }

  onHasPermission(has: boolean): void {
    this.hasPermission = has;
  }

  returnB() {
    var _id = this.returnBoxDetails[0];
    this.authService.issueNewBox(_id, new Date(), false);
    this.toastr.success('A new box has been successfully returned to the user!.', 'Success');
    document.getElementById('closeModel').click();  
  }

  collectB(){
    //Value = this.collectionBoxDetails = [0. results, 1. true, 2. this.userDetails[0].greenCurrncye + 30, 3. this.userDetails[0].boxDonated + 1, 4. this.dateNow];
    this.authService.updateCollectionStatusRecieve(this.collectionBoxDetails[0], this.collectionBoxDetails[1], this.collectionBoxDetails[2], this.collectionBoxDetails[3], new Date());
    document.getElementById('closeModel').click();  
  }

  checkInUser(results: string): void {
    
    if(this.inArray(this.qrResult, this.userList) == true)
    {
      var d = new Date();
      if(sessionStorage.getItem("lastTime") == null){
        var lastTimestamp = d.getTime();
      }
      else{
        lastTimestamp = parseInt(sessionStorage.getItem("lastTime"));
      }
      var seconds = (d.getTime() - lastTimestamp) / 1000;
      console.log("lastTimestamp: " + sessionStorage.getItem("lastTime"))
      console.log("seconds passed: " + seconds);
      if(seconds <= 2) {
        // Too soon after the last QR, so ignore.
        console.log("goes here")
        sessionStorage.setItem("lastTime", d.getTime().toString());
        lastTimestamp = parseInt(sessionStorage.getItem("lastTime"));
      }
      else{
        sessionStorage.setItem("lastTime", d.getTime().toString());
        lastTimestamp = parseInt(sessionStorage.getItem("lastTime"));
        this.authService.postGetUserDetails(results).subscribe(data => {
          var lBDD1 = Date.parse(data[0].lastBoxDonatedDate);
          var lBDD = new Date(lBDD1);
          var nBID1 = Date.parse(data[0].newBoxIssueDate);
          var nBID = new Date(nBID1);
  
          this.userDetails = data;
          if(this.userDetails[0].collected == true){
            this.userExist = 1;
            // this.toastr.warning('This person box has already been collected', 'Warning');
            document.getElementById("openModalButton").click();
            document.getElementById("modalHeading").innerHTML = this.userDetails[0].name + ' box has already been collected, would you like to issue a clean box?';
            document.getElementById("addressModalBody").innerHTML = 'Resident Address: ' + data[0].address + ' Unit No: ' + data[0].unitNo;
            document.getElementById("gcModalBody").innerHTML = 'Current Green Currency Amount: ' + data[0].greenCurrency.totalGreenCurrency;
            document.getElementById("cdModalBody").innerHTML = 'Last Food Waste Collected On: ' + lBDD;
            document.getElementById("rdModalBody").innerHTML = 'New Box Returned On: ' + nBID;
            this.boxIssuedDates = data[0].boxIssuedDates;
            this.boxDonatedDates = data[0].boxDonatedDates;
            this.returnBoxDetails = [results, data[0].greenCurrency.totalGreenCurrency, data[0].boxDonated];
            this.boolean = true;
            this.clearResult();
            this.clearMessage();
            
            return
          }
          else if(this.userDetails[0].collected == false && this.userDetails[0].newBoxIssueDate == null){
            this.userExist = true;
            // this.toastr.warning('This person box has already been collected', 'Warning');
            document.getElementById("openModalButton").click();
            document.getElementById("modalHeading").innerHTML = "This is " + this.userDetails[0].name + ' first time collecting their food waste box, would you like to issue a clean box?';
            document.getElementById("addressModalBody").innerHTML = 'Resident Address: ' + data[0].address + ' Unit No: ' + data[0].unitNo;
            document.getElementById("gcModalBody").innerHTML = 'Current Green Currency Amount: ' + data[0].greenCurrency.totalGreenCurrency;
            // document.getElementById("cdModalBody").innerHTML = 'Last Food Waste Collected On: ' + lastBoxDonatedDate;
            // document.getElementById("rdModalBody").innerHTML = 'New Box Returned On: ' + this.dateNow.toISOString();
            this.boxIssuedDates = data[0].boxIssuedDates;
            this.boxDonatedDates = data[0].boxDonatedDates;
            this.returnBoxDetails = [results, data[0].greenCurrency.totalGreenCurrency, data[0].boxDonated];
            this.boolean = true;
            this.clearResult();
            this.clearMessage();
            
            return
          }
          else if(this.userDetails[0].collected == false){
            var date = new Date();      
            this.userExist = true
            this.clearResult();
            this.clearMessage();
            if(this.userDetails[0].lastBoxDonatedDate != null){
              var str = this.userDetails[0].lastBoxDonatedDate.split("-")[2]
              var lastBoxDonatedDate = str.split("T")[0]
              if(date.getDate() == lastBoxDonatedDate){
                document.getElementById("openModalButton").click();
                document.getElementById("modalHeading").innerHTML =  this.userDetails[0].name + ' has already contributed their food waste for today';
                document.getElementById("addressModalBody").innerHTML = 'Resident Address: ' + data[0].address + ' Unit No: ' + data[0].unitNo;
                document.getElementById("gcModalBody").innerHTML = 'Current Green Currency Amount: ' + data[0].greenCurrency.totalGreenCurrency;
                if(data[0].lastBoxDonatedDate != null && data[0].boxDonatedDates != null){
                  document.getElementById("cdModalBody").innerHTML = 'Last Food Waste Collected On: ' + lBDD;
                  this.boxDonatedDates = data[0].boxDonatedDates;
                }
                document.getElementById("rdModalBody").innerHTML = 'New Box Issued On: ' + nBID;
                this.boxIssuedDates = data[0].boxIssuedDates;
                this.boolean = null;
                return  
              }
              else{
                document.getElementById("openModalButton").click();
                document.getElementById("modalHeading").innerHTML = 'User has been found! ' + this.userDetails[0].name + ' box has been check into our collection center';
                document.getElementById("addressModalBody").innerHTML = 'Resident Address: ' + data[0].address + ' Unit No: ' + data[0].unitNo;
                document.getElementById("gcModalBody").innerHTML = 'Current Green Currency Amount: ' + data[0].greenCurrency.totalGreenCurrency;
                if(data[0].lastBoxDonatedDate != null && data[0].boxDonatedDates != null){
                  document.getElementById("cdModalBody").innerHTML = 'Last Food Waste Collected On: ' + lBDD;
                  this.boxDonatedDates = data[0].boxDonatedDates;
                }
                document.getElementById("rdModalBody").innerHTML = 'New Box Issued On: ' + nBID;
                this.collectionBoxDetails = [results, true, this.userDetails[0].greenCurrency.totalGreenCurrency + 30, this.userDetails[0].boxDonated + 1, this.dateNow];
                this.boxIssuedDates = data[0].boxIssuedDates;
                this.boolean = false;
                return
              }
            }
            else {
              document.getElementById("openModalButton").click();
              document.getElementById("modalHeading").innerHTML = 'User has been found! ' + this.userDetails[0].name + ' box has been check into our collection center';
              document.getElementById("addressModalBody").innerHTML = 'Resident Address: ' + data[0].address + ' Unit No: ' + data[0].unitNo;
              document.getElementById("gcModalBody").innerHTML = 'Current Green Currency Amount: ' + data[0].greenCurrency.totalGreenCurrency;
              if(data[0].lastBoxDonatedDate != null && data[0].boxDonatedDates != null){
                document.getElementById("cdModalBody").innerHTML = 'Last Food Waste Collected On: ' + lBDD;
                this.boxDonatedDates = data[0].boxDonatedDates;
              }
              document.getElementById("rdModalBody").innerHTML = 'New Box Issued On: ' + nBID;
              this.collectionBoxDetails = [results, true, this.userDetails[0].greenCurrency.totalGreenCurrency + 30, this.userDetails[0].boxDonated + 1, this.dateNow];
              this.boxIssuedDates = data[0].boxIssuedDates;
              this.boolean = false;
              return
            }
            
          }
          else{
            return          
          }
        });
      }
    }
    else{
          this.userExist = false;
          this.toastr.warning('This person is not a User!', 'Warning');
          this.clearResult();
          this.clearMessage();
          return
        }
  }

  clearMessage() {
    setTimeout(() => {
      this.userExist = null;
    }, 6000);
  }

  checkQRJSON(qrString: string): boolean {
    if (
      /^[\],:{}\s]*$/.test(
        qrString
          .replace(/\\["\\\/bfnrtu]/g, "@")
          .replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
            "]"
          )
          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

}