 <div class="container">
          
            
  <form [formGroup]="editNewsForm"  class="p-5 bg-white">
      <h2>Edit News</h2>
      <hr>
      <div class="row form-group">
          <div class="col-md-12 mb-3 mb-md-0">
          <label class="font-weight-bold" for="title">Title:</label>
          <input type="text" id="title" formControlName="title" class="form-control" >
            </div>
      </div>
      <div class="row form-group">
          <div class="col-md-12 mb-3 mb-md-0">
          <label class="font-weight-bold" for="body">Description:</label>
          <textarea cols="35" rows="15" id="body" formControlName="body" class="form-control" ></textarea>
            </div>
      </div>
      <div class="row form-group">
          <div class="col-md-12 mb-3 mb-md-0">
          <label class="font-weight-bold" for="image">Image:</label>
          <br>
          <input type="file" id="image" formControlName="image" (change)="handleUpload($event)" accept=".png, .jpg, .jpeg, .pdf">
            </div>
      </div>
      <br>
      <hr>      
                      
          <button type="submit" class="btn btn-success"  (click)="editNews(newsInfo[0]._id)" >Save changes </button>
                </form>
       
     
  </div>
  