<br>
<br>
<br>
<br>
<br>
<br>
<div class="section-title">
  <h2>List of Projects</h2>
  
<div class="main">
  <div class="form-group has-search">
  <span class="fa fa-search form-control-feedback"></span>
  <input type="text" (keydown)="search($event)" class="form-control" placeholder="Search for project name">
  </div>
</div>
</div>
<div class="user-list">
    <table class="table table-hover">
      
        <thead class="thead-dark">
          <tr>
            <th scope="col">Image</th>
            <th scope="col">Project Title</th>
            <th scope="col">Date Published</th>
            <th scope="col">Project Details</th>
            <th scope="col">Attendees</th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let i of projects">
        <a [routerLink]="['/projectInfo',i._id]" >
          <td data-label="qr">
            <img  src={{i.image}} name="Image" width="200" height="145">
          </td>
        </a>
          <td data-label="Username" [routerLink]="['/projectInfo',i._id]">{{ i.title}}</td>
          <td data-label="publish" [routerLink]="['/projectInfo',i._id]">{{ i.publish | date : 'dd/MM/yyyy' }}</td>
            <td data-label="body" [routerLink]="['/projectInfo',i._id]"><p>{{ i.body }}</p></td>
            <td data-label="attendees">
                <select name="attendees" id={{i._id+1}} class="browser-default custom-select custom-select-lg mb-3">
                    <option *ngFor="let obj of i.attendees">{{obj}}</option>
                </select>
                <div *ngIf="service.isAdmin()">
                    <input type="text" class="form-control" id={{i._id}} name="username" placeholder="Enter username here">
                </div>
                <div *ngIf="service.isAdmin()">
                    <button type="button" class="btn btn-outline-success btn-block" (click)="addResident(i._id)">Add</button>
                </div>
                <div *ngIf="service.isAdmin()">
                    <button type="button" class="btn btn-outline-danger btn-block" (click)="removeResident(i._id)">Remove</button>
                </div>
            </td>
          
        </tr>
      </tbody>
    
    </table>
  
  </div>
  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white; text-align: center">Loading...</p>
</ngx-spinner>


