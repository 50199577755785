import { Component, OnInit } from '@angular/core';
import { PostsService } from '../posts.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-program-list',
  templateUrl: './program-list.component.html',
  styleUrls: ['./program-list.component.css']
})
export class ProgramListComponent implements OnInit {

  programs: any[];
  plist: any[];
  name = '';
  constructor(private toastr: ToastrService, public postsService: PostsService, private spinner: NgxSpinnerService, public service: AuthService) 
  { 
    this.spinner.show();
    //get all programs
    this.postsService.postGetAllProgrammes().subscribe(programs => {
      this.spinner.hide();
      this.programs = programs;
      this.plist = programs;
     
      });
  }

  ngOnInit(): void {
  }

  initializeItems(): void {
    this.programs = this.plist;
  }

  search(event) {
    
    this.initializeItems();
    //Set searchTerm with input from search bar
    const searchTerm = event.srcElement.value;
  
    if (!searchTerm) {
    return;
    }
    //Filters enquiries list with keywords from searchTerm
    this.programs = this.programs.filter(i => {
    if (i.name && searchTerm) {
    if (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
    return true;
    }
    return false;
    }
    });
  }
  // add user to the program
  addToPrograms(_id){
    let value = (<HTMLSelectElement>document.getElementById(_id)).value.trim();
    if(confirm('Do you want to add this user into the program/workshop?')){
      this.postsService.addToPrograms(_id, value).subscribe(results =>{
        this.toastr.success("Successfully added " + value + " to the program/workshop!" , 'Success!');
      })
      location.reload();
    }  
  }

  // remove user from the program
  removeFromPrograms(_id){
    let value1 = (<HTMLSelectElement>document.getElementById(_id +"1")).value;

    if(confirm('Do you want to remove this user from the program/workshop?')){
      this.postsService.removeFromPrograms(_id, value1).subscribe(results =>{
        this.toastr.success("Successfully remove user", 'Success!');
      })
      location.reload();
    }
  }

}

