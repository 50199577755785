import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service'; 
import { Router } from '@angular/router'; 
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  myForm: FormGroup;   
  results: any = false;
  continueLoginArray: any;

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private toastr: ToastrService) { }

  ngOnInit() {

    this.myForm = this.fb.group ({   
      name: '',    
      password: ''   
    });
   
  }

  // toggle password visiability
  passwordToggle() {
    var x =  (<HTMLInputElement>document.getElementById("password"));
    var y =  (<HTMLInputElement>document.getElementById("password-status"));
    if (x.type === "password") {
      x.type = "text";
      y.className = "fa fa-eye-slash field-icon";
    } else {
      x.type = "password";
      y.className = "fa fa-eye field-icon";
    }
  }

  // Get number percentage, input number and the percentage you want
  percentage(num, per)
  {
    return (num/100)*per;
  }

  continueLogin()
  {
    this.authService.useGC(this.continueLoginArray[2], 0);
    document.getElementById('closeModel').click();  
    this.authService.setSecureToken(this.continueLoginArray[0]);
    this.authService.setUserRole(this.continueLoginArray[1]);
    this.authService.setUserID(this.continueLoginArray[2])
    this.authService.setKey("loggin");
    this.toastr.success(this.myForm.value.name, 'Welcome!');
    this.router.navigateByUrl('/home');
  }


  onSubmit() {  
    if(this.myForm.value.name == "" || this.myForm.value.password ==""){
      this.toastr.warning('Fill in all input fields!', 'Warning');
    }
    else{
      this.authService.authUser(this.myForm.value.name,
        this.myForm.value.password).subscribe(data => {
          this.results = data;
          if (this.results[0].auth)
          {
            //console.log(this.results[0].greenCurrency.dateLastEdited);
            var selectedTimestamp = new Date(this.results[0].greenCurrency.dateLastEdited).getTime();
            //console.log("inputDate: " + selectedTimestamp);
            //Current date - 1 year
            var timestamp = new Date().getTime() - (31556952000);
           
            //console.log("currentdatetime: " + timestamp);

            if (timestamp > selectedTimestamp) {
              // The selected time is less than 1 year from now
              //console.log("1 year or more has passed from the input date time to now");
              document.getElementById("openModalButton").click();
              document.getElementById("modalHeading").innerHTML = "Green Currency Expired";
              document.getElementById("modalBody").innerHTML = 'Dear ' + this.myForm.value.name + ' because you did not accumulate or spent any of your green currency in the pass 1 year, your green currency has been totally deducted.';
              this.continueLoginArray = [this.myForm.value.name, this.results[0].role, this.results[0].userID];
            }
            else if (timestamp < selectedTimestamp) {
              // The selected time is more than 1 year from now
              //console.log("The input date time is less than 1 year from now");
              this.authService.setSecureToken(this.myForm.value.name);
              this.authService.setUserRole(this.results[0].role);
              this.authService.setUserID(this.results[0].userID)
              this.authService.setKey("loggin");
              this.toastr.success(this.myForm.value.name, 'Welcome!');
              this.router.navigateByUrl('/home');  
            }
            else {
              // -Exact- same timestamps.
              //console.log("Exact same timestamps");
              this.authService.setSecureToken(this.myForm.value.name);
              this.authService.setUserRole(this.results[0].role);
              this.authService.setUserID(this.results[0].userID)
              this.authService.setKey("loggin");
              this.toastr.success(this.myForm.value.name, 'Welcome!');
              this.router.navigateByUrl('/home');  
            }    
          }
          else{
           this.toastr.warning('This user is not yet verified or dosen\'t exist!', 'Warning');
           return;
          }
        }); 
    }
    
  }
 
}
