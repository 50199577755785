<br>
<br>
<br>
<br>
<br>
    <div class="container">
      <br>
      <div class="section-title">    
        <h2> My Programs & Workshops</h2>
        <br>
        <h5 *ngIf="programsArray.length == 0">{{msg}}</h5>
      </div>
    <!--Section: Block Content-->
    <section>
    
      <!--Grid row-->
      <div class="row">
    
        <!--Grid column-->
        <div class="col-lg-12">
    
          <!-- Card -->
          <div class="mb-3">
            <div class="pt-4 wish-list">
        
              <div *ngFor="let program of programsArray;let i = index">
              <div class="row mb-4">
                <div class="col-md-5 col-lg-3 col-xl-3">
                  <div class="view zoom overlay z-depth-1 rounded mb-3 mb-md-0">
                    <img class="img-fluid w-300" src={{program.image}}  width="500" alt="Sample">
                  </div>
                </div>
                <div class="col-md-7 col-lg-9 col-xl-9">
                  <div>
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5>{{program.name}}}</h5>
                        <ng-container *ngIf="program.specialProgram === true">
                          <p class="mb-3 text-muted text-uppercase small">Program Datetime - Contact us at info@otolithenrichment.com for the next available dates</p>
                        </ng-container>
                        <ng-container *ngIf="program.specialProgram === false">
                          <p class="mb-3 text-muted text-uppercase small">Program Date - {{program.date }}</p>
                          <p class="mb-3 text-muted text-uppercase small">Program Time - {{program.time }}</p>
                        </ng-container>
                        <p><strong>By: {{program.supplier.companyName}}</strong> <img src={{program.supplier.companyLogo}} alt="companyLogo" width="30" height="30"></p>
                        <p class="mb-3 text-muted text-uppercase small">Category - {{program.category}}</p>
                        <ng-container *ngIf="program.specialProgram === true">
                          <p class="mb-2 text-muted text-uppercase small">Program Price - Please email info@otolithenrichment.com for plans and pricings</p>
                        </ng-container>
                        <ng-container *ngIf="program.specialProgram === false">
                          <p class="mb-2 text-muted text-uppercase small">Program Price - {{program.price | currency:'SGD'}}</p>
                          <p class="mb-2 text-danger text-uppercase small" *ngIf="program.discount_price != null">Discounted Price: {{program.discount_price | currency:'USD'}}</p>
                        </ng-container>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <button type="button" class="btn btn-success" [routerLink]="['/programsInfo',program._id]" >View Details</button>
                        <!-- <button type="button" class="btn btn-danger" (click)="removeProgram(program._id)" >Cancel</button> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <!-- Card -->
    
        </div>
        <!--Grid column-->
    
      </div>
      <!-- Grid row -->
    
    </section>
    <!--Section: Block Content-->
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>