import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service'; 
import { Router } from '@angular/router'; 
import { FormBuilder, FormGroup } from '@angular/forms'; 
import { ToastrService } from 'ngx-toastr';
import { EncrDecrService } from '../encr-decr-service';
import endpoint from 'endpoints.config';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public account = {  
    password: null  
    
  };  
    
  public barLabel: string = "Password strength:"; 
  myForm: FormGroup;
  userNameList: any[];
  email:string;
  secretKey = endpoint.secretKey;

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private toastr: ToastrService, private EncrDecr: EncrDecrService) 
  { 
    // Post get all users
    this.authService.macSpicyTooSpicy().subscribe(data =>{
      var array = []
      for(var i = 0; i < data.length; i++){
        var collected
        var decryptedClockIn= data[i].encryptedClockIn
        var decryptedClockInDateTime= data[i].encryptedClockInDateTime;
        var decryptedClockInDateTimeList= data[i].encryptedClockInDateTimeList  
        var decryptedVolunteeringLocation= data[i].encryptedVolunteeringLocation;
        var decryptedName = this.EncrDecr.get(this.secretKey, data[i].encryptedName);
        var decryptedEmail = this.EncrDecr.get(this.secretKey, data[i].encryptedEmail);
        var decryptedAddress = this.EncrDecr.get(this.secretKey, data[i].encryptedAddress);
        var decryptedUnitNo = this.EncrDecr.get(this.secretKey, data[i].encryptedUnitNo);
        var decryptedBoxDonated= this.EncrDecr.get(this.secretKey, data[i].encryptedBoxDonated);
        var decryptedBoxDonatedDates= data[i].encryptedBoxDonatedDates;
        var decryptedBoxIssuedDates= data[i].encryptedBoxIssuedDates;
        var decryptedCollected= this.EncrDecr.get(this.secretKey, data[i].encryptedCollected);
        if(decryptedCollected == "true"){
          collected = true;
        }
        else{
          collected = false;
        }
        var decryptedHousingType= this.EncrDecr.get(this.secretKey, data[i].encryptedHousingType);
        var decryptedLastBoxDonatedDate= data[i].encryptedLastBoxDonatedDate;
        var decryptedMobile= this.EncrDecr.get(this.secretKey, data[i].encryptedMobile);
        var decryptedNewBoxIssueDate= data[i].encryptedNewBoxIssueDate;
        var decryptedPassword= this.EncrDecr.get(this.secretKey, data[i].encryptedPassword);
        var decryptedRole= this.EncrDecr.get(this.secretKey, data[i].encryptedRole);
        var decryptedSupplier= this.EncrDecr.get(this.secretKey, data[i].encryptedSupplier);
        var decryptedTemporaryToken= data[i].encryptedTemporaryToken;
        var decryptedVerified= data[i].encryptedVerified;
        var decrypted_id= this.EncrDecr.get(this.secretKey, data[i].encrypted_id);
        var obj = {"volunteeringLocation":decryptedVolunteeringLocation, "clockInDateTimeList":decryptedClockInDateTimeList,"clockInDateTime":decryptedClockInDateTime,"clockIn":decryptedClockIn,"_id":decrypted_id,"verified":decryptedVerified,"temporaryToken":decryptedTemporaryToken,"supplier":decryptedSupplier,"role":decryptedRole,"password":decryptedPassword,"newBoxIssueDate":decryptedNewBoxIssueDate,"mobile":decryptedMobile,"lastBoxDonatedDate":decryptedLastBoxDonatedDate,"housingType":decryptedHousingType,"greenCurrency":data[i].greenCurrency,"collected":collected,"boxIssuedDates":decryptedBoxIssuedDates,"boxDonatedDates":decryptedBoxDonatedDates,"boxDonated":decryptedBoxDonated,"name": decryptedName, "email": decryptedEmail, "address": decryptedAddress, "unitNo":decryptedUnitNo};
        array.push(obj);
      } 
      
      this.userNameList = array;
      //console.log(this.userNameList)
    });
  }

  ngOnInit() {
    this.myForm = this.fb.group ({
      password: '',
      repassword: ''
    });
  }
  // toggle password visiability
  passwordToggle() {
    var x =  (<HTMLInputElement>document.getElementById("password"));
    var y =  (<HTMLInputElement>document.getElementById("password-status"));
    if (x.type === "password") {
      x.type = "text";
      y.className = "fa fa-eye-slash field-icon";
    } else {
      x.type = "password";
      y.className = "fa fa-eye field-icon";
    }
  }
  // toggle repassword visiability
  repasswordToggle() {
    var x =  (<HTMLInputElement>document.getElementById("repassword"));
    var y =  (<HTMLInputElement>document.getElementById("repassword-status"));
    if (x.type === "password") {
      x.type = "text";
      y.className = "fa fa-eye-slash field-icon";
    } else {
      x.type = "password";
      y.className = "fa fa-eye field-icon";
    }
  }
  
  onSubmit() {  
   
    //Check if all input fields are filled
    if(this.myForm.value.password == "" || this.myForm.value.repassword == ""  ){
      this.toastr.warning('Fill in all input fields!', 'Warning');
    }

    else if(this.myForm.controls.password.hasError('minlength'))
    {
      this.toastr.warning('Minimum 8 character is required for password!', 'Warning');
    }

    //Check if passwords match
    else if(this.myForm.value.password != this.myForm.value.repassword){
      this.toastr.warning('Passwords don\'t match!', 'Warning');
    }

     //Update user with input from myForm 
     else{
      var unlistLength = this.userNameList.length;

      for (var i = 0; i < unlistLength; i++){
     
      
      if(this.userNameList[i].name == localStorage.getItem("data"))
      {
      //reset your password
      this.authService.resetPasswordByName(localStorage.getItem("data"),this.myForm.value.password);
      this.toastr.success('Password is reset', 'Success');
      this.router.navigateByUrl('/login');
      localStorage.removeItem("data");
      return;
       }
   
      if(this.userNameList[i].email == localStorage.getItem("data")){
      //reset your password
      this.authService.resetPasswordByEmail(this.myForm.value.password,localStorage.getItem("data"));
      this.toastr.success('Password is reset', 'Success');
      this.router.navigateByUrl('/login');
      localStorage.removeItem("data");
     
      }
    }
  }
  }

}
