import {Component,OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder,FormGroup,Validators} from '@angular/forms';
import {PostsService} from '../posts.service';
import {Router} from '@angular/router';

@Component({
	selector: 'app-add-products',
	templateUrl: './add-products.component.html',
	styleUrls: ['./add-products.component.css']
})
export class AddProductsComponent implements OnInit {
	productsForm: FormGroup;
	products: any = [];
	base64Image = null;
	last_update: Date = new Date();
	sellers: any = [];

	constructor(private postsService: PostsService, private fb: FormBuilder, private toastr: ToastrService, private router: Router) {
		this.postsService.postGetAllSellers().subscribe(sellers => {
			this.sellers = sellers;
		});
	}

	ngOnInit() {
		
		this.productsForm = this.fb.group({
			name: ['', Validators.required],
			price: ['', Validators.required],
			discountPrice: '',
			maxGreenCurrency:['', Validators.required],
			category: ['', Validators.required],
			supplier: ['', Validators.required],
			description: ['', Validators.required],
			availability: ['', Validators.required],
			image: ['', Validators.required]
		});
	}

	//Convert file to base64
	handleUpload(event) {
		const comp = this;
		const img = < File > event.target.files[0];
		const promise = new Promise((resolve) => {
			const reader = new FileReader();
			reader.onload = function () {
				resolve(reader.result);
			}
			reader.readAsDataURL(img);
		});

		promise.then(img => {
			comp.base64Image = img;
		});
	}


	onSubmit() {
		var boolean;
		if(this.productsForm.value.availability == "" || this.productsForm.value.availability == null || this.productsForm.value.availability == false){
			boolean = false;
		}
		else {
			boolean = this.productsForm.value.availability;
		}		
		if (this.productsForm.value.name == "" || this.productsForm.value.price == "" || this.productsForm.value.category == "" || this.productsForm.value.description == "" || this.productsForm.value.description == "" || this.productsForm.value.image == "" || this.productsForm.value.supplier == "" || this.productsForm.value.supplier == null || this.productsForm.value.maxGreenCurrency == "") {
			this.toastr.warning('Fill in all input fields!', 'Warning!');
		} 
		
		else if (this.productsForm.value.discountPrice == "") {
			//Add product after passing validations
			this.postsService.addProducts(this.productsForm.value.name, this.productsForm.value.price, null, this.productsForm.value.maxGreenCurrency, this.productsForm.value.supplier, this.productsForm.value.category, this.productsForm.value.description, this.last_update, boolean, this.base64Image).subscribe(results => {
				this.toastr.success("Successfully added!", 'Success!');
				this.router.navigateByUrl('/products/All').then(() =>
				{
					location.reload();
				});
			});
		} 
		
		else {
			//Add product after passing validations
			this.postsService.addProducts(this.productsForm.value.name, this.productsForm.value.price, this.productsForm.value.discountPrice, this.productsForm.value.maxGreenCurrency, this.productsForm.value.supplier, this.productsForm.value.category, this.productsForm.value.description, this.last_update, boolean, this.base64Image).subscribe(results => {
				this.toastr.success("Successfully added!", 'Success!');
				this.router.navigateByUrl('/products/All').then(() =>
				{
					location.reload();
				});
			});
		}
	}

}