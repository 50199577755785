<br>
<br>
<br>
<br>

<div class="container">
    <form [formGroup]="sellerForm"  class="p-5 bg-white" (ngSubmit)="onSubmit()">
    <h2>Add New Seller</h2>
    <hr>
    <div class="row form-group">
       <div class="col-md-12 mb-3 mb-md-0">
          <label class="font-weight-bold" for="companyName">Seller Company Name:</label>
          <input type="text" id="companyName" formControlName="companyName" class="form-control" placeholder="Enter company name...">
       </div>
    </div>
    <div class="row form-group">
        <div class="col-md-12 mb-3 mb-md-0">
           <label class="font-weight-bold" for="companyUEN">Company UEN:</label>
           <input type="text" id="companyUEN" formControlName="companyUEN" class="form-control" placeholder="Enter Companys UEN...">
        </div>
    </div>
    <div class="row form-group">
       <div class="col-md-12 mb-3 mb-md-0">
          <label class="font-weight-bold" for="email">Seller Email:</label>
          <input type="email" id="sellersEmail" formControlName="sellersEmail" class="form-control" placeholder="Enter sellers email...">
       </div>
    </div>
    <div class="row form-group">
      <div class="col-md-12 mb-3 mb-md-0">
         <label class="font-weight-bold" for="deliveryFee">Seller Delivery Fee:</label>
         <input type="number" id="deliveryFee" formControlName="deliveryFee" class="form-control" placeholder="Enter how much seller charge per delivery...">
      </div>
   </div>
   <div class="row form-group">
      <div class="col-md-12 mb-3 mb-md-0">
         <label class="font-weight-bold" for="giveFreeShipping">Required Purchase Cost for Free shipping:</label>
         <input type="number" id="giveFreeShipping" formControlName="giveFreeShipping" class="form-control" placeholder="Enter how much purchase cost will give free shipping...">
      </div>
   </div>
   <div class="row form-group">
      <div class="col-md-12 mb-3 mb-md-0">
         <label class="font-weight-bold" for="pickupLocation">Pickup Location:</label>
         <input type="text" id="pickupLocation" formControlName="pickupLocation" class="form-control" placeholder="Enter pickup location...">
      </div>
   </div>
    <div class="row form-group">
       <div class="col-md-12 mb-3 mb-md-0">
          <label  class="font-weight-bold" for="image">Company Logo:</label>
          <br>
          <input type="file" id="image" formControlName="image" (change)="handleUpload($event)" accept=".png, .jpg, .jpeg, .pdf">
       </div>
    </div>
    <br> 
    <hr>
    <div class="row form-group">
       <div class="col-md-12 mb-3 mb-md-0">
          <input type="submit" value="Submit" class="btn btn-success pill text-white px-5 py-2"  >
       </div>
    </div>
    </form>
 </div>