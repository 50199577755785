import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PostsService } from '../posts.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-edit-program',
  templateUrl: './edit-program.component.html',
  styleUrls: ['./edit-program.component.css']
})
export class EditProgramComponent implements OnInit {
  editProjectsForm: FormGroup;
  _id: string;
  private sub: any;
  projectInfo: any = [];
  base64Image = null;
  publish: Date = new Date();
  oldImage: any;
  
  constructor(private fb: FormBuilder, private postsService: PostsService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService) {}
  
  ngOnInit() {
  
    this.sub = this.route.params.subscribe(params => {
      this._id = params['_id'];
  
      this.postsService.postGetProjectsID(this._id).subscribe(projectInfo => {
        this.projectInfo = projectInfo;
  
        this.editProjectsForm = this.fb.group({
          title: this.projectInfo[0].title,
          body: this.projectInfo[0].body,
          givingSG: this.projectInfo[0].givingSGLink,
          image: null
        });
  
        this.oldImage = this.projectInfo[0].image
  
      });
  
    });
  }
  
  handleUpload(event) {
    const comp = this;
    const img = < File > event.target.files[0];
    const promise = new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      }
      reader.readAsDataURL(img);
    });
  
    promise.then(img => {
      comp.base64Image = img;
    });
  }
  
  editProject(id: string) {
    
    if (this.editProjectsForm.value.title == "" || this.editProjectsForm.value.body == "" || this.editProjectsForm.value.givingSG == "") {
      this.toastr.warning('Fill in all input fields!', 'Warning');
    } else if (this.editProjectsForm.value.image == null) {
      //update project details
      this.postsService.editProject(id,
        this.editProjectsForm.value.title, this.editProjectsForm.value.body, this.publish, this.oldImage, this.editProjectsForm.value.givingSG).subscribe(results => {
        this.toastr.success("Successfully Updated!", 'Success!');
        this.router.navigateByUrl('/projects');
      });
    } else {
      //update project details
      this.postsService.editProject(id,
        this.editProjectsForm.value.title, this.editProjectsForm.value.body, this.publish, this.base64Image, this.editProjectsForm.value.givingSG).subscribe(results => {
        this.toastr.success("Successfully Updated!", 'Success!');
        this.router.navigateByUrl('/projects');
      });
    }
  
  }
}
