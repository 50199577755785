
  <br>
  <br>
  <br>
  <br>
  
  <div class="container">
  <form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="p-5 bg-white">
   
      <h1>Account Verification</h1>
      <p>Please fill in this form to verify your account.</p>
      <hr>
        <label for="password">Current Password :</label>
        <p><input type="password" formControlName="password" id="password" placeholder="Your password"  minlength="8" required>
          <span id="password-status" (click)="passwordToggle()" class="fa fa-eye field-icon"></span></p>
  
        <label for="repassword">Confirm Password :</label>
        <p><input type="password" formControlName="repassword" id="repassword" placeholder="Your confirm password" />
          <span id="repassword-status" (click)="repasswordToggle()" class="fa fa-eye field-icon"></span></p>
    
      <hr>
      <p><button class="validatebtn" type="submit" >Verify Me</button></p>
   
  </form>
</div>
  