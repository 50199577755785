<br>
<br>
<br>
<br>

<div class="container">
  <form [formGroup]="editProductsForm"  class="p-5 bg-white">
     <h2>Edit Products</h2>
     <hr>
     <div class="row form-group">
        <div class="col-md-12 mb-3 mb-md-0">
           <label class="font-weight-bold" for="name">Name:</label>
           <input type="text" id="name" formControlName="name" class="form-control" value={{productsInfo[0].name}}>
        </div>
     </div>
     <div class="row form-group">
        <div class="col-md-12 mb-3 mb-md-0">
           <label class="font-weight-bold" for="price">Price:</label>
           <input type="number" id="price" formControlName="price" class="form-control" value={{productsInfo[0].price}}>
        </div>
     </div>
     <div class="row form-group">
        <div class="col-md-12 mb-3 mb-md-0">
           <label class="font-weight-bold" for="discountPrice">Discounted Price:</label>
           <input type="number" id="discountPrice" formControlName="discountPrice" class="form-control">
        </div>
     </div>
     
     <!-- <div class="form-group">
        <label class="font-weight-bold" for="supplier">Supplier: </label>
        <select class="form-control" id="supplier" formControlName="supplier">
        <option *ngFor="let supplier of sellers" [ngValue]="supplier">
        {{ supplier.companyName }}
        </option>
        </select>
     </div> -->
     <div class="row form-group">
      <div class="col-md-12 mb-3 mb-md-0">
        <label class="font-weight-bold" for="maxGreenCurrency">Max Percentage of Green Currency can use: (e.g 50)</label>
        <input type="number" id="maxGreenCurrency" formControlName="maxGreenCurrency" class="form-control" placeholder="Enter Maximum Percentage of Green Currency can use...">
      </div>
    </div>
    
    <div class="row form-group">
      <div class="col-md-12 mb-3 mb-md-0">
   <label class="font-weight-bold" for="supplier">Supplier:</label>
      <select type="text" name="supplier" id="supplier" formControlName="supplier" class="browser-default custom-select custom-select-lg mb-3">
        <option selected disabled>{{ supplierName }}</option>
        <option *ngFor="let supplier of sellers" [ngValue]="supplier">
          {{ supplier.companyName }}
        </option>
      </select>
    </div>
  </div>
  
     <div class="row form-group">
        <div class="col-md-12 mb-3 mb-md-0">
           <label class="font-weight-bold" for="category">Category:</label>
           <input type="text" id="category" class="form-control" formControlName="category" list="categorys" />
           <datalist id="categorys">
              <option>Vegetable</option>
              <option>Fish</option>
              <option>Fertilizer</option>
              <option>Aqua</option>
              <option>Consumable</option>
           </datalist>
        </div>
     </div>
     <div class="row form-group">
        <div class="col-md-12 mb-3 mb-md-0">
           <label class="font-weight-bold" for="description">Description:</label>
           <textarea cols="35" rows="10" id="description" formControlName="description" class="form-control"></textarea>
        </div>
     </div>
     <div class="row form-group">
        <div class="col-md-12 mb-3 mb-md-0">
            <div class="custom-control custom-checkbox">
               <input type="checkbox" class="custom-control-input" id="availability" formControlName="availability">
               <label class="custom-control-label" for="availability">Availability</label>
            </div>
           <!-- <label class="font-weight-bold" for="availability">Availability:</label>
           <input type="text" id="availability" formControlName="availability" class="form-control" > -->
        </div>
     </div>
     <div class="row form-group">
        <div class="col-md-12 mb-3 mb-md-0">
           <label class="font-weight-bold" for="image">Image:</label>
           <br>
           <input type="file" id="image" formControlName="image" (change)="handleUpload($event)" accept=".png, .jpg, .jpeg, .pdf">
        </div>
     </div>
     <br>
     <hr>
     <button type="submit" class="btn btn-success"  (click)="editProducts(productsInfo[0]._id)" >Save changes </button>
  </form>
</div>