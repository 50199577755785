<br />
<br />
<br />
<br />

<div class="container">
  <form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="p-5 bg-white">
    <h1>Register</h1>
    <p>Please fill in this form to create an account.</p>
    <hr />
    <label for="name">Username:</label>
    <p>
      <input
        type="text"
        formControlName="name"
        id="name"
        placeholder="Your Username"
        required
      />
    </p>

    <label for="email">Email:</label>
    <p>
      <input
        type="email"
        formControlName="email"
        id="email"
        placeholder="Your Email"
        required
      />
    </p>

    <label for="mobile">Mobile No.:</label>
    <p>
      <input
        type="text"
        formControlName="mobile"
        id="mobile"
        minlength="8"
        maxlength="8"
        placeholder="Your Mobile No."
        required
      />
    </p>

    <label for="address">Postal Code.: (e.g. 123456)</label>
    <p>
      <input
        type="text"
        formControlName="address"
        id="address"
        placeholder="Your house postal code"
        minlength="6"
        maxlength="6"
        required
      />
    </p>

    <label for="address">Type of housing:</label>
    <div class="form-check">
      <label class="form-check-label" for="radio1">
        <input
          type="radio"
          formControlName="radioBtn"
          id="radioHDB"
          value="HDB"
          checked
        />
        Public Housing (HDB flats)
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label" for="radio2">
        <input
          type="radio"
          formControlName="radioBtn"
          id="radioApartments"
          value="Condominiums"
        />
        Private Condominiums / Apartments
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label">
        <input
          type="radio"
          formControlName="radioBtn"
          id="radioLandedHousing"
          value="Landed Housing"
        />
        Landed Housing
      </label>
    </div>
    <br />
    <label for="address"
      >Unit Number.: (Accepted format: #01-123 & only one account is allowed per
      household)</label
    >
    <p>
      <input
        type="text"
        formControlName="unitNo"
        id="unitNo"
        placeholder="Your house unit number"
        required
      />
    </p>

    <label for="password">Password (8 characters minimum):</label>
    <p>
      <input
        type="password"
        formControlName="password"
        id="password"
        placeholder="Your password"
        [(ngModel)]="account.password"
        minlength="8"
        required
      />
      <span
        id="password-status"
        (click)="passwordToggle()"
        class="fa fa-eye field-icon"
      ></span>
    </p>

    <label for="repassword">Retype Password:</label>
    <p>
      <input
        type="password"
        formControlName="repassword"
        id="repassword"
        placeholder="Retype password"
      />
      <span
        id="repassword-status"
        (click)="repasswordToggle()"
        class="fa fa-eye field-icon"
      ></span>
    </p>

    <app-password-strength-bar
      [passwordToCheck]="account.password"
      [barLabel]="barLabel"
    >
    </app-password-strength-bar>

    <label for="address">Food Waste Collection Box Type:</label>
    <div class="form-check">
      <label class="form-check-label" for="radio1">
        <input
          type="radio"
          formControlName="radioHalalBoxType"
          id="radioBoxTypeHalal"
          value="halal"
        />
        Halal
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label" for="radio2">
        <input
          type="radio"
          formControlName="radioHalalBoxType"
          id="radioBoxTypeNonHalal"
          value="nonhalal"
        />
        Non-Halal
      </label>
    </div>
    <br />

    <br />
    <div class="row form-group">
      <div class="col-md-12 mb-3 mb-md-0">
        <p style="color: black">
          <input type="checkbox" id="tnc" formControlName="tnc" required /> By
          creating an account you agree to Eco²Balance
          <a
            (click)="showText()"
            id="myTNC3"
            style="color: blue; text-decoration: underline"
          >
            Terms and Conditions</a
          >
          &
          <a
            (click)="showText2()"
            id="myPolicy4"
            style="color: blue; text-decoration: underline"
            >Privacy Policy</a
          >
        </p>

        <p class="mb-5">
          <span id="dots3"></span
          ><span id="more3">
            <h3>Terms and Conditions</h3>
            <strong>1.INTRODUCTION</strong>
            <br />
            Welcome to the Eco²Balance platform. By accessing or using our Site,
            you acknowledge that you have read, understood, and agreed, without
            limitations or qualifications, to be bound by these Terms &
            Conditions which include our Privacy Policy. Eco²Balance reserves
            the right to amend, add or vary any of these Terms and Conditions at
            any time without prior notice.
            <br />
            <br />
            <strong>2. PRIVACY</strong>
            <br />
            <br />
            2.1 Your privacy is very important to us at Eco²Balance. To better
            protect your rights, we have provided the Eco²Balance.com Privacy
            Policy to explain our privacy practices in detail. Please review the
            Privacy Policy to understand how Eco²Balance collects and uses the
            information associated with your Account and/or your use of the
            Services. By using the Services or providing information on the
            site, you:
            <br />
            (i) consent to Eco²Balance's collection, use, disclosure and/or
            processing of your content, personal data and User Information as
            described in the Privacy Policy;
            <br />
            (ii) agree and acknowledge that the proprietary rights of your User
            Information are jointly owned by you and Eco²Balance; and
            <br />
            (iii) shall not, whether directly or indirectly, disclose your User
            Information to any third party, or otherwise allow any third party
            to access or use your User Information, without Eco²Balance’s prior
            written consent.
            <br />
            <br />
            2.2 Users in possession of another User’s personal data through the
            use of the Services (the “Receiving Party”) hereby agree that, they
            will (i) comply with all applicable personal data protection laws
            with respect to any such data; (ii) allow the User whose personal
            data the Receiving Party has collected (the “Disclosing Party”) to
            remove his or her data collected from the Receiving Party’s
            database; and (iii) allow the Disclosing Party to review what
            information have been collected about them by the Receiving Party,
            in each case of (ii) and (iii) above, in compliance with and where
            required by applicable laws.
            <br />
            <br />
            <strong>3. LIMITED LICENSE</strong>
            <br />
            <br />
            3.1 Eco²Balance grants you a limited and revocable license to access
            and use the Services subject to the Eco²Balance Terms and
            Conditions. All proprietary content, trademarks, service marks,
            brand names, logos and other intellectual property displayed on the
            Site are the property of Eco²Balance and where applicable, third
            party proprietors identified on the site. No right or license is
            granted directly or indirectly to any party accessing the site to
            use or reproduce any Intellectual Property, and no party accessing
            the Site shall claim any right, title or interest therein. By using
            or accessing the Services you agree to comply with the copyright,
            trademark, service mark, and all other applicable laws that protect
            the Services, the Site and its Content. You agree not to copy,
            distribute, republish, transmit, publicly display, publicly perform,
            modify, adapt, rent, sell, or create derivative works of any portion
            of the Services, the Site or its Content. You also may not, without
            prior written consent, mirror or frame any part or whole of the
            contents of this Site on any other server or as part of any other
            website. In addition, you agree that you will not use any robot,
            spider or any other automatic device or manual process to monitor or
            copy our Content, without our prior written consent (such consent is
            deemed given for standard search engine technology employed by
            Internet search websites to direct Internet users to this website).
            <br />
            <br />
            3.2 You are welcome to link the Site from your website, provided
            that your website does not imply any endorsement by or association
            with Eco²Balance. You acknowledge that Eco²Balance may, in its sole
            discretion and at any time, discontinue providing the Services,
            either in part or as a whole, without notice.
            <br />
            <br />
            <strong>4. SOFTWARE</strong>
            <br />
            Any software provided by us to you as part of the Services is
            subject to the provisions of these Terms and Conditions. Eco²Balance
            reserves all rights to the software not expressly granted by
            Eco²Balance hereunder. Any third-party scripts or code, linked to or
            referenced from the Services, are licensed to you by the third
            parties that own such scripts or code, not by Eco²Balance.
            <br />
            <br />
            <strong>5. ACCOUNTS AND SECURITY</strong>
            <br />
            <br />
            5.1 To access the functions of our Services, you will be required to
            register for an account by entering a username and password, and by
            providing certain personal information.
            <br />
            <br />
            5.2 You agree to (a) keep your password confidential and use only
            your username and password when logging in, (b) ensure that you log
            out from your account at the end of each session on the Site, (c)
            immediately notify Eco²Balance of any unauthorised use of your
            Account, Username and/or password, and (d) ensure that your Account
            information is accurate and up-to-date. You are fully responsible
            for all activities that occur under your Username and Account even
            if such activities or uses were not committed by you. Eco²Balance
            will not be liable for any loss or damage arising from unauthorised
            use of your password or your failure to comply with this Section.
            <br />
            <br />
            5.3 You agree that Eco²Balance may for any reason, in its sole
            discretion and with or without notice or liability to you or any
            third party, immediately terminate your Account and your User ID,
            remove or discard from the Site any Content associated with your
            Account and User ID, withdraw any subsidies offered to you, cancel
            any transactions associated with your Account and User ID,
            temporarily withhold any sale proceeds or refunds, and/or take any
            other actions that Eco²Balance deems necessary. Grounds for such
            actions may include, but are not limited to, actual or suspected (a)
            extended periods of inactivity, (b) violation of the letter or
            spirit of these Terms of Service, (c) illegal, fraudulent,
            harassing, defamatory, threatening or abusive behaviour (d) having
            multiple user accounts, (e) buying products on the Site for the
            purpose of commercial re-sale, (f) abnormal or excessive purchasing
            of products from the same Seller or related group of Sellers, (g)
            voucher abuse (including, but not limited to, selling of vouchers to
            third parties and abnormal or excessive use of vouchers on the
            Site), or (h) behaviour that is harmful to other Users, third
            parties, or the business interests of Eco²Balance. Use of an Account
            for illegal, fraudulent, harassing, defamatory, threatening or
            abusive purposes may be referred to law enforcement authorities
            without notice to you. If a legal dispute arises or law enforcement
            action is commenced relating to your Account or your use of the
            Services for any reason, Eco²Balance may terminate your Account
            immediately with or without notice.
            <br />
            <br />
            5.4 Users may terminate their Account if they notify Eco²Balance in
            writing (including via email at Eco2Balancesg.gmail.com) of their
            desire to do so. Notwithstanding any such termination, Users remain
            responsible and liable for any incomplete transaction (whether
            commenced prior to or after such termination), shipment of the
            product, payment for the product, or the like, and Users must
            contact Eco²Balance after he or she has promptly and effectively
            carried out and completed all incomplete transactions according to
            the Terms of Service. Eco²Balance shall have no liability, and shall
            not be liable for any damages incurred due to the actions taken in
            accordance with this Section. Users waive any and all claims based
            on any such action taken by Eco²Balance.
            <br />
            <br />

            <strong>6. TERM OF USE</strong>
            <br />
            <br />
            6.1 The license for use of this Site and the Services is effective
            until terminated. This license will terminate as set forth under
            these Terms of Service or if you fail to comply with any term or
            condition of these Terms of Service. In any such event, Eco²Balance
            may effect such termination with or without notice to you.
            <br />
            <br />
            6.2 You agree not to: 
            <br />
            <br />
            • post, list or upload content or items in inappropriate categories
            or areas on our sites.
            <br />
            • share your account or password with anyone.
            <br />
            • create multiple accounts and let others use your account.
            <br />
            • purchase alcohol if you are less than 18 years old
            <br />
            • breach or circumvent any laws, regulations, third-party rights or
            our systems, Services, policies, or determinations of your account
            status.
            <br />
            • fail to pay for items purchased by you, unless you have a valid
            reason, for example, the seller has materially changed the item's
            description after you bid, a clear typographical error is made, or
            you cannot contact the seller.
            <br />
            • fail to deliver items sold by you, unless you have a valid reason,
            for example, the buyer fails to comply with the posted terms in your
            listing or you cannot contact the buyer.
            <br />
            • manipulate the price of any item or interfere with any other
            user's listings.
            <br />
            • post false, inaccurate, misleading, deceptive, defamatory, or
            libelous content.
            <br />
            • take any action that may undermine the feedback or ratings
            systems.
            <br />
            • transfer your Eco²Balance account to another party without our
            consent.
            <br />
            • distribute or post spam, unsolicited or bulk electronic
            communications, chain letters, or pyramid schemes.
            <br />
            • distribute viruses or any other technologies that may harm
            Eco²Balance or the interests or property of users.
            <br />
            • use any robot, spider, scraper, data mining tools, data gathering
            and extraction tools, or other automated means to access our
            Services for any purpose, except with the prior express permission
            of Eco²Balance.
            <br />
            • interfere with the functioning of our Services, such as by
            imposing an unreasonable or disproportionately large load on our
            infrastructure.
            <br />
            • export or re-export any Eco²Balance application or tool
            <br />
            • infringe the copyright, trademark, patent, publicity, moral,
            database, and/or other intellectual property rights (collectively,
            "Intellectual Property Rights") that belong to or are licensed to
            Eco²Balance. Some, but not all, actions that may constitute
            infringement are reproducing, performing, displaying, distributing,
            copying, reverse engineering, decompiling, disassembling, or
            preparing derivative works from content that belongs to Eco²Balance
            or someone else.
            <br />
            • infringe any Intellectual Property Rights that belong to third
            parties affected by your use of our Services or post content that
            does not belong to you.
            <br />
            • commercialize any Eco²Balance application or any information or
            software associated with such application, except with the prior
            express permission of Eco²Balance.
            <br />
            • harvest or otherwise collect information about users without their
            consent; or
            <br />
            • circumvent any technical measures used to provide our Services.
            <br />
            <br />

            6.3 You understand that all Content, whether publicly posted or
            privately transmitted, is the sole responsibility of the person from
            whom such Content originated. This means that you, and not
            Eco²Balance, are entirely responsible for all Content that you
            upload, post, email, transmit or otherwise make available through
            the Site. You understand that by using the Site, you may be exposed
            to Content that you may consider to be offensive, indecent or
            objectionable. To the maximum extent permitted by applicable law,
            under no circumstances will Eco²Balance be liable in any way for any
            Content, including, but not limited to, any errors or omissions in
            any Content, or any loss or damage of any kind incurred as a result
            of the use of, or reliance on, any Content posted, emailed,
            transmitted or otherwise made available on the Site.
            <br />
            <br />

            <strong>7. PURCHASE AND PAYMENT</strong>
            <br />
            <br />
            7.1 Eco²Balance supports the following payment methods:
            <br />
            <br />
            (i) Stripe Payment Buyer are required to spend a minimum of $6
            before using stripe payment to purchase item(s). Card payments are
            processed through third-party payment channels and the type of
            credit cards accepted by these payment channels may vary.
            <br />
            (ii) PayNow/PayLah! The Buyer may make payment through
            PayNow/PayLah!. The payment is processed by Otolith Enrichment first
            and then transferred to the seller.
            <br />
            <br />
            7.2 Eco²Balance takes no responsibility and assumes no liability for
            any loss or damages to the Buyer arising from shipping information
            and/or payment information entered by the Buyer or wrong remittance
            by the Buyer in connection with the payment for the items purchased.
            We reserve the right to check whether the Buyer is duly authorised
            to use certain payment methods, and may suspend the transaction
            until such authorisation is confirmed or cancel the relevant
            transaction where such confirmation is not available.
            <br />
            <br />
            <strong>8. ECO²BALANCE GREEN CURRENCY REWARD SYSTEM</strong>
            <br />
            <br />
            8.1 Users will earn Green Currency points through donating of food
            waste at Tampines Park or through volunteering in Eco²Balance
            project. The amount of Green Currency awarded is based on the number
            of donated food waste boxes or amount of time spent for volunteering
            in project. Green Currency will generally be credited to a User’s
            Account upon completion of a successful scanning of a unique QR code
            provided by Eco²Balance. You are eligible to participate in the
            Eco²Balance Green Currency reward system if you are 13 years old and
            above.
            <br />
            <br />
            8.2 Eco²Balance Green Currency has no monetary value, does not
            constitute your property and cannot be purchased, sold, transferred
            or redeemed for cash by the Buyer.
            <br />
            <br />

            8.3 The Eco²Balance Green Currency you used for reducing the price
            of the item you are purchasing will be deducted from your
            Eco²Balance Green Currency balance. Each Eco²Balance Green Currency
            will expire 1 year after you have earned it. Once expired, Green
            Currency will not be reinstated.
            <br />
            <br />

            <strong>9.  SELLER’S RESPONSIBILITIES</strong>
            <br />
            <br />
            9.1 The Seller shall properly manage and ensure that relevant
            information such as the price and the details of items and product
            availability on Eco²Balance for the purpose of sales is updated on
            the Seller’s listing and inaccurate or misleading information shall
            not be posted.
            <br />
            <br />
            9.2 The price of items for sale will be determined by the Seller at
            his/her own discretion. The price of an item and shipping charges
            shall include the entire amount to be charged to the Buyer such as
            sales tax, value-added tax, tariffs, etc. and the Seller shall not
            charge the Buyer such amount additionally and separately. 
            <br />
            <br />
            9.3 The Seller agrees that Eco²Balance may at its discretion, engage
            in promotional activities to induce transactions between the Buyer
            and the Seller by reducing, discounting or refunding fees, or in
            other ways. The final price that the Buyer will pay actually will be
            the price that such an adjustment is applied to.
            <br />
            <br />

            <strong>10.  DISPUTES</strong>
            <br />
            <br />
            10.1 In the event a problem arises in a transaction, the Buyer and
            Seller agree to first communicate with each other to attempt to
            resolve the dispute by mutual discussions, which Eco²Balance shall
            use reasonable commercial efforts to facilitate.
            <br />
            <br />
            10.2 Each Buyer and Seller covenants and agrees that it will not
            bring suit or otherwise assert any claim against Eco²Balance or its
            Affiliates (except where Eco²Balance or its Affiliates is the Seller
            of the product that the claim relates to) in relation to any
            transaction made on the Site or any dispute related to such
            transaction.
            <br />
            <br />

            <strong>11.  FEEDBACK</strong>
            <br />
            <br />
            11.1 Eco²Balance welcomes information and feedback from our Users
            which will enable Eco²Balance to improve the quality of service
            provided. Please refer to our feedback procedure below for further
            information:
            <br />

            (i) Feedback may be made in writing through email to
            eco2balancesg@gmail.com or in the community forum found on the
            website.
            <br />

            (ii) Anonymous feedback will not be accepted.
            <br />

            (iii) Users affected by the feedback should be fully informed of all
            facts and given the opportunity to put forward their case.
            <br />

            (iv) Vague and defamatory feedback will not be entertained.
            <br />
            <br />
            <strong>12.  DISCLAIMERS</strong>
            <br />
            <br />
            12.1 THE SERVICES ARE PROVIDED "AS IS" AND WITHOUT ANY WARRANTIES,
            CLAIMS OR REPRESENTATIONS MADE BY Eco²Balance OF ANY KIND EITHER
            EXPRESSED, IMPLIED OR STATUTORY WITH RESPECT TO THE SERVICES,
            INCLUDING, WITHOUT LIMITATION, WARRANTIES OF QUALITY, PERFORMANCE,
            NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR
            PURPOSE, NOR ARE THERE ANY WARRANTIES CREATED BY COURSE OF DEALING,
            COURSE OF PERFORMANCE OR TRADE USAGE. WITHOUT LIMITING THE FOREGOING
            AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ECO²BALANCE
            DOES NOT WARRANT THAT THE SERVICES, THIS SITE OR THE FUNCTIONS
            CONTAINED THEREIN WILL BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED,
            TIMELY, SECURE, ACCURATE, COMPLETE OR ERROR-FREE, THAT DEFECTS, IF
            ANY, WILL BE CORRECTED, OR THAT THIS SITE AND/OR THE SERVER THAT
            MAKES THE SAME AVAILABLE ARE FREE OF VIRUSES, CLOCKS, TIMERS,
            COUNTERS, WORMS, SOFTWARE LOCKS, DROP DEAD DEVICES, TROJAN-HORSES,
            ROUTINGS, TRAP DOORS, TIME BOMBS OR ANY OTHER HARMFUL CODES,
            INSTRUCTIONS, PROGRAMS OR COMPONENTS.
            <br />

            12.2 YOU ACKNOWLEDGE THAT THE ENTIRE RISK ARISING OUT OF THE USE OR
            PERFORMANCE OF THE SITE AND/OR THE SERVICES REMAINS WITH YOU TO THE
            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
            <br />

            12.3 ECO²BALANCE HAS NO CONTROL OVER AND, TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW, DOES NOT GUARANTEE OR ACCEPT ANY
            RESPONSIBILITY FOR: (A) THE FITNESS FOR PURPOSE, EXISTENCE, QUALITY,
            SAFETY OR LEGALITY OF ITEMS AVAILABLE VIA THE SERVICES; OR (B) THE
            ABILITY OF SELLERS TO SELL ITEMS OR OF BUYERS TO PAY FOR ITEMS. IF
            THERE IS A DISPUTE INVOLVING ONE OR MORE USERS, SUCH USERS AGREE TO
            RESOLVE SUCH DISPUTE BETWEEN THEMSELVES DIRECTLY AND, TO THE MAXIMUM
            EXTENT PERMITTED BY APPLICABLE LAW, RELEASE ECO²BALANCE AND ITS
            AFFILIATES FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES ARISING OUT
            OF OR IN CONNECTION WITH ANY SUCH DISPUTE.
            <br />
            <br />
            <strong>13.  EXCLUSIONS AND LIMITATIONS OF LIABILITY</strong>
            <br />
            <br />
            13.1 TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
            SHALL ECO²BALANCE BE LIABLE WHETHER IN CONTRACT, WARRANTY, TORT
            (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE (WHETHER ACTIVE, PASSIVE
            OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY),
            OR OTHER CAUSE OF ACTION AT LAW, IN EQUITY, BY STATUTE OR OTHERWISE,
            FOR:
            <br />
            (i) (A) LOSS OF USE; (B) LOSS OF PROFITS; (C) LOSS OF REVENUES; (D)
            LOSS OF DATA; (E) LOSS OF GOOD WILL; OR (F) FAILURE TO REALISE
            ANTICIPATED SAVINGS, IN EACH CASE WHETHER DIRECT OR INDIRECT; OR
            <br />
            (ii) ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES,
            ARISING OUT OF OR IN CONNECTION WITH THE USE OR INABILITY TO USE
            THIS SITE OR THE SERVICES, INCLUDING, WITHOUT LIMITATION, ANY
            DAMAGES RESULTING THEREFROM, EVEN IF Eco²Balance HAS BEEN ADVISED OF
            THE POSSIBILITY OF SUCH DAMAGES.
            <br />
            <br />
            13.2 YOU ACKNOWLEDGE AND AGREE THAT YOUR ONLY RIGHT WITH RESPECT TO
            ANY PROBLEMS OR DISSATISFACTION WITH THE SERVICES IS TO REQUEST FOR
            TERMINATION OF YOUR ACCOUNT AND/OR DISCONTINUE ANY USE OF THE
            SERVICES.
            <br />
            <br />
            13.3 IF, NOTWITHSTANDING THE PREVIOUS SECTIONS, ECO²BALANCE IS FOUND
            BY A COURT OF COMPETENT JURISDICTION TO BE LIABLE (INCLUDING FOR
            GROSS NEGLIGENCE), THEN, TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW, ITS LIABILITY TO YOU OR TO ANY THIRD PARTY IS
            LIMITED TO THE LESSER OF: (A) ANY AMOUNTS DUE AND PAYABLE TO YOU
            PURSUANT TO THE Eco²Balance GUARANTEE; AND (B) SG $100 (ONE HUNDRED
            SINGAPORE DOLLARS).
            <br />
            <br />
            13.4 NOTHING IN THESE TERMS OF SERVICE SHALL LIMIT OR EXCLUDE ANY
            LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED BY ECO²BALANCE ’S
            NEGLIGENCE, FOR FRAUD OR FOR ANY OTHER LIABILITY ON THE PART OF
            ECO²BALANCE THAT CANNOT BE LAWFULLY LIMITED AND/OR EXCLUDED.
            <br />
            <br />

            <strong>14.  INDEMNITY</strong>
            <br />

            You agree to indemnify, defend and hold harmless Eco²Balance, and
            its shareholders, subsidiaries, affiliates, directors, officers,
            agents, co-branders or other partners, and employees (collectively,
            the "Indemnified Parties") from and against any and all claims,
            actions, proceedings, and suits and all related liabilities,
            damages, settlements, penalties, fines, costs and expenses
            (including, without limitation, any other dispute resolution
            expenses) incurred by any Indemnified Party arising out of or
            relating to: (a) any transaction made on the Site, or any dispute in
            relation to such transaction (except where Eco²Balance or its
            Affiliates is the Seller in the transaction that the dispute relates
            to), (b) the Eco²Balance Guarantee, (c) the hosting, operation,
            management and/or administration of the Services by or on behalf of
            Eco²Balance, (d) your violation or breach of any term of these Terms
            and Conditions or any policy or guidelines referenced herein, (e)
            your use or misuse of the Services, (f) your breach of any law or
            any rights of a third party, or (g) any Content uploaded by you.
            <br />
            <br />
            <strong>15.  SEVERABILITY</strong>
            <br />
            If any provision of these Terms and Conditions shall be deemed
            unlawful, void, or for any reason unenforceable under the law of any
            jurisdiction, then that provision shall be deemed severable from
            these Eco²Balance and shall not affect the validity and
            enforceability of any remaining provisions in such jurisdiction nor
            the validity and enforceability of the provision in question under
            the law of any other jurisdiction.
            <br />
            <br />
            <strong>16. LAW AND REGULATIONS</strong>
            <br />
            By creating an account via the website, the user agrees that
            Singapore Law shall govern the Terms and Conditions and any contract
            or legal relationship formed on the basis of the registration.
            <br />
            <br />
            <strong>17.  CONTACT US</strong>
            <br />
            If you have any questions or concerns about these Eco²Balance or any
            issues raised in these Terms and Conditions or on the Site, please
            contacts us at: eco2balancesg.gmail.com 
          </span>
        </p>
        <p class="mb-5" style="font-size: 14px">
          <span id="dots4"></span
          ><span id="more4">
            <h3>Privacy Agreement</h3>
            We, at Eco²Balance, understand your concerns on how your personal
            information would be used and shared. We would like to address your
            concern by providing you with the affirmation that the personal data
            collected through this website would be treated sensibly and in
            confidentiality, and by doing so, we seek to gain your trust. The
            following information describes our privacy policy, of which you are
            obliged to accept when accessing our webpage.
            <br />
            <br />
            <strong>When will Eco²Balance collect personal data?</strong>
            <br />
            We will/may collect personal data about you:
            <br />
            <br />
            • when you register on our website
            <br />
            • when you submit any form, including, but not limited to,
            application forms or other forms relating to any of our products and
            services, whether online or by way of a physical form;
            <br />
            • when you interact with us, such as via telephone calls (which may
            be recorded), letters, fax, face-to-face meetings, social media
            platforms and emails;
            <br />
            • when you provide us with feedback or complaints;
            <br />
            • when you register for a program/project; or
            <br />
            • when you submit your personal data to us for any reason
            <br />
            <br />
            The above does not purport to be exhaustive and sets out some common
            instances of when personal data about you may be collected.
            <br />
            <br />
            <strong>What personal data we collect and why we collect it</strong>
            <br />
            • name;
            <br />
            • email address;
            <br />
            • billing address;
            <br />
            • phone number;
            <br />
            • information sent by or associated with the device(s) used to
            access our website or app;
            <br />
            • any other information about the user when the user signs up to use
            our website
            <br />
            • comments
            <br />
            <br />
            You agree not to submit any information to us which is inaccurate or
            misleading, and you agree to inform us of any inaccuracies or
            changes to such information. We reserve the right, at our sole
            discretion to require further documentation to verify the
            information provided by you.
            <br />
            <br />
            <strong> How long we retain your data</strong>
            <br />
            If you leave a comment, the comment and its metadata are retained
            indefinitely. This is so we can recognize and approve any follow-up
            comments automatically instead of holding them in a moderation
            queue. For users that register on our website (if any), we also
            store the personal information they provide in their user profile.
            All users can see and edit their personal information at any time
            (except they cannot change their username, email, role, Green
            Currency and address). Website administrators can also see and edit
            this information.
            <br />
            <br />
            <strong>What rights you have over your data</strong>
            <br />
            If you have an account on this site, or have left comments, you can
            request to receive an exported file of the personal data we hold
            about you, including any data you have provided to us. You can also
            request that we erase any personal data we hold about you. This does
            not include any data we are obliged to keep for administrative,
            legal, or security purposes.
            <br />
            <br />
            <strong
              >How does Eco²Balance protect and retain customer
              information?</strong
            >
            <br />
            We implement a variety of security measures and strive to ensure the
            security of your personal data on our systems. User personal data is
            contained behind secured networks and is only accessible by a
            limited number of employees who have special access rights to such
            systems.
            <br />
            <br />
            <strong>Transfer of Personal Information Overseas</strong>
            <br />
            In the event where personal information are required to be shared
            with third parties vendors, some of whom may be located overseas, we
            will ensure that the recipient is bounded legally by the local
            equivalent of PDPA2012 and seek your understanding on this matter.
            <br />
            <br />
            <strong>Updates to Privacy Policy</strong>
            <br />
            We might revise this privacy policy from time to time. Please refer
            to this page for the latest version of our policy which will be used
            for governing our collection, use and disclosure of your personal
            information.  
          </span>
        </p>
      </div>
    </div>

    <hr />
    <p><button class="registerbtn" type="submit">Register</button></p>

    <div class="container signin">
      <p>
        <a routerLink="/login" routerLinkActive="active"
          >Already own an account?</a
        >
      </p>
    </div>
  </form>
</div>
