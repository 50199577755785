import { Component, OnInit } from '@angular/core';
import { PostsService } from '../posts.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service'; 
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  orders: any = [];
  p: Number = 1;
  carts: any = [];
  productName: string;
  price: number;
  category: string;
  image: string;
  quantity: number;
  msg:string;
  empty: boolean;
  program_ID:any = [];
  orderLength: number;

  constructor(private postsService: PostsService,private toastr: ToastrService, private authService: AuthService, private spinner: NgxSpinnerService) {
    this.spinner.show();
    // get login user orders
    this.postsService.postGetOrders(this.authService.getUserID()).subscribe(orders => {
      this.spinner.hide();
      this.orderLength = orders.length;
      for (let i = 1; i <= this.orders.length; i++) {
        this.orders.push(`item ${i}`);
        
      }
      this.orders = orders;

      if(this.orders.length == 0){
        this.msg = 'Your order history is empty!'
        this.empty = true
      }
      else{
        this.msg = ''
        this.empty = false
      }
    });
    //get user cart
    this.postsService.postGetCart(this.authService.getUserID()).subscribe(carts => {
      this.carts = carts;
    });

    
      
  }
  
  ngOnInit(): void {
  }
  
 
}
  
