import { Component, OnInit } from '@angular/core';
import { PostsService } from '../posts.service';

@Component({
  selector: 'app-seller-list',
  templateUrl: './seller-list.component.html',
  styleUrls: ['./seller-list.component.css']
})
export class SellerListComponent implements OnInit {
  sellerList: any = [];
  loadedSellerList: any[];

  constructor(private postsService: PostsService) 
  { 
    this.postsService.postGetAllSellers().subscribe(data =>{
      var Length = data.length;
      for (var i = 0; i < Length; i++){
        JSON.stringify(data[i]._id);
      }
      this.sellerList = data;
      this.loadedSellerList = data;
    });
  }

  ngOnInit(): void {
  }

  initializeItems(): void {
    this.sellerList = this.loadedSellerList;
  }

  search(event) {
    
    this.initializeItems();
    //Set searchTerm with input from search bar
    const searchTerm = event.srcElement.value;
  
    if (!searchTerm) {
    return;
    }
    //Filters enquiries list with keywords from searchTerm
    this.sellerList = this.sellerList.filter(i => {
    if (i.companyName && searchTerm) {
    if (i.companyName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
    return true;
    }
    return false;
    }
    });
  }
  //remove seller based on id
  deleteSeller(_id){
    if(confirm('Are you sure you want to delete this seller?'))
    {
      this.postsService.postDeleteSeller(_id).subscribe(results => {
        window.location.reload();
      });
    }
  }

}
