import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { EncrDecrService } from '../encr-decr-service';
import endpoint from 'endpoints.config';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  elementType: "url" | "canvas" | "img" = "url";
  userList: any = [];
  loadedUserList: any[];
  secretKey = endpoint.secretKey;

  constructor(private authService: AuthService, private toastr: ToastrService, private EncrDecr: EncrDecrService) 
  {
    // Post get all users
    this.authService.macSpicyTooSpicy().subscribe(data =>{
      var array = []
      for(var i = 0; i < data.length; i++){
        var collected
        var decryptedClockIn= data[i].encryptedClockIn
        var decryptedClockInDateTime= data[i].encryptedClockInDateTime;
        var decryptedClockInDateTimeList= data[i].encryptedClockInDateTimeList  
        var decryptedVolunteeringLocation= data[i].encryptedVolunteeringLocation;
        var decryptedName = this.EncrDecr.get(this.secretKey, data[i].encryptedName);
        var decryptedEmail = this.EncrDecr.get(this.secretKey, data[i].encryptedEmail);
        var decryptedAddress = this.EncrDecr.get(this.secretKey, data[i].encryptedAddress);
        var decryptedUnitNo = this.EncrDecr.get(this.secretKey, data[i].encryptedUnitNo);
        var decryptedBoxDonated= this.EncrDecr.get(this.secretKey, data[i].encryptedBoxDonated);
        var decryptedBoxDonatedDates= data[i].encryptedBoxDonatedDates;
        var decryptedBoxIssuedDates= data[i].encryptedBoxIssuedDates;
        var decryptedCollected= this.EncrDecr.get(this.secretKey, data[i].encryptedCollected);
        if(decryptedCollected == "true"){
          collected = true;
        }
        else{
          collected = false;
        }
        var decryptedHousingType= this.EncrDecr.get(this.secretKey, data[i].encryptedHousingType);
        var decryptedLastBoxDonatedDate= data[i].encryptedLastBoxDonatedDate;
        var decryptedMobile= this.EncrDecr.get(this.secretKey, data[i].encryptedMobile);
        var decryptedNewBoxIssueDate= data[i].encryptedNewBoxIssueDate;
        var decryptedPassword= this.EncrDecr.get(this.secretKey, data[i].encryptedPassword);
        var decryptedRole= this.EncrDecr.get(this.secretKey, data[i].encryptedRole);
        var decryptedSupplier= this.EncrDecr.get(this.secretKey, data[i].encryptedSupplier);
        var decryptedTemporaryToken= data[i].encryptedTemporaryToken;
        var decryptedVerified= data[i].encryptedVerified;
        var decrypted_id= this.EncrDecr.get(this.secretKey, data[i].encrypted_id);
        var obj = {"volunteeringLocation":decryptedVolunteeringLocation, "clockInDateTimeList":decryptedClockInDateTimeList,"clockInDateTime":decryptedClockInDateTime,"clockIn":decryptedClockIn,"_id":decrypted_id,"verified":decryptedVerified,"temporaryToken":decryptedTemporaryToken,"supplier":decryptedSupplier,"role":decryptedRole,"password":decryptedPassword,"newBoxIssueDate":decryptedNewBoxIssueDate,"mobile":decryptedMobile,"lastBoxDonatedDate":decryptedLastBoxDonatedDate,"housingType":decryptedHousingType,"greenCurrency":data[i].greenCurrency,"collected":collected,"boxIssuedDates":decryptedBoxIssuedDates,"boxDonatedDates":decryptedBoxDonatedDates,"boxDonated":decryptedBoxDonated,"name": decryptedName, "email": decryptedEmail, "address": decryptedAddress, "unitNo":decryptedUnitNo};
        array.push(obj);
      } 
      // for search bar
      this.userList = array;
      this.loadedUserList = array;
    });
  }

  ngOnInit(): void {
  }

  initializeItems(): void {
    this.userList = this.loadedUserList;
  }

  search(event) {
    
    this.initializeItems();
    //Set searchTerm with input from search bar
    const searchTerm = event.srcElement.value;
  
    if (!searchTerm) {
    return;
    }
    //Filters enquiries list with keywords from searchTerm
    this.userList = this.userList.filter(i => {
    if (i.name && searchTerm) {
    if (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
    return true;
    }
    return false;
    }
    });
  }
  // delete user by ID
  deleteUser(_id){
    if(confirm('Are you sure you want to delete this user?'))
    {
      this.authService.postDeleteUser(_id).subscribe(results => {
        window.location.reload();
      });
    }
  }
  // Change user role by ID
  changeRole(_id){
    let value1 = (<HTMLSelectElement>document.getElementById(_id +"1")).value;
    this.toastr.success("Successfully updated user role");
    this.authService.changeUserRole(_id, value1);
    window.location.reload();
  }
  // Verify user by ID
  verifyUser(_id){
  this.toastr.success("Successfully updated user role");
  this.authService.adminVerifyUser(_id, true);
  window.location.reload();
  }
}
