<br>
<br>
<br>
<br>

<div class="qr-scan-area">
    <!-- This is the npm package in-charge of scanning the QR -->
    <zxing-scanner
      #scanner
      [(device)]="currentDevice"
      (scanSuccess)="onCodeResult($event)"
      (permissionResponse)="onHasPermission($event)"
    ></zxing-scanner>
    <div class="qr-area">
      <div class="area"></div>
    </div>
  </div>

  <!-- Displays message on the screen if guest is valid or not -->
<div class="guest">
    <ng-container *ngIf="userExist === true">
      <div class="alert alert-success">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <strong>Success!</strong> Staff has been found!
        <p>
          Username: {{ staffDetails[0].name }} has clock in/out to/from our .
        </p>
      </div>
    </ng-container>
    <ng-container #notFound *ngIf="userExist === false">
      <div class="alert alert-warning">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <strong>Warning!</strong> This person is not a Staff!
      </div>
    </ng-container>
  </div>

  <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal">Open Modal</button>

<!-- The Modal -->
<div class="modal" id="myModal"  #content>
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h1 class="modal-title" id="modalHeading"></h1>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

        <!-- Modal body -->
        <div class="modal-body">
            <h4 id="modalBody"></h4>
        </div>

       <!-- Modal footer -->
       <div class="modal-footer">
        <select name="attendees" id="selectProject" class="browser-default custom-select custom-select-lg mb-3">
          <option *ngFor="let i of projectsList">{{i.title}}</option>
        </select>
        <div *ngIf= "boolean == true">
          <button type="button" class="btn btn-success" (click)="submit()">Clock In</button>
        </div>
        <div *ngIf= "boolean == false">
          <button type="button" class="btn btn-success" (click)="submit()">Clock Out</button>
        </div>
        <button type="button" class="btn btn-danger" data-dismiss="modal" id="closeModel">Close</button>
      </div>
    </div>
  </div>
</div>