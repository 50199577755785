import {ModuleWithProviders, NgModule} from '@angular/core'; 
import {Routes, RouterModule} from '@angular/router'; 
import {RegisterComponent} from './register/register.component'; 
import {LoginComponent} from './login/login.component'; 
import {LogoutComponent} from './logout/logout.component'; 
import {AdminComponent} from './admin/admin.component'; 
import {AuthGuard} from './auth.guard';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { HomeComponent } from './home/home.component';
import { NewsComponent } from './news/news.component';
import { NewsInfoComponent } from './news-info/news-info.component';
import { PostNewsComponent } from './post-news/post-news.component';
import { EditNewsComponent } from './edit-news/edit-news.component';
import { ScannerComponent } from './scanner/scanner.component';
import { UserListComponent } from './user-list/user-list.component';
import { ProgramsComponent } from './programs/programs.component';
import { ProgramInfoComponent } from './program-info/program-info.component';
import { EditProgramComponent } from './edit-program/edit-program.component';
import { PostProgramComponent } from './post-program/post-program.component';
import { ProductsComponent } from './products/products.component';
import { ProductsInfoComponent } from './products-info/products-info.component';
import { AddProductsComponent } from './add-products/add-products.component';
import { EditProductsComponent } from './edit-products/edit-products.component';
import { CartComponent } from './cart/cart.component';
import { OrderComponent } from './order/order.component';
import { OrderListComponent } from './order-list/order-list.component';
import { AddOrderComponent } from './add-order/add-order.component';
import { OrderInfoComponent } from './order-info/order-info.component';
import { ContactComponent } from './contact/contact.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { EmailValidationComponent } from './email-validation/email-validation.component';
import { StaffProjectListComponent } from './staff-project-list/staff-project-list.component';
import { ProgrammesComponent } from './programmes/programmes.component';
import { ProgrammesInfoComponent } from './programmes-info/programmes-info.component';
import { EditProgrammesComponent } from './edit-programmes/edit-programmes.component';
import { AddProgrammesComponent } from './add-programmes/add-programmes.component';
import { ProgramListComponent } from './program-list/program-list.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { StatisticPageComponent } from './statistic-page/statistic-page.component';
import { AboutpageComponent } from './aboutpage/aboutpage.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotUsernameComponent } from './forgot-username/forgot-username.component';
import { RegProgramsComponent } from './reg-programs/reg-programs.component';
import { RegProjectsComponent } from './reg-projects/reg-projects.component';
import { AdminCreateUserComponent } from './admin-create-user/admin-create-user.component';
import { AdminUserInfoComponent } from './admin-user-info/admin-user-info.component';
import { UserStatisticComponent } from './user-statistic/user-statistic.component';
import { ServicesComponent } from './services/services.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { TestComponent } from './test/test.component';
import { SellerListComponent } from './seller-list/seller-list.component';
import { SellerInfoComponent } from './seller-info/seller-info.component';
import { AddSellerComponent } from './add-seller/add-seller.component';
import { SupplierProductListComponent } from './supplier-product-list/supplier-product-list.component';
import { SupplierOrderListComponent } from './supplier-order-list/supplier-order-list.component';
import { VolunteerSignInComponent } from './volunteer-sign-in/volunteer-sign-in.component';
import { VolunteerEntriesLogComponent } from './volunteer-entries-log/volunteer-entries-log.component';

const appRoutes: Routes = [  
    { path: 'register', component:RegisterComponent},  
    { path: 'login', component:LoginComponent},  
    { path: 'logout', component:LogoutComponent},
    { path: 'userProfile', component:UserProfileComponent},
    { path: 'home', component:HomeComponent},
    { path: 'news', component:NewsComponent},
    { path: 'newsInfo/:_id', component:NewsInfoComponent},
    { path: 'postNews', component:PostNewsComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}}, 
    { path: 'editNews/:_id', component:EditNewsComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}}, 
    { path: 'Scanner', component:ScannerComponent, canActivate: [AuthGuard], data: {permission: {only: ["staff", "admin"]}}},
    { path: 'userList', component:UserListComponent, canActivate: [AuthGuard], data: {permission: {only: ["staff", "admin"]}}},
    { path: 'projects', component:ProgramsComponent},
    { path: 'projectInfo/:_id', component:ProgramInfoComponent},
    { path: 'editProject/:_id', component:EditProgramComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}},
    { path: 'postProject', component:PostProgramComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}}, 
    { path: 'products/:type', component:ProductsComponent},
    { path: 'productsInfo/:_id', component:ProductsInfoComponent},
    { path: 'addProducts', component:AddProductsComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}}, 
    { path: 'editProducts/:_id', component:EditProductsComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}}, 
    { path: 'cart', component:CartComponent},
    { path: 'order', component:OrderComponent},
    { path: 'orderList', component:OrderListComponent, canActivate: [AuthGuard], data: {permission: {only: ["staff", "admin"]}}},
    { path: 'addOrder', component:AddOrderComponent}, 
    { path: 'orderInfo/:_id', component:OrderInfoComponent},
    { path: 'contact', component:ContactComponent},
    { path: 'wishlist', component:WishlistComponent},
    { path: 'accountValidation/:temporaryToken', component:EmailValidationComponent},
    { path: 'staffProjectList', component:StaffProjectListComponent, canActivate: [AuthGuard], data: {permission: {only: ["staff", "admin"]}}},
    // { path: 'programs', component:ProgrammesComponent},
    { path: 'programsInfo/:_id', component:ProgrammesInfoComponent},
    { path: 'editPrograms/:_id', component:EditProgrammesComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}},
    { path: 'addPrograms', component:AddProgrammesComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}}, 
    { path: 'programList', component:ProgramListComponent, canActivate: [AuthGuard], data: {permission: {only: ["staff", "admin"]}}},
    { path: 'userInfo/:_id', component:UserInfoComponent, canActivate: [AuthGuard], data: {permission: {only: ["staff", "admin"]}}},
    { path: 'statisticPage', component:StatisticPageComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}},
    { path: 'aboutPage', component:AboutpageComponent},
    { path: 'forgotPassword', component:ForgotPasswordComponent},
    { path: 'resetPassword/:passwordToken', component:ResetPasswordComponent},
    { path: 'forgotUsername', component:ForgotUsernameComponent},
    { path: 'regPrograms', component:RegProgramsComponent},
    { path: 'regProjects', component:RegProjectsComponent},
    { path: 'userStatistic', component:UserStatisticComponent},
    { path: 'services', component:ServicesComponent},
    { path: 'admin', component:AdminComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}},
    { path: 'adminCreateUser', component:AdminCreateUserComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}},  
    { path: 'adminUserInfo/:_id', component:AdminUserInfoComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}},  
    { path: '', component:HomeComponent, pathMatch:'full'},
    { path: 'checkOut', component:CheckoutComponent}, 
    { path: 'test', component:TestComponent}, 
    { path: 'adminSellerList', component:SellerListComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}},
    { path: 'adminSellerInfo/:_id', component:SellerInfoComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}},  
    { path: 'adminAddSeller', component:AddSellerComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}},
    { path: 'supplierProductListComponent', component:SupplierProductListComponent, canActivate: [AuthGuard], data: {permission: {only: ["supplier"]}}},  
    { path: 'supplierOrderListComponent', component:SupplierOrderListComponent, canActivate: [AuthGuard], data: {permission: {only: ["supplier"]}}},
    { path: 'volunteerSignIn', component:VolunteerSignInComponent, canActivate: [AuthGuard], data: {permission: {only: ["staff", "admin"]}}},
    { path: 'volunteerEntriesLog', component:VolunteerEntriesLogComponent, canActivate: [AuthGuard], data: {permission: {only: ["admin"]}}},
];

NgModule({
    imports: [RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      scrollPositionRestoration: "top",
      onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
  })
 
export const routing:ModuleWithProviders = RouterModule.forRoot(appRoutes, {
  enableTracing: false,
  scrollPositionRestoration: "top",  
  onSameUrlNavigation: 'reload'
}); 
