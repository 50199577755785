    
     <!-- Header -->
     <header class="w3-display-container w3-content w3-wide" style="max-width:1500px;" id="home">
        <img class="w3-image" src="./assets/imgs/img5.jpg" alt="" width="100%" height="30%">
        <div class="w3-display-middle w3-margin-top w3-center">
          <h1 class="w3-xxlarge w3-text-white"><span class="w3-padding w3-black w3-opacity-min"><b>OTOLITH ENRICHMENT SERVICES</b></span> </h1>
        </div>
      </header>
      

      <div class="site-block-half d-flex">
       
        <div class="text">
          <h1>DRAFTING OF LAND TENDER PROPOSALS</h1>
        </div>
        <div class="image bg-image" ><img src="./assets/imgs/img4.jpg" height="100%" width="100%"></div>
      </div>
            
      
            <div class="site-block-half d-flex">
              <div class="image bg-image"><img src="./assets/imgs/img6.jpg" height="100%" width="100%"></div>
              <div class="text">
                <h1>DEVELOPMENT OF AQUACULTURE/AGRICULTURE OPERATIONAL PROTOCOLS(SOP)</h1>
              </div>
            </div>
            <div class="site-block-half d-flex">
             
                <div class="text">
                  <h1>DRAFTING OF GOVERNMENT FUNDING APPLICATIONS</h1>
                </div>
                <div class="image bg-image" ><img src="./assets/imgs/img7.jpg" height="100%" width="100%"></div>
              </div>
        
        
              <div class="site-block-half d-flex">
                <div class="image bg-image"><img src="./assets/imgs/img8.jpg" height="100%" width="100%"></div>
                <div class="text">
                  <h1>DESIGNING OF SUSTAINABLE SOLUTIONS FOR PRIVATE HOMES</h1>

                </div>
              </div>
              <div class="site-block-half d-flex">
             
                <div class="text">
                  <h1> DESIGNING OF AQUACULTURE/AGRICULTUREAL FACILITIES </h1>
                 
                </div>
                <div class="image bg-image" ><img src="./assets/imgs/img9.jpg" height="100%" width="100%"></div>
              </div>

              <div class="site-block-half d-flex">
                <div class="image bg-image"><img src="./assets/imgs/img10.jpg" height="100%" width="100%"></div>
                <div class="text">
                  <h1>AQUACULTURE/AGRICULTURE BUSINESS DEVELOPMENT</h1>
                </div>
              </div>
              <div class="site-block-half d-flex">

                <div class="site-block-half d-flex">
                    
                    <div class="text">
                      <h1>OUR CONSULTING TEAM</h1>
                      <p class="margin-top-s">Shane graduated from the University of Tasmania with a Masters of Applied Science (Aquaculture). His experiences in the Australian ornamental fish industry and role as the Director of National Aquaculture Training Institute Pty Ltd have provided him with extensive expertise in understanding aquaculture practices as well as the feasibility and design of aquaculture facilities. Shane is the current president for Ornamental Fish International (OFI) and Member of Executive Board of Advisors.<br>
                        <span id="dots">...</span><span id="more">     
                          Shane specialises in training and designing feasible aquaculture solutions to develop and sustain both food and ornamental fish industries. His specialization includes risk assessment, biosecurity and quality management system. His latest consultancy engagement includes cluster development projects in Jamaica and Sri Lanka.<br>
                          Our rich experience with the industry, government and academia also makes us a suitable consultant for industry partners. Specifically those that are seeking solutions built on comprehensive expertise paired with modern, sustainable farming technologies and methodologies. Together with our thorough knowledge of local government policies and plans for the agricultural sector, we aim to provide the most holistic and sustainable solution for your company.<br>
                          Shane graduated from the University of Tasmania with a Masters of Applied Science (Aquaculture). His experiences in the Australian ornamental fish industry and role as the Director of National Aquaculture Training Institute Pty Ltd have provided him with extensive expertise in understanding aquaculture practices as well as the feasibility and design of aquaculture facilities. Shane is the current president for Ornamental Fish International (OFI) and Member of Executive Board of Advisors.<br>
                          Shane specialises in training and designing feasible aquaculture solutions to develop and sustain both food and ornamental fish industries. His specialization includes risk assessment, biosecurity and quality management system. His latest consultancy engagement includes cluster development projects in Jamaica and Sri Lanka.<br>
                        </span></p>
                      <button type="button" (click)="showText()" class="btn btn-success"  id="myBtn"> Read more </button>

                    </div>
                    <div class="image bg-image"><img src="./assets/imgs/img2.jpg" height="100%" width="100%"></div>
                  </div>