<br>
<br>
<br>
<br>
<br>
<div id="print-section"> 
    <div class="container">
            <br>
            <div class="section-title">
             <h2 class="mb-5">User Statistics</h2>
           </div>   
        <h3 class="center">Your Top 5 Purchased Products</h3>
        <br>
        <h5 class="center" *ngIf = "empty1 === true">
            {{msg1}}
        </h5>
        <div class="divChart">
            <canvas id="myChartBar"></canvas>
        </div>
        <br>
        <br>
        <h3 class="center">Your Favourite Product Catergory</h3>
        <br>
        <h5 class="center" *ngIf = "empty2 === true">
            {{msg2}}
        </h5>
        <div class="divChart">
            <canvas id="myChartDoughnut"></canvas>
        </div>       
       <br>
<br>  
</div>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>