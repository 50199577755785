import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConnectionService } from '../connection.service';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  public account = {  
    password: null  
    
  };  
    
  public barLabel: string = "Password strength:"; 
  myForm: FormGroup;
  elementType: "url" | "canvas" | "img" = "url";  
  userID: string;
  userDetails: any[];
  gCM: number;

  constructor(private fb: FormBuilder, private authService: AuthService, private toastr: ToastrService, private connectionService: ConnectionService) 
  { 
    
  }

  ngOnInit() {
     //get user details
    this.authService.postGetUserDetails(this.authService.getUserID()).subscribe(data => {
      this.userDetails = data;
      this.gCM = this.userDetails[0].greenCurrency.totalGreenCurrency;

      this.myForm = this.fb.group ({
        name: this.authService.getSecureToken(),
        email: [this.userDetails[0].email, Validators.email],
        mobile: this.userDetails[0].mobile,
        address: this.userDetails[0].address,
        unitNo: this.userDetails[0].unitNo,
        housingType: this.userDetails[0].housingType,
        role: this.userDetails[0].role,
        greenCurrency: this.gCM,
        password: '',
        repassword: ''
      });
    });
    
    
    this.userID = this.authService.getUserID();
  }

  // toggle password visiability
  passwordToggle() {
    var x =  (<HTMLInputElement>document.getElementById("password"));
    var y =  (<HTMLInputElement>document.getElementById("password-status"));
    if (x.type === "password") {
      x.type = "text";
      y.className = "fa fa-eye-slash field-icon";
    } else {
      x.type = "password";
      y.className = "fa fa-eye field-icon";
    }
  }

  // toggle repassword visiability
  repasswordToggle() {
    var x =  (<HTMLInputElement>document.getElementById("repassword"));
    var y =  (<HTMLInputElement>document.getElementById("repassword-status"));
    if (x.type === "password") {
      x.type = "text";
      y.className = "fa fa-eye-slash field-icon";
    } else {
      x.type = "password";
      y.className = "fa fa-eye field-icon";
    }
  }

  onSubmit() {
    //Check if all input fields are filled
    if(this.myForm.value.password == "" || this.myForm.value.repassword == "" || this.myForm.value.mobile == "" ){
      this.toastr.warning('Fill in all input fields!', 'Warning');
    }
    else if(this.myForm.controls.password.hasError('minlength'))
    {
      this.toastr.warning('Minimum 8 character is required for password!', 'Warning');
    }

    //Check if passwords match
    else if(this.myForm.value.password != this.myForm.value.repassword){

    this.toastr.warning('Passwords don\'t match!', 'Warning');
    }


    else if(/[\d]{8}/.test(this.myForm.value.mobile) == false)
    {
      this.toastr.warning('Invalid phone number format!', 'Warning');
    }
    
   
    //Update user with input from myForm 
    else{
      
          this.toastr.success('User has been updated', 'Success');
          this.authService.updateUser(this.myForm.value.name, this.myForm.value.password, this.myForm.value.email, this.myForm.value.mobile, this.myForm.value.address, this.myForm.value.unitNo, this.myForm.value.housingType);
          window.location.reload();
        }


  }

  

}
