<br>
<br>
<br>
<br>
<br>

<p><a routerLink="/adminCreateUser" class="postUserBtn"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Create New User</a></p>
<br>
<div class="section-title">
  <h2>List Of Website Users</h2>

<div class="main">
  <div class="form-group has-search">
  <span class="fa fa-search form-control-feedback"></span>
  <input type="text" (keydown)="search($event)" class="form-control" placeholder="Search By Username">
  </div>
</div>
</div>
<div class="user-list">
    <table class="table table-hover">
      
        <thead class="thead-dark">
          <tr>
            <th scope="col">UserID QR-Code</th>
            <th scope="col">Username</th>
            <th scope="col">Verified</th>
            <th scope="col">Role</th>
            <th scope="col">Email</th>
            <th scope="col">Mobile</th>
            <th scope="col">Address</th>
            <th scope="col">Green Currency Amount</th>
            <th scope="col">Delete User</th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let i of userList">
          <td data-label="qr">
            <ngx-qrcode
            [elementType]= "elementType"
            [value]="i._id"
            class="qrcode"
          ></ngx-qrcode>
          </td>
          <td data-label="Username" [routerLink]="['/adminUserInfo',i._id]">{{ i.name }}</td>
          <td data-label="Verified">{{ i.verified }}
            <div>
              <button type="button" class="btn btn-outline-success btn-block" (click)="verifyUser(i._id)">Verify User</button>
            </div>
          </td>
          <td>
          <select name="role" id={{i._id+1}} class="browser-default custom-select custom-select-lg mb-3">
              <option selected disabled>{{ i.role }}</option>
              <option value="user">user</option>
              <option value="staff">staff</option>
              <option value="supplier">supplier</option>
              <option value="admin">admin</option>
             
          </select>
          <div>
            <button type="button" class="btn btn-outline-success btn-block" (click)="changeRole(i._id)">Change Role</button>
        </div>
          </td>
          <td data-label="Email" [routerLink]="['/adminUserInfo',i._id]">{{ i.email }}</td>
          <td data-label="Mobile" [routerLink]="['/adminUserInfo',i._id]">{{ i.mobile }}</td>
          <td data-label="Address" [routerLink]="['/adminUserInfo',i._id]">{{ i.address }} Unit No: {{ i.unitNo }}</td>
          <td data-label="greenCurrency" [routerLink]="['/adminUserInfo',i._id]">{{ i.greenCurrency.totalGreenCurrency }}</td>
          <td data-label="delete">
            <button type="button" class="btn btn-danger" (click)="deleteUser(i._id)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
