   <br>
   <br>
   <br>
   <br>
   <br>
          <div class="container">
            <br>
            <div class="section-title">    
              <h2> My Wishlist</h2>
              <br>
              <h5>{{msg}}</h5>
            </div>
          <!--Section: Block Content-->
          <section>
          
            <!--Grid row-->
            <div class="row">
          
              <!--Grid column-->
              <div class="col-lg-12">
          
                <!-- Card -->
                <div class="mb-3">
                  <div class="pt-4 wish-list">
          
                    <h5 class="mb-4">Wishlist (<span>{{wishListLength}}</span> items)</h5>
          
                    <div *ngFor="let wishlist of wishlists;let i = index">
                    <div class="row mb-4">
                      <div class="col-md-5 col-lg-3 col-xl-3">
                        <div class="view zoom overlay z-depth-1 rounded mb-3 mb-md-0">
                          <img class="img-fluid w-100" src={{wishlist.image}}  alt="Sample">
                        </div>
                      </div>
                      <div class="col-md-7 col-lg-9 col-xl-9">
                        <div>
                          <div class="d-flex justify-content-between">
                            <div>
                              <h5>{{wishlist.name}}</h5>
                              <p class="mb-2 text-muted text-uppercase small">By: {{wishlist.supplier.companyName}}</p>
                              <p class="mb-3 text-muted text-uppercase small">Category - {{wishlist.category}}</p>
                              <p class="mb-2 text-muted text-uppercase small">Retail Price - {{wishlist.price | currency:'SGD'}}</p>
                              <p class="mb-2 text-muted text-uppercase small" *ngIf="wishlist.discount_price != null">Discounted Price - {{wishlist.discount_price | currency:'SGD'}}</p>

                            </div>
                           
                          </div>
                          <div>
                              <div class="def-number-input number-input safari_only mb-0 w-100">
                                <button type="button" (click)="decreaseQty(wishlist._id,wishlist.quantity)" class="btn btn-outline-success"> - </button>
                                {{wishlist.quantity}}
                                <button type="button" (click)="increaseQty(wishlist._id,wishlist.quantity)" class="btn btn-outline-success"> + </button>
                              </div>
                            </div>
                            <br>
                          <div class="d-flex justify-content-between align-items-center">
                            <div>

                              <button type="button" class="btn btn-outline-danger" (click)="deleteWishlist(wishlist._id)"  name="Remove item"><i  class="fa fa-trash" ></i>   Remove Item</button>
                              <button type="button" class="btn btn-outline-primary" style="margin-left: 10px;" (click)="addToCart(wishlist.itemID, wishlist.name, wishlist.price, wishlist.discount_price,  wishlist.maxGreenCurrency, wishlist.category, wishlist.image, wishlist.quantity, wishlist.availability, wishlist._id, wishlist.supplier)"><i class="fa fa-shopping-cart"></i>   Add To Cart

                              </button>
                            </div>
                              <p class="mb-0" *ngIf="wishlist.discount_price == null"><span><strong id="summary">{{wishlist.price * wishlist.quantity | currency:'USD'}}</strong></span></p>
                            <p class="mb-0" *ngIf="wishlist.discount_price != null"><span><strong id="summary">{{wishlist.discount_price * wishlist.quantity | currency:'USD'}}</strong></span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <!-- Card -->
          
              </div>
              <!--Grid column-->
          
            </div>
            <!-- Grid row -->
          
          </section>
          <!--Section: Block Content-->
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>