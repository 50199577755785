<br>
<br>
<br>
<br>
<br>
<div class="container">
   <br>
   <div class="section-title">
      <h2>My Cart</h2>
      <br>
      <h5>{{msg}}</h5>
   </div>
   <!--Section: Block Content-->
   <section>
      <!--Grid row-->
      <div class="row">
         <!--Grid column-->
         <div class="col-lg-7">
            <!-- Card -->
            <div class="mb-3">
               <div class="pt-4 wish-list">
                  <h5 class="mb-4">Cart (<span>{{cartLength}}</span> items)</h5><button (click)="selectAll()" class="btn btn-outline-primary">Select All</button>
                  <br>
                  <br>
                  <div  *ngFor="let cart of carts">
                     <div class="row mb-4">
                        <div class="col-md-5 col-lg-3 col-xl-3">
                           <div class="view zoom overlay z-depth-1 rounded mb-3 mb-md-0">
                              <img class="img-fluid w-100" src={{cart.image}} alt="Sample">
                           </div>
                        </div>
                        <div class="col-md-7 col-lg-9 col-xl-9">
                           <div>
                              <div class="d-flex justify-content-between">
                                 <div>
                                    <input type="checkbox" id={{cart._id+3}} value="true" (click)="totalAmount();hideLocation()" class="selected">
                                    <h5>{{cart.name}}</h5>
                                
                                    <p class="mb-2 text-dark text-uppercase small">By: {{cart.supplier.companyName}}</p>
                                    <p class="mb-3 text-dark text-uppercase small">Category - {{cart.category}}</p>
                                    <p class="mb-2 text-dark text-uppercase small" >Retail Price - {{cart.price | currency:'SGD'}}</p>
                                    <p class="mb-2 text-danger text-uppercase small" *ngIf="cart.discount_price != null">Discount Price - {{cart.discount_price | currency:'SGD'}}</p>
                                    <p class="mb-2 text-success text-uppercase small" style="font-size:15px;" *ngIf="cart.discount_price == null">Use {{cart.price * cart.quantity * cart.maxGreenCurrency}} Green Currency To Deduct - {{cart.maxGreenCurrency}}%
                                       <input type="checkbox" id={{cart._id+1}} value="true" (click)="useGreenCurrency(cart._id, cart.maxGreenCurrency, cart.price, cart.discount_price, cart.quantity)">
                                    </p>
                                    <p class="mb-2 text-success text-uppercase small" style="font-size:15px;" *ngIf="cart.discount_price != null">Use {{cart.discount_price * cart.quantity * cart.maxGreenCurrency}} Green Currency To Deduct - {{cart.maxGreenCurrency}}%
                                       <input type="checkbox" id={{cart._id+1}} value="true" (click)="useGreenCurrency(cart._id, cart.maxGreenCurrency, cart.price, cart.discount_price, cart.quantity)">
                                    </p>
                                 </div>
                              </div>
                              <div>
                                 <div class="def-number-input number-input safari_only mb-0 w-100">
                                    <button type="button" (click)="decreaseQuantity(cart._id, cart.quantity)" class="btn btn-outline-success"> - </button>
                                    {{cart.quantity}}
                                    <button type="button" (click)="increaseQuantity(cart._id, cart.quantity)" class="btn btn-outline-success"> + </button>
                                 </div>
                              </div>
                              <br>
                              <div class="d-flex justify-content-between align-items-center">
                                 <div>
                                    <button type="button" class="btn btn-outline-danger" (click)="deleteCart(cart._id)"  name="Remove item"><i  class="fa fa-trash" ></i>  Delete</button>
                                    <button type="button" class="btn btn-outline-success" style="margin-left: 10px;"  (click)="addToWishlist(cart.itemID, cart.name, cart.price, cart.discount_price, cart.maxGreenCurrency, cart.category, cart.image, cart.availability, cart.quantity, cart._id, cart.supplier)"  name="Remove item"><i  class="fa fa-heart-o" ></i>  Move To Wishlist</button>
                                 </div>
                                 <p class="mb-0" id={{cart._id}} *ngIf="cart.discount_price == null"><span><strong id="summary">{{cart.price * cart.quantity | currency:'SGD '}}</strong></span></p>
                                 <p class="mb-0" id={{cart._id}} *ngIf="cart.discount_price != null"><span><strong id="summary">{{cart.discount_price * cart.quantity | currency:'SGD '}}</strong></span></p>
                                 <p class="mb-0" id={{cart._id+2}} style="display:none" *ngIf="cart.discount_price == null"><span><strong id="summary">{{(cart.price * cart.quantity) - cart.price * cart.quantity * cart.maxGreenCurrency / 100 | currency:'SGD '}}</strong></span></p>
                                 <p class="mb-0" id={{cart._id+2}} style="display:none" *ngIf="cart.discount_price != null"><span><strong id="summary">{{(cart.discount_price * cart.quantity) - cart.discount_price * cart.quantity * cart.maxGreenCurrency / 100 | currency:'SGD '}}</strong></span></p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <button type="button" class="btn btn-outline-warning" (click)="clearCart()"  name="Remove item">Clear All</button>
               </div>
            </div>
            <!-- Card -->
            <!-- Card -->
            <div class="mb-3">
               <div class="pt-4">
                  <h5 class="mb-4">We accept</h5>
                  <img class="mr-2" width="45px"
                     src="https://mdbootstrap.com/wp-content/plugins/woocommerce-gateway-stripe/assets/images/visa.svg"
                     alt="Visa">
                  <img class="mr-2" width="45px"
                     src="https://mdbootstrap.com/wp-content/plugins/woocommerce-gateway-stripe/assets/images/amex.svg"
                     alt="American Express">
                  <img class="mr-2" width="45px"
                     src="https://mdbootstrap.com/wp-content/plugins/woocommerce-gateway-stripe/assets/images/mastercard.svg"
                     alt="Mastercard">
                  <img class="mr-2" width="45px"
                     src="https://mdbootstrap.com/wp-content/plugins/woocommerce/includes/gateways/paypal/assets/images/paypal.png"
                     alt="PayPal acceptance mark">
                  <img class="mr-2" width="45px"
                     src="./assets/imgs/paynowCartLogo.png"
                     alt="PAYNOW">
                  <img class="mr-2" width="45px"
                     src="./assets/imgs/paylahCartLogo.jpg"
                     alt="DBS PayLah">
               </div>
            </div>
            <!-- Card -->
         </div>
         <!--Grid column-->
         <!--Grid column-->
         <div class="col-lg-5">
            <!-- Card -->
            <div class="mb-4">
               <br>
                  <h5 class="mb-3">Order Summary</h5>
                  <ul class="list-group list-group-flush">
                     <li class="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                        <strong>My Total Green Currency</strong>
                        <span><strong>{{ userGCA }}</strong></span>
                     </li>
                     <li class="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                        <strong> Cart amount </strong>
                        <span><strong>{{total | currency:'SGD '}}</strong></span>
                     </li>
                     <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                        <strong> Shipping Fee</strong>

                        <span><strong>{{shippingFee | currency:'SGD '}}</strong></span>
                     </li>
                     <li class="list-group-item align-items-center px-0">
                        <strong> Shipping Method: </strong>
                        <input type="radio" name="radioBtn" id="radioDelivery" value="Delivery" (click)="calculateShippingFee();selectDeliveryRadioBtn();hideLocation()">
                        <label class="form-check-label" for="radio1"></label> Delivery 
                        <input type="radio" name="radioBtn" id="radioCollection" value="SelfCollection" (click)="calculateShippingFee();selectCollectionRadioBtn();showLocation()">
                        <label class="form-check-label" for="radio2"></label>  Self-Collection <span id="dotsLocation"></span>  
                        <input type="radio" name="radioBtn" id="radioNA" value="NA" (click)="calculateShippingFee();selectNARadioBtn();hideLocation()">
                        <label class="form-check-label" for="radio3"></label> N.A 
                        <br>
                        <br>
                        <p id="moreLocation"><span *ngFor="let seller of sellers"><strong>Pickup Location for {{seller.companyName}} :</strong> {{seller.pickupLocation}}<br><br> </span></p>
                     </li>
               
                     <li class="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                        <div>
                           <strong>Total Amount</strong>
                        </div>
                        <span><strong id="total">{{total + shippingFee | currency:'SGD '}}</strong></span>
                     </li>
                  </ul>
                  <div class="btn-group btn-block">
                     <button type="button" class="btn btn-outline-primary btn-block mr-4 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                     Checkout With...
                     </button>
                     <div class="dropdown-menu">
                        <button class="dropdown-item" (click)="passValuePaynow()">Paynow/Paylah</button>
                        <div class="dropdown-divider"></div>
                        <button class="dropdown-item" (click)="passValueCard()">Card</button>
                     </div>
                     <button type="button" class="btn btn-outline-success btn-block" [routerLink]="['/products','All']">Continue Shopping</button>
                  </div>
                  <!-- Basic dropdown -->
               
            </div>
         </div>
         <!--Grid column-->
      </div>
      <!-- Grid row -->
   </section>
   <!--Section: Block Content-->
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
