<br>
<br>
<br>
<br>
<br>
    <div class="container">
      <br>
      <div class="section-title">    
        <h2> Order History</h2>
        <br>
        <h5>{{msg}}</h5>
      </div>
    <!--Section: Block Content-->
    <section>
    
      <!--Grid row-->
      <div class="row">
    
        <!--Grid column-->
        <div class="col-lg-12">
    
          <!-- Card -->
          <div class="mb-3">
            <div class="pt-4 wish-list">
    
              <h5 class="mb-4">Order (<span>{{orderLength}}</span> items)</h5>
    
              <div *ngFor="let order of orders | paginate: { itemsPerPage: 6, currentPage: p }">
              <div class="row mb-4">
                <!-- <div class="col-md-5 col-lg-3 col-xl-3">
                  <div class="view zoom overlay z-depth-1 rounded mb-3 mb-md-0">
                    <img class="img-fluid w-100" src={{order.image}}  alt="Sample">
                  </div>
                </div> -->
                <div class="col-md-7 col-lg-9 col-xl-9">
                  <div>
                    <div class="d-flex justify-content-between">
                      <div>
                        <h5>{{order.name}} By: {{order.supplier.companyName}}</h5>
                        <p class="mb-3 text-muted text-uppercase small">Order ID - {{order._id}}</p>
                        <p class="mb-3 text-muted text-uppercase small">Order Date - {{order.order_date | date : 'dd/MM/yyyy'}}</p>
                        <p class="mb-3 text-muted text-uppercase small">Order Status - {{order.orderStatus}}</p>
                        <p class="mb-3 text-muted text-uppercase small">Category - {{order.category}}</p>
                        <!-- <p class="mb-3 text-muted text-uppercase small">Supplier: {{order.supplier.companyName}} <img src={{order.supplier.companyLogo}} alt="companyLogo" width="50" height="50"></p> -->
                        <p class="mb-2 text-muted text-uppercase small">Retail Price - {{order.price | currency:'SGD'}}</p>
                        <p class="mb-2 text-muted text-uppercase small" *ngIf="order.discount_price != null">Discounted Price - {{order.discount_price | currency:'SGD'}}</p>
                        <!-- <p class="mb-3 text-muted text-uppercase small">Paid With Green Currency - {{order.paidWithGC}}</p> -->
                        <p class="mb-3 text-muted text-uppercase small" *ngIf="order.paidWithGC == true">
                          <strong *ngIf="order.discount_price == null">Green Currency Used : {{order.greenCurrencyUsed}}</strong>
                          <strong *ngIf="order.discount_price != null">Green Currency Used : {{order.greenCurrencyUsed}}</strong>
                        </p>
                         <p class="mb-3 text-muted text-uppercase small" *ngIf="order.paidWithGC == false"><strong>Green Currency Used : N.A</strong></p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <button type="button" class="btn btn-success"[routerLink]="['/orderInfo',order._id]" >View Details</button>
                      <!-- <button type="button" class="btn btn-outline-danger"  (click)="deleteOrder(order._id)" >Cancel</button> -->
                      <p class="mb-0"><span><strong id="summary">Total Amount Paid: {{order.amount | currency:'SGD'}}</strong></span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <!-- Card -->
    
        </div>
        <!--Grid column-->
    
      </div>
      <!-- Grid row -->
    
    </section>
    <!--Section: Block Content-->
    <div class="center">
      <pagination-controls (pageChange)="p = $event"></pagination-controls>
   </div>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>