<br>
<br>
<br>
<br>
<br>
<div class="container">
    <br>
<div class="section-title">
<h2 class="mb-5">Supplier Products & Programs List</h2>
</div>
<h3 class="mb-5">Supplier Products</h3>
 <h5 *ngIf= "productsArray.length == 0">
    {{msg}}
 </h5>
<div class="table-responsive" *ngIf="productsArray.length != 0">
   <table class="table table-hover">
      <thead class="thead-dark">
         <tr>
            <th class="font-weight-bold"><strong>Image</strong></th>
            <th class="font-weight-bold"><strong>Product Name</strong></th>
            <th class="font-weight-bold"><strong>Price</strong></th>
            <th class="font-weight-bold"><strong>Category</strong></th>
            <th class="font-weight-bold"><strong>Availability</strong></th>
         </tr>
      </thead>
      <tbody *ngFor="let product of productsArray">
         <tr>
            <a [routerLink]="['/productsInfo',product._id]">
               <td data-label="image">
                  <img  src={{product.image}} name="Image" width="200" height="145">
               </td>
            </a>
            <td data-label="name" [routerLink]="['/productsInfo',product._id]">{{product.name}}</td>
            <td data-label="price" [routerLink]="['/productsInfo',product._id]"><a *ngIf="product.discount_price == null">Retail Price: {{product.price | currency:'SGD'}}</a><a *ngIf="product.discount_price !== null">Discounted Price: {{product.discount_price | currency:'SGD'}}</a></td>
            <td data-label="category" [routerLink]="['/productsInfo',product._id]">{{product.category}}</td>
            <td data-label="availability">{{product.availability}}
                <br>
               <button type="button" class="btn btn-outline-success" (click)="supplierEditAvailabilityTrue(product.supplier._id, product._id)">In stock</button>
                <br>
                <br>
               <button type="button" class="btn btn-outline-danger"  (click)="supplierEditAvailabilityFalse(product.supplier._id, product._id)">Out of stock</button>
            </td>
         </tr>
      </tbody>
   </table>
</div>
<br>
<br>
<h3 class="mb-5">Supplier Programs & Workshops</h3>
 <h5 *ngIf= "programsArray.length == 0">
    {{msg2}}
 </h5>
<div class="table-responsive" *ngIf="programsArray.length != 0">
    <table class="table table-hover">
       <thead class="thead-dark">
          <tr>
             <th class="font-weight-bold"><strong>Image</strong></th>
             <th class="font-weight-bold"><strong>Program Name</strong></th>
             <th class="font-weight-bold"><strong>Price</strong></th>
             <th class="font-weight-bold"><strong>Category</strong></th>
             <th class="font-weight-bold"><strong>Capacity</strong></th>
          </tr>
       </thead>
       <tbody *ngFor="let program of programsArray">
          <tr>
             <a [routerLink]="['/programsInfo',program._id]">
                <td data-label="image">
                   <img  src={{program.image}} name="Image" width="200" height="145">
                </td>
             </a>
             <td data-label="name" [routerLink]="['/programsInfo',program._id]">{{program.name}}</td>
             <td *ngIf="program.specialProgram === true" data-label="price" [routerLink]="['/programsInfo',program._id]">Please email info@otolithenrichment.com for plans and pricings</td>
             <td *ngIf="program.specialProgram === false" data-label="price" [routerLink]="['/programsInfo',program._id]"><a *ngIf="program.discount_price == null">Price: {{program.price | currency:'SGD'}}</a><a *ngIf="program.discount_price !== null">Discounted Price: {{program.discount_price | currency:'SGD'}}</a></td>
             <td data-label="category" [routerLink]="['/programsInfo',program._id]">{{program.category}}</td>
             <td data-label="capacity" [routerLink]="['/programsInfo',program._id]">{{program.capacity}}
             </td>
          </tr>
       </tbody>
    </table>
 </div>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>