import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RandomDailyKeyService {
// node-schedule documentation: https://www.npmjs.com/package/cron
//   *    *    *    *    *    *
//   ┬    ┬    ┬    ┬    ┬    ┬
//   │    │    │    │    │    │
//   │    │    │    │    │    └ day of week (0 - 7) (0 or 7 is Sun)
//   │    │    │    │    └───── month (1 - 12)
//   │    │    │    └────────── day of month (1 - 31)
//   │    │    └─────────────── hour (0 - 23)
//   │    └──────────────────── minute (0 - 59)
//   └───────────────────────── second (0 - 59, OPTIONAL)

  constructor() {
    

   }


}
