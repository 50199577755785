import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service'; 
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PostsService } from '../posts.service'; 
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {
  _id: string;
  elementType: "url" | "canvas" | "img" = "url";
  userInfo: any = [];
  private sub: any;
  programsArray: any = [];
  projectsArray: any = [];
  programEmpty:boolean;
  projectEmpty:boolean;
  msg:string;
  msg2:string = "This user haven't sign up for any project";
  dateNow: Date = new Date();
  greencurrency: any;


  constructor(public postsService: PostsService,private route: ActivatedRoute, private toastr: ToastrService, private router: Router, public authService: AuthService, private spinner: NgxSpinnerService) {
     /** spinner starts on init */
     this.spinner.show();
 
    //get all programs
    this.postsService.postGetAllProgrammes().subscribe(programs => {
      this.spinner.hide();
      for(var i = 0; i < programs.length; i++)
      {
        if(programs[i].slot.length == 0)
        {
      
          this.msg = this.userInfo[0].name + " haven't sign up for any program or workshop"
          this.programEmpty = true
        }

        for(var j = 0; j < programs[i].slot.length; j++)
        {

          if(programs[i].slot[j] == this.userInfo[0].name)
          {
            this.programsArray.push(programs[i]);
            this.msg = ""
            this.programEmpty = false
          }
          else
          {
            this.msg = this.userInfo[0].name + " haven't sign up for any program or workshop"
            this.programEmpty = true
          }
        }
      }
     
          
      });
      //get all projects
      this.postsService.postGetAllProjects().subscribe(projects => {
        for(var i = 0; i < projects.length; i++)
        {
          if(projects[i].attendees.length == 0)
          {
        
            this.msg2 = this.userInfo[0].name + " haven't sign up for any project"
            this.projectEmpty = true
          }

          for(var j = 0; j < projects[i].attendees.length; j++)
          {
  
            if(projects[i].attendees[j] == this.userInfo[0].name)
            {
              this.projectsArray.push(projects[i]);
              this.msg2 = ""
              this.projectEmpty = false
            }
            else
            {
              this.msg2 = this.userInfo[0].name + " haven't sign up for any project"
              this.projectEmpty = true
            }
          }
        }
     
        
      });
  
  }

  // printPageArea(areaID){
  //   var printContent = document.getElementById(areaID);
  //   var WinPrint = window.open('', '', 'width=900,height=650');
  //   WinPrint.document.write(printContent.innerHTML);
  //   WinPrint.document.close();
  //   WinPrint.focus();
  //   WinPrint.print();
  // }
  
  //return the food waste box
  returnB(_id) {
    if(confirm('Are you sure you want to issue a new box?'))
    {
      this.authService.issueNewBox(_id, this.dateNow, false);
      location.reload();
    }
    
  }
  //collect a new food waste box
  collectB(_id, userGreenCurrency, userBoxDonated){
    if(confirm('Are you sure you collected this resident food waste box?'))
    {
      var boxDonated = parseInt(userBoxDonated);
      this.authService.updateCollectionStatusRecieve(_id, true, userGreenCurrency + 30, boxDonated + 1, this.dateNow);
      location.reload();
    }
    
  }

  ngOnInit() {
     
     
    this.sub = this.route.params.subscribe(params => {
      this._id = params['_id']; 
      
      this.authService.postGetUserDetails(this._id).subscribe(userInfo => {
      this.userInfo = userInfo;

      });
    });

  }

}
