<br>
<br>
<br>
<header class="w3-display-container w3-content w3-wide" style="max-width:1500px;" id="home">
  <div class="parallax" style="background-image: url(./assets/imgs/homeGif.gif);"></div>
  
  <div class="w3-display-middle w3-margin-top w3-center">
    <img class="w3-image" src="./assets/imgs/finalLogo.png">
    <p style="color:white">Earn Green Currency by participating in our projects to purchase items from our <a routerLink="/products/All">Eco²Balance Shop</a></p>
  </div>
</header>
<br>

<div class="container">
  <div class="row mb-5 align-items-center">
    <div class="col-lg-7 pr-lg-5 mb-5 mb-lg-0">
      <img src="./assets/imgs/our_projects.png" alt="Image placeholder" class="img-fluid">
    </div>
  <div class="col-lg-5 pl-lg-5">
  <div class="section-heading">
  <h3><strong>OUR SUSTAINABILITY PROJECTS</strong></h3>
  <h5><span class="text-success">Join us in our Green initiatives!</span></h5>

  </div>
  <p class="mb-5"> Be a volunteer in our sustainable projects; make new friends, learn new skills and earn green currency which you can use for purchasing items from our Eco²Balance Shop.</p>
  <p>
    <a routerLink="/projects" class="btn btn-success pill px-4 py-3 text-white">JOIN NOW </a>
  </p>
  </div>
 
  </div>
</div>

<div class="container">
  <div class="row mb-5 align-items-center">
  
  <div class="col-lg-5 pl-lg-5">
  <div class="section-heading">
  <h3><strong>OUR PROGRAMS</strong></h3>
  <h5><span class="text-success">Learn about sustainability!</span></h5>

  </div>
  <p class="mb-5">We offer a range of aquaculture, agriculture and environment focused programs catered for students, families as well as organizations.</p>
  <p>
    <a href="/programs" class="btn btn-success pill px-4 py-3 text-white">LEARN MORE</a>
  </p>
  </div>
    <div class="col-lg-7 pr-lg-5 mb-5 mb-lg-0">
      <img src="./assets/imgs/our_programs.png" alt="Image placeholder" class="img-fluid" height="100%" width="100%">
    </div>
  </div>
</div>


<div class="container">
  <div class="row mb-5 align-items-center">
  <div class="col-lg-7 pr-lg-5 mb-5 mb-lg-0">
  <img src="./assets/imgs/finalLogo.png" alt="Image placeholder" class="img-fluid">
  </div>
  <div class="col-lg-5 pl-lg-5">
  <div class="section-heading">
  <h3><strong>OUR ECO²BALANCE APP</strong></h3>
  <h5><span class="text-success">Sustainable based, sustainably produced!</span></h5>

  </div>
  <p class="mb-5">The Eco²Balance App was initiated to demonstrate circularity within communities through various green initiatives. The name, Eco 2 Balance, signifies the importance of ecological and economic balance in our society today. Participants can earn Green Currency by participating in our Sustainability Projects, which can be used for offsetting the cost of purchase for products and services offered by our Green partners, through our Eco²Balance shop.To participate, <a routerLink="/register">click here to register for an account</a></p>
  <p>
    <a routerLink="/products/All" class="btn btn-success pill px-4 py-3 text-white">SHOP NOW </a>

  </p>
  </div>
  </div>
</div>