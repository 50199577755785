import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PostsService } from '../posts.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service'; 
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-supplier-product-list',
  templateUrl: './supplier-product-list.component.html',
  styleUrls: ['./supplier-product-list.component.css']
})
export class SupplierProductListComponent implements OnInit {
  productsArray: any = [];
  productsEmpty: boolean;
  msg: string;
  programsArray: any = [];
  programsEmpty: boolean;
  msg2: string;
  supplierID: any;

  constructor( private postsService: PostsService, public authService: AuthService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService,private spinner: NgxSpinnerService) 
  {
    this.spinner.show();
    //get user details
    this.authService.postGetUserDetails(this.authService.getUserID()).subscribe(userInfo => {
			this.supplierID = userInfo[0].supplier
		
    });    
    //get all products
    this.postsService.postGetAllProducts().subscribe(product => {
      this.spinner.hide();
      for(var i = 0; i < product.length; i++)
      {
        if(product[i].supplier._id == this.supplierID)
        {
          this.productsArray.push(product[i]);
          this.msg = ""
          this.productsEmpty = false
        }
        else
        {
          this.msg = "This company haven't added any product"
          this.productsEmpty = true
        }
      }
       
    });
    //get all programs
    this.postsService.postGetAllProgrammes().subscribe(program => {
      for(var i = 0; i < program.length; i++)
      {
        if(program[i].supplier._id == this.supplierID)
        {
          this.programsArray.push(program[i]);
          this.msg2 = ""
          this.programsEmpty = false
        }
        else
        {
          this.msg2 = "This company haven't added any program"
          this.programsEmpty = true
        }
      }
       
    });
  }

  ngOnInit(): void {
  }
  // update product availability to in stock
  supplierEditAvailabilityTrue(pSID, pID){
    if(this.supplierID != pSID)
    {
      this.toastr.warning("Warning you can't updated product that aren't yours!", 'Warning!');

    }
    else
    {
      this.postsService.editProductsAvailability(pID, true).subscribe(results => {

        this.toastr.success("Successfully Updated!", 'Success!');
        location.reload();
  
      });
    }
  } 
  // update product availability to out of stock
  supplierEditAvailabilityFalse(pSID, pID){
    if(this.supplierID != pSID)
    {
      this.toastr.warning("Warning you can't updated a product that isn't yours!", 'Warning!');

    }
    else
    {
      this.postsService.editProductsAvailability(pID, false).subscribe(results => {

        this.toastr.success("Successfully Updated!", 'Success!');
        location.reload();
  
      });
    }

  } 

}
