import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../auth.service'; 
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-volunteer-entries-log',
  templateUrl: './volunteer-entries-log.component.html',
  styleUrls: ['./volunteer-entries-log.component.css']
})
export class VolunteerEntriesLogComponent implements OnInit {
  @ViewChild('entriesLog', { static: false }) entriesLog: ElementRef;

  entires: any = [];
  entiresClone: any = [];
  array = [1,2,5,3,4,2]
  msg:string;
  empty: boolean;
  constructor(private authService: AuthService) {
    
     
    this.authService.postGetAllVolunteerStaffEntries().subscribe(data =>{
      
      if(data.length == 0){
        this.msg = 'The volunteer entries log is empty!'
        this.empty = true
      }
      else{
        this.msg = ''
        this.empty = false
      }    

      for (var i = 0; i < data.length; i++){
        if(data[i].clockInDateTimeList == null){
   
        }
        else{
          for(var t = 0; t < data[i].clockInDateTimeList.length; t++){
            var str = data[i].clockInDateTimeList[t];
            var res = str.split("Staff/Volunteer:")[1];
            var name = res.split("Date:")[0];
            var res2 = str.split("Date:")[1];
            var dateTime = res2.split("Action:")[0].trim();
            var res3 = res2.split("Action:")[1];
            var action = res3.split("volunteeringLocation")[0];
            var res4 = res3.split("volunteeringLocation:")[1];
            var location = res4.split("greenCurrencyEarned:")[0];
            var greenCurrencyEarned =  str.split("greenCurrencyEarned:")[1];
            //console.log(location)
            this.entires.push({"name": name, "date": dateTime, "action":action, "location":location, "greenCurrencyEarned": greenCurrencyEarned});
            this.entiresClone.push({"name": name, "date": dateTime, "action":action, "location":location, "greenCurrencyEarned": greenCurrencyEarned});
          }
        }
      }
      var pattern = /(\d{2})\-(\d{2})\-(\d{4})\s(\d{2}):(\d{2}):(\d{2})/;
      this.entires.sort(function compare(a, b) {
          var dateA = +new Date(a.date.replace(pattern,'$3-$2-$1T$4:$5:$6'));
          var dateB = +new Date(b.date.replace(pattern,'$3-$2-$1T$4:$5:$6'));
          return dateB - dateA;
      });
      //console.log(this.entires);

    });

  }

  ngOnInit(): void {
  } 
  
  initializeItems(): void {
    this.entires = this.entiresClone;
  }

  search(event) {
    this.initializeItems();
    //Set searchTerm with input from search bar
    const searchTerm = event.srcElement.value;
  
    if (!searchTerm) {
    return;
    }
    //Filters enquiries list with keywords from searchTerm
    this.entires = this.entires.filter(i => {
    if (i.name && searchTerm) {
    if (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
    return true;
    }
    return false;
    }
    });
  }
   formatStringAsISO(s) {
    var b = s.split(/\D/);
    return b[2] + '-' + b[1] + '-' + b[0] + 'T' + b[3] + ':' + b[4] + ':' + b[5] +':' + b[6] ;
  }

  //export data to excel
  exportToExcel() {
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.entriesLog.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'entries.xlsx');
   }
}