<br>
<br>
<br>
<br>
<br>
<div id="print-section">
    <br>
  <div class="container">
     <div class="row">
        <div class="col-md-6 mx-auto text-center mb-5 section-heading">
           <div class="section-title">
              <h2 class="mb-5">Order Details</h2>
           </div>
           <h5 class="mb-5">Order ID # {{orderInfo[0]._id}}</h5>
           <p><strong> Order Date: {{orderInfo[0].order_date | date : 'dd/MM/yyyy'}}</strong></p>
           <p><strong> Order Status: {{orderInfo[0].orderStatus}}</strong></p>
           <div>
           <button class="btn btn-outline-success mr-2" (click)=" updateSupplierOrdersStatusCompleted(orderInfo[0]._id)">Order Received</button>
           <button class="btn btn-outline-primary mr-2"><a href="mailto:{{orderInfo[0].supplier.sellersEmail}}">Contact Sellers</a></button>
            
         </div>
         </div>
     </div>
     <div class="container">
        <div class="row align-items-center">
           <div class="col-md-6 mb-5 mb-md-0">
              <div class="img-border">
                 <!-- <img  src={{orderInfo[0].image}} name ="orderImage" width="300" height="300"> -->
                 <h5>Product / Program Details ：</h5>
                 <p><strong>Item ID : {{orderInfo[0].itemID}}</strong></p>
                 <p>Name : {{orderInfo[0].name}}</p>
                 <p>Retail Price : {{orderInfo[0].price | currency:'SGD'}}</p>
                 <p *ngIf="orderInfo[0].discount_price != null">Discounted Price : {{orderInfo[0].discount_price | currency:'SGD'}}</p>
                 <p>Supplier: {{orderInfo[0].supplier.companyName}} </p>
                 <p>Category : {{orderInfo[0].category}}</p>
                 <p>Quantity : {{orderInfo[0].quantity}}</p>
                 <p *ngIf="orderInfo[0].paidWithGC == true">
                  <strong>Green Currency Used : {{orderInfo[0].greenCurrencyUsed}}</strong>
                </p>
                 <p *ngIf="orderInfo[0].paidWithGC == false"><strong>Green Currency Used : N.A</strong></p>
                
                 <p>Total Amount : {{orderInfo[0].amount | currency:'SGD'}}</p>
              
                 <div *ngIf="service.isAdmin()">
                    <h5>Order Status Log:</h5>
                    <select class="browser-default custom-select custom-select-lg mb-3">
                      <option *ngFor="let i of orderInfo[0].orderStatusDateTimeList.slice().reverse()">{{ i }}</option>
                    </select>
                 </div>
              </div>
           </div>
           <div class="col-md-5 ml-auto">
              <div class="section-heading text-left">
              </div>
              <h5>Shipping Details ：</h5>
              <p><strong>Ordered By : {{orderInfo[0].fullname}}</strong></p>
              <p>User ID : {{orderInfo[0].userID}}</p>
              <p>Email : {{orderInfo[0].email}}</p>
              <p>Phone : {{orderInfo[0].phone}}</p>
              <p>Shipping Method : {{orderInfo[0].shippingMethod}}</p>
              <p>Address : {{orderInfo[0].address}}</p>
              <p>Unit No : {{orderInfo[0].unitNo}}</p>
              <p>Housing Type : {{orderInfo[0].housingType}}</p>
              <br>
              <h5>Billing Details ：</h5>
              <div *ngIf = "orderInfo[0].card_type != null">
                 <p><strong>By Card</strong></p>
                 <p>Card Type : </p>
              </div>
              <p>{{orderInfo[0].card_type}}</p>
              <div *ngIf = "orderInfo[0].billReferenceNumber != null">
                 <p><strong>By Paynow</strong></p>
                 <p>Bill Reference Number : </p>
              </div>
              <p>{{orderInfo[0].billReferenceNumber}}</p>
           </div>
        </div>
     </div>
  </div>
</div>

<button  id="topright" class="btn btn-outline-success mr-2" printSectionId="print-section" ngxPrint>Print</button> 