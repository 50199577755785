<br>
<br>
<br>

<!-- Header -->
<header class="w3-display-container w3-content w3-wide" style="max-width:1600px;" id="home">
  <div class="parallax" style="background-image: url(./assets/imgs/project.jpg);"></div>
  <div class="w3-display-middle w3-margin-top w3-center">
    <h1><b>PROJECTS</b></h1>
  </div>

</header>
<br>
   
    <div class="filterBtn">
        <button type="button" class="btn btn-outline-success mr-2 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-filter">   Filter</i>
        </button>
        <div class="dropdown-menu">
          <button class="dropdown-item" (click)="getProjectsByNewDate()">Date (new to old)</button>
           <div class="dropdown-divider"></div>
           <button class="dropdown-item" (click)="getProjectsByOldDate()">Date (old to new)</button>
        </div>
        
     </div>
    <div class="center">
      <div class="main">
        <div class="form-group has-search">
          <span class="fa fa-search form-control-feedback"></span>
          <input type="text" (keydown)="search($event)" class="form-control" placeholder="Search project">
        </div>
      </div>
    </div>
    <p><a routerLink="/postProject" class="postProgBtn" *ngIf="service.isAdmin()" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">Post Projects</a></p>
  <br>
<div class="row">

  <div class="col-md-6 col-lg-4 mb-5" *ngFor="let p of projects | paginate: { itemsPerPage: 6, currentPage: p } ">
     
    <div class="media-with-text">
      <div class="img-border-sm mb-4">
        <a [routerLink]="['/projectInfo',p._id]" >
          <img src={{p.image}} alt="" class="img-fluid" width="450" height="450">
        </a>
       
      </div>
    
      <h2 class="heading mb-0"><a [routerLink]="['/projectInfo',p._id]">{{p.title}}</a></h2>
      <p>Date Published: {{p.publish  | date : 'dd/MM/yyyy'}}</p>
    </div>
  </div>
  <div class="center">
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>

</div>
<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple"
>
<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>