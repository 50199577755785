<br>
<br>
<br>
<br>
<div class="container">
<form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="p-5 bg-white">
 
    <h1>Forgot Password</h1>
    <p>Please enter your username or registered email to request a password reset</p>
    <hr>

      <label for="username">Username/Email</label>
      <p><input type="text" formControlName="nameOrEmail" id="nameOrEmail" placeholder="Your Username/Email" required></p>

    <hr>
    <p><button class="btn" type="submit" >Reset Password</button></p>
 
</form>
</div>
  