    
import { Component, OnInit } from '@angular/core';
import { PostsService } from '../posts.service';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.css']
})
export class ProgramsComponent implements OnInit {

  projects: any[];
  loadedProjectList: any[];
  p: Number = 1;

  constructor(private postsService: PostsService, public service: AuthService, private spinner: NgxSpinnerService) { 
    
    this.spinner.show();
    //get all projects
    this.postsService.postGetAllProjects().subscribe(projects => {
      this.projects = projects;
      this.loadedProjectList = projects;
      this.spinner.hide();        

    });
  }

  
  getProjectsByNewDate(){
    this.postsService.postGetProjectsByNewDate().subscribe(projects => {
        this.projects = projects;
      });
  }

  getProjectsByOldDate(){
    this.postsService.postGetProjectsByOldDate().subscribe(projects => {
        this.projects = projects;
      });
  }

  ngOnInit() {
   
  }

  initializeItems(): void {
    this.projects = this.loadedProjectList;
  }

  search(event) {
    
    this.initializeItems();
    //Set searchTerm with input from search bar
    const searchTerm = event.srcElement.value;
  
    if (!searchTerm) {
    return;
    }
    //Filters enquiries list with keywords from searchTerm
    this.projects = this.projects.filter(i => {
    if (i.title && searchTerm) {
    if (i.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
    return true;
    }
    return false;
    }
    });
    }

  
}