<br>
<br>
<br>
<br>
<br>
 <div class="container">
  <br>
  <div class="section-title">    
    <h2> My Projects</h2>
    <br>
    <h5 *ngIf="projectsArray.length == 0">{{msg}}</h5>
  </div>
<!--Section: Block Content-->
<section>

  <!--Grid row-->
  <div class="row">

    <!--Grid column-->
    <div class="col-lg-12">

      <!-- Card -->
      <div class="mb-3">
        <div class="pt-4 wish-list">
    
          <div *ngFor="let project of projectsArray;let i = index">
          <div class="row mb-4">
            <div class="col-md-5 col-lg-3 col-xl-3">
              <div class="view zoom overlay z-depth-1 rounded mb-3 mb-md-0">
                <img class="img-fluid w-300" src={{project.image}}   width="500" alt="Sample">
              </div>
            </div>
            <div class="col-md-7 col-lg-9 col-xl-9">
              <div>
                <div class="d-flex justify-content-between">
                  <div>
                    <h5>{{project.title}}</h5>
                    <p class="mb-3 text-muted text-uppercase small">Project Date - {{project.publish  | date : 'dd/MM/yyyy' }}</p>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <button type="button" class="btn btn-success" [routerLink]="['/projectInfo',project._id]" >View Details</button>
                    <!-- <button type="button" class="btn btn-danger" (click)="removeProject(project._id)" >Cancel</button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <!-- Card -->

    </div>
    <!--Grid column-->

  </div>
  <!-- Grid row -->

</section>
<!--Section: Block Content-->
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
