import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service'; 
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PostsService } from '../posts.service';
import { Router } from '@angular/router'; 
import { ConnectionService } from '../connection.service';

@Component({
	selector: 'app-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

	elementType = 'url';
	value: any;
	checkOutForm: FormGroup;
	formProcess: boolean;
	userGCA: number;
	totalAmount: any;
	programID: string;
	carts: any = [];
	itemName: string;
	price: number;
	amount: number;
	category: string;
	image: string;
	quantity: number;
	order_date: Date = new Date();
	emailContent: any = [];
	supplierEmailContents: any = [];
	submitted: boolean;
	itemID: string;
	dataToken: any;
	discountPrice: number;
	loginInUserDetails: any;
	userEmail: any;
	loginUserDetails: any;
	supplier: any;
	paidWithGC: boolean;
	shippingMethod: string;
	_id: string;
	supplierTotal: number =0;
	greenCurrencyUsed:number;

  constructor(private postsService: PostsService,private fb: FormBuilder,private toastr: ToastrService, private router: Router,private authService: AuthService,private connectionService: ConnectionService) {
	//Get values passed from cart.component.ts
	const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {total: number, ID: any, userGCA: number, shippingMethod: string};
    this.userGCA = state.userGCA;
    this.totalAmount = state.total.toFixed(2);
    this.programID = state.ID;
    this.shippingMethod = state.shippingMethod;
	//Get currenty login user info
    this.authService.postGetUserDetails(this.authService.getUserID()).subscribe(userInfo => {
      this.loginUserDetails = userInfo;

      	this.userEmail = this.loginUserDetails[0].email
	//Get send email to login user to pay with paynow	
      this.connectionService.generatePaynowQRCode(this.totalAmount, this.userEmail,this.authService.getSecureToken()).subscribe(
        data  => {
			//the total amount of selected item in cart stored in a paynow qrcode
          	this.value = data["paynowQR"];
			//unique token generated in order for customer to checkout, they'll have enter it in checkout.component.html
			//billReferenceNumber input field before they can checkout.
			this.dataToken = data["token"];
        },
        error  => { 
          console.log("Error", error);
      });
	});
	// get user cart
    this.postsService.postGetCart(this.authService.getUserID()).subscribe(carts => {
      this.carts = carts;
    });


    
  }

  ngOnInit(): void {
    this.checkOutForm = this.fb.group({
      fullname: ['', Validators.required],
      email: ['', [Validators.required,Validators.email]],
      phone: ['', [Validators.required,Validators.minLength(8),Validators.maxLength(8)]],
      address: ['',[Validators.required,Validators.minLength(6),Validators.maxLength(6)]],
      radioBtn:['',Validators.required],
      unitNo:'',
      billReferenceNumber:['',Validators.required],
      tnc: ['', Validators.required]
    });
    //hide terms and conditions and privacy policy by default
    var moreText = document.getElementById("more");
    moreText.style.display = "none";
    var more2Text = document.getElementById("more2");
    more2Text.style.display = "none";
  }
  	// Remove selected item from cart if they paid for it
	clearSelectedItem() {
		this.postsService.postClearSelectedItem(this.authService.getUserID()).subscribe(results => {
			this.router.navigateByUrl('/order').then(() => {
				location.reload();
			});
		});
	}
	
	// Add user to programs if they paid for it
	addToPrograms() {
		var userName = this.authService.getSecureToken();
		for (var i = 0; i < this.programID.length; i++) {
			this.postsService.addToPrograms(this.programID[i], userName).subscribe(results => {});
		}
	}
	// Show or hide text for terms and conditions
	showText() {
		var dots = document.getElementById("dots");
		var moreText = document.getElementById("more");
		var tncText = document.getElementById("myTNC");
	
		if (dots.style.display === "none") {
			dots.style.display = "inline";
			tncText.innerHTML = "Terms and Conditions";
			moreText.style.display = "none";
		} else {
			dots.style.display = "none";
			tncText.innerHTML = "Terms and Conditions";
			moreText.style.display = "inline";
		}
	}

	// show or hide text for privacy policy
	showText2() {
		var dots2 = document.getElementById("dots2");
		var more2Text = document.getElementById("more2");
		var policyText = document.getElementById("myPolicy");
		
		if (dots2.style.display === "none") {
			dots2.style.display = "inline";
			policyText.innerHTML = "Privacy Policy"; 
			more2Text.style.display = "none";
		} else {
			dots2.style.display = "none";
			policyText.innerHTML = "Privacy Policy"; 
			more2Text.style.display = "inline";
		}
		}
	
	onSubmit() {
		var productArray: any = [];
		var supplierEmailArray: any =[];
		var productList: any = [];
		var unitNo: any;
		var userHouseLoc: any;
	
		if (this.checkOutForm.value.fullname == "" || this.checkOutForm.value.email == "" || this.checkOutForm.value.phone == "" || this.checkOutForm.value.address == "" || this.checkOutForm.value.radioBtn == "" || this.checkOutForm.value.billReferenceNumber == "" || this.checkOutForm.value.tnc == "") {
			this.toastr.warning('Please fill in all input fields and agree with the terms and conditions & privacy policy!', 'Warning');
		} else if (this.checkOutForm.controls.email.hasError('email')) {
			this.toastr.warning('Invalid email!', 'Warning');
		} else if (/[\d]{8}/.test(this.checkOutForm.value.phone) == false) {
			this.toastr.warning('Invalid phone number format!', 'Warning');
		} else if (this.checkOutForm.controls.address.hasError('minlength') || this.checkOutForm.controls.address.hasError('maxlength')) {
			this.toastr.warning('Invalid postal code!', 'Warning');
		} else if (this.checkOutForm.value.billReferenceNumber.trim() != this.dataToken) {
			this.toastr.warning('Invalid Bill Reference Number!', 'Warning');
		} else if (confirm('Is your billing details accurate?')) {
			// get your address based on postal code entered
			this.connectionService.getLocationByPostalCode(this.checkOutForm.value.address).subscribe(location => {
				for (var i = 0; i < this.carts.length; i++) {
					var deductedAmount: number = 0;
					var deductedAmount1: number = 0;
					var total1 = 0;
					var total2 = 0;
					var total3 = 0;
					var total4 = 0;
					var greenCurrency1 = 0;
				    var greenCurrency2 = 0;
					this._id = this.carts[i]._id;
					this.itemID = this.carts[i].itemID;
					this.itemName = this.carts[i].name;
					productArray.push(this.carts[i].name);
					this.price = this.carts[i].price;
					this.discountPrice = this.carts[i].discount_price;
					this.category = this.carts[i].category;
					this.quantity = this.carts[i].quantity;
					this.supplier = this.carts[i].supplier;
					delete this.supplier.companyLogo
					this.paidWithGC = this.carts[i].paidWithGC;
					
					supplierEmailArray.push({"itemName":this.carts[i].name, "itemPrice": this.carts[i].price, "itemDiscountPrice": this.carts[i].discount_price, "itemQuantity": this.carts[i].quantity, "supplierName": this.carts[i].supplier.companyName, "supplierEmail": this.carts[i].supplier.sellersEmail, "paidWithGC": this.carts[i].paidWithGC, "maxGreenCurrency": this.carts[i].maxGreenCurrency, "selected": this.carts[i].selected, "pickupLocation":this.carts[i].supplier.pickupLocation, "deliveryFee": this.carts[i].supplier.deliveryFee});
	                //check whether this item has discount price
					if (this.carts[i].discount_price != null) {
						//check whether user used green currency for this item
						if (this.carts[i].paidWithGC == true) {
							deductedAmount = this.carts[i].discount_price * this.carts[i].quantity * this.carts[i].maxGreenCurrency / 100
							deductedAmount1 = Math.round((deductedAmount + Number.EPSILON) * 100) / 100
	
							total1 += (this.carts[i].discount_price * this.carts[i].quantity - deductedAmount1);
							greenCurrency1 = this.carts[i].discount_price * this.carts[i].maxGreenCurrency;
						} else {
							total2 += this.carts[i].discount_price * this.carts[i].quantity;
						}
					} else {
						//check whether user used green currency for this item
						if (this.carts[i].paidWithGC == true) {
							deductedAmount = this.carts[i].price * this.carts[i].quantity * this.carts[i].maxGreenCurrency / 100
							deductedAmount1 = Math.round((deductedAmount + Number.EPSILON) * 100) / 100
							total3 += (this.carts[i].price * this.carts[i].quantity - deductedAmount1);
							greenCurrency2 = this.carts[i].price * this.carts[i].maxGreenCurrency;
						} else {
							total4 += this.carts[i].price * this.carts[i].quantity;
						}
					}
				
					this.amount = total1 + total2 + total3 + total4;
					this.greenCurrencyUsed = greenCurrency1 + greenCurrency2;
	
					if (location["results"][0] == null) {
						this.toastr.warning('Invalid Postal Code', 'Warning');
						return;
					} else if (this.checkOutForm.value.radioBtn == "Landed Housing") {
						userHouseLoc = location["results"][0]["ADDRESS"];
						// create a new order for the selected item
						if (this.carts[i].selected) {
					
							this.postsService.addOrderPaynow(this.authService.getUserID(), this.itemID, this.itemName, this.price, this.discountPrice, this.category, this.quantity, this.amount, this.checkOutForm.value.fullname, this.checkOutForm.value.email, this.checkOutForm.value.phone, userHouseLoc, "not applicable", this.checkOutForm.value.radioBtn, this.order_date, this.checkOutForm.value.billReferenceNumber, this.supplier, "Processing", this.paidWithGC, this.greenCurrencyUsed, this.shippingMethod).subscribe(results => {
								this.toastr.success("Successfully ordered!", 'Success!');
								this.clearSelectedItem();
								this.authService.useGC(this.authService.getUserID(), this.userGCA);
	
							});
						}
					} else {
	
						if (/^#[\d]{2}-[\d]{2,4}$/.test(this.checkOutForm.value.unitNo) == false) {
							this.toastr.warning('Invalid unit no format', 'Warning');
							return
						} else {
							// create a new order for the selected item
							if (this.carts[i].selected) {
								userHouseLoc = location["results"][0]["ADDRESS"];
								this.postsService.addOrderPaynow(this.authService.getUserID(), this.itemID, this.itemName, this.price, this.discountPrice, this.category, this.quantity, this.amount, this.checkOutForm.value.fullname, this.checkOutForm.value.email, this.checkOutForm.value.phone, userHouseLoc, this.checkOutForm.value.unitNo, this.checkOutForm.value.radioBtn, this.order_date, this.checkOutForm.value.billReferenceNumber, this.supplier, "Processing", this.paidWithGC, this.greenCurrencyUsed, this.shippingMethod).subscribe(results => {
									this.toastr.success("Successfully ordered!", 'Success!');
									this.clearSelectedItem();
									this.authService.useGC(this.authService.getUserID(), this.userGCA);
	
								});
							}
						}
	
					}
	
				}
	            // add user to the program if they have paid
				this.addToPrograms();
	
				for (var i = 0; i < productArray.length; i++) {
					if (this.carts[i].selected == true) {
						productList += "<li>" + productArray[i] + "  QTY: " + this.carts[i].quantity + "  Supplier: " + this.carts[i].supplier.companyName + "  Pickup Location: " + this.carts[i].supplier.pickupLocation + "</li>";
					}
				}
	
				if (this.checkOutForm.value.unitNo == "") {
					unitNo = 'not applicable';
				} else {
					unitNo = this.checkOutForm.value.unitNo;
				}

				var supplierProductList: any = []
				var supplierNames: any = []
				var supplierEmailNow: any;
				var supplierNameNow: any;
				var supplierDeliveryFee: any;
				for (var i = 0; i < supplierEmailArray.length; i++) {
					if(supplierNames.includes(supplierEmailArray[i].supplierName) == false){
						supplierNames.push(supplierEmailArray[i].supplierName);
					}
				}

				for(var j = 0; j < supplierNames.length; j++){
										  
						for(var i = 0; i < supplierEmailArray.length; i++) {
														
							if (supplierEmailArray[i].supplierName == supplierNames[j]) {
								
								if (supplierEmailArray[i].selected == true) {

										supplierProductList += '<li>' + supplierEmailArray[i].itemName + '  QTY: ' + supplierEmailArray[i].itemQuantity + '  Supplier: ' + supplierEmailArray[i].supplierName + '  Pickup Location: ' + supplierEmailArray[i].pickupLocation + '</li>';
										supplierEmailNow = supplierEmailArray[i].supplierEmail
										supplierNameNow = supplierEmailArray[i].supplierName;
										// if user select delivery then save the delivery fee
										if(this.shippingMethod == "Delivery"){
											supplierDeliveryFee = supplierEmailArray[i].deliveryFee;
											}
											else{
											supplierDeliveryFee = 0;
											}
										if (supplierEmailArray[i].itemDiscountPrice != null) {
											
											if (supplierEmailArray[i].paidWithGC == true) {
												var minusAmount = supplierEmailArray[i].itemDiscountPrice * supplierEmailArray[i].itemQuantity * supplierEmailArray[i].maxGreenCurrency / 100
												var minusAmount1 = Math.round((minusAmount + Number.EPSILON) * 100) / 100
					
												this.supplierTotal += (supplierEmailArray[i].itemDiscountPrice * supplierEmailArray[i].itemQuantity - minusAmount1);
											} 
											else {
												this.supplierTotal += supplierEmailArray[i].itemDiscountPrice * supplierEmailArray[i].itemQuantity;
					
											}
										} 
										else {
											
											if (supplierEmailArray[i].paidWithGC == true) {
												
												var minusAmount = supplierEmailArray[i].itemPrice * supplierEmailArray[i].itemQuantity * supplierEmailArray[i].maxGreenCurrency / 100
												
												var minusAmount1 = Math.round((minusAmount + Number.EPSILON) * 100) / 100
					
												this.supplierTotal += (supplierEmailArray[i].itemPrice * supplierEmailArray[i].itemQuantity - minusAmount1);
											} 
											else {
												this.supplierTotal += supplierEmailArray[i].itemPrice * supplierEmailArray[i].itemQuantity;
											}
										}
									}
								//console.log(this.supplierTotal)
								//console.log(supplierProductList);
							}

						}
						var supplierTotalAmount = this.supplierTotal + supplierDeliveryFee;
						this.supplierEmailContents.push(this.order_date.toISOString().slice(0, 10)); //0
						this.supplierEmailContents.push(supplierTotalAmount.toFixed(2)); //1
						this.supplierEmailContents.push(this.checkOutForm.value); //2
						this.supplierEmailContents.push(supplierProductList); //3
						this.supplierEmailContents.push(userHouseLoc); //4
						this.supplierEmailContents.push(unitNo); //5
						this.supplierEmailContents.push(this.checkOutForm.value.billReferenceNumber); //6
						this.supplierEmailContents.push(this.shippingMethod); //7
						this.supplierEmailContents.push(supplierEmailNow); //8
						this.supplierEmailContents.push(supplierNameNow); //9

						this.connectionService.sendMessageSupplierPayNow(this.supplierEmailContents).subscribe(() => {
						}, (error: any) => {
							//console.log('Error', error);
						});
					supplierProductList = [];
					supplierEmailNow ="";
					supplierNameNow =""
					this.supplierEmailContents = [];
					this.supplierTotal = 0;

				  }
				
	
				this.emailContent.push(this.order_date.toISOString().slice(0, 10)); //0
				this.emailContent.push(this.totalAmount); //1
				this.emailContent.push(this.checkOutForm.value); //2
				this.emailContent.push(productList); //3
				this.emailContent.push(userHouseLoc); //4
				this.emailContent.push(unitNo); //5
				this.emailContent.push(this.checkOutForm.value.billReferenceNumber); //6
				this.emailContent.push(this.shippingMethod);//7
	
				// sent to email
				this.connectionService.sendMessagePaynow(this.emailContent).subscribe(() => {
	
				}, (error: any) => {
					console.log('Error', error);
				});
	
			});
	
	
		} //End of if statement
	}
	
	}
