<br>
<br>
<br>
<br>
<br>

  <div class ="title" >
     <br>
    <h2>Contact Us</h2>
    
  </div>

    
    <div class="site-section site-section-sm">
      <div class="container">
        <div class="row">
       
          <div class="col-md-12 col-lg-8 mb-5">
          
            <form [formGroup]="contactForm"  (ngSubmit)="onSubmit()" class="p-5 bg-white" >
                <p>Please feel free to contact us by phone or email. Or alternatively fill in the form below, and will get back to you as soon as possible.</p>
                 <br>
			  
			  <div class="row form-group">
                <div class="col-md-12 mb-3 mb-md-0">
                  <label class="font-weight-bold" for="fullname">Fullname:</label>
                  <input type="text" id="fullname" formControlName="contactFormFullname" class="form-control" placeholder="Enter Fullname..." >
        
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <label class="font-weight-bold" for="email">Email:</label>
                  <input type="email" id="email" formControlName="contactFormEmail" class="form-control"  placeholder="Enter Email Address..." >
                </div>
              </div>


              <div class="row form-group">
                <div class="col-md-12 mb-3 mb-md-0">
                  <label class="font-weight-bold" for="subject">Subject:</label>
                  <input type="text" id="subject" formControlName="contactFormSubject" class="form-control" placeholder="Enter Subject..." >
                </div>
              </div>

              <div class="row form-group mb-4">
                <div class="col-md-12">
                  <label class="font-weight-bold" for="message">Message:</label> 
                  <textarea formControlName="contactFormMessage" id="message" cols="30" rows="6" class="form-control" placeholder="Enter Enquiries..."></textarea>
                </div>
              </div>
                <br>


              <div class="row form-group">
                <div class="col-md-12">
                    <a id="mail-link">
                  <input type="submit" value="Send Message" class="btn btn-primary pill text-white px-5 py-2"  >
                </a>
                </div>
              </div>

  
            </form>
          </div>

          <div class="col-lg-4">
            <div class="p-4 mb-3 bg-white">
              
              <!--Google map-->
              <div id="map-container-section" class="z-depth-1-half map-container-section mb-4" style="height: 400px">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15954.411703296295!2d103.8279127!3d1.4142648!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf54107044f38ea2e!2sOtolith%20Enrichment!5e0!3m2!1sen!2ssg!4v1590154670663!5m2!1sen!2ssg" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
              </div>

			
              <p class="mb-0 font-weight-bold">Address</p>
              <p class="mb-4"><i class="fa fa-map-marker">  91 Lor Chencharu, Singapore 769201</i></p>
              <p class="mb-4"><i class="fa fa-clock-o">  Mon - Fri, 9am - 6pm</i></p>

              <p class="mb-0 font-weight-bold">Phone</p>
              <p class="mb-4"><i class="fa fa-phone">  65 8223 1434</i></p>
              
              <p class="mb-0 font-weight-bold">Email Address</p>
              <p class="mb-0"><i class="fa fa-envelope">  info@otolithenrichment.com</i></p>

            </div>
            
            
          </div>
        </div>
      </div>
    </div>
    

   

    
  
      
