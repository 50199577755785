import { Component } from '@angular/core';
import { AuthService } from './auth.service';
import { PostsService } from './posts.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'MPWeb';
  productCatergoryName: any = [];
  validate: boolean = false;
  admin: boolean = false;

  constructor(public service: AuthService, private postsService: PostsService){
    this.postsService.postGetAllProducts().subscribe(products => {

      var productCatergory: any =[]
      for (var i = 0; i < products.length; i++){
        productCatergory.push(products[i].category); 
      }
      productCatergory = this.getWordCount(productCatergory);
      var entries1 = Object.entries(productCatergory);

      for (var i = 0; i < entries1.length; i++){
        this.productCatergoryName.push(entries1[i][0]);
      }
      const index = this.productCatergoryName.indexOf('Program');
      if (index > -1) {
        this.productCatergoryName.splice(index, 1);
      }
    });
    
  }

  getWordCount(array) {
    let map = {};
  for (let i = 0; i < array.length; i++) {
      let item = array[i];
      map[item] = (map[item] + 1) || 1;
    }
    return map;
  }

   // Accordion 
 myAccFunc() {
  var x = document.getElementById("demoAcc");
  if (x.className.indexOf("w3-show") == -1) {
    x.className += " w3-show";
  } else {
    x.className = x.className.replace(" w3-show", "");
  }
}

myAccFunc2() {
  var x = document.getElementById("demoAcc2");
  if (x.className.indexOf("w3-show") == -1) {
    x.className += " w3-show";
  } else {
    x.className = x.className.replace(" w3-show", "");
  }
}

myAccFunc3() {
  var x = document.getElementById("demoAcc3");
  if (x.className.indexOf("w3-show") == -1) {
    x.className += " w3-show";
  } else {
    x.className = x.className.replace(" w3-show", "");
  }
}

myAccFunc4() {
  var x = document.getElementById("demoAcc4");
  if (x.className.indexOf("w3-show") == -1) {
    x.className += " w3-show";
  } else {
    x.className = x.className.replace(" w3-show", "");
  }
}

myAccFunc5() {
  var x = document.getElementById("demoAcc5");
  if (x.className.indexOf("w3-show") == -1) {
    x.className += " w3-show";
  } else {
    x.className = x.className.replace(" w3-show", "");
  }
}

// Open and close sidebar
w3_open() {
  document.getElementById("mySidebar").style.display = "block";
  document.getElementById("myOverlay").style.display = "block";
}

w3_close() {
  document.getElementById("mySidebar").style.display = "none";
  document.getElementById("myOverlay").style.display = "none";
}


}
